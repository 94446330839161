import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";
import NoData from "../../../components/common/NoData";
import Loading from "../../../components/common/Loading";
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../Config/FbConfig';
import { Styles } from "../styles/Alumni";

export default class DepartmentAlumniActivities extends Component {

    state = {
        displayModal: false,
        modalImage: "",
        modalName: "",
        modalDesignation: "",
        modalMsg: "",
        Datas: []
    }

    //Display Modal
    displayModal = (e) => {
        this.setState({
            displayModal: !this.state.displayModal,
        })
    }

    //Display Content
    contentModal = (e) => {
        var result = this.state.Datas.filter((x) => x.Id === e.target.id);
        this.setState({
            displayModal: !this.state.displayModal,
            modalImage: result[0]["Image"],
            modalName: result[0]["Name"],
            modalDesignation: result[0]["Designation"],
            modalMsg: result[0]["Description"]
        })
    }

    //Load Data Here
    async componentDidMount() {
        const docRef = doc(firestore, this.props.match.params.id, "AlumniList");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key }, value))
            });
            Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data })
        } else {
            this.setState({
                Datas: "NO_999"
            })
        }
    }

    render() {
        const banner = {
            cse: <Banner title="Computer Science and Engineering" image="banners/department-cse.jpg" />,
            ise: <Banner title="Information Science and Engineering" image="banners/department-ise.jpg" />,
            ai: <Banner title="Artificial Intelligence and Machine Learning" image="banners/department-ai.jpg" />,
            ece: <Banner title="Electronics and Communication Engineering" image="banners/department-ece.jpg" />,
            mech: <Banner title="Mechanical Engineering" image="banners/department-me.jpg" />,
            civil: <Banner title="Civil Engineering" image="banners/department-me.jpg" />,
            eee: <Banner title="Electrical and Electronics Engineering" image="banners/department-eee.jpg" />,
            ete: <Banner title="Electronics and Telecommunication Engineering" image="banners/department-ete.jpg" />,
            eie: <Banner title="Electronics and Instrumentation Engineering" image="banners/department-eie.jpg" />,
            iem: <Banner title="Industrial Engineering and Management" image="banners/department-iem.jpg" />,
            mba: <Banner title="Master of Business Administration" image="banners/department-mba.jpg" />,
            mca: <Banner title="Master of Computer Applications (MCA)" image="banners/department-mca.jpg" />,
            phy: <Banner title="Physics" image="banners/department-phy.jpg" />,
            chem: <Banner title="Chemistry" image="banners/department-che.jpg" />,
            math: <Banner title="Mathematics" image="banners/department-math.jpg" />,
            hum: <Banner title="Humanities" image="banners/department-hum.jpg" />,
        }

        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                {banner[this.props.match.params.id]}

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Alumni</h4>
                                    </div>
                                </div>
                                <Styles>
                                    <div className="container-xl">
                                        <div className="ttm-service-description">
                                            <div className="row ttm-service-msg">
                                                {
                                                    this.state.Datas.length === 0 ? <Loading /> :
                                                        this.state.Datas === "NO_999" ? <NoData /> :
                                                            this.state.Datas.map((data, i) => (
                                                                <div className="col-6 card" style={{ marginBottom: "2rem" }} key={i}>
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <a onClick={this.contentModal}><img id={data.Id} className="img-fluid" alt="" src={data.Image} /></a>
                                                                        </div>
                                                                        <div className="col-6" style={{ padding: "1rem" }}>
                                                                            <div className="featured-title">
                                                                                <h6>{data.Name}</h6>
                                                                                <small>{data.Designation}</small>
                                                                            </div>
                                                                            <div className="post-meta">
                                                                                <p>{data.Description}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Styles>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department={this.props.match.params.id} />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal */}
                <Modal size="lg" show={this.state.displayModal} onHide={this.displayModal} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-5">
                                <Styles><img className="img-fluid modal-img" alt="" src={this.state.modalImage} /></Styles>
                            </div>
                            <div className="col-7" style={{ padding: "1rem" }}>
                                <div className="featured-title">
                                    <h5 style={{ color: "#98002e" }}>{this.state.modalName}</h5>
                                    <small>{this.state.modalDesignation}</small>
                                </div>
                                <div className="post-meta justify">
                                    <p>{this.state.modalMsg}</p>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
