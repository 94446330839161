import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Styles } from "./styles/iconBox.js";

class IconBox extends Component {
    render() {

        const Datas = [
            {
                "id": "1",
                "uniqClass": "box-icon box1",
                "boxIcon": "fas fa-chalkboard-teacher",
                "title": "Faculty Login",
                "subTitle": "Upload and Review academic datas.",
                "link": "http://bitwebserver.bittechlearn.online:8084",
                "type": "Anchor"
            },
            {
                "id": "2",
                "uniqClass": "box-icon box2",
                "boxIcon": "fas fa-book-reader",
                "title": "Student Login",
                "subTitle": "Personalized academic information.",
                "link": "http://bitwebserver.bittechlearn.online:8084/Students/StudentLogin.aspx",
                "type": "Anchor"
            },
            {
                "id": "3",
                "uniqClass": "box-icon box3",
                "boxIcon": "fas fa-user-graduate",
                "title": "Placement Registration",
                "subTitle": "Gateway for student's - Great career path",
                "link": "/placement-about",
                "type": "Link"
            }
        ]

        return (
            <Styles>
                <section className="icon-box-area">
                    <Container>
                        <Row>
                            {
                                Datas.map((data, i) => (
                                    data.type === "Anchor" ?
                                        <Col md="4" key={i}>
                                            <a href={data.link} target="_blank">
                                                <div className="full-icon-box">
                                                    <div className="icon-box d-flex">
                                                        <div className={data.uniqClass}>
                                                            <i className={data.boxIcon}></i>
                                                        </div>
                                                        <div className="box-title">
                                                            <h6>{data.title}</h6>
                                                            <p>{data.subTitle}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </Col>
                                        :
                                        <Col md="4" key={i}>
                                            <Link to={process.env.PUBLIC_URL + data.link}>
                                                <div className="full-icon-box">
                                                    <div className="icon-box d-flex">
                                                        <div className={data.uniqClass}>
                                                            <i className={data.boxIcon}></i>
                                                        </div>
                                                        <div className="box-title">
                                                            <h6>{data.title}</h6>
                                                            <p>{data.subTitle}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                ))
                            }
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default IconBox
