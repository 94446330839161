import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class ExploreSidebar extends Component {
  render() {
    return (
      <div>
        <div className="widget widget-nav-menu">
          <ul className="widget-menu">
            <li>
              <NavLink
                className="nav-link"
                to={process.env.PUBLIC_URL + "/explore-rvs"}
              >
                Management - RVS
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav-link"
                to={process.env.PUBLIC_URL + "/explore-about"}
              >
                About BIT
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav-link"
                to={process.env.PUBLIC_URL + "/explore-highlights"}
              >
                Highlights of BIT
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav-link"
                to={process.env.PUBLIC_URL + "/explore-chairman"}
              >
                Chairman - Governing Council
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav-link"
                to={process.env.PUBLIC_URL + "/explore-principal"}
              >
                Principal
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav-link"
                to={process.env.PUBLIC_URL + "/explore-vice-principal"}
              >
                Vice Principal
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav-link"
                to={process.env.PUBLIC_URL + "/explore-administration"}
              >
                Administrative Staff
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav-link"
                to={process.env.PUBLIC_URL + "/explore-mc"}
              >
                Mandatory Committees
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav-link"
                to={process.env.PUBLIC_URL + "/explore-gc"}
              >
                Governance Committees
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav-link"
                to={process.env.PUBLIC_URL + "/explore-bestpractices@bit"}
              >
                Best Practices @ BIT
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav-link"
                to={process.env.PUBLIC_URL + "/explore-rules"}
              >
                Rules and Regulations
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
