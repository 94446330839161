import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class ISECOE extends Component {

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Information Science and Engineering" image="banners/department-ise.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title"> Center of Excellence</h4>
                                    </div>
                                </div>
                                <div className="ttm-service-description justify">
                                    <p>The CoE of Department of Information Science and Engineering will leverage Information Processing and Computing Technologies to solve problems related to education, enterprises and society. Through this initiative, the center aims to use technology to address the challenges faced in industry and social segment.</p><br />
                                    <h6>The primary objectives of IPCT are:</h6><br />
                                    <ul className="ttm-list ttm-list-style-icon">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Empowering the young engineering minds to meet the challenges in the Information Processing and Computing Technologies field.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Advancement of laboratories for research, design, implementation and training.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Certification Courses to Internal and External Students, Faculty and Industry persons.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Collaboration, Consultancy and Intellectual Property with industry.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Formation of technology incubation center.</li>
                                    </ul>
                                </div>
                                <div className="ttm-service-description justify paddingtop-1">
                                    <h6>The domains under IPCT Centre of Excellence are:</h6><br />
                                    <ul className="ttm-list ttm-list-style-icon">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Data Sciences and Computing</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Internet of Things</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Artificial Intelligence and Machine learning</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Blockchain Technologies</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Advanced Web Technologies</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Cloud Computing</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Data Communication & Networking</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Programming Skills and Software design</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Automation</li>
                                    </ul>
                                </div>
                                <div className="ttm-service-description justify paddingtop-1">
                                    <h6>system, IoT and Robotics are:</h6><br />
                                    <p>The department has signed MoU with SS Technologies, Bangalore, to set up Internet of Things (IoT) related laboratory for students and research activities. With this facility, student and faculty get hands-on training on various tools and technologies of IoT.
                                    In collaboration with team of SS Technologies, the proposed projects to build on Embedded</p><br />
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <ul className="ttm-list ttm-list-style-icon">
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Basics of GPIO</li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Humidity Monitoring</li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Home Automation</li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Smart Irrigation</li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Cloud Related Projects</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6">
                                            <ul className="ttm-list ttm-list-style-icon">
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Robotic Car</li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Bluetooth Controlled Car</li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Line Following Bot</li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Edge Detection Bot</li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Obstacle Avoiding Bot</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="ttm-service-description justify paddingtop-1">
                                    <h6>The department has also signed MoU with Albertson’s Technologies, Bangalore with the following objectives.</h6><br />
                                    <ul className="ttm-list ttm-list-style-icon">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Train the Trainer</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Upgrading the students with advanced technologies through internships/projects</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Consultancy</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Intellectual Property Creation.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="ise" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
