import React, { Component } from "react";
import { Link } from "react-router-dom";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class MBAPrograms extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Master in Business Administration(MBA)" image="banners/department-mba.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Post Graduate Program</h4>
                                        </div>
                                    </div>
                                    <table className='table'>
                                        <tbody>
                                            <tr>
                                                <th>MBA</th>
                                                <td>Department of Management Studies</td>
                                            </tr>
                                            <tr>
                                                <th>Started in year</th>
                                                <td>2006</td>
                                            </tr>
                                            <tr>
                                                <th>Intake</th>
                                                <td>120 students</td>
                                            </tr>
                                            <tr>
                                                <th>For Admission</th>
                                                <td><Link to={process.env.PUBLIC_URL + "/admission-mtech"}>click here</Link></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="ttm-service-single-content-area paddingtop-2">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Ph.D Program</h4>
                                        </div>
                                    </div>
                                    <table className='table'>
                                        <tbody>
                                            <tr>
                                                <th>Name of Ph. D guide</th>
                                                <th>Area of research</th>
                                            </tr>
                                            <tr>
                                                <th>Dr. MAHESH K</th>
                                                <td>Finance and Marketing</td>
                                            </tr>
                                            <tr>
                                                <th>Dr. CHAITRA BOCHEER K S</th>
                                                <td>Marketing</td>
                                            </tr>
                                            <tr>
                                                <th>Dr. K. V DEEPAK</th>
                                                <td>Marketing</td>
                                            </tr>
                                            <tr>
                                                <th>Dr. R. P PRAKASH</th>
                                                <td>Finance</td>
                                            </tr>
                                            <tr>
                                                <th>Dr. NALINA R</th>
                                                <td>Human Resource Management</td>
                                            </tr>
                                            <tr>
                                                <th>For Admission</th>
                                                <td><Link to={process.env.PUBLIC_URL + "/admission-phd"}>click here</Link></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="mba" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
