import React, { Component } from 'react'

export default class Primelocation extends Component {
    render() {
        return (
            <div>
                <div className="section-title with-desc text-center clearfix paddingtop-2">
                    <div className="title-header">
                        <h2 className="title">BIT @ <b style={{color:"#98002e"}}>Bengaluru</b></h2>
                    </div>
                </div>
                {/* Google Map */}
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.2605439615168!2d77.57184291526981!3d12.955172818742763!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1592715c4e7f%3A0x7dfaf94e52204678!2sBangalore%20Institute%20of%20Technology!5e0!3m2!1sen!2sin!4v1636476942578!5m2!1sen!2sin" width="100%" height="350" style={{border:"0"}} allowfullscreen="" loading="lazy"></iframe>
            </div>
        )
    }
}
