import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class CHECOE extends Component {

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Chemistry" image="banners/department-che.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Center of Excellence</h4>
                                    </div>
                                    <div>
                                        <p className="justify">The Department of Chemistry has Centre of Excellence (COE) in “Alternate Energy Resources,
                                            Materials Science and Environment Sustainability”. The main objective of COE is to foster innovations through Research and Development activities in specific focus interdisciplinary areas.</p><br />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-12 justify">
                                            <ul className="ttm-list ttm-list-style-icon">
                                                <li><i className="fa fa-chevron-circle-right ttm-textcolor-skincolor  " /><span className="ttm-list-li-content"><b>Research priority areas:</b></span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content"> Bio fuels (Bio ethanol, Bio diesel)</span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Synthetic petrol</span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Non carbon containing fuels</span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Hydrogen as a Fuel</span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Solar photovoltaic cells- Si Based - First Generation, </span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Organic conducting polymer- Second Generation, </span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Dye sensitized PV cells- Third Generation, </span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Thin film - Fourth Generation</span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Fuel Cells- Hydrogen FC, MOFC, SOFC, Fuel Cell EVs and Battery EVs.</span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Sewage treatment under Microwave irradiation</span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Usage of  waste plastic in concrete</span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Low cost water purifier- Nanomaterial based</span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Low cost water purification - Based on water quality</span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Chemical Sensors for monitoring of pollutants</span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">New class of materials:</span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Synthesis, Characterization, property study & Applications of nano based materials related to Alternate Energy Resources and Environment Sustainability.</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="chem" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
