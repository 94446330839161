import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/mobileMenu.js";

export default class MobileMenu extends Component {
  menu = (e) => {
    // Mobile Menu
    const hmBtn = document.getElementById("mb-sidebar-btn");

    const mdSidebarOverlay = document.getElementById("mb-sidebar-overlay");
    const mdSidebarBody = document.getElementById("mb-sidebar-body");

    if (e.target.id === "mb-sidebar-btn") {
      mdSidebarOverlay.classList.toggle("visible");
      mdSidebarBody.classList.toggle("opened");
    } else {
      mdSidebarOverlay.classList.remove("visible");
      mdSidebarBody.classList.remove("opened");
    }
  };

  render() {
    return (
      <Styles>
        {/* Mobile Menu */}
        <section className="mobile-menu-area">
          <Container>
            <Row>
              <Col md="0" sm="12">
                <div className="mb-logo-area d-flex justify-content-between">
                  <div className="mb-logo-box d-flex">
                    <div className="mb-logo">
                      <Link to={process.env.PUBLIC_URL + "/"}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/general/Logo.png"
                          }
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="hm-button">
                      <i
                        className="fa fa-bars"
                        onClick={this.menu}
                        id="mb-sidebar-btn"
                      ></i>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Mobile Menu Sidebar */}
        <section className="mb-sidebar" id="mb-sidebar-body">
          <div className="mb-sidebar-heading d-flex justify-content-between">
            <div>
              <h5>Menu</h5>
            </div>
            <div onClick={this.menu} id="close-mb-sidebar">
              <i className="fa fa-window-close"></i>
            </div>
          </div>
          <div className="mb-sidebar-menu" onClick={this.menu}>
            <Link className="mb-menu-item" to={process.env.PUBLIC_URL + "/"}>
              Home
            </Link>
            <Link
              className="mb-menu-item"
              to={process.env.PUBLIC_URL + "/teaching-and-learning"}
            >
              Teaching - Learning
            </Link>
            <Link
              className="mb-menu-item"
              to={process.env.PUBLIC_URL + "/e-learning-online-courses"}
            >
              e-Learning Corner
            </Link>
            <a
              className="mb-menu-item"
              href="https://www.shoonyeka.com/bit-library-production/"
              target="_blank"
            >
              Library
            </a>
            <Link
              className="mb-menu-item"
              to={process.env.PUBLIC_URL + "/achievers-academics"}
            >
              Achievers
            </Link>
            <Link
              className="mb-menu-item"
              to={process.env.PUBLIC_URL + "/alumni"}
            >
              Alumni
            </Link>
            <Link
              className="mb-menu-item"
              to={process.env.PUBLIC_URL + "/admission-be"}
            >
              Admission
            </Link>
            <Link
              className="mb-menu-item"
              to={process.env.PUBLIC_URL + "/explore-rvs"}
            >
              ABOUT US
            </Link>
            <Link
              className="mb-menu-item"
              to={process.env.PUBLIC_URL + "/department-list"}
            >
              ACADEMICS
            </Link>
            <Link
              className="mb-menu-item"
              to={process.env.PUBLIC_URL + "/research-bit"}
            >
              RESEARCH
            </Link>
            <Link
              className="mb-menu-item"
              to={process.env.PUBLIC_URL + "/placement-about"}
            >
              PLACEMENT
            </Link>
            <Link
              className="mb-menu-item"
              to={process.env.PUBLIC_URL + "/life@bit-bitcampus"}
            >
              LIFE @ BIT
            </Link>
            <Link
              className="mb-menu-item"
              to={process.env.PUBLIC_URL + "/approvals-nba"}
            >
              ACCREDITATION AND APPROVALS
            </Link>
            <Link
              className="mb-menu-item"
              to={process.env.PUBLIC_URL + "/approvals-nirf"}
            >
              NIRF
            </Link>
            <Link
              className="mb-menu-item"
              to={process.env.PUBLIC_URL + "/gallery"}
            >
              GALLERY
            </Link>
            <Link
              className="mb-menu-item"
              to={process.env.PUBLIC_URL + "/contact"}
            >
              Contact
            </Link>
          </div>
        </section>
        <div
          className="mb-sidebar-overlay"
          onClick={this.menu}
          id="mb-sidebar-overlay"
        ></div>
      </Styles>
    );
  }
}
