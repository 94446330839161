import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import BackToTop from "./BackToTop";
import { Styles } from "./styles/footer.js";

function FooterTwo() {
  return (
    <Styles>
      <footer
        className="footer2"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/footer-bg.jpg)`,
        }}
      >
        <Container>
          <Row>
            <Col md="3">
              <div className="f-newsletter">
                <div className="box-content">
                  <h5>Get in Touch</h5>
                  <p>
                    Bangalore Institute of Technology,
                    <br />K R Road, V V Pura, Bengaluru, Karnataka, India -
                    560004
                  </p>
                  <p>
                    Affiliated to VTU,
                    <br />
                    Approved by AICTE and UGC,
                    <br />
                    Accredited by NBA
                    <br />
                    Accredited by NAAC with A+ Grade
                    <br />
                    CET Code: E008
                    <br />
                    COMED - K Code: E019
                    <br />
                    PGCET Code: MBA - B131, MCA - C411,
                    <br />
                    M.Tech - T812.
                  </p>
                  <p>
                    principalbit4@gmail.com
                    <br />
                    principal@bit-bangalore.edu.in
                  </p>
                  <p>+91 080 26615865</p>
                </div>
              </div>
            </Col>
            <Col md="7">
              <div className="f-links">
                <h5>Quick Links</h5>
                <Row>
                  <Col>
                    <ul className="list-unstyled">
                      <li>
                        <Link to={process.env.PUBLIC_URL + "/admission-be"}>
                          <i className="las la-angle-right"></i>Admissions
                        </Link>
                      </li>
                      <li>
                        <Link to={process.env.PUBLIC_URL + "/placement-about"}>
                          <i className="las la-angle-right"></i>Placements
                        </Link>
                      </li>
                      <li>
                        <Link to={process.env.PUBLIC_URL + "/department-list"}>
                          <i className="las la-angle-right"></i>Departments
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={process.env.PUBLIC_URL + "/teaching-and-learning"}
                        >
                          <i className="las la-angle-right"></i>Teaching and
                          Learning
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={process.env.PUBLIC_URL + "/life@bit-bitcampus"}
                        >
                          <i className="las la-angle-right"></i>Life @BIT
                        </Link>
                      </li>
                      <li>
                        <Link to={process.env.PUBLIC_URL + "/downloads"}>
                          <i className="las la-angle-right"></i>Downloads
                        </Link>
                      </li>
                    </ul>
                  </Col>
                  <Col>
                    <ul className="list-unstyled">
                      <li>
                        <a href="http://rvsangha.org/" target="_blank">
                          <i className="las la-angle-right"></i>Rajya
                          Vokkaligara Sangha
                        </a>
                      </li>
                      <li>
                        <a
                          href={`${
                            process.env.PUBLIC_URL +
                            "/assets/pdf/Home/Scholorship.pdf"
                          }`}
                          target="_blank"
                        >
                          <i className="las la-angle-right"></i>Scholarships
                        </a>
                      </li>
                      <li>
                        <Link
                          to={process.env.PUBLIC_URL + "/life@bit-studentclubs"}
                        >
                          <i className="las la-angle-right"></i>Student Clubs
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={
                            process.env.PUBLIC_URL + "/life@bit-Sportsandgames"
                          }
                        >
                          <i className="las la-angle-right"></i>Sports
                        </Link>
                      </li>
                      <li>
                        <Link to={process.env.PUBLIC_URL + "/alumni"}>
                          <i className="las la-angle-right"></i>Alumni
                        </Link>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md="2">
              <div className="f-newsletter">
                <h5 style={{ textAlign: "center" }}>Bitannica</h5>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/general/Bitannica.png"
                  }
                  alt=""
                  className="img-fluid"
                />

                <form
                  id="form4"
                  className="form paddingtop-2"
                  style={{ textAlign: "center" }}
                >
                  <a
                    href="https://drive.google.com/file/d/1jBlkejLidWJXwnjL7xmPO7kcHtQAU_dD/view"
                    target="_blank"
                    style={{ color: "white" }}
                  >
                    Download Soft Copy
                  </a>
                </form>
              </div>
            </Col>
            <Col md="12">
              <div className="copytext-area text-center">
                <p>
                  Copyright &copy; 2022, BIT Bengaluru All Rights Reserved |
                  Designed &#38; Developed by{" "}
                  <a
                    href={process.env.PUBLIC_URL + "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Shoonyeka Solutions Mysuru
                  </a>
                </p>
                <ul className="social list-unstyled list-inline">
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/bitsince1979"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://twitter.com/bitsince1979" target="_blank">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/bitsince1979/"
                      target="_blank"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.youtube.com/bitsince1979"
                      target="_blank"
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="text-center">
                <br />
                <br />
                <p style={{ color: "white" }}>Visitor Count</p>
              </div>
              <div>
                <div style={{ textAlign: "center" }}>
                  <div className="copyright">
                    <img
                      src="https://counter8.optistats.ovh/private/freecounterstat.php?c=mykps5egg1dzs1c36lj6rp2duydgy7bt"
                      alt="Visitor counter showing the number of visits to this site"
                      title="Number of visits to this site"
                      style={{ border: 0 }}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <BackToTop />
      </footer>
    </Styles>
  );
}

export default FooterTwo;
