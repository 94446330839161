import React, { Component } from "react";
import Banner from "../../components/common/Banner";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import ExploreSidebar from "./components/ExploreSidebar";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../Config/FbConfig";

export default class ExploreChairman extends Component {
  state = {
    GCDatas: [],
    Loading: true,
  };

  //Load Data Here
  async componentDidMount() {
    //Get GC Member Data
    const docRef_3 = doc(firestore, "Other", "GCMembers");
    const docSnap_3 = await getDoc(docRef_3);
    var Data_3 = [];
    if (docSnap_3.exists()) {
      Object.entries(docSnap_3.data()).forEach(([key, value]) => {
        Data_3.push(
          Object.assign(
            {
              Id: key,
              Priority: value.Priority != undefined ? value.Priority : "999",
            },
            value
          )
        );
      });

      Data_3.length === 0
        ? this.setState({ GCDatas: "NO_999", Loading: false })
        : this.setState({
            GCDatas: Data_3.sort(function (a, b) {
              return a.Priority - b.Priority;
            }),
            Loading: false,
          });
    }
  }

  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Explore BIT" image="banners/banner-explore.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <h3 className="title">Chairman</h3>
                  </div>
                </div>
                <div className="ttm-service-single-content-area">
                  <section className="ttm-row team-details-section clearfix">
                    <div className="container-xl">
                      <div className="row">
                        <div className="col-lg-4" style={{ margin: "auto" }}>
                          <div className="ttm-featured-wrapper">
                            <div className="featured-thumbnail text-center">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/assets/images/explore/chairman.jpeg`
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-8 justify"
                          style={{ margin: "auto" }}
                        >
                          <div className="ttm-team-member-content shadow-box res-991-mt-30">
                            <div className="ttm-team-member-content shadow-box res-991-mt-30">
                              <div className="ttm-team-member-single-list">
                                <h2 className="ttm-team-member-single-title">
                                  Shri. Ashok SD Jayaram
                                </h2>
                                <hr />
                                <p>
                                  Engineers build not only machine and
                                  structures but also a healthy society. There
                                  is lot of responsibility on the students of
                                  present day in understanding their role as
                                  future citizens, technocrats and leaders. I am
                                  really glad that BIT is providing platform for
                                  its students and faculty members to showcase
                                  their talent in different forms of creative
                                  expressions, technical know-how and social
                                  awareness.
                                </p>
                                <br />
                                <p>
                                  BIT has always been a campus with a lot of
                                  cultural and geographical diversity. It treats
                                  students as unique in themselves and is
                                  committed to creating an
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 paddingtop-2">
                          <p>
                            atmosphere essential for academic excellence.
                            Faculty members of the college have always guided
                            students with their full potential as teachers,
                            researchers and motivators. I appreciate the best
                            efforts of faculty members and wish them great
                            success in their attempt to be the guiding force for
                            all our students.
                          </p>
                          <br />
                          <p>Best regards</p>
                          <br />
                          <p style={{ fontWeight: "bold" }}>
                            Shri. Ashok SD Jayaram
                          </p>
                          <p>Chairman, Governing Council,</p>
                          <p>BIT, Bangalore.</p>
                        </div>
                        <div className="section-title with-desc text-left clearfix paddingtop-1">
                          <div
                            className="title-header paddingtop-2"
                            style={{ borderTopColor: "red" }}
                          >
                            <h4 className="title">Governing Council Members</h4>
                          </div>
                        </div>
                        <div
                          className="row ttm-service-description"
                          style={{ textAlign: "center" }}
                        >
                          {this.state.GCDatas.map((data, i) => (
                            <div
                              className="col-md-4 paddingtop-2"
                              style={{ textAlign: "center" }}
                            >
                              <img
                                className="img-fluid"
                                src={data.Image}
                                alt="error"
                              />
                              <h6 className="paddingtop-1">{data.Name}</h6>
                              <small>{data.Designation}</small>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <ExploreSidebar />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
