import React, { Component } from 'react';
import Banner from '../../../components/common/Banner';
import Header from '../../../components/common/Header';
import DepartmentSidebar from '../component/DepartmentSidebar';
import DepartmentIndustryCards from '../component/DepartmentIndustryCards';
import Footer from '../../../components/common/Footer';

export default class EIEIndustry extends Component {
    render() {
        return (
            <div>
            {/* Header */}
            <Header />

            {/* Banner */}
            <Banner title="Electronics and Instrumentation Engineering"   image="banners/department-eie.jpg" />

            {/* Content */}
            <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                <div className="container-xl">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-9 content-area order-lg-2">
                        <div className="ttm-service-single-content-area">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Industry Interactions</h4>
                                    </div>
                                </div>
                                <div className="ttm-service-description justify">
                                    <p>Internships are educational and career development opportunities, providing practical experience in a field or discipline. They are structured, short-term, supervised placements often focused around particular tasks or projects with defined timescales. An internship may be compensated, non-compensated or some time may be paid. The internship has to be meaningful and mutually beneficial to the intern and the organization.</p><br/>
                                    <p>Meaningful internships empower engineering students with up skilling, enrichment of practical knowledge and career building. This is exactly why AICTE has made internships mandatory in engineering curriculum. The unique Industry Certified Research Internship makes students Ready Engineers; during internship, the students work on state-of-the-art multidisciplinary projects, sharpen experimental, analytical and numerical skills, and learn the applicability of technologies in diverse fields. The Internship provides opportunity to associate with state-of-the-art infrastructure of the Centers of Excellence of BIT, Industries and hence to create professional network in their field of interest. The experiential learning during internship through one-to-one interaction with industrial experts validates the skill-set of the students and hence catalyses the chances of getting placement.</p><br/>
                                    <p>Students are encouraged to take up internship programs during their semester break. Faculty members give their guidelines, suggestions, and scope and contact details of an internship. Faculty also help the students by interacting with the industrial experts, provide the students recommendation letters and other necessary supports. The alumni coordinator constantly interacts with alumni those who are working in the industries and request them to provide necessary guidelines and supports for their junior’s internship.</p><br/>
                                    <p>Students under gone Internship in various companies like Siemens Technology and Services Ltd , ABB India Ltd, Yokogawa India Limited , Aeronautical Development Authority, HAL, Bosch Limited, Gas Turbine Research Establishment Bengaluru, BHEL, Bengaluru  etc.</p><br/>
                                    <p>To strengthen interaction with industries and to keep our students updated with the latest trends in Electronics & Instrumentation Engineering, the Department has entered into an agreement with the following companies.</p><br/>
                                </div>
                                <DepartmentIndustryCards department="eie" />
                            </div>
                        </div>
                        <div className="col-lg-3 widget-area">
                            <DepartmentSidebar department="eie" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <Footer />
        </div>
    )
}
}
