import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import ELearningSidebar from './components/ELearningSidebar';

export default class ELearningEContent extends Component {

  render() {

    const Datas = [
      {
        "id": "1",
        "header": "National Digital Library of India (NDLI) One Library All of India",
        "description": "National Digital Library of India (NDLI) makes learning resources available to the learners community through a single-window. Educational materials available for all subject areas like Technology, Social Science, Literature, Law, Medical, etc. Library integrates contents from Institutional Digital Repositories of large number of Indian Educational and Research institutions. Click link to register for free and access e-content on multiple disciplines.",
        "link": "https://ndl.iitkgp.ac.in/"
      },
      {
        "id": "2",
        "header": "e-PG Pathshala",
        "description": "e-PG Pathshala is an initiative of the MHRD under its National Mission on Education through ICT (NME-ICT) being executed by the UGC. The content and its quality being the key component of education system, high quality, curriculum-based, interactive e-content in 70 subjects across all disciplines of social sciences, arts, fine arts and humanities, natural & mathematical sciences, linguistics and languages have been developed by the subject experts working in Indian universities and other R & D institutes across the country.",
        "link": "http://epgp.inflibnet.ac.in/"
      },
      {
        "id": "3",
        "header": "The VTU e-Learning Centre",
        "description": "The main aim of The VTU e-Learning Centre is to facilitate distance education and training to the students and faculty of VTU through satellite and web. Currently, VTU e-Learning Centre has migrated from satellite based EDUSAT prgramme to web based eShikshana programme. ",
        "link": "http://elearning.vtu.ac.in/index.php"
      },
      {
        "id": "4",
        "header": "e- contents of VTU e-Learning Centre",
        "description": "Click here to access e-Contents of VTU e-Learning Centre for all courses of various BE programmes. ",
        "link": "https://drive.google.com/file/d/1vyNTBvXfoeNCRf8lWMR-4KuYqWg6Z7-i/view?usp=sharing"
      },
      {
        "id": "5",
        "header": "Library Genesis",
        "description": "Click here to search for free Science – Tecchnology books available at Library Genesis.",
        "link": "http://libgen.rs/"
      },
      {
        "id": "6",
        "header": "Project Gutenberg",
        "description": "Project Gutenberg is a library of over 60,000 free eBooks. Choose among free epub and Kindle eBooks, download them or read them online. You will find the world’s great literature here, with focus on older works for which U.S. copyright has expired. Thousands of volunteers digitized and diligently proofread the eBooks, for you to enjoy.",
        "link": "https://www.gutenberg.org/"
      },
      {
        "id": "7",
        "header": "Springer e-books",
        "description": "Springer free e- books - Links to download 486 free e-books",
        "link": "https://drive.google.com/file/d/1d-tjjMAMKLzawQTpmvAPAT3PkaBpbO6k/view?usp=sharing"
      },
      {
        "id": "8",
        "header": "Engineering e-book",
        "description": "URL’s of Open source Engineering e-books",
        "link": "https://drive.google.com/file/d/1DCMmaqa2auZ844FWKlUX8lo0tAbO0VBV/view?usp=sharing"
      },
      {
        "id": "9",
        "header": "Free e-books",
        "description": "Open the document to find the list of sites where free e-books are available",
        "link": "https://drive.google.com/file/d/1uHqP7-BZUD9iZWgi9ne45dbw9aaHx89e/view?usp=sharing"
      },
      {
        "id": "10",
        "header": "DOAB",
        "description": "Directory of Open Access Books (DOAB) is a community-driven discovery service that indexes and provides access to scholarly, peer-reviewed open access books and helps users to find trusted open access book publishers. All DOAB services are free of charge and all data is freely available. 59,456 academic peer-reviewed books available.",
        "link": "https://doabooks.org/"
      },
      {
        "id": "11",
        "header": "OKR",
        "description": "The World Bank is the largest single source of development knowledge. The World Bank Open Knowledge Repository (OKR) is The World Bank’s official open access repository for its research outputs and knowledge products.",
        "link": "https://openknowledge.worldbank.org/"
      },
      {
        "id": "12",
        "header": "Southern Connecticut State University",
        "description": "Open Access Resources: Free web collections, books, journals, teaching materials, and other resources. This guide concentrates on English language collections, though some collections mentioned do have resources in several languages.",
        "link": "https://libguides.southernct.edu/openaccess"
      },
    ]

    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="E - Learning Corner" image="banners/banner-teaching.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header" style={{ borderTopColor: "red" }}>
                    <h4 className="title">E-Content</h4>
                  </div>
                </div>
                <table class="table">
                  <thead style={{ backgroundColor: "#98002e", color: "white", textAlign: "center" }}>
                    <tr>
                      <th scope="col" style={{ width: "10%" }}>SL No</th>
                      <th scope="col" style={{ width: "25%" }}>Platform</th>
                      <th scope="col" style={{ width: "50%" }}>Description</th>
                      <th scope="col" style={{ width: "15%" }}>Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Datas.map((data, i) => (
                        <tr className="justify">
                          <td>{data.id}</td>
                          <td>{data.header}</td>
                          <td>{data.description}</td>
                          <td><a target="_blank" href={data.link}>Click Here</a></td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
              <div className="col-lg-3 widget-area">
                <ELearningSidebar />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}
