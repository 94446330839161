import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class ResearchSidebar extends Component {
    render() {
        return (
            <div>
                <div className="widget widget-nav-menu">
                    <ul className="widget-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + '/research-bit'}>Research @ BIT</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/research-facilities'}>Research Facilities</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/research-center'}>Research Centers @ BIT</NavLink></li>
                        <hr/>
                        <li><NavLink to={process.env.PUBLIC_URL + '/innovation-cell'}>Innovation Cell</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/innovation-skill-development'}>Skill Development</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/innovation-startup'}>Startup Incubation Center</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/innovation-ipr'}>IPR Cell</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/innovation-science-center'}>Science Center</NavLink></li>
                    </ul>
                </div>
            </div>
        )
    }
}