import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class AIContact extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner
          title="Artificial Intelligence & Machine Learning"
          image="banners/department-ai.jpg"
        />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-4">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <h4 className="title">Contact</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-lg-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="fa fa-phone" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h6>Head of the Department</h6>
                        </div>
                        <div className="featured-desc">
                          <p>Dr. Jyothi D. G.</p>
                          <p>E-mail Id:dgjyothi@bit-bangalore.edu.in</p>
                          <p>Contact Number:+91 9448031781</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="fa fa-phone" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h6> Foreman</h6>
                        </div>
                        <div className="featured-desc">
                          <p>Sri. B. V. Venkata Krishna, </p>
                          <p>E-mail Id:venkatakrishnabv@gmail.com </p>
                          <p>Contact Number:+91 9986586576</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-lg-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="fa fa-phone" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h6>Instructor</h6>
                        </div>
                        <div className="featured-desc">
                          <p>Mr. Keerthi Kumar L. N.</p>
                          <p>aimlbit2020@gmail.com</p>
                          <p>Contact Number:+91 7353880119</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="ai" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
