import React, { Component } from "react";
import { Link } from "react-router-dom";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class CSEPrograms extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Computer Science and Engineering" image="banners/department-cse.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-single-content-area">
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Under Graduate Program</h4>
                    </div>
                  </div>
                  <table className='table'>
                    <tbody>
                      <tr>
                        <th>Bachelor of Engineering</th>
                        <td>Computer Science & Engineering</td>
                      </tr>
                      <tr>
                        <th>Started in year</th>
                        <td>1981</td>
                      </tr>
                      <tr>
                        <th>Intake</th>
                        <td>240 students</td>
                      </tr>
                      <tr>
                        <th>For Admission</th>
                        <td><Link to={process.env.PUBLIC_URL + "/admission-be"}>click here</Link></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="ttm-service-single-content-area paddingtop-2">
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Post Graduate Program</h4>
                    </div>
                  </div>
                  <table className='table'>
                    <tbody>
                      <tr>
                        <th>M. Tech in</th>
                        <td>Computer Science & Engineering</td>
                      </tr>
                      <tr>
                        <th>Started in year</th>
                        <td>2008</td>
                      </tr>
                      <tr>
                        <th>Intake</th>
                        <td>18 students</td>
                      </tr>
                      <tr>
                        <th>For Admission</th>
                        <td><Link to={process.env.PUBLIC_URL + "/admission-mtech"}>click here</Link></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="ttm-service-single-content-area paddingtop-2">
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Ph.D Program</h4>
                    </div>
                  </div>
                  <table className='table'>
                    <tbody>
                      <tr>
                        <th>Name of Ph. D guide</th>
                        <th>Area of research</th>
                      </tr>
                      <tr>
                        <th>Dr. Asha T.</th>
                        <td>Data Mining, Soft computing, Machine learning</td>
                      </tr>
                      <tr>
                        <th>Dr. Suneetha K. R.</th>
                        <td>Web mining</td>
                      </tr>
                      <tr>
                        <th>Dr. Harish Kumar B T</th>
                        <td>Web mining</td>
                      </tr>
                      <tr>
                        <th>Dr. M S Bhargavi</th>
                        <td>Data Mining</td>
                      </tr>
                      <tr>
                        <th>Dr. Hemavathi P</th>
                        <td>Wireless Sensor, Network</td>
                      </tr>
                      <tr>
                        <th>Dr. Gunavathi H S</th>
                        <td>Image Processing</td>
                      </tr>
                      <tr>
                        <th>For Admission</th>
                        <td><Link to={process.env.PUBLIC_URL + "/admission-phd"}>click here</Link></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="cse" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
