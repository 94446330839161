import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBwYXwv8cjkhcBrgkeUC6LF9bu5Koa0cMM",
  authDomain: "rvs-bit.firebaseapp.com",
  projectId: "rvs-bit",
  storageBucket: "rvs-bit.appspot.com",
  appId: "1:452263085397:web:710fb1f46c6b220ea35bdc",
  measurementId: "G-T5TPMMB3ZE",
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { auth, firestore, storage };
