import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import DepartmentIndustryCards from "../component/DepartmentIndustryCards";
import Footer from "../../../components/common/Footer";


export default class EEEIndustry extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Electrical and Electronics Engineering" image="banners/department-eee.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Industry Interactions</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-12" style={{ margin: "auto" }}>
                                                <p className="justify">Presently there are 8 MOU's signed and associated with EEE Department. Workshops and Webinars are conducted in every academic years.</p>
                                            </div>
                                            <div className="col-md-6 col-lg-12 paddingtop-1">
                                                <div className="section-title with-desc text-left clearfix">
                                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                                        <h5>MOU's Assoicated with the following companies</h5>
                                                    </div>
                                                </div>
                                                <ul className="ttm-list ttm-list-style-icon">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">APLAB LIMIT Yeshwanthpur, Bengaluru – 560022</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">AIDDITION TECHNOLOGIES #4213, 1ndMain, Subramanyanagara Bengaluru – 560021</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">LAVEESH ENERGY CONSULTANTCY SERVICES (OPC)PRIVATE LIMITED 284, 4th Cross, 60 feet road, KEB Layout, Sanjay Nagar, Bengaluru – 560094</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">FLUX GEN ENGINEERING TECHNOLOGIES PVT. LTD Bengaluru</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">VASUKI EXPERTS SYSTEMS #7, VenkaeshwaraNilaya, 3rd Main, , Society Colony, Adugodi,, Bengaluru – 560030</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">BINARY WAVE #381, 3rdFloor, Outer ring Road, Laggere layout, Bengaluru – 560058</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">SST TECHNOLOGIES #944, 16th Cross, 1st Stage, Kumaraswamy Layout, Bengaluru – 560078</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Venjay Automation, Bengaluru.</span></li>
                                                    <li><i className="fas fa-file-pdf" /><span> More Details : <a target="_blank" href={process.env.PUBLIC_URL + "/assets/pdf/eee_mou.pdf"}>Click Here</a></span></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="section-title with-desc text-left clearfix paddingtop-2">
                                            <div className="title-header" style={{ borderTopColor: "red" }}>
                                                <h4 className="title">Internships With  Industries</h4>
                                            </div>
                                        </div>
                                        <p>With the number of students who choose engineering as their career being on the rise over the past couple of decades, making the students stand out and excel at their choice becomes an imperative of the present day scemarios. The All India Council for Technical Education (AICTE) decided to make three internships, each of 4-8 week duration mandatory for engineering undergraduates to facilitate the achievements of a few goals, the primary goal being easy employability.</p>
                                        <div className="section-title with-desc text-left clearfix paddingtop-2">
                                            <div className="title-header" style={{ borderTopColor: "red" }}>
                                                <h5 className="title">Why Internships with Industries is necessary?</h5>
                                            </div>
                                        </div>
                                        <ul className="ttm-list ttm-list-style-icon">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To fixate the standards of technical institutions in terms of delivering the objectives and quality education.</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To enhance employment opportunities for engineering students by imparting required knowledge and skills</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To increase college placement percentage.</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To increase the percentage of students participating in summer internships</span></li>
                                        </ul>
                                        <DepartmentIndustryCards department="eee" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="eee" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
