import React, { Component } from 'react';
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";
import NoData from "../../../components/common/NoData";
import Loading from "../../../components/common/Loading";
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../Config/FbConfig';

export default class ResearchPublications extends Component {

  state = { Datas: [], Datas1: [] }

  //Load Data Here
  async componentDidMount() {
    const docRef = doc(firestore, this.props.match.params.id, "ResearchPublication");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      var Data = []
      Object.entries(docSnap.data()).forEach(([key, value]) => {
        Data.push(Object.assign({ Id: key, Priority: value.Priority != undefined ? value.Priority : "999" }, value))
      });
      Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })
    } else {
      this.setState({
        Datas: "NO_999"
      })
    }

    const docRef1 = doc(firestore, this.props.match.params.id, "ResearchPatents");
    const docSnap1 = await getDoc(docRef1);

    if (docSnap1.exists()) {
      var Data1 = []
      Object.entries(docSnap1.data()).forEach(([key, value]) => {
        Data1.push(Object.assign({ Id: key, Priority: value.Priority != undefined ? value.Priority : "999" }, value))
      });
      Data1.length === 0 ? this.setState({ Datas1: "NO_999" }) : this.setState({ Datas1: Data1.sort(function (a, b) { return a.Priority - b.Priority; }) })
    } else {
      this.setState({
        Datas1: "NO_999"
      })
    }
  }

  render() {
    const banner = {
      cse: <Banner title="Computer Science and Engineering" image="banners/department-cse.jpg" />,
      ise: <Banner title="Information Science and Engineering" image="banners/department-ise.jpg" />,
      ai: <Banner title="Artificial Intelligence and Machine Learning" image="banners/department-ai.jpg" />,
      ece: <Banner title="Electronics and Communication Engineering" image="banners/department-ece.jpg" />,
      mech: <Banner title="Mechanical Engineering" image="banners/department-me.jpg" />,
      civil: <Banner title="Civil Engineering" image="banners/department-me.jpg" />,
      eee: <Banner title="Electrical and Electronics Engineering" image="banners/department-eee.jpg" />,
      ete: <Banner title="Electronics and Telecommunication Engineering" image="banners/department-ete.jpg" />,
      eie: <Banner title="Electronics and Instrumentation Engineering" image="banners/department-eie.jpg" />,
      iem: <Banner title="Industrial Engineering and Management" image="banners/department-iem.jpg" />,
      mba: <Banner title="Master of Business Administration" image="banners/department-mba.jpg" />,
      mca: <Banner title="Master of Computer Applications (MCA)" image="banners/department-mca.jpg" />,
      phy: <Banner title="Physics" image="banners/department-phy.jpg" />,
      chem: <Banner title="Chemistry" image="banners/department-che.jpg" />,
      math: <Banner title="Mathematics" image="banners/department-math.jpg" />,
      hum: <Banner title="Humanities" image="banners/department-hum.jpg" />,
    }

    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        {banner[this.props.match.params.id]}

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-single-content-area">
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Patents</h4>
                    </div>
                  </div>
                  <div className="ttm-service-description">
                    <div className="row">
                      {
                        this.state.Datas1.length === 0 ? <Loading /> :
                          this.state.Datas1 === "NO_999" ? <NoData /> :
                            this.state.Datas1.map((data, i) => (
                              <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12" style={{ marginBottom: "0.5rem" }}>
                                <div className="featured-imagebox featured-imagebox-blog" style={{ borderBottom: "solid", borderColor: "#98002e" }}>
                                  <div className="featured-content">
                                    <div className="featured-title"><h5 style={{ color: "#98002e" }}>{i + 1}. {data.Title}</h5></div>
                                    <div className="row post-meta paddingtop-2">
                                      <span className="col-lg-12 col-sm-6 col-md-6 col-xs-12 ttm-meta-line"><i className="fa fa-clock" /><b>Application :</b> {data.Application}</span>
                                    </div>
                                    <div className="row post-meta paddingtop-1">
                                      <span className="col-lg-6 col-sm-6 col-md-6 col-xs-12 ttm-meta-line"><i className="fa fa-chalkboard-teacher" /><b>Name :</b> {data.Name}</span>
                                      <span className="col-lg-6 col-sm-6 col-md-6 col-xs-12 ttm-meta-line"><i className="fa fa-hourglass-half" /><b>Status :</b> {data.Status}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                      }
                    </div>
                  </div>
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Publications</h4>
                    </div>
                  </div>
                  <div className="ttm-service-description">
                    <div className="row">
                      {
                        this.state.Datas.length === 0 ? <Loading /> :
                          this.state.Datas === "NO_999" ? <NoData /> :
                            this.state.Datas.map((data, i) => (
                              <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12" style={{ marginBottom: "0.5rem" }}>
                                <div className="featured-imagebox featured-imagebox-blog" style={{ borderBottom: "solid", borderColor: "#98002e" }}>
                                  <div className="featured-content">
                                    <div className="featured-title">
                                      <h5 style={{ color: "#98002e" }}>{i + 1}. {data.Title}</h5>
                                    </div>
                                    <div className="row post-meta paddingtop-2">
                                      <span className="col-lg-12 col-sm-6 col-md-6 col-xs-12 ttm-meta-line"><i className="fa fa-clock" /><b>Journal Name :</b> {data.JournalName}</span>
                                    </div>
                                    <div className="row post-meta">
                                      <span className="col-lg-6 col-sm-6 col-md-6 col-xs-12 ttm-meta-line"><i className="fa fa-user" /><b>Author Name :</b> {data.Name}</span>
                                      <span className="col-lg-6 col-sm-6 col-md-6 col-xs-12 ttm-meta-line"><i className="fa fa-compass" /><b>Volume :</b> {data.Volume}</span>
                                    </div>
                                    <div className="row post-meta paddingtop-1">
                                      <span className="col-lg-4 col-sm-6 col-md-6 col-xs-12 ttm-meta-line"><i className="fa fa-chalkboard-teacher" /><b>ISSN :</b> {data.ISSN}</span>
                                      <span className="col-lg-4 col-sm-6 col-md-6 col-xs-12 ttm-meta-line"><i className="fa fa-hourglass-half" /><b>Impact :</b> {data.Impact}</span>
                                      <span className="col-lg-4 col-sm-6 col-md-6 col-xs-12 ttm-meta-line"><i className="fa fa-hourglass-half" /><b>Year :</b> {data.Year}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department={this.props.match.params.id} />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}