import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
    h6 {
        color        : ${colors.bg3};
        font-weight : bold;
        overflow    : hidden;
        display     : -webkit-box;
        -webkit-line-clamp: 2;
                line-clamp: 2; 
        -webkit-box-orient: vertical;
    }

`;