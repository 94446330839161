import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import DepartmentIndustryCards from "../component/DepartmentIndustryCards";
import Footer from "../../../components/common/Footer";

export default class HUMIndustry extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Master of Computer Applications (MCA)" image="banners/department-mca.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-single-content-area">
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Industry Interactions</h4>
                    </div>
                  </div>
                  <div className="ttm-service-description">
                  <p className="justify">To prepare the students industry ready and to be updated with the latest trends, the Department encourages faculty members and students to interact with the industry. To meet this Department motivates students to carry out internships and projects in Industries. To facilitate this, Department has Memorandum of Understanding (MoU) with the following IT industries.</p>
                  <div className="row">
                      <div className="col-md-6 col-lg-12 paddingtop-2">
                        <h6>Industris with MOU :</h6>
                        <div className="row">
                          <div className="col-md-6 col-lg-6 paddingtop-2">
                            <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                              <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Zikrabyte Solutions</span></li>
                              <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">FlagRoot</span></li>
                              <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">TCS iON</span></li>
                              <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Vraio Software Solutions</span></li>
                              <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">FreeLance Corporate Trainer</span></li>
                              <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Sankir Technologies Pvt. Limited</span></li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-6 paddingtop-2">
                            <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                              <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Loctell Solutions</span></li>
                              <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Winfoware Technologies Limited</span></li>
                              <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">ImpeedCraft Technosol Private Limited</span></li>
                              <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Skill Ray</span></li>
                              <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">New Ray</span></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <DepartmentIndustryCards department="mca" />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="mca" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
