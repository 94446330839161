import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import DepartmentAchievers from "../component/DepartmentAchievers";
import DepartmentNumbers from "../component/DepartmentNumbers";
import DepartmentVideo from "../component/DepartmentVideo";
import Footer from "../../../components/common/Footer";

export default class IEMAbout extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Industrial Engineering and Management" image="banners/department-iem.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <DepartmentAchievers department="iem"/>
                                    <DepartmentNumbers 
                                        text1="Number of students on roll"
                                        num1="113"
                
                                        text2="Number of faculty"
                                        num2="08"
                
                                        text3="Number of ranks since inception"
                                        num3="77"
                
                                        text4="Number of graduated batches"
                                        num4="28"
                
                                        text5="Average Placement (%) in last 5 years"
                                        num5="65"

                                        text6="Ph. D Awarded"
                                        num6="08"
                                    />
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/departments/iem/about-1.jpg"} title="single-img-twenty" alt="single-img-twenty" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify">
                                                <ul className="ttm-list ttm-list-style-icon">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">The department of Industrial Engineering and Management was established in the year 1986. The department was previously known as Industrial Production Engineering and was renamed as Industrial Engineering and Management in the year 1992. The department was under Bangalore University between 1986 and 1998 and is under VTU since 1998.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"> All the faculty have their master’s degree with 5 having Ph.D. degree and one faculty is pursuing. The faculty are well experienced in academics and industries as well. The faculties are also associated with professional bodies like IE, IIIE, IVES and ISTE.</span></li>
                                                </ul>
                                            </div>
                                            <div className="col-md-12 paddingtop-2 justify">
                                                <ul className="ttm-list ttm-list-style-icon">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">The department offers Under-Graduate course [B.E.] and Research [Ph.D.] under VTU. </span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">It has excellent infrastructure facilities with laboratories such as Metrology and Measurements Laboratory, Simulation Laboratory, Date Analytics Laboratory, Enterprise Resource Planning Laboratory, Work Study and Ergonomics Laboratory and Quality Engineering Laboratory. </span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">The department of Industrial Engineering and Management frequently organizes Seminars, Workshops, Faculty Development Programs and other interactive programs to keep the student community and faculties updated.</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <DepartmentVideo department="iem" />
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Vision</h4>
                                        </div>
                                        <p>To be a quality Industrial Engineering education center with emphasis on employability, infusing confidence to attain higher learning and to be successful entrepreneurs.</p>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Mission</h4>
                                        </div>
                                        <ul className="ttm-list ttm-list-style-icon justify">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To encourage students in pursuing higher education</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To prepare students to provide technically sound, feasible and socially acceptable solutions to the real life problems</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To create employable graduates in Industrial Engineering and Management to cater for diverse industries.</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To encourage the graduates to become entrepreneurs.</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="iem" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Footer */}
                <Footer />
            </div>
        )
    }
}



