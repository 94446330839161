import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import ELearningSidebar from './components/ELearningSidebar';

export default class ELearningPracticalResources extends Component {

  render() {

    const Datas = [
      {
        "id": "1",
        "header": "Virtual Labs Web-enabled experiments designed for remote – operation.",
        "description": "Virtual Labs project is an initiative of Ministry of Human Resource Development (MHRD), Government of India under the aegis of National Mission on Education through Information and Communication Technology (NMEICT). This project is a consortium activity of twelve participating institutes and IIT Delhi is coordinating institute. Under Virtual Labs project, over 100 Virtual Labs consisting of approximately 700+ web-enabled experiments were designed for remote-operation and viewing.",
        "link": "https://www.vlab.co.in/"
      },
      {
        "id": "2",
        "header": "e- Yantra",
        "description": "e-Yantra is a robotics outreach program funded by the Ministry of Education and hosted at IIT Bombay. The goal is to harness the talent of young engineers to solve problems using technology across a variety of domains such as: agriculture, manufacturing, defence, home, smart-city maintenance and service industries.",
        "link": "https://www.e-yantra.org/"
      },
      {
        "id": "3",
        "header": "FOSSEE",
        "description": "FOSSEE (Free/Libre and Open Source Software for Education) project promotes the use of FLOSS tools in academia and research. The FOSSEE project is part of the National Mission on Education through Information and Communication Technology (ICT), Ministry of Education (MoE), Government of India. Click the link to know the list of projects which are promoted by FOSSEE.",
        "link": "https://fossee.in/"
      },
      {
        "id": "4",
        "header": "Spoken Tutorial",
        "description": "Software Training- The Spoken Tutorial Project is about teaching and learning a particular FOSS (Free and Open Source Software) like Linux, Scilab, LaTeX, PHP & MySQL, Java, C/C++, LibreOffice etc. via an easy Video tool - Spoken Tutorials.",
        "link": "https://spoken-tutorial.org/"
      },
      {
        "id": "5",
        "header": "Software Resources",
        "description": "Click here to find the links for various software resources.",
        "link": "https://drive.google.com/file/d/1dWKEQbjuIKIA1vkZFobZvhOqAMEkTK3a/view?usp=sharing"
      }
    ]

    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="E - Learning Corner" image="banners/banner-teaching.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header" style={{ borderTopColor: "red" }}>
                    <h4 className="title">Practical Resources</h4>
                  </div>
                </div>
                <table class="table">
                  <thead style={{ backgroundColor: "#98002e", color: "white", textAlign: "center" }}>
                    <tr>
                      <th scope="col" style={{ width: "10%" }}>SL No</th>
                      <th scope="col" style={{ width: "25%" }}>Platform</th>
                      <th scope="col" style={{ width: "50%" }}>Description</th>
                      <th scope="col" style={{ width: "15%" }}>Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Datas.map((data, i) => (
                        <tr className="justify">
                          <td>{data.id}</td>
                          <td>{data.header}</td>
                          <td>{data.description}</td>
                          <td><a target="_blank" href={data.link}>Click Here</a></td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
              <div className="col-lg-3 widget-area">
                <ELearningSidebar />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}
