import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class MATHCOE extends Component {

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Mathematics" image="banners/department-math.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Center of Excellence</h4>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-12 justify">
                                            <ul className="ttm-list ttm-list-style-icon">
                                                <li><i className="fa fa-chevron-circle-right ttm-textcolor-skincolor  " /><span className="ttm-list-li-content"><b><h6>Mathematical and Statistical Computation</h6></b></span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content"> Nowadays, one can without uncertainty, mention that there is mathematical and statistical computational in virtually everything. Development behind mobile phones, aero planes, satellites, computers, internet and so on are built on mathematics and statistical theories and applications. Cryptology and secure data transfer are based on classical number theory. New mathematical methods are necessary for understanding modern physics and chemistry, e.g. advanced mathematical and statistical models are required in climate science. Bioinformatics has quickly grown to become one of the big areas of applied mathematics. Continuing the development of mathematical and statistical methods is necessary in order to deal with the huge amounts of information generated.</span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content"> Hence, the knowledge of mathematical and statistical computations is required to solve practical problems as well as to motivate the students to pursue research in various engineering fields. Our Centre of Excellence helps to impart pedagogy training for students at both under graduate and post graduate students to learn and promote concept-based learning, with emphasis on pedagogical innovations and new ideas. The center also  prepare our students for a variety of careers in industry, government and the teaching professions; and to advance fundamental knowledge in the areas of pure mathematics, applied mathematics and statistics and computational methodologies. An emphasis will be given for the faculties to pursue research in various engineering fields and generate mathematical and statistical thinking to solve complex engineering problems, exploring and learning opportunities, and relevant applications through advanced research. </span></li>
                                                <li><i className="fa fa-chevron-circle-right ttm-textcolor-skincolor  " /><span className="ttm-list-li-content"><b><h6>Vission</h6></b></span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To achieve academic excellence, investigate new approaches for future science, technology and engineering research and applications.</span></li>
                                                <li><i className="fa fa-chevron-circle-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><b><h6>Mission.</h6></b></span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To perform widely recognized research in focused areas of mathematical and statistical computational methodology and education.</span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Development of innovative solutions and patronage new ideas and technological advancements.</span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Advancing the fundamental knowledge in mathematical and Statistical computational techniques to solve practical problems </span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Preparing future mathematicians, statisticians, scientists, engineers and teaching professionals.</span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Exploring new applications in mathematics and statistics and engage in collaborative research in an interdisciplinary environment.</span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Developing to be a center for advanced research in various areas of mathematics, statistics and computational science.</span></li>
                                                <li><i className="fa fa-chevron-circle-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><b><h6>Objectives </h6></b></span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To provide the best mathematical and statistical computation education required to strengthen the backbone of all Engineering Departments for teaching and research.</span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content"> To provide facilities for the faculty and students to conduct their research in advanced areas to develop practical solutions </span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To promote and develop collaborative and interdisciplinary research with industry and other academic and research institutions.</span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To provide opportunities for faculty and students for reskilling and upskilling to gain knowledge in frontier areas in mathematical and statistical computations.</span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To encourage research in various areas of societal developments to accelerate the delivery of appropriate mathematical and engineering applications</span></li>
                                                <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To provide openings for faculty to participate as well as to organize in various seminars, workshops, conferences and training programs.</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="math" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
