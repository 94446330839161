import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import DepartmentAchievers from "../component/DepartmentAchievers";
import DepartmentNumbers from "../component/DepartmentNumbers";
import Footer from "../../../components/common/Footer";
import DepartmentVideo from "../component/DepartmentVideo";

export default class MCAAbout extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner
          title="Master of Computer Applications (MCA)"
          image="banners/department-mca.jpg"
        />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-single-content-area">
                  <DepartmentAchievers department="mca" />
                  <DepartmentNumbers
                    text1="Number of students on roll"
                    num1="299"
                    text2="Number of ranks since inception"
                    num2="16"
                    text3="Number of graduated batches"
                    num3="25"
                    text4="Current year offers"
                    num4="160"
                    text5="Number of publications"
                    num5="30"
                    text6="Average Placement (%) in last 5 years"
                    num6="75"
                  />
                  <div className="ttm-service-description">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="ttm_single_image-wrapper">
                          <img
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/departments/mca/about-1.jpg"
                            }
                            alt="single-img-eighteen"
                          />
                        </div>
                      </div>
                      <div
                        className="col-md-7 justify"
                        style={{ margin: "auto" }}
                      >
                        <p>
                          MCA Department was established in 1997. The department
                          is having well qualified and experienced faculty
                          members. The department is affiliated to VTU and AICTE
                          has approved an intake of 180 Students. Our department
                          has bagged SIXTEEN university ranks with two gold
                          medals. We are proud that, we have received a research
                          grant from VGST. Our students are placed in very
                          reputed software industries including Infosys, WIPRO,
                          IBM, Microsoft, TCS, MindTree, Honeywell, Cognizant,
                          Accenture, Optum, Comviva etc., and conduct various
                          students’ personality development programs including
                        </p>
                      </div>
                    </div>
                    <div className="paddingtop-2">
                      <p>
                        Placement Activities Technical, Cultural, Soft skills,
                        etc. We are Proud to say that 140 offers and 105 got
                        placed during the academic year 2021-2022.
                      </p>
                    </div>
                  </div>
                </div>
                <DepartmentVideo department="mca" />
                <div className="section-title with-desc text-left clearfix">
                  <div className="section-title with-desc text-left clearfix paddingtop-2">
                    <div
                      className="title-header"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">Vision</h4>
                    </div>
                    <p>
                      To transform young graduates into skilled computer
                      professionals to meet industrial and societal needs.
                    </p>
                    <br />
                    <div
                      className="title-header"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">Mission</h4>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          To enhance the Teaching learning process to meet
                          quality education in the field of Computer
                          applications
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          To impart the knowledge in current technologies to
                          meet the industrial needs
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          To inculcate ethical values and leadership qualities
                          for the betterment of society
                        </span>
                      </li>
                    </ul>
                    <div
                      className="title-header paddingtop-2"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">
                        PROGRAM EDUCATIONAL OBJECTIVES (PEO's):
                      </h4>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          To develop quality application software with
                          innovative ideas to meet the industrial requirements{" "}
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          To imbibe the current technologies and to adopt in
                          computing profession as per the changing needs{" "}
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          To progress in their career with leadership qualities
                          and ethical values that enhances self and societal
                          growth
                        </span>
                      </li>
                    </ul>
                    <div
                      className="title-header paddingtop-2"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">PROGRAM OUTCOMES (POs):</h4>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Apply knowledge of computing fundamentals, computing
                          specialization, mathematics and domain knowledge
                          appropriate for conceptualization of computing models
                          as per the requirement
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Identify, analyze systematize and solve computer
                          application related problems using the principles of
                          mathematics, computing sciences and relevant domain
                          knowledge
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Design, develop and evaluate software solution to
                          solve complex problem with societal and environment
                          concern.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Conduct investigations of complex computing problems
                          using research-based methods to provide valid
                          conclusions.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Select, adapt and apply appropriate techniques,
                          resources and modern tools for complex computing
                          activities
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Understand ethical responsibilities and cyber
                          regulations to perform professionally.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Involve in independent learning for continual
                          development as computing professionals
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Apply and demonstrate the computing and management
                          principle to manage projects in multidisciplinary
                          environment as an individual or as a member in team.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Comprehend and write effective reports and make
                          quality presentations about complex computing
                          activities
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Understand and assess the impact of computing
                          practices and socio-environmental concern
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Work as an individual and a member or a leader in
                          multidisciplinary environmen
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Identify business opportunity and pursue using
                          innovative ideas to create value and wealth for the
                          betterment of society
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="mca" />
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
