import React, { Component } from "react";
import { Link } from "react-router-dom";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class MATHAchievers extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Mathematics" image="banners/department-math.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header">
                                        <h4 className="title">Learning Resources</h4>
                                    </div>
                                </div>
                                <div className="ttm-service-description">
                                    <p>Wide arrays of learning materials are provided to students in advance for each course to catalyze their learning ability. These resources include syllabus, course materials, and links to digital aids. Click here to access learning resources.</p>
                                    <Link className="ttm-btn" style={{ fontSize: "1.15rem", paddingTop: "1rem", color: "#00a76a" }} to={process.env.PUBLIC_URL + `/teaching-learning-material/math`}>Click Here for Learning Resources</Link>
                                </div>
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header">
                                        <h4 className="title">Teaching - Learning</h4>
                                    </div>
                                </div>
                                <div className="ttm-service-description">
                                    <p>Students require both intellectual and practical experiences throughout the course of their mathematics education to be productive in this competitive arena. In this context, teaching –learning methods are designed and implemented with improved teaching strategies to support students’ development of mathematical knowledge and problem solving as well as working skills. Calendar of events are prepared well in advance based on VTU academic schedule and accordingly faculty will implement teaching learning methodology as outlined below:</p>
                                    <h6 className="paddingtop-1">Teaching Learning Methodology</h6><br />
                                    <ul className="ttm-list ttm-list-style-icon">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Students require both intellectual and practical experiences throughout the course of their mathematics education to be productive in this competitive arena. In this context, teaching –learning methods are designed and implemented with improved teaching strategies to support students’ development of mathematical knowledge and problem solving as well as working skills. Calendar of events are prepared well in advance based on VTU academic schedule and accordingly faculty will implement teaching learning methodology as outlined below: </li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />All the theory subjects are taught normally by conventional methods through Chalkboard instruction as it is most effective and versatile as well collaborative learning movement.  Students are encouraged to interact during the lecture hour for clarifications of doubts. However, power point and video presentations are also used in teaching certain subjects, wherever requires increasing visual impact, analysing and synthesizing complex graphics.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Special classes are conducted either for better understanding of numerical problems or for the benefit of slow learners in every course.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Seminars/ technical talks/ workshops are organized regularly by inviting expert resource persons from Industry, Academia and Research Institutions.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Lecture notes, videos and power point slides for various subjects are made available for students upon every lecture/presentation for follow-up studies.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Assignments and Quiz questions covering on recent trends in developments and applications are given at regular intervals to enhance and up skill students’ knowledge.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Demonstration and hands-on-training are organised on various software relevant to the field for their use and applications.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />In the event of shortage of time to conduct direct classes or as in case of lock down, on-line classes are being conducted using various software like google meet, Microsoft teams, WebEx, Zoom, Skype by adopting innovative teaching-learning methods.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Videos of the lectures are recorded and uploaded on YouTube for the benefit of students to view at their convenient time for better understanding.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="math" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
