import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import DepartmentAchievers from "../component/DepartmentAchievers";
import DepartmentNumbers from "../component/DepartmentNumbers";
import DepartmentVideo from "../component/DepartmentVideo";
import Footer from "../../../components/common/Footer";

const students = [
  { name: "Sreya Narayan", company: "Google", package: 57 },
  { name: "Surya Pratap Singh", company: "CISCO", package: 40 },
  { name: "Janhvi Singh", company: "CISCO", package: 25 },
  { name: "Rajkumar", company: "CISCO", package: 25 },
  { name: "Smriti Verma", company: "PhonePe", package: 23 },
  { name: "Kumar Harsh", company: "PhonePe", package: 23 },
  { name: "Shivang Rajouria", company: "SYNCRON", package: 12 },
  { name: "Sushant Kumar", company: "SYNCRON", package: 12 },
  { name: "Shreya", company: "SYNCRON", package: 12 },
  { name: "Sudhanshu Shekar", company: "FIDELITY", package: 12 },
  { name: "Karuna Vernekar", company: "FIDELITY", package: 12 },
  { name: "Chirag Taloni", company: "FIDELITY", package: 12 },
  { name: "Bharath G", company: "FIDELITY", package: 12 },
  { name: "Sahaya H", company: "FIDELITY", package: 12 },
  { name: "Nikshith D", company: "ZEBRA", package: 10.5 },
];

export default class ISEAbout extends Component {
  render() {
    return (
      <div>
        <Header />

        <Banner
          title="Information Science and Engineering"
          image="banners/department-ise.jpg"
        />

        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-single-content-area">
                  <DepartmentAchievers department="ise" />
                  <DepartmentNumbers
                    text1="Number of students on roll"
                    num1="510"
                    text2="Number of faculty"
                    num2="18"
                    text3="Number of faculty with Ph. D"
                    num3="8"
                    text4="Number of ranks since inception"
                    num4="25"
                    text5="Highest package in LPA"
                    num5="57"
                    text6="Average Placement (%) in last 5 years"
                    num6="90"
                  />
                  <div className="ttm-service-description">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="ttm_single_image-wrapper">
                          <img
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/departments/ise/about-1.jpg"
                            }
                            alt="single-img-eighteen"
                          />
                        </div>
                      </div>
                      <div
                        className="col-md-7 justify"
                        style={{ margin: "auto" }}
                      >
                        <p>
                          Realizing well in time that the Information Technology
                          (IT) wave has become a phenomenon in itself over the
                          past few years, the institute established a separate
                          department to cater the ever growing demand for
                          professionals in this challenging field. The
                          department of Information Science and Engineering
                          (ISE) came into existence in the year 2000. The
                          program is affiliated to Visvesvaraya Technological
                          University (VTU) Belagavi, Karnataka, approved by
                          AICTE, New Delhi and accredited by NBA.
                        </p>
                      </div>
                      <div className="col-md-12 paddingtop-2 justify">
                        <p>
                          The department is headed by Dr. Asha.T and has a team
                          of highly qualified and thoroughly experienced
                          faculties to support in all her coaching endeavours.
                          There are a total of fifteen faculty members of which
                          nine are Ph.D. holders and another three faculties are
                          carrying out research in various topics of IT. The
                          department also supports and motivates the faculty to
                          publish their research findings in Journals and
                          conferences. Faculties attend workshops and Faculty
                          Development Programs to stay abreast with the leading
                          edge knowledge in the field of IT. It is presently
                          offering a UG program B.E in Information Science and
                          Engineering (ISE) with an intake of 180 and a Research
                          Program to pursue Ph.D. degree. The department seeks
                          to combine excellence in education and research with
                          service to the society and industry.
                        </p>
                      </div>
                      <div style={{ marginTop: "2rem" }}>
                        <h6>Placement Highlights</h6>
                      </div>
                      <div style={{ marginTop: "1rem" }}>
                        <ul>
                          {students.map((student) => (
                            <li
                              key={student.name}
                              style={{ marginBottom: "4px" }}
                            >
                              <p>
                                {student.name} - Placed at {student.company}{" "}
                                with a pay package of {student.package} LPA
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <DepartmentVideo department="ise" />
                <div className="section-title with-desc text-left clearfix">
                  <div className="section-title with-desc text-left clearfix">
                    <div
                      className="title-header paddingtop-2"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">Vision</h4>
                    </div>
                    <p>
                      Empower every student to be innovative, creative and
                      productive in the field of Information Technology by
                      imparting quality technical education, developing skills
                      and inculcating human values.
                    </p>
                  </div>
                  <div className="section-title with-desc text-left clearfix">
                    <div
                      className="title-header paddingtop-2"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">Mission</h4>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          To evolve continually as a Centre of Excellence in
                          offering quality Information Technology{" "}
                          <b>Education</b>.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          To nurture the students to meet the global competency
                          in industry for <b>Employment</b>.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          To promote collaboration with industry and academia
                          for constructive interaction to empower{" "}
                          <b>Entrepreneurship</b>.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          To provide reliable, contemporary and integrated
                          technology to support and facilitate{" "}
                          <b>Teaching, Learning, Research and Service</b>.
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="section-title with-desc text-left clearfix">
                    <div
                      className="title-header paddingtop-2"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">
                        Programme Educational Objectives (PEOs)
                      </h4>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Uplift the students through Information Technology
                          Education.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Provide exposure to emerging technologies and train
                          them to Employable in multi-disciplinary industries.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Motivate them to become good professional Engineers
                          and Entrepreneur.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Inspire them to prepare for Higher Learning and
                          Research.
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="section-title with-desc text-left clearfix">
                    <div
                      className="title-header paddingtop-2"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">
                        Program Specific Outcomes (PSOs)
                      </h4>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          To provide our graduates with Core Competence in
                          Information Processing and Management.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          To provide our graduates with Higher Learning in
                          Computing Skills.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="ise" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
