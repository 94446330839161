import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loading from '../../components/common/Loading';
import NoData from '../../components/common/NoData';
import InnovationSidebar from './components/InnovationSidebar';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../Config/FbConfig';
import { Modal } from 'react-bootstrap';
import { Styles } from "./style/InnovationStyle.js";

export default class InnovationScienceCenter extends Component {

    state = {
        displayModal: false,
        modalImage: "",
        modalDescription: "",
        modalDate: "",
        modalVideo: "",
        Datas: []
    }

    //Display Modal
    displayModal = (e) => {
        this.setState({
            displayModal: !this.state.displayModal,
        })
    }

    //Display Content
    contentModal = (e) => {
        var result = this.state.Datas.filter((x) => x.Id === e.target.id);
        this.setState({
            displayModal: !this.state.displayModal,
            modalImage: result[0]["Image"],
            modalDescription: result[0]["Description"],
            modalDate: result[0]["Year"],
            modalVideo: result[0]["Video"]
        })
    }

    //Load Data Here
    async componentDidMount() {
        const docRef = doc(firestore, "Innovation", "ScienceClub");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key }, value))
            });
            this.setState({
                Datas: Data
            })
        } else {
            this.setState({
                Datas: "NO_999"
            })
        }
    }

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Innovation" image="banners/banner-achievers.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">About - Science Club</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/innovation/scienceclub-1.jpg"} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <ul className="ttm-list ttm-list-style-icon">
                                                    <li>Science is a conduit which strengthens the society in terms of living standards, health and peace. Science and technology plays a major role in breaking the shackles of ignorance, which cripples our society. We, the faculty members at BIT passionately feel that our students are in dire need of a platform that nurtures scientific thinking and temper right from the first year of Engineering. In this connection and with a salient cue from our farsighted principal Dr. Aswath M. U., Science Centre-BIT was established on 28-02-2019. </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h6 className="title">Objectives of Science Centre - BIT</h6>
                                        </div>
                                        <div className='row jusrtify'>
                                            <div className='col-md-6 justify'>
                                                <ul className="ttm-list ttm-list-style-icon">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Design and develop experimental tools and models and use them to enhance the level of understanding of scientific concepts in different engineering subjects.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Involve and guide the students of engineering in model making in different areas.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Encourage students to metamorphose their ideas into experimental kits or tools by providing resources; with necessary technical and financial assistance.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Use the tools purposefully in tutorial classes to make the learning complete by bridging the gap between what is learnt and what is not comprehended in theory classes.</span></li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6 justify paddingtop-2">
                                                <ul className="ttm-list ttm-list-style-icon">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Motivate the students and thereby inculcate in them a scientific approach to research and development to make their technical education more meaningful and also promising them a bright future.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Organize exhibitions, workshops, quizzes etc. to provide an intellectual platform for students and faculty to learn and showcase their talent.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Contribute something beneficial to society through knowledge sharing for a better tomorrow.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To bring out newsletters.</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h6 className="title">Faculty Details</h6>
                                        </div>
                                        <table class="table">
                                            <thead style={{ backgroundColor: "#98002e", color: "white" }}>
                                                <tr>
                                                    <th scope="col">Sl No</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Designation</th>
                                                    <th scope="col">Contact</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Dr. Dhanajaya. M</td>
                                                    <td>Asst. Prof, Mathematics</td>
                                                    <td>+91 95352 53316</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Dr. Raghavendra. U. P</td>
                                                    <td>Asst Prof, Physics</td>
                                                    <td>+91 83104 84753</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Dr. Venkatesha N.J</td>
                                                    <td>Asst Prof, Chemistry</td>
                                                    <td>+91 99010 03120</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Events - Science Club</h5>
                                        </div>
                                    </div>
                                    <Styles>
                                        <div className="row ttm-service-description paddingtop-2">
                                            {
                                                this.state.Datas.length === 0 ? <Loading /> :
                                                    this.state.Datas === "NO_999" ? <NoData /> :
                                                        this.state.Datas.map((data, i) => (
                                                            <div className="col-md-4 ttm-box-col-wrapper d-flex align-items-stretch" key={i} style={{ marginBottom: "1rem" }}>
                                                                <div className="featured-imagebox featured-imagebox-blog">
                                                                    <div className="featured-thumbnail">
                                                                        <a onClick={this.contentModal}><img id={data.Id} className="img-fluid" alt="" src={data.Image} /></a>
                                                                    </div>
                                                                    <div className="featured-content">
                                                                        <div className="ttm-box-post-date">
                                                                            <span className="ttm-entry-date">
                                                                                <time className="entry-date">{data.Year}</time>
                                                                            </span>
                                                                        </div>
                                                                        <div className="featured-desc justify">
                                                                            <p>{data.Description}</p>
                                                                        </div>
                                                                        {
                                                                            data.Video != undefined ?
                                                                                <div className="post-meta" style={{ marginBottom: "-1rem" }}>
                                                                                    <a target="_blank" href={data.Video}><span className="ttm-meta-line" style={{ color: "gray" }}><i className="fa fa-link" />View Details</span></a>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                            }
                                        </div>
                                    </Styles>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <InnovationSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal */}
                <Modal size="lg" show={this.state.displayModal} onHide={this.displayModal} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-5 featured-thumbnail">
                                <img className="img-fluid" alt="" src={this.state.modalImage} />
                            </div>
                            <div className="col-7" >
                                <span className="ttm-meta-line"><b>Year : </b>{this.state.modalDate}</span>
                                <div className="featured-desc justify paddingtop-1"><p>{this.state.modalDescription}</p></div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
