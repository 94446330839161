import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import ResearchSidebar from './components/ResearchSidebar';
import { Link } from 'react-router-dom';
import Datas from './data/department-list.json';

export default class ResearchCenter extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Research" image="banners/banner-research.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Research Centers @ BIT</h4>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {
                                            Datas.dataList.map((data, i) => (
                                                <div className="col-lg-6 ttm-box-col-wrapper">
                                                    <div className="featured-imagebox featured-imagebox-blog">
                                                        <div className="row featured-content">
                                                            <div className="col-md-2" style={{ margin: "auto", fontSize: "2rem", color: "#98002e" }}>
                                                                <i className={data.icon} />
                                                            </div>
                                                            <div className="col-md-10 featured-title">
                                                                <Link to={process.env.PUBLIC_URL + "/research" + data.researchlink}><h6 style={{ color: "#98002e" }}>{data.deptTitle}</h6></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <ResearchSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}