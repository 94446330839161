import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import LifeAtBitSidebar from './components/LifeAtBitSidebar';

export default class lifeatBitNCC extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Life@BIT" image="banners/banner-lifebit.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">NCC @ BIT</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper res-767-mt-20">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifebit/ncc-1.jpg"} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <p className="">The NCC of BIT has been framed as per the requirement of NBA accreditation for Good Governance of the Institution. NCC aims to create a pool of trained, organized and motivated youth with various leadership qualities in every walk of their live and serve the Nation irrespective of career they choose.</p><br />
                                                <p>The vision of NCC at BIT is <br /><b>"To provide a suitable environment to motivate the youth to take up a career in the armed forces, to develop character, comradeship, discipline, leadership, secular outlook, spirit of adventure, and ideals of selfless service amongst the youth of the country."</b></p>
                                            </div>
                                            <div className="col-md-12 justify paddingtop-2">
                                                <h6>Mission</h6>
                                                <div className="col-md-12 justify paddingtop-2">
                                                    <ul className="ttm-list ttm-list-style-icon paddingtop-1">
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To fulfill the requirements expected in the latest socio-economic scenario in India.</span></li>
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Aims at development of character, discipline, comradeship, secular outlook, ideals of selfless services and adventure spirits amongst large numbers of young citizens.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-12 justify paddingtop-2">
                                                <h6>Aims and Objectives</h6>
                                                <div className="col-md-12 justify paddingtop-2">
                                                    <ul className="ttm-list ttm-list-style-icon paddingtop-1">
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">NCC aims to create a pool of trained, organized and motivated youth with various leadership qualities in every walk of their live and serve the Nation irrespective of career they choose.</span></li>
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">National Cadets Corps also provide a sound environment conductive towards motivating large numbers of young people of India to join armed forces.</span></li>
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Regular Activities. NSS volunteers generally work in villages, slums and voluntary agencies to complete 120 hours of regular activities during an academic year.</span></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            {/*chairman*/}
                                            <div className="section-title with-desc text-left clearfix">
                                                <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                                    <h4 className="title">Chairman of NCC Committee</h4>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <div className="ttm_single_image-wrapper mb-35">
                                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifebit/nss-chairman-1.jpg"} title="single-img-twenty" alt="single-img-twenty" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <div className="ttm-pf-single-detail-box mb-35">
                                                            <ul className="ttm-pf-detailbox-list">
                                                                <li>
                                                                    <i className="fa fa-user" />
                                                                    <span> Name : </span>Mr. Sushil. V. Kumar
                                                                </li>
                                                                <li>
                                                                    <i className="fas fa-id-card" />
                                                                    <span> Designation :</span>Asst. Professor and Chairman
                                                                </li>
                                                                <li>
                                                                    <i className="fas fa-graduation-cap" />
                                                                    <span> Department :</span>Mechanical Engineering
                                                                </li>
                                                                <li>
                                                                    <i className="fas fa-envelope" />
                                                                    <span> Mail-ID : </span>sushilkumarv@bit-bangalore.edu.in
                                                                </li>
                                                                <li>
                                                                    <i className="fas fa-phone" />
                                                                    <span> Contact Number : </span>9738115276
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className=" paddingtop-2 justify">
                                                        <h5>Roles and Responsibilities :</h5>
                                                        <div className='row'>
                                                            <div className="col-md-6 justify paddingtop-2">
                                                                <h6>NSS Unit and Programme Officer</h6>
                                                                <div className='paddingtop-2 justify'>
                                                                    <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                                                        <li><i className=" ttm-textcolor-skincolor" /><span className="ttm-list-li-content ">One NSS unit consists of 100 student volunteers guided by one Program Officer who will be a faculty member. The Programme Officer is expected to motivate the student youth to understand the values and philosophy of NSS. The overall function of Program Officer is to help the students to plan, implement and evaluate the activities of NSS under his/her charge and give proper guidance and directions to the student volunteers. </span></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 justify paddingtop-2">
                                                                <h6>NSS Unit and Programme Officer</h6>
                                                                <div className='paddingtop-2 justify'>
                                                                    <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                                                        <li><i className=" ttm-textcolor-skincolor" /><span className="ttm-list-li-content ">Any student enrolled as an NSS Volunteers, as per NSS Manual should put in at least 240 hours of useful social work in a continuous period of two year (i.e. 120 hours per year). A work diary is to be maintained by each NSS Volunteer, which will help him/her in the assessment his/her performance. Such volunteer is eligible to get NSS certificate from the colleges. The service certificate given in the NSS manual should be strictly adhered.</span></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*end chairman*/}

                                            <div className='row paddingtop-2'>
                                                <div className="col-lg-6">
                                                    <div className="ttm-pf-single-detail-box mb-35 justify">
                                                        <h5>Constitution</h5>
                                                        <ul className="ttm-pf-detailbox-list">
                                                            <li><span> The committee shall have four members, A Chairman and Three committee members.</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="ttm-pf-single-detail-box mb-35 justify">
                                                        <h5>Function</h5>
                                                        <ul className="ttm-pf-detailbox-list">
                                                            <li><span> It helps the Indian youth to develop and prepare themselves in all three Indian forces ie,  Army, Navy and Airforce.</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="ttm-pf-single-detail-box mb-35 justify">
                                                        <h5>Term</h5>
                                                        <ul className="ttm-pf-detailbox-list">
                                                            <li><span> The term of committee shall be for a period of 3 years and may be renewed till the reconstitution of new committee.</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="ttm-pf-single-detail-box  justify">
                                                        <h5>Quoram &#38; Meetings</h5>
                                                        <ul className="ttm-pf-detailbox-list">
                                                            <li><span>Quorum shall be 50% of the members to be present &#38; Minimum two meetings to be conducted every semester.</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <LifeAtBitSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}