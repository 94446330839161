import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class AdmissionSidebar extends Component {
    render() {
        return (
            <div>
                <div className="widget widget-nav-menu">
                    <ul className="widget-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + '/admission-be'}>BE Programs</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/admission-mtech'}>M. Tech Programs</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/admission-mca'}>Master in Computer Applications (MCA)</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/admission-mba'}>Master in Business Administration (MBA)</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/admission-phd'}>Ph. D Programs</NavLink></li>
                    </ul>
                </div>
            </div>
        )
    }
}
