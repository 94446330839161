import React, { Component } from "react";
import { Link } from "react-router-dom";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class ISETeaching extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Information Science and Engineering" image="banners/department-ise.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <h4 className="title">Learning Resources</h4>
                  </div>
                </div>
                <div className="ttm-service-description">
                  <p>Wide arrays of learning materials are provided to students in advance for each course to catalyze their learning ability. These resources include syllabus, course materials, and links to digital aids. Click here to access learning resources.</p>
                  <Link className="ttm-btn" style={{ fontSize: "1.15rem", paddingTop: "1rem", color: "#00a76a" }} to={process.env.PUBLIC_URL + `/teaching-learning-material/ise`}>Click Here for Learning Resources</Link>
                </div>
                <div className="section-title with-desc text-left clearfix paddingtop-2">
                  <div className="title-header">
                    <h4 className="title">Teaching - Learning</h4>
                  </div>
                </div>
                <div className="ttm-service-description justify">
                  <p>Teaching &#38; Learning Process (TLP) include adherence to academic calendar and improving instruction methods using pedagogical initiatives such as real-world examples, collaborative learning, quality of laboratory experience with regard to conducting experiments, recording observations, analysis of data, encouraging bright students, assisting weak students etc.</p><br />
                  <p>The faculties of Information Science and Engineering have the best practices in teaching learning process. The Internal Quality Assurance Cell monitors the academic activities of the department. The academic activities of the department start with subject allotment process based on the specialization of the faculties. The teacher will plan the curriculum delivery, mode of delivery and materials to be exhibited will be shown to the module coordinator, program coordinator and HOD for approval. From the commencement to end of the semester, all staffs are instructed to follow the prescribed rules of the University, Institution and the Department. Pedagogical initiatives and instructional methods are included in the teaching learning process.</p><br />
                  <ul className="ttm-list ttm-list-style-icon">
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Additional coaching is given to slow learners through Remedial classes, simplified exam-oriented coaching and materials.</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Class Toppers are honoured by certificate and memento.</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Students are encouraged to Attend National & International conferences, workshops and to publish papers in conferences/Journals.</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Take up innovative projects and apply for funding.</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Participate in various competitions.</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Take part in club activities</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />The students having high academic track records are encouraged by faculties to achieve University ranks, also encouraged to take up competitive examinations like GATE, GRE etc.,</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />The students are given additional responsibilities like Class Representatives, Placement Coordinators and organizing events in the Department/Institution level etc.</li>
                  </ul>
                </div>
                <div className="ttm-service-description">
                  <h6 className="paddingtop-1">Pedagogical Initiatives:</h6><br />
                  <ul className="ttm-list ttm-list-style-icon">
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Conventional Black Board Method</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Power Point Presentations</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />ICT Methods (Video Lectures /Animations/MOOC)</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Group Activities - Presentations</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Problem Based Learning</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Demonstrations - Mini Projects, Internships, Technical Seminars and Projects</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Providing study material through BIT website</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="ise" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
