import React, { Component } from "react";
import Banner from "../../components/common/Banner";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import Loading from "../../components/common/Loading";
import NoData from "../../components/common/NoData";
import LifeAtBitSidebar from "./components/LifeAtBitSidebar";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../Config/FbConfig";
import { Modal } from "react-bootstrap";
import { Styles } from "./style/manthanActivities.js";
import { Styles as Styles1 } from "../placement/style/placementTeam";

export default class lifeatBitSportsAndGames extends Component {
  state = {
    displayModal: false,
    modalImage: "",
    modalDescription: "",
    modalDate: "",
    modalVideo: "",
    Datas: [],
    Datas_1: [
      {
        id: "1",
        name: "Dr. B.N. Shankar Gowda",
        designation: "DEAN, Student Affairs",
        image: "sports-1.jpg",
        phone: "+91 99029 84984",
      },
      {
        id: "2",
        name: "Mr. Manu M",
        designation: "Asst. Physical Education Director",
        image: "sports-2.jpg",
        phone: "+91 96326 54332",
      },
      {
        id: "3",
        name: "Mrs. Ranjitha B",
        designation: "Asst. Physical Education Director",
        image: "sports-3.jpg",
        phone: "+91 73493 17478",
      },
      {
        id: "4",
        name: "Mr.Lava K R",
        designation: "Asst. Physical  Education director",
        image: "sports-4.jpg",
        phone: "9008520022",
      },
    ],
  };

  //Display Modal
  displayModal = (e) => {
    this.setState({
      displayModal: !this.state.displayModal,
    });
  };

  //Display Content
  contentModal = (e) => {
    var result = this.state.Datas.filter((x) => x.Id === e.target.id);
    this.setState({
      displayModal: !this.state.displayModal,
      modalImage: result[0]["Image"],
      modalDescription: result[0]["Description"],
      modalDate: result[0]["Year"],
      modalVideo: result[0]["Video"],
    });
  };

  //Load Data Here
  async componentDidMount() {
    const docRef = doc(firestore, "LifeBit", "Sports");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      var Data = [];
      Object.entries(docSnap.data()).forEach(([key, value]) => {
        Data.push(Object.assign({ Id: key }, value));
      });
      this.setState({
        Datas: Data,
      });
    } else {
      this.setState({
        Datas: "NO_999",
      });
    }
  }

  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Life@BIT" image="banners/banner-lifebit.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-single-content-area">
                  <div className="section-title with-desc text-left clearfix">
                    <div
                      className="title-header"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">
                        Department Of Physical Education And Sports
                      </h4>
                    </div>
                  </div>
                  <div className="ttm-service-description">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="ttm_single_image-wrapper">
                          <img
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/lifebit/sports.jpg"
                            }
                            alt="single-img-eighteen"
                          />
                        </div>
                      </div>
                      <div
                        className="col-md-7 justify"
                        style={{ margin: "auto" }}
                      >
                        <p>
                          The Department of Physical Education & Sports BIT was
                          established in the year 1981 with the affiliation to
                          Bangalore University, under the leadership of Mr.
                          Lingaiah, then Sports officer at BIT. Mr. K. T.
                          Dasegowda served as the Physical Education Director
                          during 1988 - 2021. Presently, Mr. Manu M and Mrs
                          Ranjitha B are the Assistant Physical Education
                          Directors. The Department of Physical Education &
                          Sports at BIT plays an integral role in the lives of
                          both students and staff on campus. Its primary
                          objective is to offer a wide range of sports,
                          recreational, and leisure activities for students and
                          staff members.
                        </p>
                      </div>
                      <div className="section-title with-desc text-left clearfix paddingtop-2">
                        <p>
                          The department is dedicated to offering services and
                          programs aimed at fostering the growth, development,
                          and retention of students in a modern and secure
                          environment that cultivates fitness, wellness, social
                          interaction, and leadership opportunities.
                        </p>
                        <div
                          className="title-header paddingtop-2"
                          style={{ borderTopColor: "red" }}
                        >
                          <h5 className="title">Vision</h5>
                        </div>
                        <div className="col-md-6  col-lg-12 justify">
                          <ul className="ttm-list ttm-list-style-icon">
                            <li>
                              <i className=" ttm-textcolor-skincolor" />
                              <span className="ttm-list-li-content">
                                To promote “Sport for ALL” and be the state of
                                art facility centre to the student and staff for
                                sound physical and psychological health.”
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="section-title with-desc text-left clearfix">
                        <div
                          className="title-header paddingtop-2"
                          style={{ borderTopColor: "red" }}
                        >
                          <h5 className="title">Mision</h5>
                        </div>
                        <div className="col-md-6  col-lg-12 justify">
                          <ul className="ttm-list ttm-list-style-icon">
                            <li>
                              <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                              <span className="ttm-list-li-content">
                                To create state of art Infrastructure for Sports
                                and Games
                              </span>
                            </li>
                            <li>
                              <i className="  fa fa-check-circle ttm-textcolor-skincolor" />
                              <span className="ttm-list-li-content">
                                To motivate Student and Staff for active
                                participation for overall personality
                                development{" "}
                              </span>
                            </li>
                            <li>
                              <i className=" fa fa-check-circle ttm-textcolor-skincolor" />
                              <span className="ttm-list-li-content">
                                To build a comprehensive program that enhances
                                student learning, team building leadership
                                qualities and wellness.
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="section-title with-desc text-left clearfix">
                        <div
                          className="title-header paddingtop-2"
                          style={{ borderTopColor: "red" }}
                        >
                          <h5 className="title">
                            Objectives of Sports and Games is to Promote{" "}
                          </h5>
                        </div>
                        <div className="row paddingtop-2 justify">
                          <div className="col-md-6  col-lg-6 justify">
                            <ul className="ttm-list ttm-list-style-icon">
                              <li>
                                <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                                <span className="ttm-list-li-content">
                                  {" "}
                                  Health: reducing Stress and strain on body and
                                  mind
                                </span>
                              </li>
                              <li>
                                <i className="  fa fa-check-circle ttm-textcolor-skincolor" />
                                <span className="ttm-list-li-content">
                                  Easy to follow, Precise Steps – By following
                                  these steps, you have clear, focused goals
                                  which make it simple to train and create the
                                  drive towards sports.
                                </span>
                              </li>
                              <li>
                                <i className=" fa fa-check-circle ttm-textcolor-skincolor" />
                                <span className="ttm-list-li-content">
                                  Good habits: Physical Education early on
                                  ensures that adults to continue exercising for
                                  many years to come.
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6  col-lg-6 justify">
                            <ul className="ttm-list ttm-list-style-icon">
                              <li>
                                <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                                <span className="ttm-list-li-content">
                                  General wellbeing: Physical Education enhances
                                  feelings of happiness.
                                </span>
                              </li>
                              <li>
                                <i className="  fa fa-check-circle ttm-textcolor-skincolor" />
                                <span className="ttm-list-li-content">
                                  Team players: Physical Education makes
                                  participants into good team players.
                                </span>
                              </li>
                              <li>
                                <i className=" fa fa-check-circle ttm-textcolor-skincolor" />
                                <span className="ttm-list-li-content">
                                  Self – help: a great way to help ourselves
                                  with our issues.
                                </span>
                              </li>
                              <li>
                                <i className=" fa fa-check-circle ttm-textcolor-skincolor" />
                                <span className="ttm-list-li-content">
                                  Overall Development: all round development,
                                  not just academic.
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*sports constitution*/}
                  <div className="section-title with-desc text-left clearfix">
                    <div
                      className="title-header paddingtop-2"
                      style={{ borderTopColor: "red" }}
                    >
                      <h5 className="title">Staff Details</h5>
                    </div>
                    <Styles1>
                      <div className="row ttm-service-msg">
                        {this.state.Datas_1.length == 0 ? (
                          <Loading />
                        ) : (
                          this.state.Datas_1.map((data, i) => (
                            <div
                              className="col-6 card"
                              style={{ marginBottom: "2rem" }}
                            >
                              <div className="row">
                                <div className="col-6">
                                  <img
                                    id={data.id}
                                    className="img-fluid"
                                    alt=""
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/lifebit/" +
                                      data.image
                                    }
                                    style={{
                                      borderRight: "solid 5px",
                                      borderColor: "#98002e",
                                    }}
                                  />
                                </div>
                                <div
                                  className="col-6"
                                  style={{ padding: "1rem" }}
                                >
                                  <div className="featured-title">
                                    <h6>{data.name}</h6>
                                    <small>{data.designation}</small>
                                  </div>
                                  <div className="post-meta">
                                    <p>{data.phone}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </Styles1>
                  </div>

                  <div className="section-title with-desc text-left clearfix">
                    <div
                      className="title-header paddingtop-2"
                      style={{ borderTopColor: "red" }}
                    >
                      <h5 className="title">
                        BIT Sports teams, Events & Accomplishments
                      </h5>
                    </div>
                  </div>
                  <Styles>
                    <div className="row ttm-service-description paddingtop-1">
                      {this.state.Datas.length === 0 ? (
                        <Loading />
                      ) : this.state.Datas === "NO_999" ? (
                        <NoData />
                      ) : (
                        this.state.Datas.map((data, i) => (
                          <div
                            className="col-md-4 ttm-box-col-wrapper d-flex align-items-stretch"
                            key={i}
                            style={{ marginBottom: "1rem" }}
                          >
                            <div className="featured-imagebox featured-imagebox-blog">
                              <div className="featured-thumbnail">
                                <a onClick={this.contentModal}>
                                  <img
                                    id={data.Id}
                                    className="img-fluid"
                                    alt=""
                                    src={data.Image}
                                  />
                                </a>
                              </div>
                              <div className="featured-content">
                                <div className="ttm-box-post-date">
                                  <span className="ttm-entry-date">
                                    <time className="entry-date">
                                      {data.Year}
                                    </time>
                                  </span>
                                </div>
                                <div className="featured-desc justify">
                                  <p>{data.Description}</p>
                                </div>
                                {data.Video != undefined ? (
                                  <div
                                    className="post-meta"
                                    style={{ marginBottom: "-1rem" }}
                                  >
                                    <a target="_blank" href={data.Video}>
                                      <span
                                        className="ttm-meta-line"
                                        style={{ color: "gray" }}
                                      >
                                        <i className="fa fa-link" />
                                        View Details
                                      </span>
                                    </a>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </Styles>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <LifeAtBitSidebar />
              </div>
            </div>
          </div>
        </div>

        {/* Modal */}
        <Modal
          size="lg"
          show={this.state.displayModal}
          onHide={this.displayModal}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-5">
                <img
                  className="img-fluid"
                  alt=""
                  src={this.state.modalImage}
                  style={{ borderRight: "solid 5px", borderColor: "#98002e" }}
                />
              </div>
              <div className="col-7" style={{ padding: "1rem" }}>
                <div className="post-meta justify">
                  <p>{this.state.modalDescription}</p>
                </div>
                <div className="date" style={{ color: "#98002e" }}>
                  {this.state.modalDate}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
