import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import LifeAtBitSidebar from './components/LifeAtBitSidebar';

export default class lifeatBitNSS_NCC extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Life@BIT" image="banners/banner-lifebit.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">NSS @ BIT</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper res-767-mt-20">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifebit/nss-1.jpg"} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <p>National Service Scheme popularly known as NSS was the scheme launched in ‘Gandhi Centenary Year 1969’ and aimed at developing students’ personality through community service.</p>
                                                <p>At BIT he activities of NSS mainly focused on technology transfer to rural mass and help the rural youth for creating the job opportunity through conducting different types of training programs. As a step towards this direction, Bangalore Institute of Technology NSS Cell has brought out the following guidelines for conducting various regular and special camping activities and to ensure uniformity in the programs. The NSS volunteers will get the benefits like self-confidence, leadership quality job opportunities etc. from the NSS activities.  </p>
                                            </div>
                                            <div className='row paddingtop-2 justify'>
                                                <h6>Objectives of NSS</h6>
                                                <div className='col-md-6 paddingtop-2 justify'>
                                                    <ul className="ttm-list ttm-list-style-icon" style={{ marginTop: "1rem" }}>
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Understand the community in which they work.</span></li>
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Understand themselves in relation to their community.</span></li>
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Develop capacity to meet emergencies and natural disasters.</span></li>
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Identify the needs and problems of the community and involve them in problem-solving.</span></li>
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Develop among themselves a sense of social and civic responsibility.</span></li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6 justify paddingtop-2">
                                                    <ul className="ttm-list ttm-list-style-icon">
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Gain skills in mobilizing community participation.</span></li>
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Acquire leadership qualities and democratic attitudes.</span></li>
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Develop competence required for group-living and sharing of responsibilities.</span></li>
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Utilize their knowledge in finding practical solutions to individual and community problems.</span></li>
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Practice national integration and social harmony.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-7 col-lg-12 paddingtop-1">
                                                <p className="justify">These objectives are attained through the service to the community. NSS is a permanent youth program under the Ministry of Youth Affairs and Sports, Government of India and funded by Government of Karnataka and Government of India in the ratio 5:7. The Guidelines take care of the required modifications based on the feedback received from the Principal and NSS Programme Officers.</p>
                                            </div>
                                            <div className="col-md-6 col-lg-12 paddingtop-1">
                                                <h6>Aims:</h6>
                                                <p className='paddingtop-2 justify'>The NSS aims in developing the inner potentialities and transforming the students mentally to fit into the society to which he/she belongs</p>
                                            </div>
                                            <div className="col-md-7 col-lg-12 paddingtop-1">
                                                <h6>Objectives at BIT</h6>
                                                <div className=' paddingtop-2 justify'>
                                                    <p>To develop multidisciplinary personality of the student youth so as to gain skills in mobilising community participation, leadership qualities, and democratic attitudes, develop capacity to meet emergencies and natural disasters, etc. along with the academics.</p>
                                                </div>

                                                {/*chairman*/}
                                                <div className="section-title with-desc text-left clearfix">
                                                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                                        <h4 className="title">Chairman of NSS Committee</h4>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <div className="ttm_single_image-wrapper mb-35">
                                                                <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifebit/ncc-chairman-1.jpg"} alt="Error" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-8">
                                                            <div className="ttm-pf-single-detail-box mb-35">
                                                                <ul className="ttm-pf-detailbox-list">
                                                                    <li>
                                                                        <i className="fa fa-user" />
                                                                        <span> Name : </span>Kartheek B S
                                                                    </li>
                                                                    <li>
                                                                        <i className="fas fa-id-card" />
                                                                        <span> Designation :</span>Asst. Professor and Chairman
                                                                    </li>
                                                                    <li>
                                                                        <i className="fas fa-graduation-cap" />
                                                                        <span> Department :</span>Civil
                                                                    </li>
                                                                    <li>
                                                                        <i className="fas fa-envelope" />
                                                                        <span>Mail-ID : </span>karthikbs@bit-bangalore.edu.in
                                                                    </li>
                                                                    <li>
                                                                        <i className="fas fa-phone" />
                                                                        <span> Contact Number : </span>+91 98867 36199
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 paddingtop-2 justify">
                                                            <h5>Roles and Responsibilities of the members of NSS committee:</h5>
                                                            <div className="col-md-12 justify paddingtop-2">
                                                                <ul className="ttm-list ttm-list-style-icon paddingtop-1">
                                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content ">The committee shall take the active interest in general welfare of the students residing in the Institute.</span></li>
                                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">The committee act as bridge between the administration and students.</span></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='paddingtop-2 justify'>
                                                    <h5> NSS Outcomes </h5>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-md-6 col-lg-6 justify paddingtop-2">
                                                        <ul className="ttm-list ttm-list-style-icon">
                                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Improve the quality of educated manpower by fostering social responsibility</span></li>
                                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Raising society to a higher material and moral level by preparing students for final dedication in the service of nation</span></li>
                                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Introduce urban students to rural life by living in contact with the community </span></li>
                                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Making campus relevant to the needs of the community </span></li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-6 col-lg-6 justify paddingtop-2">
                                                        <ul className="ttm-list ttm-list-style-icon">
                                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Develop constructive linkage between the campus and the community by supplementing technical education with social and economic reconstruction of the country </span></li>
                                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Involvement in the tasks of national development </span></li>
                                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Better understanding and appreciation of the problems of the society </span></li>
                                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Encourage community participation Roles and Responsibilities </span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className='paddingtop-2 justify'>
                                                    <h5> Events Organised</h5>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-md-6 col-lg-12 justify paddingtop-2">
                                                        <p>The NSS Unit of BIT has been fulfilling the objectives of NSS by encouraging and supporting active participation of youth in the process of socially valuable and nation building activities and has also been important in the development of personality of the youth. The volunteers participated in many activities and programmes throughout the year in order to achieve above mentioned objectives.  A few of them are given here:</p>
                                                    </div>
                                                </div>
                                                <div className='row paddingtop-2'>
                                                    <div className="col-lg-6">
                                                        <div className="ttm-pf-single-detail-box mb-35 justify">
                                                            <h5>Orientation programme</h5>
                                                            <p className='paddingtop-1'>An orientation programme was held for the newly registered volunteers of NSS. The programme was held to understand the view of newly registered volunteer about their role in nation development.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="ttm-pf-single-detail-box mb-35 justify">
                                                            <h5>Tree Plantation</h5>
                                                            <p className='paddingtop-1'>The purpose of tree plantation is to save the endangered environment and to beautify our life. NSS volunteers planted various types of more than 50 plants in the BIT campus. Tree Plantation programme was held in July and August month. </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="ttm-pf-single-detail-box mb-35 justify">
                                                            <h5>Visit to Special home</h5>
                                                            <p className='paddingtop-1'>NSS volunteers visited the hostel for hearing impaired (deaf and dumb) children and tried to convey the importance of “Swachh Bharat Mission” with the help of posters. NSS volunteers arranged a group dinner for the hostel children from the fund collected during game fair.  The visits were useful to develop a trust relationship between NSS volunteers and special children.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="ttm-pf-single-detail-box mb-35 justify">
                                                            <h5>Uttar-karnataka relief fund </h5>
                                                            <p className='paddingtop-1'>The NSS volunteers and other students along with faculty members took an initiative of collecting funds in order to support the people affected by flood in the Uttar Karnataka. •	Around 51,000 rupees were collected in the college and from other sources too. We also received few donations other than cash like rice, soaps, clothes, etc. <a href={process.env.PUBLIC_URL + "/assets/pdf/Nss/Nss.pdf"} target="_blank">Click here for report</a></p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <LifeAtBitSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}