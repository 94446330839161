import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class CSECOE extends Component {

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Electrical and Electronics Engineering" image="banners/department-eee.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title"> Center of Excellence</h4>
                                    </div>
                                    <div className="ttm-service-description">
                                        <p>The Department of Electrical and Electronics Engineering in association with department of Civil Engineering has established Centre of Excellence (COE) in interdisciplinary area “Energy Engineering”.
                                            <a className="ttm-btn" style={{ paddingLeft: "10px" }} href={process.env.PUBLIC_URL + "/assets/pdf/COE/COE - EEE.pdf"} target="_blank">For More Details Click Here</a>
                                        </p>
                                    </div>
                                    <div className="ttm-service-single-content-area">
                                        <div className="section-title with-desc text-left clearfix">
                                            <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                                <h5 className="title">Vision</h5>
                                            </div>
                                            <p>To empower excellence in research and technology development in the area of energy engineering that adapts to the pioneering philosophy of education and enhance industrial growth.</p>
                                        </div>
                                        <div className="section-title with-desc text-left clearfix">
                                            <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                                <h5 className="title">Mission</h5>
                                            </div>
                                            <ul className="ttm-list ttm-list-style-icon justify">
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Envisage to establish excellence in education, competitive edge in research and real time relevance activities with futuristic thrusts</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To promote energy education, environmental awareness, entrepreneurship development and National & International collaboration for technological growth.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To produce high quality trained professionals for  Energy Industry worldwide.</span></li>
                                            </ul>
                                        </div>
                                        <div className="section-title with-desc text-left clearfix">
                                            <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                                <h5 className="title">Objectives</h5>
                                            </div>
                                            <ul className="ttm-list ttm-list-style-icon justify">
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To develop research milieu through knowledge sharing among industry-faculty & researchers</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To provide students an industry working environment through internships& projects.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To facilitates consultancy/funded projects/publications/patent through research ambience & industry interaction.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To provide training / hands on experience/simulation & certification through C.O.E.</span></li>
                                            </ul>
                                        </div>
                                        <div className="section-title with-desc text-left clearfix">
                                            <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                                <h5 className="title">Goal</h5>
                                            </div>
                                            <p>To preserve research ambience and promote industry academic co-ordination for innovative practical application in Energy Engineering.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="eee" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
