import React, { Component } from "react";
import { Link } from "react-router-dom";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class IEMPrograms extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Industrial Engineering and Management" image="banners/department-iem.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-single-content-area">
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Under Graduate Program</h4>
                    </div>
                  </div>
                  <table className='table'>
                    <tbody>
                      <tr>
                        <th>Bachelor of Engineering</th>
                        <td>Industrial Engineering and Management</td>
                      </tr>
                      <tr>
                        <th>Started in year</th>
                        <td>1986</td>
                      </tr>
                      <tr>
                        <th>Intake</th>
                        <td>60 students</td>
                      </tr>
                      <tr>
                        <th>For Admission</th>
                        <td><Link to={process.env.PUBLIC_URL + "/admission-be"}>click here</Link></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="ttm-service-single-content-area paddingtop-2">
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Ph.D Program</h4>
                    </div>
                  </div>
                  <table className='table'>
                    <tbody>
                      <tr>
                        <th>Name of Ph. D guide</th>
                        <th>Area of research</th>
                      </tr>
                      <tr>
                        <th>Dr.Davy George Valavi</th>
                        <td>Quality Function Deployment</td>
                      </tr>
                      <tr>
                        <th>Dr.N J Krishnaprasad</th>
                        <td>Composite materials</td>
                      </tr>
                      <tr>
                        <th>Dr. Nalaraj</th>
                        <td>Composite materials</td>
                      </tr>
                      <tr>
                        <th>Dr. Harish K</th>
                        <td>Machine tools</td>
                      </tr>
                      <tr>
                        <th>Dr. Preethi K H</th>
                        <td>Welding</td>
                      </tr>
                      <tr>
                        <th>For Admission</th>
                        <td><Link to={process.env.PUBLIC_URL + "/admission-phd"}>click here</Link></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="iem" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
