import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";
import DepartmentIndustryCards from "../component/DepartmentIndustryCards";

export default class  ECEIndustry extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Electronics and Communication Engineering" image="banners/department-ece.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Industry Interactions</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description justify">
                                        <p className="justify">Department Industry Interaction encourages Faculty, Researcher and Students to explore practical knowledge with industry interaction in all possible ways. Department of Electronics and Communication Engineering, BIT has signed 11 Memorandum of Understanding (MOU) to enhance the industry institute interaction by bridging the gap pertaining to advance in the field of Electronics.</p>
                                        <DepartmentIndustryCards department="ece" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="ece" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
