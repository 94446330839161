import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import ResearchSidebar from './components/ResearchSidebar';
import Loading from '../../components/common/Loading';
import NoData from '../../components/common/NoData';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../Config/FbConfig';
import { Modal } from 'react-bootstrap';
import { Styles } from "./components/ResearchStyle.js";

export default class ResearchFacilities extends Component {

    state = {
        displayModal: false,
        modalFacilityName: "",
        modalDescription: "",
        modalDepartment: "",
        Datas: []
    }

    //Display Modal
    displayModal = (e) => {
        this.setState({
            displayModal: !this.state.displayModal,
        })
    }

    //Display Content
    contentModal = (e) => {
        var result = this.state.Datas.filter((x) => x.Id === e.target.id);
        this.setState({
            displayModal: !this.state.displayModal,
            modalFacilityName: result[0]["Name"],
            modalDescription: result[0]["Description"],
            modalDepartment: result[0]["Department"]
        })
    }

    //Load Data Here
    async componentDidMount() {
        const docRef = doc(firestore, "Research", "Facilities");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key }, value))
            });
            this.setState({
                Datas: Data
            })
        } else {
            this.setState({
                Datas: "NO_999"
            })
        }
    }

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Research" image="banners/banner-research.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Research Facilities</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            {
                                                this.state.Datas.length === 0 ? <Loading /> :
                                                    this.state.Datas === "NO_999" ? <NoData /> :
                                                        this.state.Datas.map((data, i) => (
                                                            <div className="col-lg-4 col-sm-12 col-md-12 col-xs-12 d-flex align-items-stretch">
                                                                <div className="no-gutters featured-imagebox featured-imagebox-post ttm-box-view-left-image box-shadow" style={{ borderBottom: "solid", borderColor: "#98002e" }}>
                                                                    <Styles>
                                                                        <div className="featured-content" key={i}>
                                                                            <div className="post-title featured-title">
                                                                                <h6 style={{ color: "#98002e" }}>{i + 1}. {data.Name}</h6>
                                                                            </div>
                                                                            <div className="featured-desc ttm-box-desc">
                                                                                <span className="ttm-meta-line"><b>Department :</b> {data.Department}</span>
                                                                            </div>
                                                                            <div className="featured-desc ttm-box-desc justify">
                                                                                <p>{data.Description}</p>
                                                                            </div>
                                                                            <a id={data.Id} className="ttm-btn btn-inline" style={{ marginTop: "1rem" }} onClick={this.contentModal}>Read More <i className="fas fa-angle-double-right" /></a>
                                                                        </div>
                                                                    </Styles>
                                                                </div>
                                                            </div>
                                                        ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <ResearchSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal */}
                <Modal size="lg" show={this.state.displayModal} onHide={this.displayModal} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <div className="no-gutters featured-imagebox featured-imagebox-post ttm-box-view-left-image box-shadow" style={{ borderBottom: "solid", borderColor: "#98002e" }}>
                        <div className="featured-content">
                            <div className="post-title featured-title">
                                <h6 style={{ color: "#98002e" }}>{this.state.modalFacilityName}</h6>
                            </div>
                            <div className="featured-desc ttm-box-desc">
                                <span className="ttm-meta-line"><b>Department :</b> {this.state.modalDepartment}</span>
                            </div>
                            <div className="featured-desc ttm-box-desc justify">
                                <p>{this.state.modalDescription}</p>
                            </div>
                        </div>
                    </div>
                </Modal>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}