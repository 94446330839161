import React, { Component } from "react";
import { Link } from "react-router-dom";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";


export default class ECEPrograms extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Electronics and Communication Engineering" image="banners/department-ece.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Under Graduate Program</h4>
                                        </div>
                                    </div>
                                    <table className='table'>
                                        <tbody>
                                            <tr>
                                                <th>Bachelor of Engineering</th>
                                                <td>Electronics and Communication Engineering</td>
                                            </tr>
                                            <tr>
                                                <th>Started in year</th>
                                                <td>1979</td>
                                            </tr>
                                            <tr>
                                                <th>Intake</th>
                                                <td>180</td>
                                            </tr>
                                            <tr>
                                                <th>For Admission</th>
                                                <td><Link to={process.env.PUBLIC_URL + "/admission-be"}>click here</Link></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="ttm-service-single-content-area paddingtop-2">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Post Graduate Program</h4>
                                        </div>
                                    </div>
                                    <table className='table'>
                                        <tbody>
                                            <tr>
                                                <th>M. Tech in</th>
                                                <td>VLSI Design and Embedded System</td>
                                            </tr>
                                            <tr>
                                                <th>Started in year</th>
                                                <td>2008</td>
                                            </tr>
                                            <tr>
                                                <th>Intake</th>
                                                <td>18</td>
                                            </tr>
                                            <tr>
                                                <th>For Admission</th>
                                                <td><Link to={process.env.PUBLIC_URL + "/admission-mtech"}>click here</Link></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="ttm-service-single-content-area paddingtop-2">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Ph.D Program</h4>
                                        </div>
                                    </div>
                                    <table className='table'>
                                        <tbody>
                                            <tr>
                                                <th>Name of Ph. D guide</th>
                                                <th>Area of research</th>
                                            </tr>
                                            <tr>
                                                <th>Dr Hemath Kumar A R</th>
                                                <td>Sensor Networks, Wireless Networks, Mobile Networks </td>
                                            </tr>
                                            <tr>
                                                <th>Dr Vijayaprakash A M</th>
                                                <td>VLSI, Signal and video Processing</td>
                                            </tr>
                                            <tr>
                                                <th>Dr C Byrareddy</th>
                                                <td>Antennas and Wireless Communication</td>
                                            </tr>
                                            <tr>
                                                <th>Dr A B Kalpana</th>
                                                <td>VLSI, Embedded Systems, HDL, Signal Processing</td>
                                            </tr>
                                            <tr>
                                                <th>Dr Narendra C P	</th>
                                                <td>Signal Processing</td>
                                            </tr>
                                            <tr>
                                                <th>Dr Mukthi S L</th>
                                                <td>VLSI, Antennas and Embedded System</td>
                                            </tr>
                                            <tr>
                                                <th>Dr D Vijayalakshmi</th>
                                                <td>VLSI Design</td>
                                            </tr>
                                            <tr>
                                                <th>Dr Jalaja S</th>
                                                <td>VLSI Architecture, VLSI in AI, Low VLSI Design</td>
                                            </tr>
                                            <tr>
                                                <th>For Admission</th>
                                                <td><Link to={process.env.PUBLIC_URL + "/admission-phd"}>click here</Link></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="ece" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div >
        )
    }
}
