import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import { Styles } from "./styles/event.js";
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../Config/FbConfig';

export default class Events extends Component {

    state = {
        Datas: [],
        Loading: true
    }

    //Load Data Here
    async componentDidMount() {
        const docRef = doc(firestore, "Home", "Events");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key }, value))
            });
            this.setState({
                Datas: Data,
                Loading: false
            })
        } else {
            this.setState({
                Datas: "NO_999",
                Loading: false
            })
        }
    }

    render() {
        const settings = {
            slidesPerView: 4,
            loop: true,
            speed: 1000,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            spaceBetween: 30,
            watchSlidesVisibility: true,
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                576: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 3
                },
                992: {
                    slidesPerView: 4
                }
            }
        };

        return (
            <div>
                <section className="ttm-row bottomzero-padding-section ttm-bg ttm-bgimage-yes bg-img5 clearfix" style={{ backgroundColor: "#98002e" }}>
                    <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
                    <div className="container-xl pb-5 res-991-pb-0">
                        <div className="row align-items-center ttm-textcolor-white">
                            <div className="col-lg-5 res-991-text-center">
                                <div className="section-title style3 mb-0 clearfix">
                                    <div className="title-header mb-0">
                                        <h2 className="title ttm-textcolor-white">Events @ BIT</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 res-991-text-center">
                                <div className="title-desc">BIT is a place of happenings with Youth energy, Creativity, Intellectuality and Spirit</div>
                            </div>
                        </div>
                    </div>
                </section>
                <Styles>
                    <section className="event-area">
                        <Container>
                            {this.state.Loading === true ? "Loading.." :
                                <Swiper {...settings}>
                                    {this.state.Datas.map((data, i) => (
                                        <div className="team-item" key={i}>
                                            <a target="_blank" href={data.Image}><img src={data.Image} alt="" className="img-fluid" /></a>
                                            <div className="sec-title">
                                                <p className='justify'>{data.Description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </Swiper>
                            }
                        </Container>
                    </section>
                </Styles>
            </div>
        )
    }
}
