import React, { Component } from "react";
import DepartmentIndustryCards from "../component/DepartmentIndustryCards";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class CSEIndustry extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Computer Science and Engineering" image="banners/department-cse.jpg"/>

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Industry Interactions</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <p className="justify">Presently there are 7 MOU's signed and associated with CSE Department. Workshops and Webinars are conducted in every academic years.
                                            <a className="ttm-btn" style={{ paddingLeft: "10px" }} href="https://docs.google.com/spreadsheets/d/1z5dv00JBkef7Yibt7oFCezojK0W-E53-8p2l3sUtUus/edit?usp=sharing" target="_blank">Industry Interactions Link</a>
                                            <a className="ttm-btn" style={{ paddingLeft: "10px" }} href="https://drive.google.com/drive/folders/1FNeCAKJsxG5BcnvevlCsJmaKsL8oGr_0?usp=sharing" target="_blank">MOU Agreements Copy</a>
                                        </p>
                                        <div className="section-title with-desc text-left clearfix paddingtop-2">
                                            <div className="title-header" style={{ borderTopColor: "red" }}>
                                                <h5> Internships With Industries</h5>
                                            </div>
                                        </div>
                                        <p>With the number of students who choose engineering as their career being on the rise over the past couple of decades, making the students stand out and excel at their choice becomes an imperative of the present day scemarios. The All India Council for Technical Education (AICTE) decided to make three internships, each of 4-8 week duration mandatory for engineering undergraduates to facilitate the achievements of a few goals, the primary goal being easy employability.</p>
                                        <div className="section-title with-desc text-left clearfix paddingtop-2">
                                            <div className="title-header" style={{ borderTopColor: "red" }}>
                                                <h5>Why Internships with Industries is necessary?</h5>
                                            </div>
                                        </div>
                                        <ul className="ttm-list ttm-list-style-icon">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To fixate the standards of technical institutions in terms of delivering the objectives and quality education.</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To enhance employment opportunities for engineering students by imparting required knowledge and skills.</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To increase college placement percentage.</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To increase the percentage of students participating in summer internships.</span></li>
                                        </ul>
                                        <DepartmentIndustryCards department="cse" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="cse" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
