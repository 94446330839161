import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class IEMCOE extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner
          title="Industrial Engineering and Management"
          image="banners/department-iem.jpg"
        />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-single-content-area">
                  <div className="section-title with-desc text-left clearfix">
                    <div
                      className="title-header"
                      style={{ borderTopColor: "red" }}
                    >
                      <h3 className="title">
                        L D Miles Centre of Excellence for value Engineering
                      </h3>
                    </div>
                  </div>
                  <div className="ttm-service-description">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="ttm_single_image-wrapper">
                          <img
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/departments/iem/iem-coe-1.jpg"
                            }
                            alt="single-img-eighteen"
                          />
                        </div>
                      </div>
                      <div className="col-md-7 justify">
                        <ul className="ttm-list ttm-list-style-icon">
                          <li>
                            <i className="ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              The L D Miles Center of Excellence for Value
                              Engineering is established in the year 2021 with
                              Prof. V S Giri as coordinator. This center is in
                              association with Indian Value Engineering Society
                              (INVEST).{" "}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/*start of objectives of COE*/}
                  <div className="row paddingtop-2">
                    <div className="col-md-12">
                      <div className="row paddingtop-2">
                        <div className="col-md-12">
                          <div className="section-title with-desc text-left clearfix">
                            <div className="title-header">
                              <h4 className="title">Objecives</h4>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <ul className="ttm-list ttm-list-style-icon">
                            <li>
                              <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                              <span className="ttm-list-li-content">
                                To promote the importance of value and value
                                engineering.{" "}
                              </span>
                            </li>
                            <li>
                              <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                              <span className="ttm-list-li-content">
                                To take up consultation in value engineering.
                              </span>
                            </li>
                            <li>
                              <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                              <span className="ttm-list-li-content">
                                To take up project works.
                              </span>
                            </li>
                            <li>
                              <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                              <span className="ttm-list-li-content">
                                To have Institution and Industry interaction.{" "}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                          <ul className="ttm-list ttm-list-style-icon">
                            <li>
                              <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                              <span className="ttm-list-li-content">
                                To conduct workshop, seminar and conference.{" "}
                              </span>
                            </li>
                            <li>
                              <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                              <span className="ttm-list-li-content">
                                To interact with experts in the field.
                              </span>
                            </li>
                            <li>
                              <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                              <span className="ttm-list-li-content">
                                To have certified course in value engineering
                                (CVS Certification).
                              </span>
                            </li>
                            <li>
                              <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                              <span className="ttm-list-li-content">
                                To encourage students to carry on research work
                                and project works.
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*end of objecitves of coe*/}
                  {/* start pics of coe*/}
                  <div className="ttm-service-description">
                    <div className="row paddingtop-2">
                      <div className="col-md-6">
                        <div className="ttm_single_image-wrapper">
                          <img
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/departments/iem/iem-coe-2.jpg"
                            }
                            alt="single-img-eighteen"
                          />
                        </div>
                      </div>
                      <div className="col-md-6" style={{ margin: "auto" }}>
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/departments/iem/iem-coe-3.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                    </div>
                    <div className="row paddingtop-2">
                      <div className="col-md-6">
                        <div className="ttm_single_image-wrapper">
                          <img
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/departments/iem/iem-coe-4.jpg"
                            }
                            alt="single-img-eighteen"
                          />
                        </div>
                      </div>
                      <div className="col-md-6" style={{ margin: "auto" }}>
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/departments/iem/iem-coe-5.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                    </div>
                  </div>
                  {/*end pics of coe*/}
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="iem" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
