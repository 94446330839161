import React, { Component } from 'react'

export default class DepartmentVideo extends Component {
  render() {
    const video = {
      cse:   "https://player.vimeo.com/video/765605544",
      ise:   "https://player.vimeo.com/video/714699414",
      ai:    "https://player.vimeo.com/video/751075881",
      ece:   "https://player.vimeo.com/video/701957411" ,
      mech:  "https://player.vimeo.com/video/715463517",
      civil: "https://player.vimeo.com/video/706046662",
      eee:   "https://player.vimeo.com/video/765607412",
      ete:   "https://player.vimeo.com/video/733933381",
      eie:   "https://player.vimeo.com/video/701957012",
      iem:   "https://player.vimeo.com/video/714383705",
      mba:   "https://player.vimeo.com/video/751072899",
      mca:   "https://player.vimeo.com/video/701957695",
      phy:   "https://player.vimeo.com/video/752841144",
      chem:  "https://player.vimeo.com/video/706507837",
      math:  "https://player.vimeo.com/video/752851672"
    }
    return (
        <div className="section-title with-desc text-left clearfix">
            <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                <h4 className="title">Department Video</h4>
            </div>
            <iframe title="1" src={video[this.props.department]} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
        </div>
    )
  }
}

