import React, { Component } from 'react';
import Banner from '../../../components/common/Banner';
import Header from '../../../components/common/Header';
import DepartmentSidebar from '../component/DepartmentSidebar';
import Footer from '../../../components/common/Footer';

export default class EEEContact extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Electrical and Electronics Engineering" image="banners/department-eee.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header">
                                        <h3 className="title">Contact</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>HOD</h6></div>
                                                <div className="featured-desc">
                                                    <p>Dr. Sudha.G</p>
                                                    <p>E-mail Id:eeebit2020@gmail.com</p>
                                                    <p>Contact Number: 9902730103</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Foreman</h6></div>
                                                <div className="featured-desc">
                                                    <p>Y.Sadashiva</p>
                                                    <p>E-mail Id:prashanthkumarkn@bit-bangalore.edu.in</p>
                                                    <p> Contact Number: 9844419092</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Instructor</h6></div>
                                                <div className="featured-desc">
                                                    <p>Govind Raj</p>
                                                    <p>E-mail Id:nikithaks@bit-bangalore.edu.in </p>
                                                    <p> Contact Number: 9845113837</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Telegram Link</h6></div>
                                                <div className="featured-desc"><a href="https://t.me/joinchat/Rg4QQBac_1-V_I_cjaOM3A" target="_blank">https://t.me/joinchat/Rg4QQBac_1-V_I_cjaOM3A</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="eee" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}

