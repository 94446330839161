import React, { Component } from 'react';
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";
import NoData from "../../../components/common/NoData";
import Loading from "../../../components/common/Loading";
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../Config/FbConfig';

export default class DepartmentSpecificTeachingFaculties extends Component {

  state = { Datas: [] }

  //Load Data Here
  async componentDidMount() {
    const docRef = doc(firestore, this.props.match.params.department, "FacultyTeaching");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      var Data = []
      Object.entries(docSnap.data()).forEach(([key, value]) => {
        if (key == this.props.match.params.id) {
          Data.push(Object.assign({ Id: key }, value))
        }
      });
      console.log(Data)
      console.log(Data.ResearchInterest)
      this.setState({
        Datas: Data
      })
    } else {
      this.setState({
        Datas: "NO_999"
      })
    }
  }

  render() {
    const banner = {
      cse: <Banner title="Computer Science and Engineering" image="banners/department-cse.jpg" />,
      ise: <Banner title="Information Science and Engineering" image="banners/department-ise.jpg" />,
      ai: <Banner title="Artificial Intelligence and Machine Learning" image="banners/department-ai.jpg" />,
      ece: <Banner title="Electronics and Communication Engineering" image="banners/department-ece.jpg" />,
      mech: <Banner title="Mechanical Engineering" image="banners/department-me.jpg" />,
      ce: <Banner title="Civil Engineering" image="banners/department-me.jpg" />,
      eee: <Banner title="Electrical and Electronics Engineering" image="banners/department-eee.jpg" />,
      ete: <Banner title="Electronics and Telecommunication Engineering" image="banners/department-ete.jpg" />,
      eie: <Banner title="Electronics and Instrumentation Engineering" image="banners/department-eie.jpg" />,
      iem: <Banner title="Industrial Engineering and Management" image="banners/department-iem.jpg" />,
      mba: <Banner title="Master of Business Administration" image="banners/department-mba.jpg" />,
      mca: <Banner title="Master of Computer Applications (MCA)" image="banners/department-mca.jpg" />,
      phy: <Banner title="Physics" image="banners/department-phy.jpg" />,
      chem: <Banner title="Chemistry" image="banners/department-che.jpg" />,
      math: <Banner title="Mathematics" image="banners/department-math.jpg" />,
      hum: <Banner title="Humanities" image="banners/department-hum.jpg" />,
    }
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        {banner[this.props.match.params.department]}

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-description paddingtop-1 justify">
                  <table class="table">
                    <tbody>
                      {
                        this.state.Datas.length === 0 ? <Loading /> :
                          this.state.Datas === "NO_999" ? <NoData /> :
                            <div className="row">
                              <div className="col-lg-3" style={{ margin: "auto" }}>
                                <div className="ttm-featured-wrapper">
                                  <div className="featured-thumbnail text-center">
                                    <img src={this.state.Datas[0].Image} className="img-fluid" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-9 justify" style={{ margin: "auto" }}>
                                <div className="member-info-box border">
                                  <h5>{this.state.Datas[0].Name}</h5>
                                  <small>{this.state.Datas[0].Designation}</small><hr />
                                  <ul className="info-list">
                                    <li><b>Phone :</b> <span>{this.state.Datas[0].PhoneNumber}</span></li>
                                    <li><b>Email Id :</b> <span>{this.state.Datas[0].EmailId}</span></li>
                                    <li><b>Qualification :</b> <span>{this.state.Datas[0].Qualification}</span></li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-lg-12 justify" style={{ margin: "auto" }}>
                                <div style={{ backgroundColor: "#98002e", color: "white", fontSize: "1.5rem", padding: "1rem", fontWeight: "bold" }}>Specialization</div>
                                <div className="member-info-box border"><p>{this.state.Datas[0].Specialisation}</p></div>
                              </div>
                              <div className="col-lg-12 justify" style={{ margin: "auto" }}>
                                <div style={{ backgroundColor: "#98002e", color: "white", fontSize: "1.5rem", padding: "1rem", fontWeight: "bold" }}>Teaching Interest</div>
                                <div className="member-info-box border"><p>{this.state.Datas[0].TeachingInterest}</p></div>
                              </div>
                              <div className="col-lg-12 justify" style={{ margin: "auto", display: this.state.Datas[0].ResearchInterest != null && this.state.Datas[0].ResearchInterest != "" && this.state.Datas[0].ResearchInterest != undefined ? "block" : "none" }}>
                                <div style={{ backgroundColor: "#98002e", color: "white", fontSize: "1.5rem", padding: "1rem", fontWeight: "bold" }}>Research Interest and Guide ship</div>
                                <div className="member-info-box border"><p>{this.state.Datas[0].ResearchInterest}</p></div>
                              </div>
                              <div className="col-lg-12 justify" style={{ margin: "auto", display: this.state.Datas[0].Publications != null && this.state.Datas[0].Publications != "" && this.state.Datas[0].Publications != undefined ? "block" : "none" }}>
                                <div style={{ backgroundColor: "#98002e", color: "white", fontSize: "1.5rem", padding: "1rem", fontWeight: "bold" }}>Publications</div>
                                <div className="member-info-box border"><p>{this.state.Datas[0].Publications}</p></div>
                              </div>
                              <div className="col-lg-12 justify" style={{ margin: "auto" }}>
                                <div style={{ backgroundColor: "#98002e", color: "white", fontSize: "1.5rem", padding: "1rem", fontWeight: "bold" }}>Academic Links</div>
                                <div className="member-info-box border">
                                  <div className="row">
                                    <div className="col-lg-4" style={{ display: this.state.Datas[0].googleScholar != null ? "block" : "none" }}>
                                      <b>Google Scholar : </b>
                                      <a href={this.state.Datas[0].googleScholar} target="_blank">Click Here</a>
                                    </div>
                                    <div className="col-lg-4" style={{ display: this.state.Datas[0].researchGate != null ? "block" : "none" }}>
                                      <b>Research Gate : </b>
                                      <a href={this.state.Datas[0].researchGate} target="_blank">Click Here</a>
                                    </div>
                                    <div className="col-lg-4" style={{ display: this.state.Datas[0].orchidAccounts != null ? "block" : "none" }}>
                                      <b>Orchid Account : </b>
                                      <a href={this.state.Datas[0].orchidAccounts} target="_blank">Click Here</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12" style={{ margin: "auto", display: this.state.Datas[0].books.length != 0 ? "block" : "none" }}>
                                <div style={{ backgroundColor: "#98002e", color: "white", fontSize: "1.5rem", padding: "1rem", fontWeight: "bold" }}>Books Authored</div>
                                <div className="member-info-box border">
                                  <table className='table'>
                                    <tbody>
                                      <tr>
                                        <th>Title</th>
                                        <th>Year</th>
                                        <th>Name of the Publisher</th>
                                        <th>ISBN/ISSN Number</th>
                                      </tr>
                                      {
                                        this.state.Datas[0].books.map((data, i) => (
                                          <tr>
                                            <td>{data.Title}</td>
                                            <td>{data.Year}</td>
                                            <td>{data.Publisher}</td>
                                            <td>{data.ISBN}</td>
                                          </tr>
                                        ))
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="col-lg-12 justify" style={{ margin: "auto", display: this.state.Datas[0].memberships != null && this.state.Datas[0].memberships != "" && this.state.Datas[0].memberships != undefined ? "block" : "none" }}>
                                <div style={{ backgroundColor: "#98002e", color: "white", fontSize: "1.5rem", padding: "1rem", fontWeight: "bold" }}>Professional and Academic Memberships</div>
                                <div className="member-info-box border"><p>{this.state.Datas[0].memberships}</p></div>
                              </div>
                              <div className="col-lg-12 justify" style={{ margin: "auto", display: this.state.Datas[0].grants.length != 0 ? "block" : "none" }}>
                                <div style={{ backgroundColor: "#98002e", color: "white", fontSize: "1.5rem", padding: "1rem", fontWeight: "bold" }}>Research grants and student project grants</div>
                                <div className="member-info-box border">
                                  <table className='table'>
                                    <tbody>
                                      <tr>
                                        <th>Name of the research project</th>
                                        <th>Funding Agency</th>
                                        <th>Amount sanctioned</th>
                                        <th>Year of sanction</th>
                                      </tr>
                                      {
                                        this.state.Datas[0].grants.map((data, i) => (
                                          <tr>
                                            <td>{data.Name}</td>
                                            <td>{data.Agency}</td>
                                            <td>{data.Amount}</td>
                                            <td>{data.Year}</td>
                                          </tr>
                                        ))
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="col-lg-12 justify" style={{ margin: "auto", display: this.state.Datas[0].workshops.length != 0 ? "block" : "none" }}>
                                <div style={{ backgroundColor: "#98002e", color: "white", fontSize: "1.5rem", padding: "1rem", fontWeight: "bold" }}>Workshops / Seminar / FDP organized</div>
                                <div className="member-info-box border">
                                  <table className='table'>
                                    <tbody>
                                      <tr>
                                        <th>Name</th>
                                        <th>Date</th>
                                        <th>Number of participants</th>
                                        <th>Report</th>
                                      </tr>
                                      {
                                        this.state.Datas[0].workshops.map((data, i) => (
                                          <tr>
                                            <td>{data.Name}</td>
                                            <td>{data.Date}</td>
                                            <td>{data.participants}</td>
                                            <td><a href={data.Link} target="_blank">Link</a></td>
                                          </tr>
                                        ))
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="col-lg-12 justify" style={{ margin: "auto", display: this.state.Datas[0].talks.length != 0 ? "block" : "none" }}>
                                <div style={{ backgroundColor: "#98002e", color: "white", fontSize: "1.5rem", padding: "1rem", fontWeight: "bold" }}>Invited Talks</div>
                                <div className="member-info-box border">
                                  <table className='table'>
                                    <tbody>
                                      <tr>
                                        <th>Title</th>
                                        <th>Date</th>
                                        <th>Invited by</th>
                                        <th>Report</th>
                                      </tr>
                                      {
                                        this.state.Datas[0].talks.map((data, i) => (
                                          <tr>
                                            <th>{data.Title}</th>
                                            <td>{data.Date}</td>
                                            <td>{data.Invited}</td>
                                            <td><a href={data.Link} target="_blank">Link</a></td>
                                          </tr>
                                        ))
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="col-lg-12 justify" style={{ margin: "auto", display: this.state.Datas[0].awards.length != 0 ? "block" : "none" }}>
                                <div style={{ backgroundColor: "#98002e", color: "white", fontSize: "1.5rem", padding: "1rem", fontWeight: "bold" }}>State / National / International Recognitions and Awards</div>
                                <div className="member-info-box border">
                                  <table className='table'>
                                    <tbody>
                                      <tr>
                                        <th>Awardee</th>
                                        <th>Award</th>
                                        <th>Awarding Agency</th>
                                        <th>Year</th>
                                      </tr>
                                      {
                                        this.state.Datas[0].awards.map((data, i) => (
                                          <tr>
                                            <th>{data.Awardee}</th>
                                            <td>{data.Award}</td>
                                            <td>{data.Agency}</td>
                                            <td>{data.Year}</td>
                                          </tr>
                                        ))
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="col-lg-12 justify" style={{ margin: "auto", display: this.state.Datas[0].extension != null && this.state.Datas[0].extension == "" && this.state.Datas[0].extension == undefined ? "block" : "none" }}>
                                <div style={{ backgroundColor: "#98002e", color: "white", fontSize: "1.5rem", padding: "1rem", fontWeight: "bold" }}>Extension & Outreach Activities</div>
                                <div className="member-info-box border"><p>{this.state.Datas[0].extension}</p></div>
                              </div>
                              <div className="col-lg-12 justify" style={{ margin: "auto"}}>
                                <div style={{ backgroundColor: "#98002e", color: "white", fontSize: "1.5rem", padding: "1rem", fontWeight: "bold" }}>Personal website</div>
                                <div className="member-info-box border"><div className="title-header"><h5 className="title"></h5></div>
                                  <p><a href={this.state.Datas[0].personalWebsite} target="_blank">{this.state.Datas[0].personalWebsite}</a></p>
                                </div>
                              </div>
                            </div>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department={this.props.match.params.department} />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}
