import React, { Component } from "react";
import Banner from "../../components/common/Banner";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import LifeAtBitSidebar from "./components/LifeAtBitSidebar";

export default class lifeatBitFacilities extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Life@BIT" image="banners/banner-lifebit.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix justify">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-single-content-area">
                  <div className="section-title with-desc text-left clearfix">
                    <div
                      className="title-header"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">Facilities</h4>
                    </div>
                  </div>
                  <div className="ttm-service-description">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="ttm_single_image-wrapper">
                          <img
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/lifebit/infrastructure.jpg"
                            }
                            alt="Error"
                          />
                        </div>
                      </div>
                      <div className="col-md-8" style={{ margin: "auto" }}>
                        <ul className="ttm-list ttm-list-style-icon">
                          <li>
                            <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              <div style={{ fontWeight: "bold" }}>
                                Infrastructure:
                              </div>
                              BIT is located in the heart of the city with all
                              the required infrastructure facilities like well -
                              furnished digital classrooms, well-equipped
                              laboratories in all the departments and adequate
                              infrastructure for indoor and outdoor games and
                              extra-curricular activities.
                            </span>
                            <br />
                            <br />
                            <a
                              style={{ color: "#572700", fontWeight: "bold" }}
                              target="_blank"
                              href="https://drive.google.com/file/d/1630NAzcV7FqNaeSB64qt1O-uPiPxtiAS/view?usp=share_link"
                            >
                              For More Info Click Here
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="row paddingtop-2">
                      <div className="col-md-4">
                        <div className="ttm_single_image-wrapper">
                          <img
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/lifebit/hostel.jpg"
                            }
                            alt="Error"
                          />
                        </div>
                      </div>
                      <div className="col-md-8" style={{ margin: "auto" }}>
                        <ul className="ttm-list ttm-list-style-icon">
                          <li>
                            <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              <div style={{ fontWeight: "bold" }}>Hostel:</div>{" "}
                              Hostel is like a family of students from all
                              corners of the country. Students develop a sense
                              of friendship and fellow feeling. They learn the
                              value of discipline, a sense of responsibility; do
                              all the works on their own and thus becoming
                              independent. It will not be an exaggeration to say
                              that hostels in BIT campus provides a room for an
                              all-round development of students besides serving
                              hygienic food.
                            </span>
                            <br />
                            <br />
                            <a
                              style={{ color: "#572700", fontWeight: "bold" }}
                              target="_blank"
                              href="https://drive.google.com/file/d/1i0YS8IakFzyEUHkvZvJFjrRF8NM7n4mo/view?usp=sharing"
                            >
                              For More Info Click Here
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="row paddingtop-2">
                      <div className="col-md-4">
                        <div className="ttm_single_image-wrapper">
                          <img
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/lifebit/canteen.jpg"
                            }
                            alt="Error"
                          />
                        </div>
                      </div>
                      <div className="col-md-8" style={{ margin: "auto" }}>
                        <ul className="ttm-list ttm-list-style-icon">
                          <li>
                            <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              <div style={{ fontWeight: "bold" }}>Canteen:</div>{" "}
                              College canteen is one of the most happening
                              places in the campus. Of course, it is known for
                              its savory food, but it is also the place where
                              students make some of the best memories of their
                              college life. In BIT campus one can find two
                              canteens at the main entrance and at the back
                              entrance catering all possible varieties of food.
                              Both the canteens are hygienic and well
                              maintained.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="row paddingtop-2">
                      <div className="col-md-4">
                        <div className="ttm_single_image-wrapper">
                          <img
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/lifebit/health.jpg"
                            }
                            alt="Error"
                          />
                        </div>
                      </div>
                      <div className="col-md-8" style={{ margin: "auto" }}>
                        <ul className="ttm-list ttm-list-style-icon">
                          <li>
                            <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              <div style={{ fontWeight: "bold" }}>
                                Health service:
                              </div>{" "}
                              Health Services can create an environment of
                              support by providing students with knowledge to
                              deal with an acute or chronic illness. A sense of
                              support can lessen the students’ anxiety when they
                              are ill, which may decrease the discomfort of a
                              physical malady. Treatment for illness and a
                              supportive environment facilitates a quicker
                              recovery, which may enable the student to return
                              to class and course of studies in a timely manner
                              than they
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-12" style={{ margin: "auto" }}>
                        <p className="paddingtop-2">
                          would otherwise. BIT believes in this and has taken
                          the responsibility to provide primary health support.
                          Students of BIT get adequate support in this regard
                          from the sister concerned institution- KIMS hospital
                          which is also housed in the same campus.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="row paddingtop-2">
                      <div className="col-md-4">
                        <div className="ttm_single_image-wrapper">
                          <img
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/lifebit/bank.jpg"
                            }
                            alt="Error"
                          />
                        </div>
                      </div>
                      <div className="col-md-8" style={{ margin: "auto" }}>
                        <ul className="ttm-list ttm-list-style-icon">
                          <li>
                            <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                            <span className="ttm-list-li-content">
                              <div style={{ fontWeight: "bold" }}>Bank:</div>{" "}
                              Canara Bank has a branch and ATM counter in the
                              campus. It is in the First floor of the main
                              building. Students can open the account easily by
                              fulfilling the common requirements of the bank.
                              The working hours of the bank MON-SAT: 10.00 am –
                              3.30pm except on Second and Fourth Saturday,
                              General holiday and Bank holidays
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <LifeAtBitSidebar />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
