import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class PHYCOE extends Component {

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Physics" image="banners/department-phy.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Center of Excellence</h4>
                                        </div>
                                        <h6>Shock waves and Material Science in Engineering</h6>
                                        <div className="section-title with-desc text-left clearfix paddingtop-2">
                                            <div className="title-header" style={{ borderTopColor: "red" }}>
                                                <h5 className="title">Motives</h5>
                                            </div>
                                        </div>
                                        <b>i.   Shock waves</b><br /><br />
                                        <p>Shock waves have been traditionally considered to be an integral part of flow field features in the area of high-speed aerodynamics. Physically the propagation of shock waves in any media is invariably associated with instantaneous increase in pressure and temperature behind the shockwave. The capability of shock waves to generate non-linear pressure and temperature spikes in the medium of propagation finds very interesting applications in variety of areas such as medicine, biological sciences, material processing, manufacturing, and microelectronic industries. Some of the promising applications are (i) preservative injection into bamboos, (ii) sandal oil extraction,(iii) removal of micron size dust from silicon wafer surfaces (iv) enhancement of fluorescence properties of heterocyclic compounds (v) aerodynamic applications such as transonic gas turbine blade tip gaps, transonic turbine blade passages, scramjet isolator ducts, supersonic aircraft engine intakes, adjacent to transonic and supersonic flight vehicle surfaces, and nearby surfaces of rockets, missiles etc</p><br />
                                        <b>ii.  Material Science</b><br /><br />
                                        <p>Materials have become inevitable part of human life. Every segment of our daily life is influenced by materials. Development and advancement of the civilization is intimately connected to usage of materials from time to time. Initially man was aware of very few materials such as wood, stone, clay and very few metals. With the development of technology, he learnt how to synthesize newer materials. Today, in the present world, we have thousands of materials which include metals, alloys, plastics, glasses, fibers, polymers, composite materials etc.</p><br />
                                        <p>The usage of the material is strongly decided by its mechanical, thermal, electrical and optical properties. These properties directly depend on its structure. Materials Science deals with investigating relationships that exist between the structure and properties of materials. On the basis of these structure-property correlations, designing or engineering the structure of a material to produce a pre-determined set of properties is nothing but Materials Engineering. Synthesis and characterizational studies of materials for thin films, composite materials, polymers, ceramics, liquid crystals, piezo electric materials, magnetic materials, fluorescent materials, nanomaterials is a promising field in recent days.</p><br />
                                        <b>II. Action Plan:</b><br /><br />
                                        <p>Shock waves is a part of Engineering Physics curriculum and all the faculty are acquainted with the fundamentals of shock waves. Further, the department of physics had organized TWO days’ workshop on Shock waves which gave them required confidence to proceed in the field of shock waves.</p><br />
                                        <p>Ph.D work of five faculty members is very closely related to material science. Hence quality work may be expected in the field of Material Science and engineering.</p><br />
                                        <p>Conducted few  department level meetings and have decided to </p><br />
                                        <p>To meet Dr.KPJ Reddy Rtd Professor, Department of Aeronautics Engineering, IISc and Dr.G.Jagadeesh, Professor,  Department of Aeronautics Engineering, IISc</p><br />
                                        <p>To interact with Dr.Mahesh H M Chairman, Department of electronics, Bangalore University, Bengaluru</p><br />
                                        <p>Dr.Lokanath N K, Prof, Department of Physics, Manasagangotri, Mysore</p><br />
                                        <b>III. Objectives</b><br /><br />
                                        <p>Collaborate with leading R & D institutions such as IISc, IIT and JNCASR</p><br />
                                        <p>Assist Engineering students of CV, ME, EEE, IEM and EC branches in their project works</p><br />
                                        <p>To publish research articles in Scopus indexed journals</p><br />
                                        <p>Jointly work with other departments within the campus</p><br />
                                        <b>IV. Long term goals</b><br /><br />
                                        <p>To put an effort to interact with experts from International Shock Wave Research Centers like Tohoku University Sendai, Japan.</p><br />
                                        <p>Collaborate with related industries</p><br />
                                        <p>Apply for research grants and patents</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="phy" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
