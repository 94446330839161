import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class CHEConsultancy extends Component {

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Chemistry" image="banners/department-che.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Consultancy Services</h4>
                                    </div>
                                    <div>
                                        <p>Chemistry Research Centre, BIT is equipped with various  analytical facilities for characterization of catalysts and other chemical samples. These facilities are open to serve the needs of research scholars of other departments of the institute and also researchers from various colleges, universities and Industries.</p><br />
                                    </div>
                                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">EXPERIMENTAL AND ANALYTICAL TEST FACILITIES:</h4>
                                    </div>
                                    <div>
                                        <p>Chemistry Research Centre, BIT is equipped with various reactors and analytical facilities for conducting catalytic studies as well characterization of catalysts and other chemical samples. These facilities are open to serve the needs of research students of the other departments of the institute and also researchers from various colleges, universities and Industries. The users are charged with a nominal amount to partly meet the maintenance cost of the equipment.</p><br />
                                    </div>
                                    <div>
                                        <h6>The facilities available in Chemistry research Centre and the charges for each facility is given below.</h6><br />
                                        <table class="table">
                                            <thead style={{ backgroundColor: "#98002e", color: "white" }}>
                                                <tr>
                                                    <th scope="col" style={{ width: "10%" }}>Sl No</th>
                                                    <th scope="col">Facility</th>
                                                    <th scope="col">Requirements</th>
                                                    <th scope="col">Academic use</th>
                                                    <th scope="col">Industrial use</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">1</th>
                                                    <td>BET Surface area analyzer</td>
                                                    <td>Liquid Nitrogen (5 Ltr/ Sample)
                                                        N2 gas cylinder
                                                    </td>
                                                    <td>1500 Rs/ Sample</td>
                                                    <td>3000 Rs/ Sample</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">1</th>
                                                    <td>BET Surface area analyzer</td>
                                                    <td>Liquid Nitrogen (5 Ltr/ Sample)
                                                        N2 gas cylinder
                                                    </td>
                                                    <td>1500 Rs/ Sample</td>
                                                    <td>3000 Rs/ Sample</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">2</th>
                                                    <td>Gas Chromatograph</td>
                                                    <td>N2 gas cylinder
                                                        Zero air Cylinder
                                                        H2 gas Cylinder

                                                    </td>
                                                    <td>600 Rs/ Sample</td>
                                                    <td>1200 Rs/ Sample</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">3</th>
                                                    <td>Microwave reactor</td>
                                                    <td>
                                                    </td>
                                                    <td>1000 Rs/ 6 Hrs</td>
                                                    <td>2000 Rs/ Hrs</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">4</th>
                                                    <td>UV- Visible spectrometer</td>
                                                    <td>
                                                    </td>
                                                    <td>200 Rs/ Sample</td>
                                                    <td>400 Rs/ Sample</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">5</th>
                                                    <td>FT-IR spectrometer</td>
                                                    <td>KBr for pellet
                                                    </td>
                                                    <td>300 Rs/ Sample</td>
                                                    <td>600 Rs/ Sample</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">6</th>
                                                    <td>Vapour phase reactor </td>
                                                    <td>N2 gas cylinder
                                                        Zero air Cylinder
                                                        H2 gas Cylinder

                                                    </td>
                                                    <td>1000 Rs/ 6 Hrs</td>
                                                    <td>2000 Rs/ 6 Hrs</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">7</th>
                                                    <td> Liquid phase reactor </td>
                                                    <td>N2 gas cylinder
                                                        Zero air Cylinder
                                                        H2 gas Cylinder

                                                    </td>
                                                    <td>1000 Rs/ 6 Hrs</td>
                                                    <td>2000 Rs/ 6 Hrs</td>

                                                </tr>
                                                <tr>
                                                    <th scope="row">8</th>
                                                    <td>Muffle furnace, autoclaves, Oven etc</td>
                                                    <td>
                                                    </td>
                                                    <td>500 Rs/ 6 Hrs</td>
                                                    <td>1000 Rs/ 6 Hrs</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-6 col-lg-12 justify">
                                        <h6>Formalities required for submitting the samples</h6><br />
                                        <p>An authentication letter in the letter head of an appropriate authority from your organization or an e-mail stating the purpose of the analysis/test.</p><br />
                                        <p>A duly filled Requisition Form (which can be downloaded from our website) should accompany all samples.</p><br />
                                        <p>Other requirements for submission of samples are given in table for each facility. For any clarification and queries, users are requested to contact: </p><br />
                                        <ul className="ttm-list ttm-list-style-icon">
                                            <li><i className="fa fa-chevron-circle-right ttm-textcolor-skincolor  " /><span className="ttm-list-li-content"><b>Contact Us</b></span></li>
                                            <li>For Research Consultancy Services:               </li>
                                            <li>Dr. Chandra shekara .B.M.</li>
                                            <li>Professor and Head</li>
                                            <li>Department of Chemistry</li>
                                            <li>Bangalore Institute of Technology</li>
                                            <li>Contact No: +919008380008, 8310321157</li>
                                            <li>E-mail: chembit.consultancy@gmail.com</li>
                                        </ul>
                                        <a href={process.env.PUBLIC_URL + "/assets/pdf/che_cs.pdf"} target="_blank" style={{ color: "#98002e", fontWeight: "bold" }}>Download Application </a>                                        </div>

                                </div>


                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="chem" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
