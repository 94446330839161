import React, { Component } from "react";
import { Link } from "react-router-dom";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class MBATeaching extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Master in Business Administration(MBA)" image="banners/department-mba.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <h4 className="title">Learning Resources</h4>
                  </div>
                </div>
                <div className="ttm-service-description">
                  <p>Wide arrays of learning materials are provided to students in advance for each course to catalyze their learning ability. These resources include syllabus, course materials, and links to digital aids. Click here to access learning resources.</p>
                  <Link className="ttm-btn" style={{ fontSize: "1.15rem", paddingTop: "1rem", color: "#00a76a" }} to={process.env.PUBLIC_URL + `/teaching-learning-material/mba`}>Click Here for Learning Resources</Link>
                </div>
                <div className="ttm-service-single-content-area paddingtop-2">
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Teaching - Learning</h4>
                    </div>
                  </div>
                  <ul className="ttm-list ttm-list-style-icon justify">
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Department of MBA prides itself on having highly proficient teaching faculty who strive persistently towards upholding the values and beliefs of the institution.</span></li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">The faculty work towards creating an environment that is conducive to learning and guiding them towards the right path for a brighter career.</span></li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">The faculty members make the class more interactive so that students can connect the theories with practical examples which help them to gain skills, knowledge and thinking ability.</span></li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">The department follows good mentoring system where the faculty members meet students periodically, counsel them to remove their difficulties in academic performance. Their issues are addressed, discussed and proper guidance is provided to ensure comfort level of students in the campus towards their knowledge journey.</span></li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Every teacher is encouraged to combine traditional and innovative methods of teaching and learning such as activity-based learning, leadership and team building activities, simulation games, role plays, personality development programs, case study analysis, internship programmes, and industrial visits are some of the innovative pedagogical methods adopted.</span></li>
                  </ul>
                  <br />
                  <div>
                    <table class="table"> <thead style={{ backgroundColor: "#98002e", color: "white" }}>
                      <tr>
                        <th scope="col" style={{ width: "10%" }}>Sl No</th>
                        <th scope="col" style={{ width: "30%" }}>Teaching Learning Tool</th>
                        <th scope="col">Description</th>
                      </tr>
                    </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Case Study Analysis</td>
                          <td>This is one of the most popular technique which we use. It involves students in addressing the simulated real-world problems.</td></tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Power-point</td>
                          <td>The standard way being the use of Power Point, Excel & other IT Applications. It is one of the visual technique prevalent in our teaching pedagogy,</td></tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>Video-clippings</td>
                          <td>When a student gets involved and engrossed in an Activity, then his learning is more. The video technique is highly useful in getting the attention of student and explaining him the intricate details.</td></tr>
                        <tr>
                          <th scope="row">4</th>
                          <td>Experience based Projects</td>
                          <td>Experience is the mother of all Learnings. Unless, one experiences, one remains biased and into his own perceptions.</td></tr>
                        <tr>
                          <th scope="row">5</th>
                          <td>Interactive Lectures</td>
                          <td>This technique works on the development and enhancement of communication skills, through which they learn how to interact with their peers and bosses</td></tr>
                        <tr>
                          <th scope="row">6</th>
                          <td>Role Plays</td>
                          <td>This technique is used in teaching to generate the debate among the students for their learning process. Apart from this, the student can appreciate the position of an individual, once he has been in his shoe</td></tr>
                        <tr>
                          <th scope="row">7</th>
                          <td>Business plans run business</td>
                          <td>A good business plan guides you through each stage of starting and managing your business. business plan as a roadmap for how to structure, run, and grow your new business. It’s a way to think throughthe key elements of your business.</td></tr>
                        <tr>
                          <th scope="row">8</th>
                          <td> Internship</td>
                          <td>We Find the best paid summer internships in Bangalore from top companies for MBA students in all the streams</td></tr>
                        <tr>
                          <th scope="row">9</th>
                          <td>Project work</td>
                          <td>We Find the best paid projects in Bangalore from top companies for MBA students in all the streams</td></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="mba" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
