import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class PlacementsSidebar extends Component {
    render() {
        return (
            <div className="widget widget-nav-menu">
                <ul className="widget-menu">
                    <li><NavLink className="nav-link" to={process.env.PUBLIC_URL + '/placement-about'}>About Placement</NavLink></li>
                    <li><NavLink className="nav-link" to={process.env.PUBLIC_URL + '/placement-students'}>Students Placed</NavLink></li>
                    <li><NavLink className="nav-link" to={process.env.PUBLIC_URL + '/placement-ourrecruiters'}>Our recruiters</NavLink></li>
                    <li><NavLink className="nav-link" to={process.env.PUBLIC_URL + '/placement-activities'}>Placement Activities</NavLink></li>
                    <li><NavLink className="nav-link" to={process.env.PUBLIC_URL + '/placement-alumnifeedback'}>Alumni Feedback</NavLink></li>
                    <li><NavLink className="nav-link" to={process.env.PUBLIC_URL + '/placement-facilities'}>Facilities</NavLink></li>
                    <li><NavLink className="nav-link" to={process.env.PUBLIC_URL + '/placement-team'}>Placement Team</NavLink></li>
                </ul>
            </div>
        )
    }
}
