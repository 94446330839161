import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import ExploreSidebar from './components/ExploreSidebar';
import { Styles } from "./style/highlights.js";

export default class ExploreHighlightsofBit extends Component {

    render() {

        const Datas = [
            {
                "id": "1",
                "imgUrl": "explore/highlights_1.jpg",
                "highlights": "In Karnataka State, Bangalore Institute of Technology was the pioneer in establishing the Computer Science and Engineering Department, with affiliation to Bangalore University in the year 1981."

            },
            {
                "id": "2",
                "imgUrl": "explore/highlights_2.jpg",
                "highlights": "Centrally located at V V Pura, Bengaluru, 3.5 km from Central Railway Station and Bus station and 3 mins walk from National College Metro Station."

            },
            {
                "id": "3",
                "imgUrl": "explore/highlights_3.jpg",
                "highlights": "BIT provides Hostel facilities for both boys and girls with all amenities."

            },
            {
                "id": "4",
                "imgUrl": "explore/highlights_4.jpg",
                "highlights": "BIT is ranked one among the top three Private Engineering Colleges in Bengaluru for the year 2021 as per, The Week - Hansa research magazine."

            },
            {
                "id": "5",
                "imgUrl": "explore/highlights_5.jpeg",
                "highlights": "Excellent Placement opportunities are provided with reputed companies to B.E, M. Tech, MBA and MCA students, more than 120 companies visit BIT for placements."

            },
            {
                "id": "6",
                "imgUrl": "explore/highlights_6.jpg",
                "highlights": "Scholarships have been provided in merit and sports category by RVS and BIT Alumnus."
            },
            {
                "id": "7",
                "imgUrl": "explore/highlights_7.jpg",
                "highlights": "Latest state of the art infrastructure and 262 highly qualified faculty."

            },
            {
                "id": "8",
                "imgUrl": "explore/highlights_8.jpg",
                "highlights": "BIT has 90 faculty members with Ph.D. degrees and 56 are pursuing."

            },
            {
                "id": "9",
                "imgUrl": "explore/highlights_9.jpg",
                "highlights": "Dynamic Professional Bodies Forum (PBF), memberships with ISTE, IIPE, IEEE, SAE, CSI, ACCE (I), ICI, INSTRUCT, IETE, BITES, IWS etc."

            },
            {
                "id": "10",
                "imgUrl": "explore/highlights_10.jpg",
                "highlights": "More than 100 MOUs with Industries for internships, projects, placements etc. Centers of Excellence in all the departments for research activities."

            },
            {
                "id": "11",
                "imgUrl": "explore/highlights_11.jpg",
                "highlights": "30+ student activity clubs to facilitate overall personality development."
            },
            {
                "id": "12",
                "imgUrl": "explore/highlights_12.jpg",
                "highlights": "Good Mentoring system is being practiced. Also, counselling is provided through expert Psychiatrist from KIMS, Bengaluru to handle psychological issues."
            },
            {
                "id": "13",
                "imgUrl": "explore/highlights_13.jpg",
                "highlights": "Innovation and Entrepreneurship cell, Incubation Centre, Idea Lab, Startup Centre, Skill development centre, Science centre, Nuface and many facilities for overall development of the students."
            },
            {
                "id": "14",
                "imgUrl": "explore/highlights_14.jpg",
                "highlights": "COVID-19 situation is handled very well in the campus by implementing all the SOPs. BIT provides a high bandwidth leased line internet facility and Wi-Fi enabled campus wide internet connectivity."
            }
        ]

        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Explore BIT" image="banners/banner-explore.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Highlights of BIT</h4>
                                        </div>
                                    </div>
                                    <Styles>
                                        <section className="highlights-area">
                                            <Container>
                                                <Row>
                                                    {
                                                        Datas.map((data, i) => (
                                                            <Col md="4" key={i}>
                                                                <div className="team-item" key={i}>
                                                                    <img src={process.env.PUBLIC_URL + `/assets/images/${data.imgUrl}`} alt="" className="img-fluid" />
                                                                    <div className="sec-title">
                                                                        <a>{data.Header}</a><br/>
                                                                        <p className='justify'>{data.highlights}</p>
                                                                    </div>
                                                                    <br />
                                                                </div>
                                                            </Col>
                                                        ))
                                                    }
                                                </Row>
                                            </Container>
                                        </section>
                                    </Styles>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <ExploreSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
