import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/landerBlock.js";

export default class IndustryPartners extends Component {
  render() {
    return (
      <Styles>
        <section
          className="bitHeader-area"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/home/industry-partner.jpg)`,
          }}
        >
          <Container>
            <Row>
              <Col md="12">
                <div className="section-title with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Industry -{" "}
                      <span style={{ color: "#98002e" }}>Partnership</span>
                    </h2>
                  </div>
                </div>
                <h5>
                  Faculties Students interaction <br /> with Industries
                </h5>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    );
  }
}
