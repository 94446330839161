import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import CountUp from "react-countup";

export default class MEAbout extends Component {
  render() {
    return (
      <div>
        <div className="section-title with-desc text-left clearfix paddingtop-2">
          <div className="title-header" style={{ borderTopColor: "red" }}>
            <h4 className="title">About the Department</h4>
          </div>
        </div>
        <Row>
          <Col sm="4">
            <div className="counter-box box1 text-center">
              <h3>
                <CountUp end={this.props.num1} duration={5} delay={1.5} />
                <i className="las la-plus"></i>
              </h3>
              <p>{this.props.text1}</p>
            </div>
          </Col>
          <Col sm="4">
            <div className="counter-box box2 text-center">
              <h3>
                <CountUp end={this.props.num2} duration={5} delay={1.5} />
                <i className="las la-plus"></i>
              </h3>
              <p>{this.props.text2}</p>
            </div>
          </Col>
          <Col sm="4">
            <div className="counter-box box3 text-center">
              <h3>
                <CountUp end={this.props.num3} duration={5} delay={1.5} />
                <i className="las la-plus"></i>
              </h3>
              <p>{this.props.text3}</p>
            </div>
          </Col>
        </Row>
        <Row className="paddingtop-2">
          <Col sm="4">
            <div className="counter-box box3 text-center">
              <h3>
                <CountUp end={this.props.num4} duration={5} delay={1.5} />
                <i className="las la-plus"></i>
              </h3>
              <p>{this.props.text4}</p>
            </div>
          </Col>
          <Col sm="4">
            <div className="counter-box box1 text-center">
              <h3>
                <CountUp end={this.props.num5} duration={5} delay={1.5} />
                <i className="las la-plus"></i>
              </h3>
              <p>{this.props.text5}</p>
            </div>
          </Col>
          <Col sm="4">
            <div className="counter-box box2 text-center">
              <h3>
                <CountUp end={this.props.num6} duration={5} delay={1.5} />
                <i className="las la-plus"></i>
              </h3>
              <p>{this.props.text6}</p>
            </div>
          </Col>
        </Row>
        <br />
        <br />
      </div>
    );
  }
}
