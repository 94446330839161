import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Styles } from "./styles/Program.js";

export default class Program extends Component {
  render() {
    return (
      <Styles>
        <section
          className="ttm-row bottomzero-padding-section  ttm-bg ttm-bgimage-yes bg-img5 clearfix"
          style={{ backgroundColor: "#98002e" }}
        >
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl pb-5 res-991-pb-0">
            <div className="row align-items-center ttm-textcolor-white">
              <div className="col-lg-5 res-991-text-center">
                <div className="section-title style3 mb-0 clearfix">
                  <div className="title-header mb-0">
                    <h2 className="title ttm-textcolor-white">
                      Programs @ BIT
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 res-991-text-center">
                <div className="title-desc">
                  BIT is an excellent platform to excel in academics, skills,
                  leadership, entrepreneurship &#38; to be a great citizen for
                  society.
                </div>
              </div>
              <div className="col-lg-2 text-right res-991-text-center res-991-mt-20">
                <a
                  className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white"
                  href={`${
                    process.env.PUBLIC_URL + "/assets/pdf/Home/Prospectus.pdf"
                  }`}
                  target="_blank"
                >
                  Prospectus
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="ttm-row topzero-padding-section res-991-pt-0 clearfix">
          <div className="container-xl">
            <div className="row no-gutters mt_100 res-991-mt-0">
              <div className="col-md-4">
                <div className="ttm-pricing-plan box-shadow text-left clearfix h-100">
                  <Link
                    to={process.env.PUBLIC_URL + "/admission-be"}
                    className="ttm-ptablebox-title"
                  >
                    <h3 style={{ color: "#98002e" }}>UG Programs</h3>
                  </Link>
                  <div className="ttm-ptablebox-desc">
                    BIT offers Bachelor of Engineering (BE) programs in the
                    following disciplines.
                  </div>
                  <div className="ttm-ptablebox-content">
                    <div className="ttm-ptablebox-price-w">
                      <img
                        className="img-fluid"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/home/program-ug.jpg"
                        }
                        alt="single-img-eighteen"
                      />
                    </div>
                    <div className="ttm-ptablebox-desc justify">
                      <table>
                        <tr>
                          <td>1</td>
                          <td>Civil Engineering</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Mechanical Engineering</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Electrical &#38; Electronics Engineering</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>Electronics &#38; Communication Engineering</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Computer Science &#38; Engineering</td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Electronics &#38; Instrumentation Engineering</td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>
                            Electronics &#38; Telecommunicataion Engineering
                          </td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td>Industrial Engineering &#38; Management</td>
                        </tr>
                        <tr>
                          <td>9</td>
                          <td>Information Science &#38; Engineering</td>
                        </tr>
                        <tr>
                          <td>10</td>
                          <td>
                            Artificial Intelligence &#38; Machine Learning
                          </td>
                        </tr>
                        <tr>
                          <td>11</td>
                          <td>
                            Computer Science &#38; Engineering (IOT & Cyber
                            Security including Blockchain Technology)
                          </td>
                        </tr>
                        <tr>
                          <td>12</td>
                          <td>
                            Computer Science &#38; Engineering (Data Science)
                          </td>
                        </tr>
                        <tr>
                          <td>13</td>
                          <td>Robotics &#38; Artificial Intelligence</td>
                        </tr>
                        <tr>
                          <td>14</td>
                          <td>
                            Electronic Engineering (VLSI Design & Technology)
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-pricing-plan box-shadow text-left clearfix h-100">
                  <Link
                    to={process.env.PUBLIC_URL + "/admission-mtech"}
                    className="ttm-ptablebox-title"
                  >
                    <h3 style={{ color: "#98002e" }}>PG Programs</h3>
                  </Link>
                  <div className="ttm-ptablebox-desc">
                    M.Tech for Engineering, MBA and MCA are the PG Programmes
                    offered at BIT.
                  </div>
                  <div className="ttm-ptablebox-content">
                    <div className="ttm-ptablebox-price-w">
                      <img
                        className="img-fluid"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/home/program-pg.jpg"
                        }
                        alt="single-img-eighteen"
                      />
                    </div>
                    <div className="ttm-ptablebox-desc justify">
                      <table>
                        <tr>
                          <td>1</td>
                          <td>Master of Computer Applications (MCA)</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Master of Business Administration (MBA)</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Structural Engineering (Civil Department)</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>
                            Computer Science &#38; Engineering (CSE Department)
                          </td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>
                            VlSI Design &#38; Embedded System (ECE Department)
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="ttm-pricing-plan box-shadow text-left clearfix h-100">
                  <Link
                    to={process.env.PUBLIC_URL + "/admission-phd"}
                    className="ttm-ptablebox-title"
                  >
                    <h3 style={{ color: "#98002e" }}>Ph.D Programs</h3>
                  </Link>
                  <div className="ttm-ptablebox-desc">
                    BIT offers Ph. D by research program in the following
                    deciplines.
                  </div>
                  <div className="ttm-ptablebox-content">
                    <div className="ttm-ptablebox-price-w">
                      <img
                        className="img-fluid"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/home/program-phd.jpg"
                        }
                        alt="single-img-eighteen"
                      />
                    </div>
                    <div className="ttm-ptablebox-desc justify">
                      <table>
                        <tr>
                          <td>1</td>
                          <td>Civil Engineering</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Mechanical Engineering</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Electrical &#38; Electronics Engineering</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>Electronics &#38; Communication Engineering</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Computer Science &#38; Engineering</td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Electronics &#38; Instrumentation Engineering</td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>Industrial Engineering &#38; Management</td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td>
                            Electronics &#38; Telecommunicataion Engineering
                          </td>
                        </tr>
                        <tr>
                          <td>9</td>
                          <td>Information Science &#38; Engineering</td>
                        </tr>
                        <tr>
                          <td>10</td>
                          <td>
                            Artificial Intelligence &#38; Machine Learning
                          </td>
                        </tr>
                        <tr>
                          <td>11</td>
                          <td>Physics</td>
                        </tr>
                        <tr>
                          <td>12</td>
                          <td>Chemistry</td>
                        </tr>
                        <tr>
                          <td>13</td>
                          <td>Mathematics</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Styles>
    );
  }
}
