import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class CSECOE extends Component {

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Computer Science and Engineering" image="banners/department-cse.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title"> Center of Excellence</h4>
                                    </div>
                                    <div className="ttm-service-description">
                                        <p>Computer Science Engineering has established Centre of Excellence in Data Science.
                                            <a className="ttm-btn" style={{ paddingLeft: "10px" }} href={process.env.PUBLIC_URL + "/assets/pdf/COE/COE - CSE.pdf"} target="_blank">For More Details Click Here</a>
                                        </p>
                                    </div>
                                    <div className="ttm-service-single-content-area">
                                        <div className="section-title with-desc text-left clearfix">
                                            <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                                <h5 className="title">Vision</h5>
                                            </div>
                                            <p>To foster an environment that would empower young minds through value addition, knowledge sharing and training for enhancing the quality of employability, higher learning and promoting entrepreneurship in the field of Data Science.</p>
                                        </div>
                                        <div className="section-title with-desc text-left clearfix">
                                            <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                                <h5 className="title">Mission</h5>
                                            </div>
                                            <ul className="ttm-list ttm-list-style-icon justify">
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Develop employable human resource to meet the challenges in the field of IT.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Strengthen the Industry-Institute interaction through knowledge transfer and skill development.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Establish a business incubation centre for Data Science.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Establish a platform for academia and industry to interact, innovate and co-create newer technologies.</span></li>
                                            </ul>
                                        </div>
                                        <div className="section-title with-desc text-left clearfix">
                                            <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                                <h5 className="title">Objectives</h5>
                                            </div>
                                            <ul className="ttm-list ttm-list-style-icon justify">
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To deliver Value Added Certification Courses in collaboration with Industry experts.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To build real-time projects under the guidance of industry experts, to help solve real business problems through application of Data Science.</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="cse" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
