import React, { Component } from "react";
import { Link } from "react-router-dom";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class CETeaching extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Civil Engineering" image="banners/department-ce.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <h4 className="title">Learning Resources</h4>
                  </div>
                </div>
                <div className="ttm-service-description">
                  <p>Wide arrays of learning materials are provided to students in advance for each course to catalyze their learning ability. These resources include syllabus, course materials, and links to digital aids. Click here to access learning resources.</p>
                  <Link className="ttm-btn" style={{ fontSize:"1.15rem",paddingTop:"1rem", color:"#00a76a" }} to={process.env.PUBLIC_URL + `/teaching-learning-material/civil`}>Click Here for Learning Resources</Link>
                </div>
                <div className="ttm-service-single-content-area paddingtop-2">
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Teaching - Learning</h4>
                    </div>
                  </div>
                  <div className="ttm-service-description">
                    <p>
                      It is the very important part of engineering
                      education. The knowledge and information is shared
                      with students in many ways. We follow VTU curriculum
                      which is formed under outcome based education system
                      or Choice Based Credit System (CBCS). The advanced
                      teaching techniques and models are used to deliver the
                      courses.
                    </p>
                    <h6 className="paddingtop-2">Course Delivery Methods used in Civil Engineering Department</h6><br />
                    <div className="row">
                      <div className="col-lg-6">
                        <ul className="ttm-list ttm-list-style-icon">
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Lectures/ Chalk & Talk/ Smart Board</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Traditional face to face interaction</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Online mode of teaching (Zoom, WebEx, Google meet and Microsoft teams)</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Virtual Labs by NIT / IIT</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Google class room</span></li>
                        </ul>
                      </div>
                      <div className="col-lg-6">
                        <ul className="ttm-list ttm-list-style-icon">
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Power point Presentation</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Experimental & Laboratory Work</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Project work (Group work)</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Video lectures</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To effectively communicate the significance of Engineering principle in design/drawing/practice - models, charts, software’s are used.</span></li>
                        </ul>
                      </div>
                    </div>
                    <h6 className="paddingtop-1">The list of models and charts/maps available are as follows: Models:</h6><br />
                    <div className="row">
                      <div className="col-lg-6">
                        <ul className="ttm-list ttm-list-style-icon">
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Single floor and double floor building with cross sectional views, etc.</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Stair case different types.</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Reinforcement details of footings, retaining walls.</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Water treatment plant – sand filter, sedimentation tank.</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Clover junction of road, points and crossings in railway.</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Dam with ogee spillway.</span></li>
                        </ul>
                      </div>
                      <div className="col-lg-6">
                        <ul className="ttm-list ttm-list-style-icon">
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Irrigation tank with waste weir.</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Plug sluice.</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Canal drop.</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Canal regulator, etc.</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Related to structural geology ( hills, valleys, folds, faults )</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Crystal models for study of crystallography.</span></li>
                        </ul>
                      </div>
                    </div>
                    <h6 className="paddingtop-2">Seminar, Internship and Project work: Models:</h6><br />
                    <ul className="ttm-list ttm-list-style-icon">
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Involving students in process of identifying the appropriate topic having significant engineering and societal importance for seminars and project works.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Do it by yourself concept in the laboratory.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">The field visits and participation in fieldactivities organized by the department andinteraction with the technical staff of ongoing projects etc.,</span></li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          The extensive survey project exposes the students
                          to real field conditions and enables them to gain
                          the experience of working as a team and away from
                          home.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Development of technical report writing skill with
                          appropriate charts/ drawings.
                        </span>
                      </li>
                    </ul>
                    <h6 className="paddingtop-2">Research and Innovations:</h6><br />
                    <p>The research centre of the department encourages research scholars to take the advanced topics to pursue their research. The students of UG and PG do rigorous literature review before selecting their project work. Innovative methods and materials are used in their work to understand the sustainability and serviceability of structural elements</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="civil" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
