import React, { Component } from "react";
import Swiper from "react-id-swiper";

export default class Recruiters extends Component {
  render() {
    const settings = {
      slidesPerView: 6,
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      watchSlidesVisibility: true,
      pagination: {
        el: ".slider-dot.text-center",
        clickable: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        576: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        992: {
          slidesPerView: 4,
        },
        1026: {
          slidesPerView: 5,
        },
        1216: {
          slidesPerView: 6,
        },
      },
    };

    const Datas = {
      dataList: [
        {
          id: "1",
          companyLogo: "cl-1.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-2.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "2",
          companyLogo: "cl-3.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-4.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "3",
          companyLogo: "cl-5.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-6.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "4",
          companyLogo: "cl-7.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-8.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "5",
          companyLogo: "cl-9.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-10.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "6",
          companyLogo: "cl-11.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-12.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "7",
          companyLogo: "cl-13.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-14.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "8",
          companyLogo: "cl-15.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-16.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "9",
          companyLogo: "cl-17.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-18.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "10",
          companyLogo: "cl-19.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-20.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "11",
          companyLogo: "cl-21.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-22.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "12",
          companyLogo: "cl-23.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-24.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "13",
          companyLogo: "cl-25.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-26.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "14",
          companyLogo: "cl-27.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-28.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "15",
          companyLogo: "cl-29.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-30.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "16",
          companyLogo: "cl-31.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-32.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "17",
          companyLogo: "cl-33.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-34.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "18",
          companyLogo: "cl-35.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-36.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "19",
          companyLogo: "cl-37.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-38.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "20",
          companyLogo: "cl-39.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-40.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "21",
          companyLogo: "cl-41.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-42.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "22",
          companyLogo: "cl-43.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-44.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "23",
          companyLogo: "cl-45.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-46.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "24",
          companyLogo: "cl-47.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-48.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "25",
          companyLogo: "cl-49.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-50.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "26",
          companyLogo: "cl-51.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-52.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "27",
          companyLogo: "cl-53.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-54.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "28",
          companyLogo: "cl-55.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-56.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "29",
          companyLogo: "cl-57.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-58.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "30",
          companyLogo: "cl-59.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-60.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "31",
          companyLogo: "cl-61.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-62.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "32",
          companyLogo: "cl-63.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-64.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "33",
          companyLogo: "cl-65.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-66.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "34",
          companyLogo: "cl-67.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-68.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "35",
          companyLogo: "cl-69.jpg",
          companyName: "Company Name Here",
          companyLogo1: "cl-70.jpg",
          companyName1: "Company Name Here",
        },
        {
          id: "36",
          companyLogo: "cl-71.jpg",
          companyName: "Company Name Here",
        },
      ],
    };
    return (
      <div>
        <section className="ttm-row bottomzero-padding-section position-relative clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Recruiters of <b style={{ color: "#98002e" }}>BITians</b>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="row multi-columns-row ttm-boxes-spacing-10px ttm-bgcolor-white">
              <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                <div className="featured-imagebox style2">
                  <div className="featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/home/placement-1.jpg"
                      }
                      alt="image"
                    />
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                <div className="featured-imagebox style2">
                  <div className="featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/home/placement-2.jpg"
                      }
                      alt="image"
                    />
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                <div className="featured-imagebox style2">
                  <div className="featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/home/placement-3.jpg"
                      }
                      alt="image"
                    />
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                <div className="featured-imagebox style2">
                  <div className="featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/home/placement-4.jpg"
                      }
                      alt="image"
                    />
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                <div className="featured-imagebox style2">
                  <div className="featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/home/placement-5.jpg"
                      }
                      alt="image"
                    />
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                <div className="featured-imagebox style2">
                  <div className="featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/home/placement-6.jpg"
                      }
                      alt="image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="ttm-row our-partner-section ttm-bgcolor-skincolor mt_90 res-991-mt-0 res-991-p-0 clearfix"
          style={{ backgroundColor: "#98002e" }}
        >
          <div className="container-xl">
            <div className="row">
              <Swiper {...settings}>
                {Datas.dataList.map((data, i) => (
                  <div className="col-2 text-center">
                    <div className="ttm-box-col-wrapper">
                      <div className="client-box ttm-box-view-boxed-logo">
                        <div className="client">
                          <div
                            className="ttm-client-logo-tooltip"
                            data-tooltip={data.companyName}
                          >
                            <img
                              className="img-fluid shadow-lg"
                              src={
                                process.env.PUBLIC_URL +
                                `/assets/images/home/${data.companyLogo}`
                              }
                              alt="image"
                            />
                            <br />
                            <br />
                            <img
                              className="img-fluid shadow-lg"
                              src={
                                process.env.PUBLIC_URL +
                                `/assets/images/home/${data.companyLogo1}`
                              }
                              alt="image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Swiper>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
