import React from "react";
import { useLocation } from "react-router-dom";
import Banner from "../../components/common/Banner";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import NoData from "../../components/common/NoData";
import Loading from "../../components/common/Loading";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../Config/FbConfig";
import { useHistory } from "react-router-dom";

const DownloadList = () => {
  const location = useLocation();
  const { category, items } = location.state;

  return (
    <div>
      <Header />
      <Banner title="Downloads" image="banners/banner-explore.jpg" />

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}
      >
        <h4>Download List</h4>
      </div>

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}
      >
        <h5>Category : {category}</h5>
      </div>

      <div style={{ padding: "6rem" }}>
        <div className="row">
          {items.map((item) => (
            <div key={item.Id} className="col-lg-4 col-md-4 mb-4">
              <div
                style={{
                  background: "#fff",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                  padding: "20px",
                  boxSizing: "border-box",
                  transition: "box-shadow 0.3s ease",
                  marginBottom: "20px",
                  width: "100%",
                }}
              >
                <div>
                  <h5
                    style={{
                      marginTop: "0",
                      marginBottom: "10px",
                      fontSize: "15px",
                    }}
                  >
                    {item.Header}
                  </h5>
                  <p style={{ marginBottom: "20px", color: "#666" }}>
                    Priority: {item.Priority}
                  </p>
                  <a
                    href={item.Content}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: "inline-block",
                      padding: "10px 20px",
                      color: "#fff",
                      backgroundColor: "#007bff",
                      textAlign: "center",
                      borderRadius: "5px",
                      textDecoration: "none",
                      transition: "background-color 0.3s ease",
                    }}
                  >
                    View Document
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default DownloadList;
