import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import ExploreSidebar from './components/ExploreSidebar';

export default class ExploreRules extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Explore BIT" image="banners/banner-explore.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Rules & Regulations</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <table className='table'>
                                            <thead style={{ backgroundColor: "#98002e", color: "white" }}>
                                                <tr>
                                                    <th scope="col" style={{ width: "7%" }}>Sl No</th>
                                                    <th scope="col">Service Rules - RVS</th>
                                                    <th scope="col" style={{ width: "20%" }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">1</th>
                                                    <td>Rajaya Vokkaligara Sangha Service Rules</td>
                                                    <td><a href={process.env.PUBLIC_URL + "/assets/pdf/Rules/R1.pdf"} target="_blank" style={{ color: "#98002e", fontWeight: "bold" }}>View Document</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className='table'>
                                            <thead style={{ backgroundColor: "#98002e", color: "white" }}>
                                                <tr>
                                                    <th scope="col" style={{ width: "7%" }}>Sl No</th>
                                                    <th scope="col">C and R Rules - BIT</th>
                                                    <th scope="col" style={{ width: "20%" }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">2</th>
                                                    <td>Cadre & Recruitment Rules - BIT</td>
                                                    <td><a href={process.env.PUBLIC_URL + "/assets/pdf/Rules/R3.pdf"} target="_blank" style={{ color: "#98002e", fontWeight: "bold" }}>View Document</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className='table'>
                                            <thead style={{ backgroundColor: "#98002e", color: "white" }}>
                                                <tr>
                                                    <th scope="col" style={{ width: "7%" }}>Sl No</th>
                                                    <th scope="col">AICTE Rules and Regulations</th>
                                                    <th scope="col" style={{ width: "20%" }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">3.1</th>
                                                    <td>AICTE Regulations - 2021</td>
                                                    <td><a href={process.env.PUBLIC_URL + "/assets/pdf/Rules/R41.pdf"} target="_blank" style={{ color: "#98002e", fontWeight: "bold" }}>View Document</a></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">3.2</th>
                                                    <td>AICTE Process Handbook - 2022-23</td>
                                                    <td><a href={process.env.PUBLIC_URL + "/assets/pdf/Rules/R42.pdf"} target="_blank" style={{ color: "#98002e", fontWeight: "bold" }}>View Document</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className='table'>
                                            <thead style={{ backgroundColor: "#98002e", color: "white" }}>
                                                <tr>
                                                    <th scope="col" style={{ width: "7%" }}>Sl No</th>
                                                    <th scope="col">VTU Rules and Regulations</th>
                                                    <th scope="col" style={{ width: "20%" }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">4</th>
                                                    <td> Regulations Governing the degree of B.E/B.Tech</td>
                                                    <td><a href={process.env.PUBLIC_URL + "/assets/pdf/Rules/R5.pdf"} target="_blank" style={{ color: "#98002e", fontWeight: "bold" }}>View Document</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <ExploreSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
