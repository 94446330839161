import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class MCACOE extends Component {

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Master of Computer Applications (MCA)" image="banners/department-mca.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Center of Excellence</h4>
                                        </div>
                                        <p>The Centre for Excellence in Artificial Intelligence and Data Science has been set up to serve the industrial and societal needs. The main objective of setting up a Centre of Excellence (CoE) is to bridge the gap between industry and academia. This enriches the knowledge of faculty members, enhances skills of students, creates employability and motivates students towards research.</p>
                                        <div className="section-title with-desc text-left clearfix paddingtop-2">
                                            <div className="title-header" style={{ borderTopColor: "red" }}>
                                                <h5 className="title">CoE – MCA BIT will focus on the following:</h5>
                                            </div>
                                        </div>
                                        <ul className="ttm-list ttm-list-style-icon">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Training staff and students on latest technologies and motivating students for developing in-house projects</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Conducting certificate programs</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Increasing the value added courses</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Increasing industrial consultancy</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Increasing the number of MoU’s with leading industries</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Writing proposals for getting research funds from various agencies</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="mca" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
