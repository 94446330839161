import React, { Component } from 'react';
import Banner from '../../../components/common/Banner';
import Header from '../../../components/common/Header';
import DepartmentSidebar from '../component/DepartmentSidebar';
import Footer from '../../../components/common/Footer';


export default class CEContact extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Civil Engineering" image="banners/department-ce.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header">
                                        <h4 className="title">Contact</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>HOD</h6></div>
                                                <div className="featured-desc">
                                                    <p>Dr B S Putte Gowda</p>
                                                    <p>E-mail Id:hodcivil@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: +91-99021 60550</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>FOREMAN</h6></div>
                                                <div className="featured-desc">
                                                    <p>H N Manjunath</p>
                                                    <p>E-mail Id:civil@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: +91-9141169066</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Internship & Placement Co-ordinator</h6></div>
                                                <div className="featured-desc">
                                                    <p>Mr. Gangadhara S</p>
                                                    <p>E-mail Id:gangadharas@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: +91-9740351730</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Alumni Co-ordinator</h6></div>
                                                <div className="featured-desc">
                                                    <p>Dr. Jeevan N</p>
                                                    <p>E-mail Id:njeevan@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: +91-9731649246</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Anti-ragging Co-ordinator</h6></div>
                                                <div className="featured-desc">
                                                    <p>Mr. Pradeep C R</p>
                                                    <p>E-mail Id:pradeepcr@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: +91-9611768234</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Women’s Grievance cell  Co-ordinator</h6></div>
                                                <div className="featured-desc">
                                                    <p>Mrs. Archana D P</p>
                                                    <p>E-mail Id:archanadp@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: +91-9742924204</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="civil" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}

