import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import DepartmentAchievers from "../component/DepartmentAchievers";
import DepartmentNumbers from "../component/DepartmentNumbers";
import Footer from "../../../components/common/Footer";
import DepartmentVideo from "../component/DepartmentVideo";

export default class MBAAbout extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Master in Business Administration(MBA)" image="banners/department-mba.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area"> 
                                    <DepartmentAchievers department="mba" />
                                    <DepartmentNumbers
                                        text1="Number of students on roll"
                                        num1="110"

                                        text2="Number of ranks since inception"
                                        num2="3"

                                        text3="Number of graduated batches"
                                        num3="16"

                                        text4="Number of publications"
                                        num4="45"

                                        text5="Ph. D Awarded"
                                        num5="4"

                                        text6="Average Placement (%) in last 5 years"
                                        num6="44"
                                    />
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/departments/mba/about-1.jpg"} title="single-img-twenty" alt="single-img-twenty" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{margin:"auto"}}>
                                                <p>The Master of Business Administration (MBA) programme of Visveswaraya Technological University has carved a niche in management education in the country. MBA is a two years full time post graduate programme with four semesters. The programme curriculum is designed to provide graduates with life skills and competencies effectively transforming them into a dynamic managerial professional. The programme moulds the overall personality of graduates enabling them to adapt to the evolving trends of the business eco system. The Department currently has an intake of 60 students.</p>
                                            </div>
                                            <div className="col-md-12 justify paddingtop-2">
                                                <p>We offer three major specializations namely, Finance, Human Resource and Marketing. The total credit points of MBA program is 100. The department also offers Ph.D program in Management.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <DepartmentVideo department="mba" />
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Vision</h4>
                                        </div>
                                        <p>To establish as a distinctive center of learning by delivering holistic education, quality research and creating socially responsible ethical leaders with an entrepreneurial and innovative mindset for a prosperous and sustainable future.</p>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Mission</h4>
                                        </div>
                                        <ul className="ttm-list ttm-list-style-icon justify">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To promote continuous engagement for impactful teaching.</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To build a tenacious research culture.</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To support competency and skill-based approaches.</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To foster dynamic personage with moral values.</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">PROGRAMME EDUCATIONAL OBJECTIVES (PEO)</h4>
                                    </div>
                                    <ul className="ttm-list ttm-list-style-icon justify">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Pursue lifelong learning in generating innovative and creative management solutions using varied problem-solving skills.</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Acquire essential knowledge and proficiency necessary for professional practice in management for higher studies and research.</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Demonstrate managerial abilities and leadership to become pioneer professionals in the field of management.</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Possess a professional attitude as an individual or a team member with consideration for society, professional ethics, environmental factors and motivation for continuous development.</span></li>
                                    </ul>
                                </div>
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">PROGRAM OUTCOMES</h4>
                                    </div>
                                    <ul className="ttm-list ttm-list-style-icon justify">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Apply knowledge of management theories and practices to solve business problems.</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Foster Analytical and critical thinking abilities for data-based decision making. </span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Ability to develop Value based Leadership ability.</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Ability to understand, analyze and communicate global, economic, legal, and ethical aspects of business. </span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Ability to lead themselves and others in the achievement of organizational goals, contributing effectively to a team environment.</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="mba" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Footer */}
                <Footer />
            </div>
        )
    }
}

