import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class ECECOE extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Electronics and Communication Engineering" image="banners/department-ece.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title"> Center of Excellence</h4>
                                    </div>
                                    <div className="ttm-service-description">
                                        <p>Electronics and Communication Engineering has established Centre of Excellence in VLSI Design.
                                            <a className="ttm-btn" style={{ paddingLeft: "10px" }} href={process.env.PUBLIC_URL + "/assets/pdf/COE/COE - ECE.pdf"} target="_blank">For More Details Click Here</a>
                                        </p>
                                    </div>
                                    <div className="ttm-service-single-content-area paddingtop-2">
                                        <p>The main objective of this centre is to create state of art infrastructure in the field of VLSI Design to create an Industry-Academic Research Hub, which will be recognised globally. The focus of this COE is to create a platform for students, faculty & researchers to carry out innovative projects starting from RTL to GDS-II. Any Innovative ideas of ASIC or FPGA design approach can be completely designed in this COE. The centre is equipped with a complete bundle of Cadence EDA Tool which is one of the leading industry EDA tools. Other facilities like MATLAB, FPGA design suite tool, DSP and FPGA design kits are also part of this Centre of Excellence in VLSI Design. This centre also focuses on knowledge sharing between faculty, researchers and industry experts in the core areas of VLSI Design and Signal Processing. Providing comprehensive Industrial training for students, faculty and researchers in the ASIC Design Verification, Physical Design and DFT is one of the main goals of this COE. This COE has an ample of domain experts who are qualified and experienced which will lead to innovative new products.</p>
                                        <div className="section-title with-desc text-left clearfix">
                                            <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                                <h5 className="title">Vision</h5>
                                            </div>
                                            <p>To develop the center into a globally recognized Industrial-Academic Research Hub.</p>
                                        </div>
                                        <div className="section-title with-desc text-left clearfix">
                                            <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                                <h5 className="title">Mission</h5>
                                            </div>
                                            <ul className="ttm-list ttm-list-style-icon justify">
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To facilitate knowledge sharing amongst Faculty, Researchers and Industry.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To develop new products by providing State of the Art Infrastructure for Researchers.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To provide a platform for students to carry out Innovative Projects.</span></li>
                                            </ul>
                                        </div>
                                        <div className="section-title with-desc text-left clearfix">
                                            <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                                <h5 className="title">Objectives</h5>
                                            </div>
                                            <p>To create state of art infrastructure to integrate Industry & Academia.</p>
                                        </div>
                                        <div className="section-title with-desc text-left clearfix">
                                            <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                                <h5 className="title">Goal</h5>
                                            </div>
                                            <ul className="ttm-list ttm-list-style-icon justify">
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To produce good number of projects, publications & patents.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To provide platform for internships, mini projects & research activities.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To train faculty and students to meet the industry standards.</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="ece" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}