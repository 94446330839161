import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Swiper from "react-id-swiper";
import { Styles } from "./styles/landerBlock.js";
import { Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../Config/FbConfig";
import "./styles/Lander.css";

const LanderBlock = () => {
  const [progressValue, setProgressValue] = useState("0%");
  const [Datas, setDatas] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(firestore, "Home", "ImportantNotification");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const Data = [];
        Object.entries(docSnap.data()).forEach(([key, value]) => {
          Data.push(Object.assign({ Id: key }, value));
        });
        setDatas(Data);
      } else {
        setDatas("NO_999");
      }
      setLoading(false);
    };

    fetchData();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    slidesPerView: 1,
    loop: true,
    speed: 2000,
    effect: "fade",
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    watchSlidesVisibility: true,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
    },
  };

  const dataList = [
    {
      id: "6",
      landerImage: "home/lander-4.jpg",
      landerImageMobile: "home/lander-4-m.jpg",
      landerTitle: "Unlock the path to a brighter tomorrow",
      landerTag: "Enroll with us ",
      class: "left",
      "button-name": "Register",
      "button-link": "/admission-be",
    },
    {
      id: "3",
      landerImage: "home/lander-3.jpg",
      landerImageMobile: "home/lander-3-m.jpg",
      landerTitle: "Education towards sustainability",
      landerTag: "Join us for quality education",
      class: "right",
      "button-name": "Principal",
      "button-link": "/explore-principal",
    },
    {
      id: "2",
      landerImage: "home/lander-6.jpg",
      landerImageMobile: "home/lander-6-m.jpg",
      landerTitle: "NBA - Accredited",
      landerTag: "NAAC Accredation with A+",
      class: "left",
      "button-name": "Accreditation",
      "button-link": "/approvals-nba",
    },
    {
      id: "1",
      landerImage: "home/lander-1.jpg",
      landerImageMobile: "home/lander-1-m.jpg",
      landerTitle: "Holistic learning environment",
      landerTag: "Quality Infrastructure & Resources",
      class: "right",
      "button-name": "BIT Campus",
      "button-link": "/life@bit-bitcampus",
    },
    {
      id: "4",
      landerImage: "home/lander-2.jpg",
      landerImageMobile: "home/lander-2-m.jpg",
      landerTitle: "Industry Association for Trending Skills",
      landerTag: "We Care Students Career",
      class: "left",
      "button-name": "Placements",
      "button-link": "/placement-about",
    },
    {
      id: "5",
      landerImage: "home/lander-5.jpg",
      landerImageMobile: "home/lander-5-m.jpg",
      landerTitle: "360 degree Approach",
      landerTag: "Learning | Interacting | Enjoying | Exploring ",
      class: "right",
      "button-name": "Achievers",
      "button-link": "/achievers-academics",
    },
  ];

  return (
    <Styles>
      <div
        className="progress"
        style={{ height: "0.3rem", marginBottom: "0.25rem" }}
      >
        <div
          className="progress-bar bg-warning"
          role="progressbar"
          style={{ width: "100%" }}
        ></div>
      </div>
      <marquee
        className="example1"
        direction="left"
        scrollamount="5"
        behavior="scroll"
      >
        {Loading
          ? "Loading..."
          : Datas === "NO_999"
          ? "No Data"
          : Datas.map((data, i) => (
              <a key={i} target="_blank" href={data.Content}>
                {data.Header}&nbsp;&nbsp;
              </a>
            ))}
      </marquee>
      <div
        className="progress"
        style={{ height: "0.3rem", marginTop: "0.25rem" }}
      >
        <div
          className="progress-bar bg-warning"
          role="progressbar"
          style={{ width: "100%" }}
        ></div>
      </div>
      <Swiper {...settings}>
        {dataList.map((data, i) => (
          <section
            className="bitHeader-area"
            key={i}
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${
                isMobile ? data.landerImageMobile : data.landerImage
              })`,
            }}
          >
            <Container>
              <Row>
                <Col md="12" className={data.class}>
                  <h4 className="title">{data.landerTitle}</h4>
                  <h6>{data.landerTag}</h6>
                  <Link to={process.env.PUBLIC_URL + data["button-link"]}>
                    <button>{data["button-name"]}</button>
                  </Link>
                </Col>
              </Row>
            </Container>
          </section>
        ))}
      </Swiper>
    </Styles>
  );
};

export default LanderBlock;
