import React, { Component } from "react";
import { Link } from "react-router-dom";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class ISETeaching extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Industrial Engineering and Management" image="banners/department-iem.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <h4 className="title">Learning Resources</h4>
                  </div>
                </div>
                <div className="ttm-service-description">
                  <p>Wide arrays of learning materials are provided to students in advance for each course to catalyze their learning ability. These resources include syllabus, course materials, and links to digital aids. Click here to access learning resources.</p>
                  <Link className="ttm-btn" style={{ fontSize: "1.15rem", paddingTop: "1rem", color: "#00a76a" }} to={process.env.PUBLIC_URL + `/teaching-learning-material/iem`}>Click Here for Learning Resources</Link>
                </div>
                <div className="section-title with-desc text-left clearfix paddingtop-2">
                  <div className="title-header">
                    <h4 className="title">Teaching - Learning</h4>
                  </div>
                </div>
                <div className="ttm-service-description justify">
                  <ul className="ttm-list ttm-list-style-icon">
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />The department has smart class rooms with good internet facility and offers classes through blended mode.</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />The department conducts Continuous Internal Evaluation process including tests, quiz and assignments on regular basis as per VTU norms and is scrutinized thoroughly.</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />The faculty prepares lesson plan as per calendar of events</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />The course material is uploaded to the website.</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />The students have the e-learning facility through which they can access digital library, open educational resources like NPTEL, e-journals and e-books.</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />The department adopts a methodology by which faculty can identify weak students and assist them to cope up</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />The faculty identifies bright students and helps them to maintain their academic performance.</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />The students are taught to work as a team to improve their knowledge and working skills</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />The students are encouraged to take up academic project work in industries to have an understanding of real time problems</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />The students have to undergo mandatory internship in industries during final year</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" /> There will be weekly monitoring regarding the progress of project work of the students</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" /> The students have to submit feedback on the course and faculty.</li>
                    <li><i className="fas fa-file-pdf" /> Teaching Learning Process<a target="_blank" href={process.env.PUBLIC_URL + "/assets/pdf/iem_teaching_process.pdf"}>Click Here</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="iem" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
