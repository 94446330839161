import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";


export default class ECEContact extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Electronics and Communication Engineering" image="banners/department-ece.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header">
                                        <h4 className="title">Contact</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>HOD</h6></div>
                                                <div className="featured-desc">
                                                    <p>Dr. Hemanth Kumar A R</p>
                                                    <p>E-mail Id:drhkar2010@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: 9901966111</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>PG coordinator -VLSI</h6></div>
                                                <div className="featured-desc">
                                                    <p>Dr. Vijaya Prakash A M</p>
                                                    <p>E-mail Id:am_vprakash@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: 9844658446</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>PG coordinator DEC</h6></div>
                                                <div className="featured-desc">
                                                    <p>Dr. C R Byrareddy</p>
                                                    <p>E-mail Id: byrareddy@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: 9343792605</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Alumni coordinator</h6></div>
                                                <div className="featured-desc">
                                                    <p>K P Leela</p>
                                                    <p>E-mail Id: leelakp@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: 9844241787</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Research coordinator</h6></div>
                                                <div className="featured-desc">
                                                    <p>Dr. Hemanth Kumar A R</p>
                                                    <p>E-mail Id: drhkar2010@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: 9901966111</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>VTU online exam coordinator</h6></div>
                                                <div className="featured-desc">
                                                    <p>N Nirmala</p>
                                                    <p>E-mail Id: nirmala.bit@gmail.com</p>
                                                    <p>Contact Number:9844385343</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Marks card issue coordinator</h6></div>
                                                <div className="featured-desc">
                                                    <p>H C Doreswamy</p>
                                                    <p>E-mail Id: doreswamy_hc@yahoo.co.in</p>
                                                    <p>Contact Number: 9740121023</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Department Library coordinator</h6></div>
                                                <div className="featured-desc">
                                                    <p>N B Ravi</p>
                                                    <p>E-mail Id: ravinb_ravinb@yahoo.co.in</p>
                                                    <p> Contact Number:9483054509</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Remuneration queries coordinator</h6></div>
                                                <div className="featured-desc">
                                                    <p>L. Pradeep</p>
                                                    <p>E-mail Id: kanasubit1976@gmail.com</p>
                                                    <p> Contact Number: 9980168857</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>COE + Consultancy coordinator</h6></div>
                                                <div className="featured-desc">
                                                    <p>Dr. A B Kalpana</p>
                                                    <p>E-mail Id: kalpana@bit-bangalore.edu.in</p>
                                                    <p> Contact Number: 9480037827</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Internship with Industries coordinator</h6></div>
                                                <div className="featured-desc">
                                                    <p>R Sreenivasamurthy</p>
                                                    <p>E-mail Id:srinivasamurthy@bit-bangalore.edu.in</p>
                                                    <p> Contact Number: 9901140362</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>MOU + Industry sponsored labs coordinator</h6></div>
                                                <div className="featured-desc">
                                                    <p>Shilpa B</p>
                                                    <p>E-mail Id:bs_ece@bit-bangalore.edu.in</p>
                                                    <p> Contact Number: 9901253926</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Foreman</h6></div>
                                                <div className="featured-desc">
                                                    <p>V S Chandrika</p>
                                                    <p>E-mail Id:chandrika_bit@yahoo.co.in </p>
                                                    <p> Contact Number: 9844438338</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Alumni Coordinator</h6></div>
                                                <div className="featured-desc">
                                                    <p>Navneeth Nataraj</p>
                                                    <p>E-mail Id:nn_ece@bit-bangalore.edu.in </p>
                                                    <p> Contact Number: xxxxx xxxxx</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>VTU online exam coordinator</h6></div>
                                                <div className="featured-desc">
                                                    <p>H C Doreswamy</p>
                                                    <p>E-mail Id:doreswamy_hc@yahoo.co.in    </p>
                                                    <p> Contact Number:9740121023</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div><div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>VTU online exam coordinator</h6></div>
                                                <div className="featured-desc">
                                                    <p>C Dhali gowda</p>
                                                    <p>E-mail Id:dhaligowda@gmail.com </p>
                                                    <p> Contact Number: 9845058764</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="ece" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
