import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import { Styles } from "./styles/StudentAchivers.js";
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../Config/FbConfig';

export default class StudentAchivers extends Component {

    state = {
        Datas: [],
        Loading: true
    }

    //Load Data Here
    async componentDidMount() {
        const docRef = doc(firestore, "Home", "FacultyAchievers");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key }, value))
            });
            this.setState({
                Datas: Data,
                Loading: false
            })
        } else {
            this.setState({
                Datas: "NO_999",
                Loading: false
            })
        }
    }

    render() {
        const settings = {
            slidesPerView: 4,
            loop: true,
            speed: 1000,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            spaceBetween: 30,
            watchSlidesVisibility: true,
            pagination: {
                el: '.slider-dot.text-center',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                576: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 3
                },
                992: {
                    slidesPerView: 4
                }
            }
        };

        return (
            <Styles>
                {/* Team Slider */}
                <section className="team-member-area">
                    <Container>
                        <Row>
                            <Col md="5">
                                <div className="section-title style3 mb-0 clearfix">
                                    <div className="title-header mb-0">
                                        <h2 className="title ttm-textcolor-white"> <span className="ttm-textcolor-white" style={{ color: "#98002e" }}>Faculty Achievers</span></h2>
                                    </div>
                                </div>
                            </Col>
                            <Col md="7" style={{ marginTop: "1rem" }}><hr /></Col>
                            <Col md="12" className="team-slider" style={{ paddingTop: "4rem" }}>
                                {this.state.Loading === true ? "Loading.." :
                                    <Swiper {...settings}>
                                        {
                                            this.state.Datas.map((data, i) => (
                                                <div className="team-item" key={i}>
                                                    <img src={data.Image} alt="" className="img-fluid" />
                                                    <div className="img-content text-center" style={{ maxHeight: "7.5rem", minHeight: "7.5rem" }}>
                                                        <p>{data.Description}</p>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </Swiper>
                                }
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}