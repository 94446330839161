import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loading from '../../components/common/Loading';
import NoData from '../../components/common/NoData';
import LifeAtBitSidebar from './components/LifeAtBitSidebar';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../Config/FbConfig';

export default class lifeatBitStudentClubs extends Component {

    state = { Datas: [] }

    //Load Data Here
    async componentDidMount() {
        const docRef = doc(firestore, "LifeBit", "StudentsClub");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key }, value))
            });
            this.setState({
                Datas: Data
            })
        } else {
            this.setState({
                Datas: "NO_999"
            })
        }
    }

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Life@BIT" image="banners/banner-lifebit.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Students Club</h4>
                                        </div>
                                    </div>
                                    <table class="table">
                                        <thead style={{ backgroundColor: "#98002e", color: "white" }}>
                                            <tr>
                                                <th scope="col">Sl</th>
                                                <th scope="col">Club Logo</th>
                                                <th scope="col">Club Name</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Report</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.Datas.length === 0 ? <Loading /> :
                                                    this.state.Datas === "NO_999" ? <NoData /> :
                                                        this.state.Datas.map((data, i) => (
                                                            <tr key={i} className="justify">
                                                                <td>{i + 1}</td>
                                                                <td><img src={data.Image} style={{ width: "10rem" }} /></td>
                                                                <td>{data.Name}</td>
                                                                <td>{data.Description}</td>
                                                                <td><a href={data.Report} target="_blank" style={{ color: "#98002e", fontWeight: "bold" }}>View Report</a></td>
                                                            </tr>
                                                        ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <LifeAtBitSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
