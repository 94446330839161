import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Swiper from 'react-id-swiper';

export default class BitUniqueness extends Component {

    render() {
        
        const settings = {
            slidesPerView: 5,
            loop: true,
            speed: 1000,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            spaceBetween: 30,
            watchSlidesVisibility: true,
            pagination: {
                el: '.slider-dot.text-center',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                576: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 3
                },
                992: {
                    slidesPerView: 4
                },
                1192: {
                    slidesPerView: 5
                }
            }
        };

        const Datas = [
            {
                "id": "01",
                "imgUrl": "uniq-1.jpg",
                "uniqueness": "9 UG Programs and MCA course are NBA accredited"
            },
            {
                "id": "02",
                "imgUrl": "uniq-2.jpg",
                "uniqueness": "First in Karnataka to start CSE program"
            },
            {
                "id": "03",
                "imgUrl": "uniq-3.jpg",
                "uniqueness": "One among top 5 Engineering colleges"
            },
            {
                "id": "04",
                "imgUrl": "uniq-4.jpg",
                "uniqueness": "Innovation and Entrepreneurship Development Cell"
            },
            {
                "id": "05",
                "imgUrl": "uniq-5.jpg",
                "uniqueness": "Qualified and Experienced Faculty with 99 % retention"
            },
            {
                "id": "06",
                "imgUrl": "uniq-6.jpg",
                "uniqueness": "Excellent placements, 250 + companies recruiting BITians"
            },
            {
                "id": "07",
                "imgUrl": "uniq-7.jpg",
                "uniqueness": "Centre of Excellence in each Department"
            },
            {
                "id": "08",
                "imgUrl": "uniq-8.jpg",
                "uniqueness": "36 student activity clubs to facilitate overall personality development."
            },
            {
                "id": "09",
                "imgUrl": "uniq-9.jpg",
                "uniqueness": "Consistently securing 20+ VTU Ranks and gold medals every year."
            },
            {
                "id": "10",
                "imgUrl": "uniq-10.jpg",
                "uniqueness": "Incubation, Startup, Idea Lab, Skill development and other supportive and nurturing centers"
            },
            {
                "id": "11",
                "imgUrl": "uniq-11.jpg",
                "uniqueness": "Full pledged digital library"
            },
            {
                "id": "12",
                "imgUrl": "uniq-12.jpg",
                "uniqueness": "BITians pursuing higher studies across the globe."
            },
            {
                "id": "13",
                "imgUrl": "uniq-13.jpg",
                "uniqueness": "Global alumni connection."
            },
            {
                "id": "14",
                "imgUrl": "uniq-14.jpg",
                "uniqueness": "@ heart of the city: 3.5 Kms to central bus and railway station: 0.3 Km to metro station."
            },
            {
                "id": "15",
                "imgUrl": "uniq-15.jpg",
                "uniqueness": "KIMS and VSDC hospitals within the campus."
            }
        ]

        return (
            <section className="team-member-area" style={{ marginBottom: "2.5rem", marginRight: "1.25rem", marginLeft: "1.25rem" }}>
                <Row>
                    <Col md="4" style={{ marginTop: "1rem" }}><hr /></Col>
                    <Col md="4" style={{ textAlign: "center" }}>
                        <div className="section-title style3 mb-0 clearfix">
                            <div className="title-header mb-0">
                                <h2 className="title ttm-textcolor-white"><span className="ttm-textcolor-white" style={{ color: "#98002e" }}>Uniqueness of BIT</span></h2>
                            </div>
                        </div>
                    </Col>
                    <Col md="4" style={{ marginTop: "1rem" }}><hr /></Col>
                    <Col md="12" className="team-slider" style={{ paddingTop: "4rem" }}>
                        <div className="ttm-processbox-wrapper">
                            <Swiper {...settings}>
                                {
                                    Datas.map((data, i) => (
                                        <div className="ttm-processbox text-center" key={i}>
                                            <div className="ttm-box-image">
                                                <img className="img-fluid" alt="step-one" title="step-one" src={process.env.PUBLIC_URL + `/assets/images/home/${data.imgUrl}`} />
                                                <div className="process-num">
                                                    <span className="number">{data.id}</span>
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="ttm-box-description">{data.uniqueness}</div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </Swiper>
                        </div>
                    </Col>
                </Row>
            </section>
        )
    }
}
