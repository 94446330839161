import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class LifeAtBitSidebar extends Component {
    render() {
        return (
            <div>
                <div className="widget widget-nav-menu">
                    <ul className="widget-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + '/life@bit-bitcampus'}>BIT Campus</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/life@bit-manthanfest'}>Manthan BIT fest</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/life@bit-studentclubs'}>Student Clubs</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/life@bit-Sportsandgames'}>Sports and Games</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/life@bit-nss'}>NSS @ BIT</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/life@bit-ncc'}>NCC @ BIT</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/life@bit-facilities'}>Facilities</NavLink></li>
                    </ul>
                </div>
            </div>
        )
    }
}

// <li><NavLink to={process.env.PUBLIC_URL + '/life@bit-graduation'}>Graduation Day</NavLink></li>
// <li><NavLink to={process.env.PUBLIC_URL + '/life@bit-culture'}>Cultural Events</NavLink></li>