import React, { Component } from "react";
import Banner from "../../components/common/Banner";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import ExploreSidebar from "./components/ExploreSidebar";

export default class ExploreMC extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Explore BIT" image="banners/banner-explore.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-single-content-area">
                  <div className="section-title with-desc text-left clearfix">
                    <div
                      className="title-header"
                      style={{ borderTopColor: "red" }}
                    >
                      <h3 className="title">Mandatory Committees</h3>
                    </div>
                  </div>
                  <div className="ttm-service-description">
                    <table class="table">
                      <thead
                        style={{ backgroundColor: "#98002e", color: "white" }}
                      >
                        <tr>
                          <th scope="col">
                            Women Grievances and Redressal Cell (WGRC)
                          </th>
                          <th scope="col" style={{ width: "20%" }}></th>
                          <th scope="col" style={{ width: "20%" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            Chairman : <b>Dr. Pramila P</b>
                          </td>
                          <td>
                            <a
                              href="https://drive.google.com/file/d/1Svqk8CHcGZeiZ5wZOL9-7-apUVh6_Zck/view?usp=sharing"
                              target="_blank"
                              style={{ color: "#98002e", fontWeight: "bold" }}
                            >
                              View Document
                            </a>
                          </td>
                          <td>
                            <a
                              href="https://sites.google.com/view/wgcbit"
                              target="_blank"
                              style={{ color: "#98002e", fontWeight: "bold" }}
                            >
                              View Website
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="table">
                      <thead
                        style={{ backgroundColor: "#98002e", color: "white" }}
                      >
                        <tr>
                          <th scope="col">Anti-Ragging Committee</th>
                          <th scope="col" style={{ width: "20%" }}></th>
                          <th scope="col" style={{ width: "20%" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            Chairman : <b>Dr. Aswath M U</b>
                          </td>
                          <td>
                            <a
                              href="https://drive.google.com/file/d/1fvr39fOQpcznN-CGsCVGZ_BwID1gyM9v/view"
                              target="_blank"
                              style={{ color: "#98002e", fontWeight: "bold" }}
                            >
                              View Document
                            </a>
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="table">
                      <thead
                        style={{ backgroundColor: "#98002e", color: "white" }}
                      >
                        <tr>
                          <th scope="col">SC-ST Committee</th>
                          <th scope="col" style={{ width: "20%" }}></th>
                          <th scope="col" style={{ width: "20%" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            Chairman : <b>Dr. Narendra C P</b>
                          </td>
                          <td>
                            <a
                              href="https://drive.google.com/file/d/1--eXQiJlo5D7qConLBgxHCveH2IkxGSx/view"
                              target="_blank"
                              style={{ color: "#98002e", fontWeight: "bold" }}
                            >
                              View Document
                            </a>
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="table">
                      <thead
                        style={{ backgroundColor: "#98002e", color: "white" }}
                      >
                        <tr>
                          <th scope="col">
                            College Internal complaint Committee (CICC)
                          </th>
                          <th scope="col" style={{ width: "20%" }}></th>
                          <th scope="col" style={{ width: "20%" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            Chairman : <b>Dr. Roopa K M</b>
                          </td>
                          <td>
                            <a
                              href="https://drive.google.com/file/d/1qLIvHro4CrZTTmHefvV8icWkmnBwVkY5/view?usp=sharing"
                              target="_blank"
                              style={{ color: "#98002e", fontWeight: "bold" }}
                            >
                              View Document
                            </a>
                          </td>
                          <td>
                            <a
                              href="https://sites.google.com/view/ciccbit"
                              target="_blank"
                              style={{ color: "#98002e", fontWeight: "bold" }}
                            >
                              View Website
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="table">
                      <thead
                        style={{ backgroundColor: "#98002e", color: "white" }}
                      >
                        <tr>
                          <th scope="col">
                            Internal Quality Assurance Cell (IQAC)
                          </th>
                          <th scope="col" style={{ width: "20%" }}></th>
                          <th scope="col" style={{ width: "20%" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            Chairman : <b>Dr. T. Vijaya Kumar</b>
                          </td>
                          <td>
                            <a
                              href="https://drive.google.com/file/d/1Y5YJtK0QKAA9eHNyxUvhD9OfX_rpbqQ-/view?usp=sharing"
                              target="_blank"
                              style={{ color: "#98002e", fontWeight: "bold" }}
                            >
                              View Document
                            </a>
                          </td>
                          <td>
                            <a
                              href="https://sites.google.com/bit-bangalore.edu.in/bit-iqac"
                              target="_blank"
                              style={{ color: "#98002e", fontWeight: "bold" }}
                            >
                              View Website
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <ExploreSidebar />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
