import React, { useState } from "react";
import Banner from "../../components/common/Banner";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import DepartmentNumbers from "../departments/component/DepartmentNumbers";
import PlacementsSidebar from "./components/PlacementsSidebar";
import PlacementMovements from "./PlacementMovements";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const PlacementAbout = () => {
  const tableStyles = {
    margin: "50px auto",
    borderCollapse: "collapse",
    fontSize: "0.9em",
    fontFamily: "Arial, sans-serif",
    minWidth: "800px",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.15)",
  };

  const headerRowStyles = {
    backgroundColor: "#009879",
    color: "#ffffff",
    textAlign: "left",
  };

  const cellStyles = {
    padding: "12px 15px",
  };

  const bodyRowStyles = {
    borderBottom: "1px solid #dddddd",
  };

  const evenRowStyles = {
    backgroundColor: "#f3f3f3",
  };

  const lastRowStyles = {
    borderBottom: "2px solid #009879",
  };

  const accordionStyles = {
    margin: "50px auto",
    width: "80%",
    fontFamily: "Arial, sans-serif",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.15)",
  };

  const headerStyles = {
    backgroundColor: "#009879",
    color: "#ffffff",
    padding: "10px 15px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const contentStyles = {
    padding: "10px 15px",
    backgroundColor: "#f3f3f3",
  };

  const [openEthnus, setOpenEthnus] = useState(false);
  const [openQuantMasters, setOpenQuantMasters] = useState(false);

  const toggleEthnus = () => setOpenEthnus(!openEthnus);
  const toggleQuantMasters = () => setOpenQuantMasters(!openQuantMasters);

  return (
    <div>
      {/* Header */}
      <Header />

      {/* Banner */}
      <Banner title="Placement" image="banners/banner-placement.jpg" />

      {/* Content */}
      <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
        <div className="container-xl">
          <div className="row">
            <div className="col-lg-9 content-area order-lg-2">
              <div className="ttm-service-single-content-area">
                <PlacementMovements />
                <DepartmentNumbers
                  text1="Total Offers - 2021-22"
                  num1="1145"
                  text2="Total Offers - 2022-2023"
                  num2="809"
                  text3="Total Offers - 2023-24"
                  num3="604"
                  text4="Companies visited"
                  num4="124"
                  text5="Highest Package (LPA)"
                  num5="58"
                  text6="Average Package (LPA)"
                  num6="10"
                />
                <div className="ttm-service-description">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="ttm_single_image-wrapper">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/about-1.jpg"
                          }
                          alt="Error"
                        />
                      </div>
                    </div>
                    <div className="col-md-7">
                      <h4 className="res-767-mt-20"></h4>
                      <p className="justify">
                        Department of Training &#38; Placement Training and
                        Placement Center was established in the year 1994 under
                        the visionary leadership of Dr. Aswath. M. U, with the
                        objective of making our students self-reliant, capable,
                        skilled and competent technocrats. Many milestones have
                        been set by our center. To keep our students
                        professionally updated to the unfolding dynamic
                        environment, our center offers apex quality training
                        services, many career guidance and development
                        programmes on personality development, communication
                        skills, Industry to Institute Convergence expert
                        interaction sessions, aptitude tests and logical
                        reasoning sessions, confidence grooming sessions, Group
                        Discussion.
                      </p>
                    </div>
                    <div className="col-md-12">
                      <h4 className="res-767-mt-20"></h4>
                      <p className="paddingtop-1 justify">
                        and Mock Interview Sessions, Public Sector Competitive
                        Exams Training and Industry Internship Programs. Due to
                        which our students are well equipped to handle the
                        working norms of the industry. The Center provides
                        employment opportunities for the students in acclaimed
                        world class organizations. Every year more than 100 top
                        notch companies visit our Institution and conduct
                        placement activities for the full-time recruitment and
                        internship. The Training and Placement Centre takes
                        immense pleasure in stating that around 70 percent of
                        eligible students are placed in their dream companies
                        with attractive packages. The average compensation is Rs
                        6.5 LPA and highest compensation is Rs 37.5 LPA during
                        21-22. Leading companies like Infosys, Accenture,
                        Capgemini, OneDirect, Cisco, Akamai, Microfocus, HPE,
                        SAP, Nokia, Microchip, BEL, Mercedes Benz, Bosch, L&T-
                        ECC and Brigade Group recruit our students. Our
                        resourceful alumni have set commendable standards in the
                        corporate world through their admirable contributions.
                        The consistent placement record illustrates our
                        commitment and devotion towards creating employment
                        opportunities to our students. We have excellent
                        infrastructure facilities in terms of full-fledged
                        seminar hall, training hall, conference and board rooms,
                        interview cabins, full-fledged computer labs for
                        conducting recruitment process.
                      </p>
                    </div>
                    <div className="col-md-12 paddingtop-2">
                      <div className="section-title with-desc text-left clearfix">
                        <div
                          className="title-header"
                          style={{ borderTopColor: "red" }}
                        >
                          <h4 className="title">VISION</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12" style={{ margin: "auto" }}>
                      <p className="justify">
                        To enhance and equip our students with the
                        conceptualized employability and professional skills in
                        meeting the dynamic challenges of the corporate world
                        and acquaint our faculty members with current industrial
                        trends.
                      </p>
                      <br />
                    </div>
                    <div className="col-md-12 paddingtop-2">
                      <div className="section-title with-desc text-left clearfix">
                        <div
                          className="title-header"
                          style={{ borderTopColor: "red" }}
                        >
                          <h4 className="title">MISSION</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12" style={{ margin: "auto" }}>
                      <ul className="ttm-list ttm-list-style-icon">
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            To train the students to meet the industry
                            requirements.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            To guide the students to achieve their career goals.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            To provide ample placement opportunities for the
                            students to get their dream jobs..
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6 col-lg-12 paddingtop-2">
                      <div className="section-title with-desc text-left clearfix">
                        <div
                          className="title-header"
                          style={{ borderTopColor: "red" }}
                        >
                          <h4 className="title">
                            The Objectives Of The Centre Are As Under
                          </h4>
                        </div>
                      </div>
                      <ul className="ttm-list ttm-list-style-icon">
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            To enhance industry-institute interaction..{" "}
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            To organize seminar, training programmes and
                            work-shops for students so as to help students to
                            learn latest technologies.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            To organize seminars, technical talks by eminent
                            personalities from academic institutes and industry
                            to enhance the awareness of students.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            To provide guidance and information for higher
                            studies.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            To conduct mock tests, interviews, Group Discussions
                            for students..
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            To conduct training programs to enhance the
                            communication skills and develop inter-personal
                            skills of students..
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            To arrange campus-interviews by inviting leading
                            companies to recruit students..
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-12 paddingtop-2">
                      <div className="section-title with-desc text-left clearfix">
                        <div
                          className="title-header"
                          style={{ borderTopColor: "red" }}
                        >
                          <h4 className="title">Placement Process</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="ttm_single_image-wrapper">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/about-2.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                    </div>
                    <div className="col-md-7">
                      <h4 className="res-767-mt-20"></h4>
                      <p className="justify" style={{ margin: "auto" }}>
                        Every year the enrollment process for placements begins
                        when students come to pre final year. Each department
                        will have one faculty and two student coordinators to
                        facilitate training and placement activities. The
                        coordinators organize mock test and group discussion in
                        their respective classes. External trainers are invited
                        to train the students on solving the aptitude, prepare
                        for group discussion, interview facing skills and
                        technical skills. Experts from the varied industries are
                        invited to give guest lecture on the latest
                        technologies. Every year more than three hundred reputed
                        companies are invited to visit our institution to
                        recruit our students for internship and full time jobs.
                      </p>
                    </div>
                    <div className="col-md-12">
                      <h4 className="res-767-mt-20"></h4>
                      <p className="paddingtop-1 justify">
                        Several companies send their assessment form to assess
                        our institute before they visit for recruitment. We
                        provide all the required information to the companies.
                        Based on the information provided, companies visit our
                        institution for campus recruitment. More than one
                        hundred and sixty companies from different sectors visit
                        BIT to recruit students for internship and full-time
                        job. More than six hundred students get their dream jobs
                        through placement center.
                      </p>
                    </div>
                    <div className="col-md-12 paddingtop-2">
                      <div className="section-title with-desc text-left clearfix">
                        <div
                          className="title-header"
                          style={{ borderTopColor: "red" }}
                        >
                          <h4 className="title">Placement Training</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-12">
                      <ul className="ttm-list ttm-list-style-icon">
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            To create awareness about "career planning" and
                            "career mapping" among the students.{" "}
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            To train the students on "Personality development".
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            To organize Various Training Programmes to train the
                            students in the areas of Quantitative Aptitude,
                            Logical reasoning and Verbal reasoning.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            To train the students through Mock Interviews to
                            perform well in the professional interviews as per
                            the Industries expectations.
                          </span>
                        </li>
                      </ul>
                    </div>

                    {/* xsdfd */}

                    <div className="col-md-12 paddingtop-2">
                      <div className="section-title with-desc text-left clearfix">
                        <div
                          className="title-header"
                          style={{ borderTopColor: "red" }}
                        >
                          <h4 className="title">Training Highlights</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-12">
                      <ul className="ttm-list ttm-list-style-icon">
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            We provide training for the students to prepare for
                            campus recruitment to get the offers in their dream
                            companies.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Industry best practices, Aptitude, Soft Skills and
                            Technical training
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Experienced Trainers and latest Lab Facility
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Resume & Interview Preparation Support
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Training and Placement Centre mainly concentrates on
                            working-out an action plan for students, according
                            to the demand of the corporates, which are not
                            included in the regular curriculum. The training
                            caters to sharpen the students’ communication &
                            interview skills.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Students are given access to the virtual
                            self-leaning platform to learn, practice and take
                            mock assessments.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Each department arrange domain specific placement
                            training on niche areas to the students regularly.
                            Department placement coordinators support student’s
                            during campus interviews and training programs.
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-12 paddingtop-2">
                      <div className="section-title with-desc text-left clearfix">
                        <div
                          className="title-header"
                          style={{ borderTopColor: "red" }}
                        >
                          <h4 className="title">Training Modules</h4>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 paddingtop-1">
                      <h5 className="title">Career Orientation</h5>
                    </div>
                    <div className="App">
                      <table style={tableStyles}>
                        <thead>
                          <tr style={headerRowStyles}>
                            <th style={cellStyles}>Objective</th>
                            <th style={cellStyles}>Application</th>
                            <th style={cellStyles}>Methodology</th>
                            <th style={cellStyles}>Take Away</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style={bodyRowStyles}>
                            <td style={cellStyles}>Goal Setting</td>
                            <td style={cellStyles}>
                              <ul>
                                <li>What is a goal?</li>
                                <li>Why set a goal?</li>
                                <li>Goal setting steps</li>
                                <li>Follow-up techniques</li>
                                <li>Goal upgrade</li>
                              </ul>
                            </td>
                            <td style={cellStyles}>
                              Classroom activity and debriefing
                            </td>
                            <td style={cellStyles}>
                              Students will have a clear focus on their
                              objectives and aspirations during the course of
                              their graduation studies.
                            </td>
                          </tr>
                          <tr style={{ ...bodyRowStyles, ...evenRowStyles }}>
                            <td style={cellStyles}>SWOT Analysis</td>
                            <td style={cellStyles}>
                              <ul>
                                <li>What is a SWOT?</li>
                                <li>Correlation with Goals</li>
                                <li>S.M.A.R.T</li>
                                <li>Tools to stay focused</li>
                              </ul>
                            </td>
                            <td style={cellStyles}>Introspection Techniques</td>
                            <td style={cellStyles}>
                              Based on the set Goals, students will understand
                              the tools required to achieve them and also work
                              on any chinks in his/ her armour.
                            </td>
                          </tr>
                          <tr style={bodyRowStyles}>
                            <td style={cellStyles}>Resume Building</td>
                            <td style={cellStyles}>
                              <ul>
                                <li>What is a resume?</li>
                                <li>Resume sections</li>
                                <li>How to email a resume?</li>
                              </ul>
                            </td>
                            <td style={cellStyles}>Resume writing</td>
                            <td style={cellStyles}>
                              An original, unique, self-made resume with every
                              student.
                            </td>
                          </tr>
                          <tr
                            style={{
                              ...bodyRowStyles,
                              ...evenRowStyles,
                              ...lastRowStyles,
                            }}
                          >
                            <td style={cellStyles}>Email Drafting</td>
                            <td style={cellStyles}>
                              <ul>
                                <li>When do we write emails?</li>
                                <li>Email contexts</li>
                                <li>Business language</li>
                              </ul>
                            </td>
                            <td style={cellStyles}>
                              Classroom session and email writing
                            </td>
                            <td style={cellStyles}>
                              Business email writing skill with focus on
                              ‘writing up’ the chain of command.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="col-md-12 paddingtop-1">
                      <h5 className="title">
                        Campus Recruitment Training (CRT)
                      </h5>
                    </div>
                    <div className="App">
                      <table style={tableStyles}>
                        <thead>
                          <tr style={headerRowStyles}>
                            <th style={cellStyles}>Objective</th>
                            <th style={cellStyles}>Application</th>
                            <th style={cellStyles}>Methodology</th>
                            <th style={cellStyles}>Take Away</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style={bodyRowStyles}>
                            <td style={cellStyles}>
                              Aptitude Test Preparation
                            </td>
                            <td style={cellStyles}>
                              <ul>
                                <li>Quantitative Ability</li>
                                <li>Verbal Ability</li>
                                <li>Analytical Ability</li>
                                <li>Reasoning Ability</li>
                                <li>Data Interpretation</li>
                                <li>Writing skills</li>
                                <li>Story writing</li>
                              </ul>
                            </td>
                            <td style={cellStyles}>Classroom and online</td>
                            <td style={cellStyles}>
                              All concepts required to be learnt by the student
                              to successfully clear a placement aptitude test.
                            </td>
                          </tr>
                          <tr style={{ ...bodyRowStyles, ...evenRowStyles }}>
                            <td style={cellStyles}>Group Discussions</td>
                            <td style={cellStyles}>
                              <ul>
                                <li>Dynamics of GD</li>
                                <li>GD Etiquettes</li>
                                <li>Topics of discussion</li>
                                <li>Thought process</li>
                                <li>Value addition</li>
                                <li>Effective speech</li>
                              </ul>
                            </td>
                            <td style={cellStyles}>
                              Practical application of GD, Group Reviews,
                              Individual Feedback
                            </td>
                            <td style={cellStyles}>
                              A comprehensive guide to group discussions and
                              everything related to maximizing your chances of
                              clearing the GD.
                            </td>
                          </tr>
                          <tr style={bodyRowStyles}>
                            <td style={cellStyles}>Personal Interviews</td>
                            <td style={cellStyles}>
                              <ul>
                                <li>Interview FAQs</li>
                                <li>Domain specific questions</li>
                                <li>Answering Strategies</li>
                                <li>Question Prediction</li>
                                <li>Rapport Building</li>
                                <li>Selling Yourself</li>
                              </ul>
                            </td>
                            <td style={cellStyles}>
                              Overview preparation and insights
                            </td>
                            <td style={cellStyles}>
                              Perspective: 99% Actual Interview & 1% Simulation
                              experience.
                            </td>
                          </tr>
                          <tr style={{ ...bodyRowStyles, ...evenRowStyles }}>
                            <td style={cellStyles}>Mock Assessments</td>
                            <td style={cellStyles}>
                              <ul>
                                <li>Pre training assessment</li>
                                <li>Post training assessment</li>
                                <li>Recruiter specific assessment</li>
                              </ul>
                            </td>
                            <td style={cellStyles}>Computer based tests</td>
                            <td style={cellStyles}>
                              High degree of awareness and familiarity with
                              aptitude tests and Management reporting on batch
                              performance.
                            </td>
                          </tr>
                          <tr style={bodyRowStyles}>
                            <td style={cellStyles}>Technical Essentials</td>
                            <td style={cellStyles}>
                              <ul>
                                <li>
                                  Programming using C, C++, Java, Python, DS,
                                  Algorithms
                                </li>
                              </ul>
                            </td>
                            <td style={cellStyles}>
                              Classroom exercises and practical applications
                            </td>
                            <td style={cellStyles}>
                              A comprehensive guide to all technical concepts
                              that helps students clear a technical online test.
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <h4 style={{ textAlign: "center" }}>Module Type</h4>
                      <table style={tableStyles}>
                        <thead>
                          <tr style={headerRowStyles}>
                            <th style={cellStyles}>Module Type</th>
                            <th style={cellStyles}>Focus Areas</th>
                            <th style={cellStyles}>Duration</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style={bodyRowStyles}>
                            <td style={cellStyles}>Quantitative Aptitude</td>
                            <td style={cellStyles}>
                              <ul>
                                <li>Number Properties</li>
                                <li>Percentages</li>
                                <li>Averages, LCM and HCF</li>
                                <li>Profit and Loss</li>
                                <li>Simple Interest & Compound Interest</li>
                                <li>Ratios, Proportion and Variation</li>
                                <li>Time and Work</li>
                                <li>Time, Speed, and Distance</li>
                                <li>Number Series and Letter Series</li>
                                <li>Coding and Decoding</li>
                                <li>Permutation and Combination</li>
                                <li>Probability</li>
                              </ul>
                            </td>
                            <td style={cellStyles}>14 Hours</td>
                          </tr>
                          <tr style={{ ...bodyRowStyles, ...evenRowStyles }}>
                            <td style={cellStyles}>Verbal Aptitude</td>
                            <td style={cellStyles}>
                              <ul>
                                <li>Phrasal Verbs</li>
                                <li>Idioms</li>
                                <li>Proverbs</li>
                                <li>Prepositions</li>
                                <li>Articles</li>
                                <li>Vocabulary</li>
                                <li>Collective Nouns</li>
                                <li>Commonly Confused Words</li>
                                <li>Tense-Based Errors</li>
                                <li>Subject-Verb Agreement</li>
                              </ul>
                            </td>
                            <td style={cellStyles}>6 Hours</td>
                          </tr>
                          <tr style={bodyRowStyles}>
                            <td style={cellStyles}>Analytical Reasoning</td>
                            <td style={cellStyles}>
                              <ul>
                                <li>Statement and Conclusion</li>
                                <li>Visual Reasoning</li>
                                <li>Blood Relations</li>
                                <li>Flow Charts</li>
                                <li>Ages</li>
                                <li>Direction Sense</li>
                                <li>Logical Reasoning</li>
                                <li>Order and Ranking</li>
                                <li>Seating Arrangements</li>
                                <li>Analogy and Classification recognition</li>
                              </ul>
                            </td>
                            <td style={cellStyles}>10 Hours</td>
                          </tr>
                          <tr style={{ ...bodyRowStyles, ...evenRowStyles }}>
                            <td style={cellStyles}>Soft Skills</td>
                            <td style={cellStyles}>
                              <ul>
                                <li>Interview Skills</li>
                                <li>Group Discussions</li>
                                <li>Resume Writing</li>
                              </ul>
                            </td>
                            <td style={cellStyles}>6 Hours</td>
                          </tr>
                          <tr style={bodyRowStyles}>
                            <td style={cellStyles}>Technical</td>
                            <td style={cellStyles}>
                              <ul>
                                <li>Data Structures</li>
                                <li>Operating System</li>
                                <li>Pattern Programming</li>
                                <li>C Programming</li>
                                <li>C++ Programming</li>
                                <li>Java</li>
                                <li>Python</li>
                                <li>DBMS</li>
                                <li>Computer Systems</li>
                                <li>Algorithms</li>
                                <li>Problem Solving</li>
                                <li>Dynamic Programming</li>
                              </ul>
                            </td>
                            <td style={cellStyles}>60 Hours</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="App" style={{ textAlign: "center" }}>
                      <h4>Training Partners</h4>
                      <div style={accordionStyles}>
                        <div style={headerStyles} onClick={toggleEthnus}>
                          <span>Ethnus</span>
                          {openEthnus ? <FaChevronUp /> : <FaChevronDown />}
                        </div>
                        {openEthnus && (
                          <div style={contentStyles}>
                            <p>Mr. Badrinath Lingaraju</p>
                          </div>
                        )}
                      </div>
                      <div style={accordionStyles}>
                        <div style={headerStyles} onClick={toggleQuantMasters}>
                          <span>Quant Masters</span>
                          {openQuantMasters ? (
                            <FaChevronUp />
                          ) : (
                            <FaChevronDown />
                          )}
                        </div>
                        {openQuantMasters && (
                          <div style={contentStyles}>
                            <p>Mr. Himanshu Sharma</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 widget-area">
              <PlacementsSidebar />
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default PlacementAbout;
