import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class DepartmentResearch extends Component {

  render() {
    const banner = {
      cse: <Banner title="Computer Science and Engineering" image="banners/department-cse.jpg" />,
      ise: <Banner title="Information Science and Engineering" image="banners/department-ise.jpg" />,
      ai: <Banner title="Artificial Intelligence and Machine Learning" image="banners/department-ai.jpg" />,
      ece: <Banner title="Electronics and Communication Engineering" image="banners/department-ece.jpg" />,
      mech: <Banner title="Mechanical Engineering" image="banners/department-me.jpg" />,
      civil: <Banner title="Civil Engineering" image="banners/department-me.jpg" />,
      eee: <Banner title="Electrical and Electronics Engineering" image="banners/department-eee.jpg" />,
      ete: <Banner title="Electronics and Telecommunication Engineering" image="banners/department-ete.jpg" />,
      eie: <Banner title="Electronics and Instrumentation Engineering" image="banners/department-eie.jpg" />,
      iem: <Banner title="Industrial Engineering and Management" image="banners/department-iem.jpg" />,
      mba: <Banner title="Master of Business Administration" image="banners/department-mba.jpg" />,
      mca: <Banner title="Master of Computer Applications (MCA)" image="banners/department-mca.jpg" />,
      phy: <Banner title="Physics" image="banners/department-phy.jpg" />,
      chem: <Banner title="Chemistry" image="banners/department-che.jpg" />,
      math: <Banner title="Mathematics" image="banners/department-math.jpg" />,
      hum: <Banner title="Humanities" image="banners/department-hum.jpg" />,
    }

    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        {banner[this.props.match.params.id]}

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-single-content-area">
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Research &#38; Innovation</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 ttm-box-col-wrapper d-flex align-items-stretch" style={{ marginBottom: "1rem" }}>
                      <div className="featured-imagebox featured-imagebox-blog">
                        <div className="featured-thumbnail">
                          <Link className="ttm-btn" style={{ padding: "10px" }} to={process.env.PUBLIC_URL + `/department-research-guides/${this.props.match.params.id}`}>
                            <div className="post-meta">
                              <span style={{ fontWeight: "bold", fontSize: "20px", color: "#98002e" }}>Research Guides / <br />Ph. D awarded</span><br />
                            </div>
                            <p className="sentence-case">Working with expert faculty to conduct academic research is an amazing way to learn new skills.  Find here the details of research guides recognized by VTU with their area of research and Ph. D degrees awarded from the Research Centre of the department.</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ttm-box-col-wrapper d-flex align-items-stretch" style={{ marginBottom: "1rem" }}>
                      <div className="featured-imagebox featured-imagebox-blog">
                        <div className="featured-thumbnail">
                          <Link className="ttm-btn" style={{ padding: "10px" }} to={process.env.PUBLIC_URL + `/department-research-facilities/${this.props.match.params.id}`}>
                            <div className="post-meta">
                              <span style={{ fontWeight: "bold", fontSize: "20px", color: "#98002e" }}>Research Facilities</span><br /><br />
                            </div>
                            <p className="sentence-case">Sufficient Research facilities are available in the Centre for the students to complete their research work successfully. Check here for the details of facilities, uses and In-charge faculty.</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ttm-box-col-wrapper d-flex align-items-stretch" style={{ marginBottom: "1rem" }}>
                      <div className="featured-imagebox featured-imagebox-blog">
                        <div className="featured-thumbnail">
                          <Link className="ttm-btn" style={{ padding: "10px" }} to={process.env.PUBLIC_URL + `/department-research-grants/${this.props.match.params.id}`}>
                            <div className="post-meta">
                              <span style={{ fontWeight: "bold", fontSize: "20px", color: "#98002e" }}>Research Grants / Student Project Grants</span><br />
                            </div>
                            <p className="sentence-case">Securing a research grant is a matter of prestige for a researcher and it also helps in the advancement of career.  Check here for the grants received by faculty and students of the department.</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ttm-box-col-wrapper d-flex align-items-stretch" style={{ marginBottom: "1rem" }}>
                      <div className="featured-imagebox featured-imagebox-blog">
                        <div className="featured-thumbnail">
                          <Link className="ttm-btn" style={{ padding: "10px" }} to={process.env.PUBLIC_URL + `/department-research-publications/${this.props.match.params.id}`}>
                            <div className="post-meta">
                              <span style={{ fontWeight: "bold", fontSize: "20px", color: "#98002e" }}>Patents / Publications</span><br />
                            </div>
                            <p className="sentence-case">The scientific and technical contributions of a researcher in a particular field are disseminated to others through publications and patents. Click here to know the contributions made by researchers of the department.</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ttm-box-col-wrapper d-flex align-items-stretch" style={{ marginBottom: "1rem" }}>
                      <div className="featured-imagebox featured-imagebox-blog">
                        <div className="featured-thumbnail">
                          <Link className="ttm-btn" style={{ padding: "10px" }} to={process.env.PUBLIC_URL + `/${this.props.match.params.id}-coe`}>
                            <div className="post-meta">
                              <span style={{ fontWeight: "bold", fontSize: "20px", color: "#98002e" }}>Center of Excellence</span><br />
                            </div>
                            <p className="sentence-case">BIT has established Center of Excellence in various areas of expertise to help students and faculty to acquire fine tune professional skills as required by global market.</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ttm-box-col-wrapper d-flex align-items-stretch" style={{ marginBottom: "1rem" }}>
                      <div className="featured-imagebox featured-imagebox-blog">
                        <div className="featured-thumbnail">
                          <Link className="ttm-btn" style={{ padding: "10px" }} to={process.env.PUBLIC_URL + `/${this.props.match.params.id}-ce`}>
                            <div className="post-meta">
                              <span style={{ fontWeight: "bold", fontSize: "20px", color: "#98002e" }}>Consultancy services</span><br />
                            </div>
                            <p className="sentence-case">The faculty of the department are encouraged to openly share their expertize, knowledge resources, and facilities for the mutual benefit of institution and external bodies through consultancy services.</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department={this.props.match.params.id} />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}
