import React, { Component } from "react";
import { Link } from "react-router-dom";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class ECETeaching extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Electronics and Communication Engineering" image="banners/department-ece.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <h4 className="title">Learning Resources</h4>
                  </div>
                </div>
                <div className="ttm-service-description">
                  <p>Wide arrays of learning materials are provided to students in advance for each course to catalyze their learning ability. These resources include syllabus, course materials, and links to digital aids. Click here to access learning resources.</p>
                  <Link className="ttm-btn" style={{ fontSize: "1.15rem", paddingTop: "1rem", color: "#00a76a" }} to={process.env.PUBLIC_URL + `/teaching-learning-material/ece`}>Click Here for Learning Resources</Link>
                </div>
                <div className="ttm-service-single-content-area paddingtop-2">
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Teaching - Learning</h4>
                    </div>
                  </div>
                  <div className="ttm-service-description">
                    <p>It is the very important part of engineering education. The knowledge and information is shared with students in many ways. We follow VTU curriculum which is formed under outcome based education system or Choice Based Credit System (CBCS). The advanced teaching techniques and models are used to deliver the courses.</p>
                    <h6 className="paddingtop-2">General Activities</h6><br />
                    <div className="row">
                      <div className="col-lg-6">
                        <ul className="ttm-list ttm-list-style-icon">
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Students Counseling</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Parents Teachers Meet</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Central Digital Library facility</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Department Library facility</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Placement Activities</span></li>
                        </ul>
                      </div>
                      <div className="col-lg-6">
                        <ul className="ttm-list ttm-list-style-icon">
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">IEEE Students chapters</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">ECSA Activities [ECSA – Electronics and communication students associations]</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Project Exhibition</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="ece" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
