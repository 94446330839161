import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class DepartmentSidebar extends Component {
    render() {
        return (
            <div className="widget widget-nav-menu">
                <ul className="widget-menu">
                    <li><NavLink to={process.env.PUBLIC_URL + `/${this.props.department}-about`}>About</NavLink></li>
                    <li><NavLink to={process.env.PUBLIC_URL + `/${this.props.department}-programs`}>Programs</NavLink></li>
                    <li><NavLink to={process.env.PUBLIC_URL + `/department-hod/${this.props.department}`}>Head of the Department</NavLink></li>
                    <li><NavLink to={process.env.PUBLIC_URL + `/teaching-faculties/${this.props.department}`}>Teaching Faculty</NavLink></li>
                    <li><NavLink to={process.env.PUBLIC_URL + `/non-teaching-faculties/${this.props.department}`}>Technical &#38; Supporting Staff</NavLink></li>
                    <li><NavLink to={process.env.PUBLIC_URL + `/${this.props.department}-teaching`}>Teaching - Learning</NavLink></li>
                    <li><NavLink to={process.env.PUBLIC_URL + `/research/${this.props.department}`}>Research and Innovations</NavLink></li>
                    <li style={{display: this.props.department == "math" || this.props.department == "chem" || this.props.department == "phy" ? "none" : "block"}}>
                        <NavLink to={process.env.PUBLIC_URL + `/${this.props.department}-industry`}>Industry Interactions</NavLink>
                    </li>
                    <li style={{display: this.props.department == "math" || this.props.department == "chem" || this.props.department == "phy" ? "none" : "block"}}>
                        <NavLink to={process.env.PUBLIC_URL + `/alumni/${this.props.department}`}>Alumni</NavLink>
                    </li>
                    <li><NavLink to={process.env.PUBLIC_URL + `/activities/${this.props.department}`}>Activities & Reports</NavLink></li>
                    <li><NavLink to={process.env.PUBLIC_URL + `/${this.props.department}-contact`}>Contact</NavLink></li>
                </ul>
            </div>
        )
    }
}

