import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class MECOE extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Mechanical Engineering" image="banners/department-me.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title"> Center of Excellence</h4>
                                    </div>
                                    <div className="ttm-service-description">
                                        <p className="justify">Process modeling is a branch of engineering science which deals with the optimization of control parameters. It has great importance in the industry for current engineering applications to improve the quality of the product by using optimization tools such as ANN, Taguchi, and Genetic Algorithms etc. The behavior of the design process can be predicted by using improved computational techniques. The main objective of the optimized process is to minimize the number of actual experimental trials. On the other hand, actual data can be collected and analysed via process modeling to predict the control parameters.</p>
                                    </div>
                                    <div className="ttm-service-single-content-area">
                                        <div className="section-title with-desc text-left clearfix">
                                            <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                                <h5 className="title">Vision</h5>
                                            </div>
                                            <p>Propagate the use of process modelling tools amongst academics and industries through continuing education programmes.</p>
                                        </div>
                                        <div className="section-title with-desc text-left clearfix">
                                            <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                                <h5 className="title">Objectives</h5>
                                            </div>
                                            <ul className="ttm-list ttm-list-style-icon justify">
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">MOU with reputed industries and research institutions.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Skill development training for staff and students.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Optimization of Product Design.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Optimizing Process Parameters..</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="mech" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}