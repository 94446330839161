import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import ELearningSidebar from './components/ELearningSidebar';

export default class ELearningResearchResources extends Component {

  render() {

    const Datas = [
      {
        "id": "1",
        "header": "BIT- VTU Consortium",
        "description": "BIT- VTU Consortium	The following e-resource Databases have been subscribed by Bangalore Institute of Technology under VTU Consortium for the year 2022-23. Click the link to access these resources.",
        "link": "https://drive.google.com/file/d/1deYhN7p9Gv96Hk0XVAFQfomu7ZM8Sp3T/view?usp=sharing"
      },
      {
        "id": "2",
        "header": "Shodhganga: a reservoir of Indian Theses",
        "description": "Shodhganga provides a platform for research students to deposit their Ph.D. theses and make it available to the entire scholarly community in open access. The repository has the ability to capture, index, store, disseminate and preserve ETDs submitted by the researchers. Online availability of electronic theses through centrally-maintained digital repositories, not only ensure easy access and archiving of Indian doctoral theses but will also help in raising the standard and quality of research. This would overcome serious problem of duplication of research and poor quality resulting from the poor visibility and the unseen factor in research output.",
        "link": "hhttps://shodhganga.inflibnet.ac.in/"
      },
      {
        "id": "3",
        "header": "e-ShodhSindhu ",
        "description": "The e-ShodhSindh provides current as well as archival access to more than 10,000 core and peer-reviewed journals and a number of bibliographic, citation and factual databases in different disciplines from a large number of publishers and aggregators to its member institutions including centrally-funded technical institutions, universities and colleges that are covered under 12(B) and 2(f) Sections of the UGC Act. Bangalore Institute of Technology is covered under 12(B) and 2(f) Sections of the UGC Act.",
        "link": "https://ess.inflibnet.ac.in/"
      },
      {
        "id": "4",
        "header": "VIDWAN",
        "description": "VIDWAN is the premier database of profiles of scientists / researchers and other faculty members working at leading academic institutions and other R & D organisation involved in teaching and research in India. It provides important information about expert's background, contact address, experience, scholarly publications, skills and accomplishments, researcher identity, etc. The database would be instrumental in selection of panels of experts for various committees, taskforce, established by the Ministries / Govt. establishments for m",
        "link": "https://vidwan.inflibnet.ac.in/"
      },
      {
        "id": "5",
        "header": "IRIS",
        "description": "IRINS is web-based Research Information Management (RIM) service portal that facilitates the academic, R&D organisations and faculty members, scientists to collect, curate and showcase the scholarly communication activities and provide an opportunity to create the scholarly network. The IRINS is available as free software-as-service to the academic and R&D organisations in India. The IRINS would support to integrate the existing research management system such as HR system, course management, grant management system, institutional repository, open and commercial citation databases, scholarly publishers, etc. It has integrated with academic identity such as ORCID ID, ScopusID, Research ID, Microsoft Academic ID, Google Scholar ID for ingesting the scholarly publication from various sources.",
        "link": "http://irins.org/irins/"
      },
      {
        "id": "6",
        "header": "NDLTD",
        "description": "The Networked Digital Library of Theses and Dissertations (NDLTD) is an international organization dedicated to promoting the adoption, creation, use, dissemination, and preservation of electronic theses and dissertations (ETDs). We support electronic publishing and open access to scholarship in order to enhance the sharing of knowledge worldwide.",
        "link": "https://ndltd.org/"
      },
      {
        "id": "7",
        "header": "DOAJ",
        "description": "DIRECTORY OF OPEN ACCESS JOURNALS (DOAJ) is a community-curated online directory that indexes and provides access to high quality, open access, peer-reviewed journals. All DOAJ services are free of charge including being indexed. All data is freely available.",
        "link": "https://doaj.org/"
      },
      {
        "id": "8",
        "header": "SSRN",
        "description": "Tomorrow´s Research Today. SSRN´s e-Library provides 1,152,461 research papers from 961,350 researchers in more than 65 disciplines.",
        "link": "https://www.e-yantra.org/"
      },
      {
        "id": "9",
        "header": "Open Access Theses and Dissertations",
        "description": "Advanced research and scholarship. Theses and dissertations, free to find, free to use",
        "link": "https://oatd.org/"
      },
      {
        "id": "10",
        "header": "Science Direct Open Access Content",
        "description": "1.4 million articles on ScienceDirect are open access. Articles published open access are peer-reviewed and made freely available for everyone to read, download and reuse in line with the user license displayed on the article.",
        "link": "https://www.sciencedirect.com/#open-access"
      },
      {
        "id": "11",
        "header": "Springer Open",
        "description": "Access and publish in springer open books, and journals.",
        "link": "https://www.springeropen.com/"
      },
      {
        "id": "12",
        "header": "Oxford open",
        "description": "Access and publish in Oxford open books, and journals.",
        "link": "https://academic.oup.com/pages/open-research/open-access"
      },
      {
        "id": "13",
        "header": "Project euclid",
        "description": "The complete holdings of the following publications are currently accessible to any Project Euclid user without a subscription or site license.",
        "link": "https://www.projecteuclid.org/open-access"
      },
      {
        "id": "14",
        "header": "Taylor & Francis Open Access",
        "description": "Publish your research in open journals- Taylor & Francis Group’s flourishing program of full open access journals enables you to make your research freely and permanently available so anyone, anywhere can read and build upon your work.",
        "link": "https://www.tandfonline.com/openaccess/openjournals"
      },
      {
        "id": "16",
        "header": "Cambridge University Press open Access",
        "description": "At Cambridge University Press, Open Access submissions are accepted in more than 350 of our journals. For more information on how to publish Open Access and the benefits of doing so, click here.",
        "link": "https://www.cambridge.org/core/what-we-publish/open-access"
      },
      {
        "id": "17",
        "header": "Free Journal resources",
        "description": "Click here to find the links for various free journal resources.",
        "link": "https://drive.google.com/file/d/1d-tjjMAMKLzawQTpmvAPAT3PkaBpbO6k/view?usp=sharing"
      }
    ]

    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="E - Learning Corner" image="banners/banner-teaching.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header" style={{ borderTopColor: "red" }}>
                    <h4 className="title">Research Resources</h4>
                  </div>
                </div>
                <table class="table">
                  <thead style={{ backgroundColor: "#98002e", color: "white", textAlign: "center" }}>
                    <tr>
                      <th scope="col" style={{ width: "10%" }}>SL No</th>
                      <th scope="col" style={{ width: "25%" }}>Platform</th>
                      <th scope="col" style={{ width: "50%" }}>Description</th>
                      <th scope="col" style={{ width: "15%" }}>Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Datas.map((data, i) => (
                        <tr className="justify">
                          <td>{data.id}</td>
                          <td>{data.header}</td>
                          <td>{data.description}</td>
                          <td><a target="_blank" href={data.link}>Click Here</a></td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
              <div className="col-lg-3 widget-area">
                <ELearningSidebar />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}
