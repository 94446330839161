import React, { Component } from "react";
import Banner from "../../components/common/Banner";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import PlacementsSidebar from "./components/PlacementsSidebar";

export default class PlacementOurRecruiters extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Placement" image="banners/banner-placement.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-single-content-area">
                  <div className="section-title with-desc text-left clearfix">
                    <div
                      className="title-header"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">Our Recruiters</h4>
                    </div>
                  </div>
                  <div className="ttm-service-description">
                    <div className="row">
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-1.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-2.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-3.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-4.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-5.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-6.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-7.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-8.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-9.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-10.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-11.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-12.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-13.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-14.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-15.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-16.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-17.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-18.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-19.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-20.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-21.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-22.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-23.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-24.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-25.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-26.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-27.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-28.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-29.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-30.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-31.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-32.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-33.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-34.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-35.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-36.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-37.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-38.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-39.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-40.jpg"
                          }
                          alt="single-img-eighteen"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-41.jpg"
                          }
                          alt="single-img-41"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-42.jpg"
                          }
                          alt="single-img-42"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-43.jpg"
                          }
                          alt="single-img-43"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-44.jpg"
                          }
                          alt="single-img-44"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-45.jpg"
                          }
                          alt="single-img-45"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-46.jpg"
                          }
                          alt="single-img-46"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-47.jpg"
                          }
                          alt="single-img-47"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-48.jpg"
                          }
                          alt="single-img-48"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-49.jpg"
                          }
                          alt="single-img-49"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-50.jpg"
                          }
                          alt="single-img-50"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-51.jpg"
                          }
                          alt="single-img-51"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-52.jpg"
                          }
                          alt="single-img-52"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-53.jpg"
                          }
                          alt="single-img-53"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-54.jpg"
                          }
                          alt="single-img-54"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-55.jpg"
                          }
                          alt="single-img-55"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-56.jpg"
                          }
                          alt="single-img-56"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-57.jpg"
                          }
                          alt="single-img-57"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-58.jpg"
                          }
                          alt="single-img-58"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-59.jpg"
                          }
                          alt="single-img-59"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-60.jpg"
                          }
                          alt="single-img-60"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-61.jpg"
                          }
                          alt="single-img-61"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-62.jpg"
                          }
                          alt="single-img-62"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-63.jpg"
                          }
                          alt="single-img-63"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-64.jpg"
                          }
                          alt="single-img-64"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-65.jpg"
                          }
                          alt="single-img-65"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-66.jpg"
                          }
                          alt="single-img-66"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-67.jpg"
                          }
                          alt="single-img-67"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-68.jpg"
                          }
                          alt="single-img-68"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-69.jpg"
                          }
                          alt="single-img-69"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-70.jpg"
                          }
                          alt="single-img-70"
                        />
                      </div>
                      <div className="col-md-3 paddingtop-2">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/placement/cl-71.jpg"
                          }
                          alt="single-img-71"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <PlacementsSidebar />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
