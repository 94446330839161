import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class EIEContact extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Electronics and Instrumentation Engineering" image="banners/department-eie.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header">
                                        <h3 className="title">Contact</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Head of the Department</h6></div>
                                                <div className="featured-desc">
                                                    <p>Dr. Aswathappa P</p>
                                                    <p>E-mail Id: ashwathapap@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: +91 94487 68598</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Centre of Excellence</h6></div>
                                                <div className="featured-desc">
                                                    <p>Dr. L K Sudha</p>
                                                    <p>E-mail Id: sudhalk@bit-bangalore.edu.in  </p>
                                                    <p>Contact Number:+91 98457 49897</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Placement Coordinator</h6></div>
                                                <div className="featured-desc">
                                                    <p>Prof. Anjan Kumar B S</p>
                                                    <p>E-mail Id:  anjankumarbs@bit-bangalore.edu.in</p>
                                                    <p> Contact Number: +91 94485 54852</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Alumini Coordinator</h6></div>
                                                <div className="featured-desc">
                                                    <p>Prof. Bharathi D</p>
                                                    <p>E-mail Id:  bharathid@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: +91 98442 56934</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Result Coordinator</h6></div>
                                                <div className="featured-desc">
                                                    <p>Prof. Anjan Kumar B S</p>
                                                    <p>E-mail Id: anjankumarbs@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: 94485 54852</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Attendance Coordinator</h6></div>
                                                <div className="featured-desc">
                                                    <p>Prof. Vidya S S</p>
                                                    <p>E-mail Id: vidyass@bit-bangalore.edu.in</p>
                                                    <p>Contact Number:9448297828</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Internal Assessment Coordinator</h6></div>
                                                <div className="featured-desc">
                                                    <p>Prof. Lalitha H</p>
                                                    <p>E-mail Id: lalithah@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: 98457 49897</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Department Library Coordinator</h6></div>
                                                <div className="featured-desc">
                                                    <p>Prof. Bharathi D</p>
                                                    <p>E-mail Id:  bharathid@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: 98442 56934</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Professional Body/ MOU’s Coordinator</h6></div>
                                                <div className="featured-desc">
                                                    <p>Prof. Neethashree N R</p>
                                                    <p>E-mail Id: neethashreenr@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: 90362 97807</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header">
                                        <h3 className="title">Student Counsellors</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>1st Year Students</h6></div>
                                                <div className="featured-desc">
                                                    <p>Dr. L K Sudha</p>
                                                    <p>E-mail Id: sudhalk@bit-bangalore.edu.in  </p>
                                                    <p>Contact Number: +91 98457 49897</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>1st Year Students</h6></div>
                                                <div className="featured-desc">
                                                    <p>Prof. Neethashree N R</p>
                                                    <p>E-mail Id: neethashreenr@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: +91 90362 97807</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>2nd Year Students</h6></div>
                                                <div className="featured-desc">
                                                    <p>Prof. Bharathi D</p>
                                                    <p>E-mail Id: bharathid@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: +91 98442 56934</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>2nd Year Students</h6></div>
                                                <div className="featured-desc">
                                                    <p>Prof. Anjan Kumar B S</p>
                                                    <p>E-mail Id: anjankumarbs@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: +91 94485 54852</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>3rd Year Students</h6></div>
                                                <div className="featured-desc">
                                                    <p>Prof. Bharathi D</p>
                                                    <p>E-mail Id: bharathid@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: +91 98442 56934</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>3rd Year Students</h6></div>
                                                <div className="featured-desc">
                                                    <p>Prof. Lalitha H</p>
                                                    <p>E-mail Id: lalithah@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: +91 98457 49897</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>4th Year Students</h6></div>
                                                <div className="featured-desc">
                                                    <p>Prof. Venkatesh Murthy N K</p>
                                                    <p>E-mail Id: venkateshmurthynk@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: +91 96207 12563</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>4th Year Students</h6></div>
                                                <div className="featured-desc">
                                                    <p>Prof. Vishwanatha Reddy R B</p>
                                                    <p>E-mail Id: viswanathreddy@bit-bangalore.edu.in</p>
                                                    <p>Contact Number: +91 90196 40130</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="eie" />
                            </div>
                        </div>{/* row end */}

                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
