import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import DepartmentAchievers from "../component/DepartmentAchievers";
import DepartmentNumbers from "../component/DepartmentNumbers";
import Footer from "../../../components/common/Footer";
import DepartmentVideo from "../component/DepartmentVideo";

export default class CEAbout extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Civil Engineering" image="banners/department-ce.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <DepartmentAchievers department="civil"/>
                                    <DepartmentNumbers
                                        text1="Number of students on roll"
                                        num1="669"

                                        text2="Number of ranks since inception"
                                        num2="22"

                                        text3="Number of graduated batches"
                                        num3="38"

                                        text4="Average Placement (%) in last 5 years"
                                        num4="40"

                                        text5="Number of publications"
                                        num5="300"

                                        text6="Ph.D Awarded"
                                        num6="17"
                                    />
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/departments/civil/about-1.jpg"} title="single-img-twenty" alt="single-img-twenty" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <p>The department was started in the year 1979 with an intake of 90. Currently the department has an intake of 180 for undergraduate program. The students on roll are 700+ including all the semesters.</p>
                                                <p className="paddingtop-1">Post graduate program in structural engineering started in the year 2003 with an intake of 18. The research center established in the year 2004 recognized by VTU offers M.Sc. Engineering by research and Ph.D. Programs.</p>
                                                <p className="paddingtop-1">The department has association with several acclaimed professional bodies like, ACCE, ICI, ACHMM, IGBC, INSTRUCT.</p>
                                            </div>
                                            <div className='col-md-12 paddingtop-2 justify'>
                                                <p>The department has entered into MOU with more than 15 leading industries, consulting and professional organizations to help the student internship programs and placements.</p>
                                                <p className="paddingtop-1">Many of our students have also excelled in extracurricular activities and awarded many prizes and recognitions at both college and inter colleges events. Most of our students have been placed in Government/ non-government esteemed organizations.</p><br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-title with-desc text-left clearfix">
                                    <DepartmentVideo department="civil" />
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Vision</h4>
                                        </div>
                                        <p>To become a center of excellence in Academics, Research and Consultancy by imparting quality education and developing competent civil engineering professionals who will be sensitive, ethical and serve for the betterment of the society.</p>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Mission</h4>
                                        </div>
                                        <ul className="ttm-list ttm-list-style-icon">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To train civil engineering students to graduate with knowledge and skills required to solve civil engineering problems, to become leaders in organizations and for a wide range of careers.</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To create civil engineering professionals through collaborative programs with professional bodies, research organisations and industry for the betterment of the society. </span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To be recognized by the industry as a centre of excellence for Research and Development to solve civil engineering problems.</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To create a learning environment and provide limitless possibilities to students and staff to excel in their careers.</span></li>
                                        </ul>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="section-title with-desc text-left clearfix">
                                            <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                                <h4 className="title">Program Educational Objectives (PEO's)</h4>
                                            </div>
                                            <ul className="ttm-list ttm-list-style-icon">
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"> will be trained to excel in their professional career by acquiring factual, analytical, procedural, application and creative knowledge in mathematical, computing and civil engineering principles.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Will be trained to analyse and design practically sustainable civil engineering systems which involve sound civil engineering skills, optimum and acceptable solutions to the society.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Will be trained to exhibit professionalism, ethics with good communications skills background. </span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"> Will be trained in continuing education and engage them in lifelong learning to be competitive and enterprising</span></li>
                                            </ul>
                                        </div>
                                        <div className="ttm-service-single-content-area">
                                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                                    <h4 className="title">Program Outcomes</h4>
                                                </div>
                                            </div>
                                            <ul className="ttm-list ttm-list-style-icon">
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Engineering knowledge: Apply the knowledge of mathematics, science, engineering
                                                    fundamentals, and an engineering specialization to the solution of complex engineering
                                                    problems.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Problem analysis: Identify, formulate, review research literature, and analyze complex
                                                    engineering problems reaching substantiated conclusions using first principles of
                                                    mathematics, natural sciences, and engineering sciences.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Design/development of solutions: Design solutions for complex engineering problems
                                                    and design system components or processes that meet the specified needs with appropriate
                                                    consideration for the public health and safety, and the cultural, societal, and environmental
                                                    considerations.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Conduct investigations of complex problems: Use research-based knowledge and
                                                    research methods including design of experiments, analysis and interpretation of data, and
                                                    synthesis of the information to provide valid conclusions.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Modern tool usage: Create, select, and apply appropriate techniques, resources, and
                                                    modern engineering and IT tools including prediction and modelling to complex
                                                    engineering activities with an understanding of the limitations.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">The engineer and society: Apply reasoning informed by the contextual knowledge to
                                                    assess societal, health, safety, legal and cultural issues and the consequent responsibilities
                                                    relevant to the professional engineering practice.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Environment and sustainability: Understand the impact of the professional engineering
                                                    solutions in societal and environmental contexts, and demonstrate the knowledge of, and
                                                    need for sustainable development.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Ethics: Apply ethical principles and commit to professional ethics and responsibilities and
                                                    norms of the engineering practice.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Individual and team work: Function effectively as an individual, and as a member or
                                                    leader in diverse teams, and in multidisciplinary settings.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"> Communication: Communicate effectively on complex engineering activities with the
                                                    engineering community and with society at large, such as, being able to comprehend and
                                                    write effective reports and design documentation, make effective presentations, and give
                                                    and receive clear instructions.
                                                </span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Project management and finance: Demonstrate knowledge and understanding of the
                                                    engineering and management principles and apply these to one’s own work, as a member
                                                    and leader in a team, to manage projects and in multidisciplinary environments.
                                                </span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"> Life-long learning: Recognize the need for, and have the preparation and ability to engage
                                                    in independent and life-long learning in the broadest context of technological change.
                                                </span></li>
                                            </ul>
                                        </div>
                                        <div className="section-title with-desc text-left clearfix">
                                            <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                                <h4 className="title">Program Specific Outcomes</h4>
                                            </div>
                                            <ul className="ttm-list ttm-list-style-icon">
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"> The graduates will have the ability to plan, analyze, design, execute and maintain cost effective civil engineering structures without over exploitation of natural resources.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"> The graduates of civil engineering program will have the ability to take up employment, entrepreneurship, research and development for sustainable civil society.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"> The graduates will be able to peruse opportunities for personal and professional growth, higher studies, demonstrate leadership skills and engage in lifelong learning by active participation in the civil engineering profession.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"> The graduates will be able to demonstrate professional integrity and an appreciation of ethical, environmental, regulatory and issues relative to civil engineering projects.</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="civil" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}

