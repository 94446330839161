import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Banner from '../../components/common/Banner';
import Loading from '../../components/common/Loading';
import NoData from '../../components/common/NoData';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../Config/FbConfig';
import { Styles } from './styles/contact.js';

export default class contact extends Component {

    state = { Datas: [] }

    //Load Data Here
    async componentDidMount() {
        const docRef = doc(firestore, "Other", "Contact");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key }, value))
            });
            this.setState({
                Datas: Data
            })
        } else {
            this.setState({
                Datas: "NO_999"
            })
        }
    }

    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper contact-page">

                    {/* Header */}
                    <Header />

                    {/* Breadcroumb */}
                    <Banner title="Contact Us" image="banners/banner-contact.jpg" />

                    {/* Contact Area */}
                    <section className="contact-area">
                        <Container>
                            <Row>
                                <Col md="4">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Contact Info</h4>
                                        </div>
                                    </div>
                                    <div className="contact-icon-box d-flex">
                                        <div className="icon">
                                            <i className="fas fa-map-marker"></i>
                                        </div>
                                        <div className="box-content">
                                            <h5>Our Location</h5>
                                            <p>Bangalore Institute of Technology,</p>
                                            <p>K R Road, V V Pura, Bangalore, Karnataka, India.</p>
                                            <p>Pincode - 560004.</p>
                                        </div>
                                    </div>
                                    <div className="contact-icon-box d-flex">
                                        <div className="icon">
                                            <i className="fas fa-envelope-open"></i>
                                        </div>
                                        <div className="box-content">
                                            <h5>Email Address</h5>
                                            <p>principalbit4@gmail.com<br />principal@bit-bangalore.edu.in</p>
                                        </div>
                                    </div>
                                    <div className="contact-icon-box d-flex">
                                        <div className="icon">
                                            <i className="fas fa-phone"></i>
                                        </div>
                                        <div className="box-content">
                                            <h5>Phone Number</h5>
                                            <p>+91 080 26615865</p>
                                        </div>
                                    </div>
                                    <div className="contact-social">
                                        <ul className="social list-unstyled list-inline">
                                            <li className="list-inline-item"><a href="https://www.facebook.com/bitsince1979" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                            <li className="list-inline-item"><a href="https://twitter.com/bitsince1979" target="_blank"><i className="fab fa-twitter"></i></a></li>
                                            <li className="list-inline-item"><a href="https://www.instagram.com/bitsince1979/" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                            <li className="list-inline-item"><a href="https://www.youtube.com/bitsince1979" target="_blank"><i className="fab fa-youtube"></i></a></li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col md="8">
                                    <div className="contact-form">
                                        <div className="section-title with-desc text-left clearfix">
                                            <div className="title-header" style={{ borderTopColor: "red" }}>
                                                <h4 className="title">Get In Touch</h4>
                                            </div>
                                        </div>
                                        <div className="form-box">
                                            <form id="form_contact" className="form" method="post" action="contactform.php">
                                                <Row>
                                                    <Col md="6">
                                                        <p className="form-control">
                                                            <input type="text" placeholder="Full Name" name="name" required/>
                                                            <span className="contact_input-msg"></span>
                                                        </p>
                                                    </Col>
                                                    <Col md="6">
                                                        <p className="form-control">
                                                            <input type="email" placeholder="Email Address" name="email" required/>
                                                            <span className="contact_input-msg"></span>
                                                        </p>
                                                    </Col>
                                                    <Col md="12">
                                                        <p className="form-control">
                                                            <input type="text" placeholder="Subject" name="subject" required/>
                                                            <span className="contact_input-msg"></span>
                                                        </p>
                                                    </Col>
                                                    <Col md="12">
                                                        <p className="form-control">
                                                            <textarea placeholder="Enter Message" name="message" required></textarea>
                                                            <span className="contact_input-msg"></span>
                                                        </p>
                                                    </Col>
                                                    <Col md="12">
                                                        <button type="submit">Send Message</button>
                                                    </Col>
                                                </Row>
                                            </form>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                        <br /><br />

                        {/* Google Map */}
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.2605439615168!2d77.57184291526981!3d12.955172818742763!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1592715c4e7f%3A0x7dfaf94e52204678!2sBangalore%20Institute%20of%20Technology!5e0!3m2!1sen!2sin!4v1636476942578!5m2!1sen!2sin" width="100%" height="350" style={{ border: "0" }} allowfullscreen="" loading="lazy"></iframe>

                        {/* Dynamic Contact Details */}
                        <Container className="department-contact paddingtop-2">
                            <Row>
                                <Col md="12">
                                    <h4>Department Contact Information</h4>
                                </Col>
                            </Row>
                            <Row className="paddingtop-2">
                                {
                                    this.state.Datas.length === 0 ? <Loading /> :
                                        this.state.Datas === "NO_999" ? <NoData /> :
                                            this.state.Datas.map((data, i) => (
                                                <Col md="4" key={i}>
                                                    <div className="contact-icon-box d-flex">
                                                        <div className="icon dept">
                                                            <i className="fas fa-phone"></i>
                                                        </div>
                                                        <div className="box-content">
                                                            <div className="department-title">{data.Department}</div>
                                                            <p>{data.Name}</p>
                                                            <p>{data.Designation}</p>
                                                            <p>{data.PhoneNumber}</p>
                                                            <p>{data.EmailId}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))
                                }
                            </Row>
                        </Container>
                    </section>

                    {/* Footer */}
                    <Footer />

                </div>
            </Styles>
        )
    }
}
