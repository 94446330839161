import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
    .highlights-area {
        padding: 63px 0 42px;

        .sec-title {
            h4 {
                color        : ${colors.black1};
                line-height  : 35px;
                font-weight  : 600;
                max-width    : 550px;
                margin       : auto;
                margin-bottom: 48px;

                @media(max-width: 575px) {
                    margin-bottom: 15px;
                    font-size: 20px;
                }
            }
        }

        .highlights-card {
            margin-bottom: 30px;

            .highlights-img {
                img {
                    border-radius : 5px;
                    width: 100%;
                    margin-bottom: 50px;

                    @media(max-width: 991px) {
                        width: 100%;
                        height: 215px;
                        border-radius: 5px 5px 0 0;
                        margin-bottom: 0px;
                    }

                    @media(max-width: 767px) {
                        height: 250px;
                        margin-bottom: 0px;
                    }
                }
            }

            .highlights-content {
                position: relative;

                .content-box {
                    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.08);
                    padding   : 18px;
                    background: #ffffff;
                    border-radius : 5px;
                    position: absolute;
                    top     : 20px;
                    left    : -22%;
                    z-index : 1;

                    .highlight-no {
                        p {
                            font-size  : 12px;
                            color      : #fff;
                            background : ${colors.bg3};
                            padding    : 8px 10px;
                            line-height: 20px;
                            border-radius : 5px;
                            font-weight : 500;
                            margin-right: 10px;
                            margin-bottom: 10px;
                        }
                    }

                    .highlights-desk {
                        p {
                            font-size     : 13.5px;
                            color         : ${colors.text3};
                            padding-bottom: 10px;
                            margin-bottom : 10px;
                            overflow: hidden;
                            max-height: 5.6em;
                            min-height: 5.6em;
                            line-height: 1.5em;
                        }
                    }

                    @media(max-width: 1199px) {
                        top: 9px;
                        left: -70%;
                    }

                    @media(max-width: 991px) {
                        position : unset;
                        border-radius: 0 0 5px 5px;
                    }
                }
            }
        }

        @media(max-width: 767px) {
            padding: 30px 0 10px;
        }
    }
`;