import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loading from '../../components/common/Loading';
import NoData from '../../components/common/NoData';
import LifeAtBitSidebar from './components/LifeAtBitSidebar';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../Config/FbConfig';
import { Modal } from 'react-bootstrap';
import { Styles } from "./style/manthanActivities.js";

export default class lifeatBitMathanFest extends Component {

    state = {
        displayModal: false,
        modalImage: "",
        modalDescription: "",
        modalDate: "",
        modalVideo: "",
        Datas: []
    }

    //Display Modal
    displayModal = (e) => {
        this.setState({
            displayModal: !this.state.displayModal,
        })
    }

    //Display Content
    contentModal = (e) => {
        var result = this.state.Datas.filter((x) => x.Id === e.target.id);
        this.setState({
            displayModal: !this.state.displayModal,
            modalImage: result[0]["Image"],
            modalDescription: result[0]["Description"],
            modalDate: result[0]["Year"],
            modalVideo: result[0]["Video"]
        })
    }

    //Load Data Here
    async componentDidMount() {
        const docRef = doc(firestore, "LifeBit", "MAnthanEvent");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key }, value))
            });
            this.setState({
                Datas: Data
            })
        } else {
            this.setState({
                Datas: "NO_999"
            })
        }
    }

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Life@BIT" image="banners/banner-lifebit.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Manthan Fest</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-7 paddingtop-1">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifebit/manthan.jpg"} alt="Error" />
                                                </div>
                                            </div>
                                            <div className="col-md-5 paddingtop-1" style={{ margin: "auto" }}>
                                                <p className="justify">MANTHAN, the annual techno-cultural fest of, Bangalore Institute Of Technology is a two day celebration where BIT comes alive with music, dance and entertainment where memories are made. MANTHAN has been a grand tradition for BIT and has grown into one of the most anticipated student organised youth festivals in the City. This much awaited inter College festival happens in summer every year usually in the month of APRIL with more than 50 cultural and technical events. This includes Dance: Solo & Duel-Western 	&#38; contemporary, Music: Voice of Manthan- western 	&#38; classical- vocal solo, Quiz – General quiz 	&#38; Cricket Quiz, Minute to win it, Debate, Photography, Air Crash,</p>
                                            </div>
                                        </div>
                                        <div className="row paddingtop-2">
                                            <div className="col-md-12">
                                                <p className="justify"> Pot Pourri 	&#38; creative writing, Mad ads, Battle of Bands, Amazing Race, Dumd Charades, Arm wrestling , Face painting 	&#38; Sketching, Cooking without fire, Rangoli so on and so forth… MANTHAN also witnesses Fashion show, DJ night and a Rock Band concert. Teams from various colleges from Karnataka participated in the glamorous fashion show presenting a wide range dresses, colours & models with a theme. The most awaited crowd pulling event the DJ NIGHT could celebrate the Power, Energy, Joy and Enthusiasm of the Young child beneath every Soul. MANTHAN has witnessed excellent participation bringing a great acclaim and appreciation among management &#38; engineering colleges across the state. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row paddingtop-2">
                                        <div className="col-md-4">
                                            <div className="ttm_single_image-wrapper">
                                                <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifebit/manthan1.jpg"} alt="Error" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="ttm_single_image-wrapper">
                                                <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifebit/manthan2.jpg"} alt="Error" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="ttm_single_image-wrapper">
                                                <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifebit/manthan3.jpg"} alt="Error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Manthan Fest Events</h5>
                                        </div>
                                    </div>
                                    <Styles>
                                        <div className="row ttm-service-description paddingtop-1">
                                            {
                                                this.state.Datas.length === 0 ? <Loading /> :
                                                    this.state.Datas === "NO_999" ? <NoData /> :
                                                        this.state.Datas.map((data, i) => (
                                                            <div className="col-md-4 ttm-box-col-wrapper d-flex align-items-stretch" key={i} style={{ marginBottom: "1rem" }}>
                                                                <div className="featured-imagebox featured-imagebox-blog">
                                                                    <div className="featured-thumbnail">
                                                                        <a onClick={this.contentModal}><img id={data.Id} className="img-fluid" alt="" src={data.Image} /></a>
                                                                    </div>
                                                                    <div className="featured-content">
                                                                        <div className="ttm-box-post-date">
                                                                            <span className="ttm-entry-date">
                                                                                <time className="entry-date">{data.Year}</time>
                                                                            </span>
                                                                        </div>
                                                                        <div className="featured-desc justify">
                                                                            <p>{data.Description}</p>
                                                                        </div>
                                                                        {
                                                                            data.Video != undefined ?
                                                                                <div className="post-meta" style={{ marginBottom: "-1rem" }}>
                                                                                    <a target="_blank" href={data.Video}><span className="ttm-meta-line" style={{ color: "gray" }}><i className="fa fa-link" />View Details</span></a>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                            }
                                        </div>
                                    </Styles>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <LifeAtBitSidebar />
                            </div>
                        </div>
                    </div>
                </div>


                {/* Modal */}
                <Modal size="lg" show={this.state.displayModal} onHide={this.displayModal} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-5">
                                <img className="img-fluid" alt="" src={this.state.modalImage} style={{ borderRight: "solid 5px", borderColor: "#98002e" }} />
                            </div>
                            <div className="col-7" style={{ padding: "1rem" }}>
                                <div className="post-meta justify">
                                    <p>{this.state.modalDescription}</p>
                                </div>
                                <div className="date" style={{ color: "#98002e" }}>{this.state.modalDate}</div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}

