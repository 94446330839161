import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class CSETeaching extends Component {

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Computer Science and Engineering" image="banners/department-cse.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header">
                                        <h4 className="title">Learning Resources</h4>
                                    </div>
                                </div>
                                <div className="ttm-service-description">
                                    <p>Wide arrays of learning materials are provided to students in advance for each course to catalyze their learning ability. These resources include syllabus, course materials, and links to digital aids. Click here to access learning resources.</p>
                                    <Link className="ttm-btn" style={{ fontSize: "1.15rem", paddingTop: "1rem", color: "#00a76a" }} to={process.env.PUBLIC_URL + `/teaching-learning-material/cse`}>Click Here for Learning Resources</Link>
                                </div>
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header">
                                        <h4 className="title">Teaching - Learning</h4>
                                    </div>
                                </div>
                                <div className="ttm-service-description">
                                    <h6 className="paddingtop-1">Initiatives in Teaching:</h6><br />
                                    <ul className="ttm-list ttm-list-style-icon">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />An Academic Calendar is prepared before the start of the semester in par with the Academic Calendar of the College.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />The Academic Calendar consists of the curricular, co-curricular and extra-curricular activities to be carried out during the semester.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />The Faculty prepare the lesson plan, course statistics, lecture notes/study material /PPT/ICT, Course Beginning survey, Content Beyond the syllabus and define/refinement of CO-PO’s.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Faculty adapts traditional chalk and talk and ICT (PPT, Audio Visual presentations, Blended mode, case studies, mini-projects, virtual labs, etc.,) in teaching and learning process to create the best learning environment for the students.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Internal Assessment question papers are set based on the Bloom’s Taxonomy.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />The student feedback is collected in two phases and appropriate actions are taken.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />To bring out the best and quality project work, continuous interaction and timely presentations are practiced.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Faculty will discuss the scheme / solution in the class which enable the students to perform well in the test thereby exams.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Invited technical talks/seminars/workshops are conducted to fill the curriculum gaps.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Faculty enrolls in online courses/value added courses to upgrade their knowledge  in emerging technologies</li>
                                    </ul>
                                </div>
                                <div className="ttm-service-description">
                                    <h6 className="paddingtop-1">Learning Initiatives:</h6><br />
                                    <ul className="ttm-list ttm-list-style-icon">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Collaborative learning (Flip class, think-pair-share Audio-visual).</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Self-learning (Assignment/Case study)</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Project based Learning ( mini /main Project)</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Presentation based learning</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Practice of theoretical  concepts in virtual labs and Regular labs </li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Slow learners are counselled periodically and motivated to improve their performance.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Bright students are encouraged to participate in various Technical events, publications, internship and higher learning.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Value added certification courses to enhance the skill set in emerging technologies and industrial needs.</li>
                                    </ul>
                                </div>
                                <div className="ttm-service-description">
                                    <h6 className="paddingtop-1">Initiatives in Evaluation:</h6><br />
                                    <ul className="ttm-list ttm-list-style-icon">
                                        <li><i className="fa fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Laboratory: Evaluation is based on the program  write up , execution and viva voce  </span></li>
                                        <li><i className="fa fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Theory: Evaluation of written answers, quiz, assignment and case studies/mini projects through presentation and reports.</span></li>
                                        <li><i className="fa fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Project: Weekly assessment of progress on the project, and continuous assessment (Synopsis, literature review, Interim, final presentation) based on the rubrics.</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="cse" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
