import React, { Component } from "react";
import { Link } from "react-router-dom";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class MATHAchievers extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Physics" image="banners/department-phy.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header">
                                        <h4 className="title">Learning Resources</h4>
                                    </div>
                                </div>
                                <div className="ttm-service-description">
                                    <p>Wide arrays of learning materials are provided to students in advance for each course to catalyze their learning ability. These resources include syllabus, course materials, and links to digital aids. Click here to access learning resources.</p>
                                    <Link className="ttm-btn" style={{ fontSize: "1.15rem", paddingTop: "1rem", color: "#00a76a" }} to={process.env.PUBLIC_URL + `/teaching-learning-material/phy`}>Click Here for Learning Resources</Link>
                                </div>
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Teaching - Learning</h4>
                                    </div>
                                    <ul className="ttm-list ttm-list-style-icon">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">The traditional Chalk and Talk method is used for teaching.</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">The advanced methodologies like PPT presentation, YOUTUBE videos made by faculty and shared with the students</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Google drive links for study materials are also shared</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Smart board/digi pad usage for online teaching mode etc.</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Seminar based learning, tutorial class interaction and assignment work</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Usage of online tools like MS Teams, Google Classroom, Webex meetings, Zoom class etc.</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Apart from regular method of lab teaching also conducted online lab classes and shared detailed videos of experiments</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">The students are assessed by 2 methods</span></li>
                                        <ul className="ttm-list ttm-list-style-icon">
                                            <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content"> Continuous Internal Evaluation (CIE) through Internal tests, Seminars, Quiz, Assigments etc. </span></li>
                                            <li><i className="fa fa-arrow-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Semester End Examination (SEE) at the end of each semester conducted by VTU</span></li>
                                        </ul>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="phy" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
