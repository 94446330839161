import React, { Component } from 'react';
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";
import myData from '../component/HOD.json';

export default class DepartmentHODDetails extends Component {

  render() {
    const banner = {
      cse: <Banner title="Computer Science and Engineering" image="banners/department-cse.jpg" />,
      ise: <Banner title="Information Science and Engineering" image="banners/department-ise.jpg" />,
      ai: <Banner title="Artificial Intelligence and Machine Learning" image="banners/department-ai.jpg" />,
      ece: <Banner title="Electronics and Communication Engineering" image="banners/department-ece.jpg" />,
      mech: <Banner title="Mechanical Engineering" image="banners/department-me.jpg" />,
      civil: <Banner title="Civil Engineering" image="banners/department-ce.jpg" />,
      eee: <Banner title="Electrical and Electronics Engineering" image="banners/department-eee.jpg" />,
      ete: <Banner title="Electronics and Telecommunication Engineering" image="banners/department-ete.jpg" />,
      eie: <Banner title="Electronics and Instrumentation Engineering" image="banners/department-eie.jpg" />,
      iem: <Banner title="Industrial Engineering and Management" image="banners/department-iem.jpg" />,
      mba: <Banner title="Master of Business Administration" image="banners/department-mba.jpg" />,
      mca: <Banner title="Master of Computer Applications (MCA)" image="banners/department-mca.jpg" />,
      phy: <Banner title="Physics" image="banners/department-phy.jpg" />,
      chem: <Banner title="Chemistry" image="banners/department-che.jpg" />,
      math: <Banner title="Mathematics" image="banners/department-math.jpg" />,
      hum: <Banner title="Humanities" image="banners/department-hum.jpg" />,
    }
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        {banner[this.props.match.params.id]}

        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-description paddingtop-1 justify">
                  <table class="table">
                    <tbody>
                      <div className="row">
                        <div className="col-lg-3" style={{ margin: "auto" }}>
                          <div className="ttm-featured-wrapper">
                            <div className="featured-thumbnail text-center">
                              <img className="img-fluid" src={process.env.PUBLIC_URL + `/assets/images/departments/${myData[this.props.match.params.id].photo}`} title="single-img-twenty" alt="single-img-twenty" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-9 justify" style={{ margin: "auto" }}>
                          <div className="member-info-box border">
                            <h5>{myData[this.props.match.params.id].name}</h5>
                            <small>{myData[this.props.match.params.id].designation}</small><hr />
                            <ul className="info-list">
                              <li><b>Phone :</b> <span>{myData[this.props.match.params.id].contact}</span></li>
                              <li><b>Email Id :</b> <span>{myData[this.props.match.params.id].mail}</span></li>
                              <li><b>Qualification :</b> <span>{myData[this.props.match.params.id].Qualification}</span></li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-12 justify" style={{ margin: "auto", display : myData[this.props.match.params.id].specialization != null ? "block" : "none"}}>
                          <div style={{ backgroundColor: "#98002e", color: "white", fontSize:"1.5rem", padding:"1rem", fontWeight:"bold" }}>Specialization</div>  
                          <div className="member-info-box border"><p>{myData[this.props.match.params.id].specialization}</p></div>
                        </div>
                        <div className="col-lg-12 justify" style={{ margin: "auto", display : myData[this.props.match.params.id].teachingInterest != null ? "block" : "none"}}>
                          <div style={{ backgroundColor: "#98002e", color: "white", fontSize:"1.5rem", padding:"1rem", fontWeight:"bold" }}>Teaching Interest</div>  
                          <div className="member-info-box border"><p>{myData[this.props.match.params.id].teachingInterest}</p></div>
                        </div>
                        <div className="col-lg-12 justify" style={{ margin: "auto", display : myData[this.props.match.params.id].researchInterest != null ? "block" : "none"}}>
                          <div style={{ backgroundColor: "#98002e", color: "white", fontSize:"1.5rem", padding:"1rem", fontWeight:"bold" }}>Research Interest and Guide ship</div>  
                          <div className="member-info-box border"><p>{myData[this.props.match.params.id].researchInterest}</p></div>
                        </div>
                        <div className="col-lg-12 justify" style={{ margin: "auto" }}>
                          <div style={{ backgroundColor: "#98002e", color: "white", fontSize:"1.5rem", padding:"1rem", fontWeight:"bold" }}>Publications</div>  
                          <div className="member-info-box border">
                            <p style={{ display : myData[this.props.match.params.id].publications != null ? "block" : "none"}}> 
                              {myData[this.props.match.params.id].publications}
                            </p><hr />
                            <div className="row">
                              <div className="col-lg-4" style={{ display : myData[this.props.match.params.id].googleScholar != null ? "block" : "none"}}> 
                                <b>Google Scholar : </b>
                                <a href={myData[this.props.match.params.id].googleScholar}>Click Here</a>
                              </div>
                              <div className="col-lg-4" style={{ display : myData[this.props.match.params.id].researchGate != null ? "block" : "none"}}> 
                                <b>Research Gate : </b>
                                <a href={myData[this.props.match.params.id].researchGate}>Click Here</a>
                              </div>
                              <div className="col-lg-4" style={{ display : myData[this.props.match.params.id].orchidAccounts != null ? "block" : "none"}}> 
                                <b>Orchid Account : </b>
                                <a href={myData[this.props.match.params.id].orchidAccounts}>Click Here</a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12" style={{ margin: "auto", display : myData[this.props.match.params.id].books.length != 0 ? "block" : "none"}}>
                          <div style={{ backgroundColor: "#98002e", color: "white", fontSize:"1.5rem", padding:"1rem", fontWeight:"bold" }}>Books Authored</div>  
                          <div className="member-info-box border">
                            <table className='table'>
                              <tbody>
                                  <tr>
                                      <th>Title</th>
                                      <th>Year</th>
                                      <th>Name of the Publisher</th>
                                      <th>ISBN/ISSN Number</th>
                                  </tr>
                                  {
                                    myData[this.props.match.params.id].books.map((data, i) => (
                                      <tr>
                                          <td>{data.title}</td>
                                          <td>{data.year}</td>
                                          <td>{data.publisher}</td>
                                          <td>{data.isbn}</td>
                                      </tr>
                                    ))
                                  }
                              </tbody>
                          </table>
                          </div>
                        </div>
                        <div className="col-lg-12 justify" style={{ margin: "auto", display : myData[this.props.match.params.id].memberships != null ? "block" : "none"}}>
                          <div style={{ backgroundColor: "#98002e", color: "white", fontSize:"1.5rem", padding:"1rem", fontWeight:"bold" }}>Professional and Academic Memberships</div>  
                          <div className="member-info-box border"><p>{myData[this.props.match.params.id].memberships}</p></div>
                        </div>
                        <div className="col-lg-12 justify" style={{ margin: "auto", display : myData[this.props.match.params.id].ResearchGrant.length != 0 ? "block" : "none"}}>
                          <div style={{ backgroundColor: "#98002e", color: "white", fontSize:"1.5rem", padding:"1rem", fontWeight:"bold" }}>Research grants and student project grants</div>  
                          <div className="member-info-box border">
                            <table className='table'>
                              <tbody>
                                  <tr>
                                      <th>Name of the research  project</th>
                                      <th>Funding Agency</th>
                                      <th>Amount sanctioned</th>
                                      <th>Year of sanction</th>
                                  </tr>
                                  {
                                    myData[this.props.match.params.id].ResearchGrant.map((data, i) => (
                                      <tr>
                                          <td>{data.title}</td>
                                          <td>{data.year}</td>
                                          <td>{data.publisher}</td>
                                          <td>{data.isbn}</td>
                                      </tr>
                                    ))
                                  }
                              </tbody>
                          </table>
                          </div>
                        </div>
                        <div className="col-lg-12 justify" style={{ margin: "auto", display : myData[this.props.match.params.id].workshopSeminorFDP.length != 0 ? "block" : "none"}}>
                          <div style={{ backgroundColor: "#98002e", color: "white", fontSize:"1.5rem", padding:"1rem", fontWeight:"bold" }}>Workshops/ Seminar /FDP organized</div>  
                          <div className="member-info-box border">
                            <table className='table'>
                              <tbody>
                                  <tr>
                                      <th>Name</th>
                                      <th>Date</th>
                                      <th>Number of participants</th>
                                      <th>Link to report</th>
                                  </tr>
                                  {
                                    myData[this.props.match.params.id].workshopSeminorFDP.map((data, i) => (
                                      <tr>
                                          <td>{data.title}</td>
                                          <td>{data.year}</td>
                                          <td>{data.publisher}</td>
                                          <td>{data.isbn}</td>
                                      </tr>
                                    ))
                                  }
                              </tbody>
                          </table>
                          </div>
                        </div>
                        <div className="col-lg-12 justify" style={{ margin: "auto", display : myData[this.props.match.params.id].invitedTalks.length != 0 ? "block" : "none"}}>
                          <div style={{ backgroundColor: "#98002e", color: "white", fontSize:"1.5rem", padding:"1rem", fontWeight:"bold" }}>Invited Talks</div>  
                          <div className="member-info-box border">
                            <table className='table'>
                              <tbody>
                                  <tr>
                                      <th>Title</th>
                                      <th>Date</th>
                                      <th>Invited by</th>
                                      <th>Link to report</th>
                                  </tr>
                                  {
                                    myData[this.props.match.params.id].invitedTalks.map((data, i) => (
                                      <tr>
                                          <th>{data.title}</th>
                                          <td>{data.year}</td>
                                          <td>{data.publisher}</td>
                                          <td>{data.isbn}</td>
                                      </tr>
                                    ))
                                  }
                              </tbody>
                          </table>
                          </div>
                        </div>
                        <div className="col-lg-12 justify" style={{ margin: "auto", display : myData[this.props.match.params.id].recognition.length != 0 ? "block" : "none"}}>
                          <div style={{ backgroundColor: "#98002e", color: "white", fontSize:"1.5rem", padding:"1rem", fontWeight:"bold" }}>State/National/International Recognitions and Awards</div>  
                          <div className="member-info-box border">
                            <table className='table'>
                              <tbody>
                                  <tr>
                                      <th>Awardee</th>
                                      <th>Award</th>
                                      <th>Awarding Agency</th>
                                      <th>Year</th>
                                  </tr>
                                  {
                                    myData[this.props.match.params.id].recognition.map((data, i) => (
                                      <tr>
                                          <th>{data.title}</th>
                                          <td>{data.year}</td>
                                          <td>{data.publisher}</td>
                                          <td>{data.isbn}</td>
                                      </tr>
                                    ))
                                  }
                              </tbody>
                          </table>
                          </div>
                        </div>
                        <div className="col-lg-12 justify" style={{ margin: "auto", display : myData[this.props.match.params.id].extension != null ? "block" : "none"}}>
                          <div style={{ backgroundColor: "#98002e", color: "white", fontSize:"1.5rem", padding:"1rem", fontWeight:"bold" }}>Extension & Outreach Activities</div>  
                          <div className="member-info-box border"><p>{myData[this.props.match.params.id].extension}</p></div>
                        </div>
                        <div className="col-lg-12 justify" style={{ margin: "auto", display : myData[this.props.match.params.id].personalWebsite != null ? "block" : "none"}}>
                          <div style={{ backgroundColor: "#98002e", color: "white", fontSize:"1.5rem", padding:"1rem", fontWeight:"bold" }}>Personal website</div>  
                          <div className="member-info-box border"><div className="title-header"><h5 className="title"></h5></div>
                            <p><a href={myData[this.props.match.params.id].personalWebsite} target="_blank">{myData[this.props.match.params.id].personalWebsite}</a></p>
                          </div>
                        </div>
                      </div>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department={this.props.match.params.id} />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}
