import React, { Component } from "react";
import { Link } from "react-router-dom";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class EEETeaching extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Electrical and Electronics Engineering" image="banners/department-eee.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <h4 className="title">Learning Resources</h4>
                  </div>
                </div>
                <div className="ttm-service-description">
                  <p>Wide arrays of learning materials are provided to students in advance for each course to catalyze their learning ability. These resources include syllabus, course materials, and links to digital aids. Click here to access learning resources.</p>
                  <Link className="ttm-btn" style={{ fontSize:"1.15rem",paddingTop:"1rem", color:"#00a76a" }} to={process.env.PUBLIC_URL + `/teaching-learning-material/eee`}>Click Here for Learning Resources</Link>
                </div>
                <div className="section-title with-desc text-left clearfix paddingtop-2">
                  <div className="title-header">
                    <h4 className="title">Teaching - Learning</h4>
                  </div>
                </div>
                <div className="ttm-service-description justify">
                  <p>The Lead Society for the Electrical and Electronics Engineering is the Institute of Electrical and Electronics Engineers (IEEE).As per the IEEE, the program criteria for Electrical and Electronics Engineering is that the structure of the curriculum must provide an in depth knowledge across the range of engineering topics implied by the title of the program.</p><br />
                  <p>The Visvesvaraya Technological University provides program curriculum to the institutes. The curriculum has a total of 60 courses spread over eight semesters. The curriculum comprises 44 technical courses, 16 laboratory courses (one technical seminar, one Internship seminar and one project). It also includes 2 audit courses namely Technical English and Kannada. This makes the total number of courses to 64.The program curriculum is designed to develop engineers who have a background in mathematics, physics and allied sciences. It contains courses in mathematics, science and engineering fundamentals with emphasis on the development of analytical and creative abilities.</p><br />
                  <p>As per VTU curriculum, teaching–learning methodologies are planned and practiced. Based on the VTU academic calendar, a calendar of events at institution level is prepared.</p><br />
                  <ul className="ttm-list ttm-list-style-icon">
                    <p>Teaching-learning process at Electrical & Electronic engineering department involves following methodologies:</p><br />
                    <h6>Lecture methods and Interactive learning:</h6><br />
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />All the theory courses are engaged by conventional methods. The faculty use traditional methods in teaching and learning. The students are encouraged to interact during the lecture hour for doubts clarification. Faculty also use ICT tools like powerpoint and video presentations in teaching certain courses. Course materials are prepared and made available on the Institute website.</li>
                  </ul>
                </div>
                <div className="ttm-service-description">
                  <h6 className="paddingtop-1">Experiential learning:</h6>
                  <br />
                  <ul className="ttm-list ttm-list-style-icon">
                    <li>
                      <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content" />
                      Students are engaged in two laboratories per semester from first to seventh semesters. Apart from the university specified curriculum, students are exposed to additional experiments beyond syllabus for the better and clear understanding of the course. The Laboratory manuals for all practical courses are prepared and made available for students. Students are demonstrated with the latest software tools to enhance the learning techniques.
                    </li>
                  </ul>
                </div>
                <div className="ttm-service-description">
                  <h6 className="paddingtop-1"> Participative Learning:</h6>
                  <br />
                  <ul className="ttm-list ttm-list-style-icon">
                    <li>
                      <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content" />
                      Students are encouraged for active participative learning like attending conferences, competitions & project exhibitions.
                    </li>
                  </ul>
                </div>
                <div className="ttm-service-description">
                  <h6 className="paddingtop-1">Seminar-based learning:</h6>
                  <br />
                  <ul className="ttm-list ttm-list-style-icon">
                    <li>
                      <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content" />
                      During the final year students have to present a seminar. Students are advised to carry out literature survey on a latest topic in any discipline, present the seminar in front of evaluation panel and finally submit a report
                    </li>
                  </ul>
                </div>
                <div className="ttm-service-description">
                  <h6 className="paddingtop-1">Project-based learning:</h6>
                  <br />
                  <ul className="ttm-list ttm-list-style-icon">
                    <li>
                      <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content" />A Flipped
                      During the final year, many real time projects are given to the students and are guided by both faculty and Industry/Research personnel. Regular seminars are presented by students regarding their project progress and completion.
                    </li>
                  </ul>
                </div>
                <div className="ttm-service-description">
                  <h6 className="paddingtop-1">Industrial/ power plant Visits:</h6>
                  <br />
                  <ul className="ttm-list ttm-list-style-icon">
                    <li>
                      <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content" />
                      During the second/third/fourth year, the departments conduct visits to industries, power plants and substations for practical exposure in their respective field.
                    </li>
                  </ul>
                </div>
                <div className="ttm-service-description">
                  <h6 className="paddingtop-1">Tutorial classes:</h6>
                  <br />
                  <ul className="ttm-list ttm-list-style-icon">
                    <li>
                      <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content" />
                      Tutorial classes are conducted for slow learners for better understanding of numerical problems.
                    </li>
                  </ul>
                </div>
                <div className="ttm-service-description">
                  <h6 className="paddingtop-1">Assignments:</h6>
                  <br />
                  <ul className="ttm-list ttm-list-style-icon">
                    <li>
                      <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content" />
                      Assignment questions are to be given to each student for a course and the teachers evaluate the assignments and are considered for awarding IA marks along with three internal tests. Students are informed to submit the assignments in a timely manner and marks are allocated on submission.                    </li>
                  </ul>
                </div>
                <div className="ttm-service-description">
                  <h6 className="paddingtop-1">Seminars/technical talk/ workshops/ webinars:</h6>
                  <br />
                  <ul className="ttm-list ttm-list-style-icon">
                    <li>
                      <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content" />
                      Regular seminars/ technical talk/ workshops/ webinars are organized through clubs, alumni and departments. Resource persons from industry, academia and research institutions are invited to deliver a series of lectures.                    </li>
                  </ul>
                </div>
                <div className="ttm-service-description">
                  <h6 className="paddingtop-1">Online lectures:</h6>
                  <br />
                  <ul className="ttm-list ttm-list-style-icon">
                    <li>
                      <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content" />
                      In the event of unavoidable circumstances or as in case of lock down, online classes are engaged using various free/ paid software tools like Google meet, Microsoft team, WebEx, zoom, Skype etc.,
                    </li>
                  </ul>
                </div>
                <div className="ttm-service-description">
                  <h6 className="paddingtop-1">Lecture videos:</h6>
                  <br />
                  <ul className="ttm-list ttm-list-style-icon">
                    <li>
                      <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content" />
                      The videos of the lectures are recorded and uploaded to student resources on the college website. The students are informed to view the same at their convenient time for better understanding.                    </li>
                  </ul>
                </div>
                <div className="ttm-service-description">
                  <h6 className="paddingtop-1">Videos of Laboratory experiments: </h6>
                  <br />
                  <ul className="ttm-list ttm-list-style-icon">
                    <li>
                      <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content" />
                      Videos of conduction of laboratory experiments along with necessary details are prepared and uploaded in the resource portal so that students can refer to it for better understanding.                    </li>
                  </ul>
                </div>

              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="eee" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
