import React, { Component } from "react";
import { Container } from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import NoData from "../../components/common/NoData";
import Loading from "../../components/common/Loading";
import { Styles } from '../departments/styles/Movements';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../Config/FbConfig';

export default class PlacementMovements extends Component {

    state = { Datas: [], Loading: true }

    //Load Data Here
    async componentDidMount() {
        const docRef = doc(firestore, "Placement", "Moments");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key }, value))
            });
            Data.length === 0 ? this.setState({ Datas: "NO_999", Loading: false }) : this.setState({ Datas: Data, Loading: false })
        }
        else { this.setState({ Datas: "NO_999" }) }
    }

    render() {
        const settings = {
            slidesPerView: 3,
            loop: true,
            speed: 1000,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            spaceBetween: 30,
            watchSlidesVisibility: true,
            pagination: {
                el: '.slider-dot.text-center',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                576: {
                    slidesPerView: 2
                },
                992: {
                    slidesPerView: 3
                }
            }
        };

        return (
            <div>
                <div className="section-title with-desc text-left clearfix">
                    <div className="title-header" style={{ borderTopColor: "red" }}>
                        <h4 className="title">Moments of the Department</h4>
                    </div>
                </div>
                <Styles>
                    <section className="event-area">
                        <Container>
                            {this.state.Loading === true ? "Loading.." :
                                <Swiper {...settings}>
                                    {
                                        this.state.Datas.length === 0 ? <Loading /> :
                                            this.state.Datas === "NO_999" ? <NoData /> :
                                                this.state.Datas.map((data, i) => (
                                                    <div className="team-item" key={i}>
                                                        <img src={data.Image} className="img-fluid" />
                                                        <div className="sec-title">
                                                            <a>{data.Header}</a>
                                                            <p className='justify'>{data.Description}</p>
                                                        </div>
                                                        <br />
                                                    </div>
                                                ))
                                    }
                                </Swiper>
                            }
                        </Container>
                    </section>
                </Styles>
            </div>
        )
    }
}
