import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import DepartmentAchievers from "../component/DepartmentAchievers";
import DepartmentNumbers from "../component/DepartmentNumbers";
import DepartmentVideo from "../component/DepartmentVideo";
import Footer from "../../../components/common/Footer";

export default class MATHAbout extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Mathematics" image="banners/department-math.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-single-content-area">
                  <DepartmentAchievers department="math" />
                  <DepartmentNumbers
                    text1="Number of students on roll"
                    num1="1140"
                    text2="Number of faculty"
                    num2="21"
                    text3="Number of faculty with Ph. D"
                    num3="9"
                    text4="Faculty with ranks"
                    num4="3"
                    text5="Number of publications"
                    num5="91"
                    text6="Ph.D Awarded"
                    num6="3"
                  />
                  <div className="ttm-service-description">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="ttm_single_image-wrapper">
                          <img
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/departments/math/about-1.jpg"
                            }
                            alt="single-img-eighteen"
                          />
                        </div>
                      </div>
                      <div
                        className="col-md-7 justify"
                        style={{ margin: "auto" }}
                      >
                        <p>
                          The Department of Mathematics, BIT was established in
                          1979. Prof. M. ChowdeGowda and Prof. D. Prakash were
                          the first to join the Department in 1980. Late Prof. K
                          Kenchaiah headed the Department between 1981 and 2001.
                          In 2001, the number of faculty members had risen to
                          seven, two of whom, Dr. G Rohini and Dr. C D Suruma
                          Devi had doctoral degree in Fluid Mechanics. Prof. M.
                          Chowde Gowda was the Head of the Department during
                          2001 – 2014. During his tenure in 2005, Research
                          Centre affiliated to VTU at Department of Mathematics
                          was established. Dr. C D Surma Devi was the Head of
                          the Department during 2014 – 2016.Between 2016 – 2021
                          Dr K M Roopa was the Head of the Department and
                          currently the Dean for Skill Development. Dr. K.
                          Manjula was the Head of the Department during 2021 –
                          2024.At present the Department is headed by Professor
                          Prashanthakumar.
                        </p>
                      </div>
                      <div className="col-md-12 paddingtop-2 justify">
                        {/* <p>
                          Department and currently the Dean for Skill
                          Development. At present the Department is headed by Dr
                          K. Manjula.
                        </p>
                        <br /> */}
                        <p>
                          Ms Uma A L, Assistant Professor is working on the
                          project “Mathematical modelling for Climate change in
                          the selected regions of Karnataka – A Geostatistical
                          approach” Sponsored by VGST, GoK under RGS/F Scheme.
                          The Department is actively involved in organizing
                          workshops, conferences and webinars collaborating with
                          other interdisciplinary branches.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <DepartmentVideo department="math" />
                <div className="section-title with-desc text-left clearfix paddingtop-2">
                  <div className="section-title with-desc text-left clearfix">
                    <div
                      className="title-header"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">Vision</h4>
                    </div>
                    <p>
                      To provide with sufficient understanding and educational
                      experience in all areas of Mathematics.
                    </p>
                  </div>
                  <div className="section-title with-desc text-left clearfix">
                    <div
                      className="title-header paddingtop-2"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">Mission</h4>
                    </div>
                  </div>
                  <ul className="ttm-list ttm-list-style-icon">
                    <li>
                      <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        Providing quality education through innovative teaching.
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        Applying mathematics as a tool to model and solve
                        Engineering problems.
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        Creating a pool of talent to undertake research and
                        development.
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="section-title with-desc text-left clearfix">
                  <div className="section-title with-desc text-left clearfix paddingtop-2">
                    <div
                      className="title-header"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">Program Outcomes</h4>
                    </div>
                  </div>
                  <ul className="ttm-list ttm-list-style-icon">
                    <li>
                      <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        Engineering knowledge: Apply the knowledge of
                        mathematics, science, engineering fundamentals, and an
                        engineering specialization to the solution of complex
                        engineering problems.
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        Problem analysis: Identify, formulate, review research
                        literature, and analyze complex engineering problems
                        reaching substantiated conclusions using first
                        principles of mathematics, natural sciences, and
                        engineering sciences.
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        Design/development of solutions: Design solutions for
                        complex engineering problems and design system
                        components or processes that meet the specified needs
                        with appropriate consideration for the public health and
                        safety, and the cultural, societal, and environmental
                        considerations.
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                      <span className="ttm-list-li-content">
                        Life-long learning: Recognize the need for, and have the
                        preparation and ability to engage in independent and
                        life-long learning in the broadest context of
                        technological change.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="math" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
