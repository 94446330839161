import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loading from '../../components/common/Loading';
import NoData from '../../components/common/NoData';
import { Modal } from 'react-bootstrap';
import AchiversSidebar from './components/AchieversSidebar';
import { Styles } from "./components/AchieversStyle.js";
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../Config/FbConfig';

export default class AchieversIandE extends Component {

    state = {
        displayModal: false,
        modalImage: "",
        modalName: "",
        modalCourse: "",
        modaldepartment: "",
        modalAchievement: "",
        Datas: []
    }

    //Display Modal
    displayModal = (e) => {
        this.setState({
            displayModal: !this.state.displayModal,
        })
    }

    //Display Content
    contentModal = (e) => {
        var result = this.state.Datas.filter((x) => x.Id === e.target.id);
        this.setState({
            displayModal: !this.state.displayModal,
            modalImage: result[0]["Image"],
            modalName: result[0]["Name"],
            modalCourse: result[0]["Course"],
            modaldepartment: result[0]["Department"],
            modalAchievement: result[0]["Achievement"]
        })
    }

    //Load Data Here
    async componentDidMount() {
        const docRef = doc(firestore, "Achievers", "I&E");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key }, value))
            });
            this.setState({
                Datas: Data
            })
        } else {
            this.setState({
                Datas: "NO_999"
            })
        }
    }

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Achievements" image="banners/banner-achievers.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h3 className="title">Innovations and Entrepreneurship</h3>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <p>Students of BIT are creative, talented and always eager to tackle real-world, multidisciplinary engineering problems. They take part regularly in national and international innovations and entrepreneurship competitions and won many prizes. Many of the student projects are sponsored by Karnataka State Council for Science and Technology (KSCST) under Student Project Programme and VTU.</p>
                                        <br /><a style={{ color: "#572700", fontWeight: "bold" }} target="_blank" href="https://drive.google.com/file/d/1loW4WxI9enIk3hAVEyOcLfMABwPF1F9_/view?usp=share_link">For More Info Click Here</a><br /><br />
                                        <Styles>
                                            <div className="row">
                                                {
                                                    this.state.Datas.length === 0 ? <Loading /> :
                                                        this.state.Datas === "NO_999" ? <NoData /> :
                                                            this.state.Datas.map((data, i) => (
                                                                <div className="col-md-4 ttm-box-col-wrapper d-flex align-items-stretch" key={i} style={{ marginBottom: "1rem" }}>
                                                                    <div className="featured-imagebox featured-imagebox-blog">
                                                                        <div className="featured-thumbnail">
                                                                            <a onClick={this.contentModal}><img id={data.Id} className="img-fluid" alt="Loading Error" src={data.Image} /></a>
                                                                        </div>
                                                                        <div className="featured-content" style={{ marginTop: "-1.5rem" }}>
                                                                            <div className="featured-title"><h6 style={{ color: "#98002e" }}>{data.Name}</h6></div>
                                                                            <div className="post-meta">
                                                                                <span className="ttm-meta-line"><i className="fa fa-graduation-cap" />{data.Course}</span>
                                                                                <span className="ttm-meta-line"><i className="fa fa-user" />{data.Department}</span>
                                                                            </div>
                                                                            <div className="featured-desc threelineend">
                                                                                <p>{data.Achievement}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                }
                                            </div>
                                        </Styles>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <AchiversSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal */}
                <Modal size="lg" show={this.state.displayModal} onHide={this.displayModal} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="featured-imagebox-blog" >
                            <div className="row">
                                <div className="col-4 featured-thumbnail">
                                    <Styles><img className="img-fluid" alt="" src={this.state.modalImage} /></Styles>
                                </div>
                                <div className="col-8" >
                                    <div className="featured-title"><h5 style={{ color: "#98002e" }}>{this.state.modalName}</h5></div>
                                    <div className="post-meta">
                                        <span className="ttm-meta-line"><i className="fa fa-graduation-cap" />{this.state.modalCourse}</span>
                                        <span className="ttm-meta-line"><i className="fa fa-user" />{this.state.modaldepartment}</span>
                                    </div>
                                    <div className="featured-desc justify"><p>{this.state.modalAchievement}</p></div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}                                      