import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import DepartmentIndustryCards from "../component/DepartmentIndustryCards";
import Footer from "../../../components/common/Footer";

export default class AIIndustry extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner
          title="Artificial Intelligence &#38; Machine Learning"
          image="banners/department-ai.jpg"
        />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-single-content-area">
                  <div className="section-title with-desc text-left clearfix">
                    <div
                      className="title-header"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">Industry Interactions</h4>
                    </div>
                  </div>
                  <div className="ttm-service-description">
                    <div className="row">
                      <div className="col-md-12" style={{ margin: "auto" }}>
                        <p className="justify">
                          To explore and identify common avenues of interaction
                          with industry as per the requirement of the
                          institution. The purpose of the MoU is to find out the
                          gap between the needs of the industry and the end
                          product of the institute. The MoU is the bridge
                          between the industry, the real world and the
                          institute. Industrial exposure of the Faculty is very
                          much helpful to guide students about the latest
                          industrial practices. Industries are able to know
                          recent developments and inventions in their fields and
                          implement projects for a technologically driven
                          economy. The objective of bridging the gap between
                          industry and the academic institutions is achieved by
                          preparing engineering students for jobs in
                          multinational companies, by exposing them to newer
                          technologies and engineering methodologies. The
                          Department of Artificial Intelligence and Machine
                          Learning has undergone MOU with various IT companies
                          like, Corpshaala Technologies Solutions Private
                          Limited, iPEC Solutions Private Limited. As a part of
                          MOU we are also offering industry internships for the
                          students to provide an industry exposure.
                        </p>
                      </div>
                    </div>
                    <DepartmentIndustryCards department="ai" />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="ai" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
