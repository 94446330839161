import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import PlacementsSidebar from './components/PlacementsSidebar';

export default class PlacementFacilities extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Placement" image="banners/banner-placement.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Facilities</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + `/assets/images/placement/facilities-1.jpg`} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <ul className="ttm-list ttm-list-style-icon">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Placement Office: 550 Sq.ft.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Interview Rooms : 4 Nos</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Pantry Room: Microwave oven, Cutlery, water purifier and etc.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Group Discussion Rooms : 2 Nos</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Computer Labs to Conduct Online Tests : 8 Nos</span></li>
                                                </ul>
                                            </div>
                                            <div className="col-md-12 paddingtop-2 justify">
                                                <ul className="ttm-list ttm-list-style-icon">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Seminar Hall : 250 Seats capacity with Audio video facilities  along with  WI-FI network for internet connectivity.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Training Hall :  50 Seats capacity with Audio video facilities  along with  WI-FI network for internet connectivity</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Special Facilities : Audio and Video Conferencing</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row paddingtop-2">
                                    <div className="col-md-4">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/placement/facilities-2.jpg"} alt="Error" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/placement/facilities-3.jpg"} alt="Error" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/placement/facilities-4.jpg"} alt="Error" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <PlacementsSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}