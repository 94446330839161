import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class ECEConsultancy extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Electronics and Communication Engineering" image="banners/department-ece.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Consultancy services</h4>
                                    </div>
                                    <div className="title-desc">
                                        <p>The consulting industry can trace its roots back to the late 19th century, when the world's first modern consulting firms were founded. From the turn of the century, management consulting – which focused mainly on engineering and finance early on – increasingly gained terrain in the business world.</p>
                                        <p>Department faculty of this institute is competent enough to provide a wide variety of consultancy services in Electronics and Communication Engineering domain.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="ece" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
