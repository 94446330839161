import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class ELearningSidebar extends Component {
    render() {
        return (
            <div>
                <div className="widget widget-nav-menu">
                    <ul className="widget-menu">
                        <li><NavLink className="nav-link" to={process.env.PUBLIC_URL + '/e-learning-online-courses'}>Online Courses</NavLink></li>
                        <li><NavLink className="nav-link" to={process.env.PUBLIC_URL + '/e-learning-e-content'}>E-Contents</NavLink></li>
                        <li><NavLink className="nav-link" to={process.env.PUBLIC_URL + '/e-learning-practical-resourse'}>Practical Resources</NavLink></li>
                        <li><NavLink className="nav-link" to={process.env.PUBLIC_URL + '/e-learning-research-resourse'}>Research Resources</NavLink></li>
                    </ul>
                </div>
            </div>
        )
    }
}