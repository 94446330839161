import React, { Component } from "react";
import { Link } from "react-router-dom";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class AITeaching extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Artificial Intelligence &#38; Machine Learning" image="banners/department-ai.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <h4 className="title">Learning Resources</h4>
                  </div>
                </div>
                <div className="ttm-service-description">
                  <p>Wide arrays of learning materials are provided to students in advance for each course to catalyze their learning ability. These resources include syllabus, course materials, and links to digital aids. Click here to access learning resources.</p>
                  <Link className="ttm-btn" style={{ fontSize: "1.15rem", paddingTop: "1rem", color: "#00a76a" }} to={process.env.PUBLIC_URL + `/teaching-learning-material/ai`}>Click Here for Learning Resources</Link>
                </div>
                <div className="section-title with-desc text-left clearfix paddingtop-2">
                  <div className="title-header">
                    <h4 className="title">Teaching - Learning</h4>
                  </div>
                </div>
                <div className="ttm-service-description justify">
                  <ul className="ttm-list ttm-list-style-icon">
                    <h6>Brainstorming</h6><br />
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Process for generating creative ideas and solutions through intensive and freewheeling group discussion.</li>
                  </ul>
                </div>
                <div className="ttm-service-description">
                  <h6 className="paddingtop-1">Video Lecture</h6><br />
                  <ul className="ttm-list ttm-list-style-icon">
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Video lecture is a modern method of learning which comes with numerous benefits of its own. This method is individual focused, easy for teachers, and keeps the students interested in the whole process.</li>
                  </ul>
                </div>
                <div className="ttm-service-description">
                  <h6 className="paddingtop-1"> Group discussion</h6><br />
                  <ul className="ttm-list ttm-list-style-icon">
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Group discussion provides opportunities for student to speak in front of others and receive feedback from them. It increases active participation of everyone and provides platform to express their ideas.</li>
                  </ul>
                </div>
                <div className="ttm-service-description">
                  <h6 className="paddingtop-1"> Peer to Peer Learning</h6><br />
                  <ul className="ttm-list ttm-list-style-icon">
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />It is considered as ‘student-to-student’ support system. In this system, the students will be formed as a group and each one of the group will assist others to learn.</li>
                  </ul>
                </div>
                <div className="ttm-service-description">
                  <h6 className="paddingtop-1">Flipped Classroom</h6><br />
                  <ul className="ttm-list ttm-list-style-icon">
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />A Flipped classroom is an instructional strategy and a type of blended learning that reverses the traditional learning environment by delivering instructional content, often online, outside of the classroom.</li>
                  </ul>
                </div>
                <div className="ttm-service-description">
                  <h6 className="paddingtop-1">Virtual Programming Lab</h6><br />
                  <ul className="ttm-list ttm-list-style-icon">
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Virtual Programming Lab is an activity module that manages programming assignments. That lets edit and execute programs and enable the automatic and continuous assessment.</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="ai" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
