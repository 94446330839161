import React, { Component } from "react";
import Banner from "../../components/common/Banner";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import PlacementsSidebar from "./components/PlacementsSidebar";
import Loading from "../../components/common/Loading";
import { Styles } from "./style/placementTeam.js";

export default class PlacementTeam extends Component {
  state = {
    Datas: [],
  };

  componentDidMount() {
    this.setState({
      Datas: [
        {
          id: "1",
          name: "Dr. Aswath M.U",
          designation: "Principal, Advisor-Training & Placement",
          image: "rt-1.jpg",
          email: "aswathmu@gmail.com",
        },
        {
          id: "2",
          name: "Mr. Girish S.R",
          designation: "Training & Placement Officer",
          image: "rt-2.jpg",
          phone: "+91 99002 62772, 080-22422741",
          email: "bitplacement@yahoo.com, placement@bit-bangalore.edu.in",
        },
        {
          id: "2",
          name: "Deekshitha S",
          designation: "Assistant Placement Officer",
          image: "rt-2_1.jpg",
          phone: "+91 80508 96634",
          email: "deekshithas@bit-bangalore.edu.in",
        },
        {
          id: "2",
          name: "Pallavi R",
          designation: "Assistant Placement Officer",
          image: "rt-2_2.jpg",
          phone: "+91 77958 46471",
          email: "pallavir@bit-bangalore.edu.in",
        },
        {
          id: "3",
          name: "Dr. B. V. Lingesh",
          designation:
            "Assistant Professor - Mechanical Engineering, Placement Coordinator",
          image: "rt-3.jpg",
          phone: "+91 88670 83215",
          email: "gangadharasbit@gmail.com",
        },
        {
          id: "4",
          name: "Dr. Nalina R",
          designation: "Assistant Professor- MBA, Placement Coordinator",
          image: "rt-4.jpg",
          phone: "+91 98808 79754",
          email: "nalinar@bit-bangalore.edu.in",
        },
        {
          id: "5",
          name: "Anjan Kumar B S",
          designation:
            "Assistant Professor - Electronics and Instrumentation Engineering, Placement Coordinator",
          image: "rt-5.jpg",
          phone: "+91 94485 54852",
          email: "anjan.mips@gmail.com",
        },
        {
          id: "6",
          name: "Dr. Vani V",
          designation:
            "Assistant Professor - Information Science and Engineering, Placement Coordinator",
          image: "rt-6.jpg",
          phone: " 9945824760",
          email: "vanisrin@gmail.com",
        },
        {
          id: "7",
          name: "Prof. Deepika M S",
          designation:
            "Assistant Professor – Industrial Engineering & Management, Placement Coordinator",
          image: "rt-7.jpg",
          phone: "+91 95382 42447",
          email: "dpksreeram@gmail.com",
        },
        {
          id: "8",
          name: "Lakshmi R",
          designation:
            "Assistant Professor – Electrical & Electronics Engineering, Placement Coordinator",
          image: "rt-8.jpg",
          phone: "+91 98867 46450",
          email: "lakshmi174.r@gmail.com",
        },
        {
          id: "9",
          name: "Prof. Krishna R ",
          designation:
            "Associate Professor- Electronics and Communication Engineering, Placement Coordinator",
          image: "rt-9.jpeg",
          phone: "+91 99013 98846",
          email: "krishnar@bit-bangalore.edu.in",
        },
        {
          id: "10",
          name: "S Poorna Chandra Thejaswi",
          designation:
            "Assistant Professor – Department of Civil Engineering, Placement Coordinator",
          image: "rt-10.jpg",
          phone: "+91 94491 65763",
          email: "poornachandrathejaswis@bit-bangalore.edu.in",
        },
        {
          id: "11",
          name: "Prof Sudha B",
          designation:
            "Assistant Professor -  Electronics & Telecommunication Engineering, Placement Coordinator",
          image: "rt-11.jpg",
          phone: "9980103459",
          email: "sudhab@bit-bangalore.edu.in",
        },
        {
          id: "12",
          name: "Prof. Prathima. M. G",
          designation:
            "Assistant Professor -  Computer  Science & Engineering, Placement Coordinator",
          image: "rt-12.jpg",
          phone: "9611999441",
          email: "prathima005@gmail.com",
        },
        {
          id: "13",
          name: "Usha M",
          designation: "Assistant Professor – MCA, Placement Coordinator",
          image: "rt-13.jpg",
          phone: "+91 99456 55545",
          email: "usha@bit-bangalore.edu.in",
        },
        {
          id: "13_0",
          name: "Dr. Kempanna M",
          designation: "Associate Professor - AI&ML",
          image: "rt-13_0.jpg",
          phone: "9972001401",
          email: "kempannam@bit-bangalore.edu.in",
        },

        {
          id: "15",
          name: "Spoorthi A",
          designation: "Assistant Professor – English, Placement coordinator",
          image: "rt-17.jpg",
          phone: "+91 97431 98279",
          email: "spoorthia@bit-bangalore.edu.in",
        },
        {
          id: "16",
          name: "Nisarga M",
          designation: "Assistant Professor – English, Placement Coordinator",
          image: "rt-18.jpg",
          phone: "+91 97424 72074",
          email: "nisargam@bit-bangalore.edu.in",
        },
        {
          id: "17",
          name: "Shylaja V",
          designation:
            "Assistant Professor – Electronics and Communication Engineering, Placement Coordinator",
          image: "rt-19.jpg",
          phone: "+91 99016 07567",
          email: "shylajav@bit-bangalore.edu.in",
        },
        {
          id: "18",
          name: "Manjushree N S",
          designation:
            "Assistant Professor – Computer Science and Engineering, Placement Coordinator",
          image: "rt-20.jpg",
          phone: "+91 88843 14850",
          email: "manjushreens@bit-bangalore.edu.in",
        },
        {
          id: "19",
          name: "Dr. Ashok Kumar S",
          designation:
            "Assistant Professor – Electrical and Electronics Engineering, Placement Coordinator",
          image: "rt-23.jpg",
          phone: "+91 99860 91750",
          email: "ashok0584@gmail.com",
        },
        {
          id: "14",
          name: "H.M Dharmapale Gowda",
          designation: "Mechanic",
          image: "rt-14.jpg",
          phone: "9743375045",
          email: "bitdharma@gmail.com",
        },
      ],
    });
  }

  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Placement" image="banners/banner-placement.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-single-content-area">
                  <div className="section-title with-desc text-left clearfix">
                    <div
                      className="title-header"
                      style={{ borderTopColor: "red" }}
                    >
                      <h3 className="title">Placement Team</h3>
                    </div>
                  </div>
                  <Styles>
                    <div className="row ttm-service-msg">
                      {this.state.Datas.length == 0 ? (
                        <Loading />
                      ) : (
                        this.state.Datas.map((data, i) => (
                          <div
                            className="col-6 card"
                            style={{ marginBottom: "2rem" }}
                          >
                            <div className="row">
                              <div className="col-6">
                                <img
                                  id={data.id}
                                  className="img-fluid"
                                  alt=""
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/placement/" +
                                    data.image
                                  }
                                  style={{
                                    borderRight: "solid 5px",
                                    borderColor: "#98002e",
                                  }}
                                />
                              </div>
                              <div
                                className="col-6"
                                style={{ padding: "1rem" }}
                              >
                                <div className="featured-title">
                                  <h6>{data.name}</h6>
                                  <small>{data.designation}</small>
                                </div>
                                <div className="post-meta">
                                  <p>{data.phone}</p>
                                  <p>{data.email}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </Styles>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <PlacementsSidebar />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
