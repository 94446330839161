import React, { Component } from "react";
import { Link } from "react-router-dom";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";
import myData from "../component/HOD.json";

export default class DepartmentHODMsg extends Component {
  render() {
    const banner = {
      cse: (
        <Banner
          title="Computer Science and Engineering"
          image="banners/department-cse.jpg"
        />
      ),
      ise: (
        <Banner
          title="Information Science and Engineering"
          image="banners/department-ise.jpg"
        />
      ),
      ai: (
        <Banner
          title="Artificial Intelligence and Machine Learning"
          image="banners/department-ai.jpg"
        />
      ),
      ece: (
        <Banner
          title="Electronics and Communication Engineering"
          image="banners/department-ece.jpg"
        />
      ),
      mech: (
        <Banner
          title="Mechanical Engineering"
          image="banners/department-me.jpg"
        />
      ),
      civil: (
        <Banner title="Civil Engineering" image="banners/department-ce.jpg" />
      ),
      eee: (
        <Banner
          title="Electrical and Electronics Engineering"
          image="banners/department-eee.jpg"
        />
      ),
      ete: (
        <Banner
          title="Electronics and Telecommunication Engineering"
          image="banners/department-ete.jpg"
        />
      ),
      eie: (
        <Banner
          title="Electronics and Instrumentation Engineering"
          image="banners/department-eie.jpg"
        />
      ),
      iem: (
        <Banner
          title="Industrial Engineering and Management"
          image="banners/department-iem.jpg"
        />
      ),
      mba: (
        <Banner
          title="Master of Business Administration"
          image="banners/department-mba.jpg"
        />
      ),
      mca: (
        <Banner
          title="Master of Computer Applications (MCA)"
          image="banners/department-mca.jpg"
        />
      ),
      phy: <Banner title="Physics" image="banners/department-phy.jpg" />,
      chem: <Banner title="Chemistry" image="banners/department-che.jpg" />,
      math: <Banner title="Mathematics" image="banners/department-math.jpg" />,
    };

    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        {banner[this.props.match.params.id]}

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div
                    className="title-header paddingtop-2"
                    style={{ borderTopColor: "red" }}
                  >
                    <h4 className="title">Head of the Department</h4>
                  </div>
                  <div className="row">
                    <div className="col-lg-4" style={{ margin: "auto" }}>
                      <div className="ttm_single_image-wrapper mb-35">
                        <img
                          className="img-fluid"
                          src={
                            process.env.PUBLIC_URL +
                            `/assets/images/departments/${
                              myData[this.props.match.params.id].photo
                            }`
                          }
                          title="single-img-twenty"
                          alt="single-img-twenty"
                        />
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="ttm-pf-single-detail-box mb-35">
                        <ul className="ttm-pf-detailbox-list">
                          <li>
                            <i className="fa fa-user" />
                            <span>Name : </span>
                            {myData[this.props.match.params.id].name}
                          </li>
                          <li>
                            <i className="fas fa-id-card" />
                            <span>Designation :</span>
                            {myData[this.props.match.params.id].designation}
                          </li>
                          <li>
                            <i className="fas fa-graduation-cap" />
                            <span>Qualification :</span>
                            {myData[this.props.match.params.id].Qualification}
                          </li>
                          <li>
                            <i className="fas fa-envelope" />
                            <span>Mail-ID : </span>
                            {myData[this.props.match.params.id].mail}
                          </li>
                          <li>
                            <i className="fas fa-phone" />
                            <span>Contact Number : </span>
                            {myData[this.props.match.params.id].contact}
                          </li>
                          <li>
                            <i className="fas fa-link" />
                            <span>Read More : </span>
                            <Link
                              to={
                                process.env.PUBLIC_URL +
                                `/department-teaching-faculties/${
                                  myData[this.props.match.params.id].knowmore
                                }`
                              }
                            >
                              Click Here
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-12 paddingtop-1 justify">
                      {myData[this.props.match.params.id].msg1}
                    </div>
                    <div className="col-lg-12 paddingtop-1 justify">
                      {myData[this.props.match.params.id].msg2}
                    </div>
                    <div className="col-lg-12 paddingtop-1 justify">
                      {myData[this.props.match.params.id].msg3}
                    </div>
                    <div className="col-lg-12 paddingtop-1 justify">
                      {myData[this.props.match.params.id].msg4}
                    </div>
                    <div className="col-lg-12 paddingtop-1 justify">
                      {myData[this.props.match.params.id].msg5}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department={this.props.match.params.id} />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
