import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';

export default class TeachingAndLearning extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Teaching - Learning" image="banners/banner-teaching.jpg" />

        <div className="container-xl" style={{ paddingTop: "4rem" }}>
          <div className="ttm-service-description">
            <div className="row">
              <div className="col-md-5">
                <div className="ttm_single_image-wrapper">
                  <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/t-l/t-learning.jpg"} title="single-img-twenty" alt="single-img-twenty" />
                </div>
              </div>
              <div className="col-6 justify" style={{ margin: "auto" }}>
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header paddingtop-1" style={{ borderTopColor: "red" }}>
                    <h4 className="title">Teacher student connection lines at BIT</h4>
                  </div>
                </div>
                <p>Institution and teachers can have great influence on extent and quality of students learning.  BIT’s strength lies in its teaching faculty. Good and qualified teachers of BIT continuously assess the understanding needs, establish particular learning objectives, formulate teaching strategies, enforce a plan of work, and assess the outcomes of the instruction. Complete information of a course such as syllabus, relevant and helpful course materials, links to video lectures are provided in advance so that at classroom level, teachers can involve in a range of complex classroom interactions and discussions. BIT is also pioneering new ways of teaching and learning by inventing and leveraging digital technologies.</p>
              </div>
            </div>
            <div className="row paddingtop-2">
              <div className="col-md-4 paddingtop-1" style={{ border: "solid", borderColor: "#98002e", borderWidth: "1px" }}>
                <ol>
                  <li><Link to={process.env.PUBLIC_URL + '/civil-teaching'} style={{ color: "#98002e" }}>1.	Civil Engineering</Link></li><br />
                  <li><Link to={process.env.PUBLIC_URL + '/mech-teaching'} style={{ color: "#98002e" }}>2.	Mechanical Engineering</Link></li><br />
                  <li><Link to={process.env.PUBLIC_URL + '/eee-teaching'} style={{ color: "#98002e" }}>3.	Electrical and Electronics Engineering.</Link></li><br />
                  <li><Link to={process.env.PUBLIC_URL + '/ece-teaching'} style={{ color: "#98002e" }}>4.	Electronics and Communication Engineering</Link></li><br />
                  <li><Link to={process.env.PUBLIC_URL + '/cse-teaching'} style={{ color: "#98002e" }}>5. Computer Science and Engineering</Link></li><br />
                </ol>
              </div>
              <div className="col-md-4 paddingtop-1" style={{ border: "solid", borderColor: "#98002e", borderWidth: "1px", borderLeft: "none" }}>
                <ol>
                  <li><Link to={process.env.PUBLIC_URL + '/eie-teaching'} style={{ color: "#98002e" }}>6.	Electronics and Instrumentation Engineering</Link></li><br />
                  <li><Link to={process.env.PUBLIC_URL + '/iem-teaching'} style={{ color: "#98002e" }}>7.	Industrial Engineering and Management</Link></li><br />
                  <li><Link to={process.env.PUBLIC_URL + '/ete-teaching'} style={{ color: "#98002e" }}>8. Electronics and Telecommunication Engineering</Link></li><br />
                  <li><Link to={process.env.PUBLIC_URL + '/ise-teaching'} style={{ color: "#98002e" }}>9. Information Science and Engineering</Link></li><br />
                  <li><Link to={process.env.PUBLIC_URL + '/ai-teaching'} style={{ color: "#98002e" }}>10. Artificial Intelligence and Machine Learning</Link></li><br />
                </ol>
              </div>
              <div className="col-md-4 paddingtop-1" style={{ border: "solid", borderColor: "#98002e", borderWidth: "1px", borderLeft: "none" }}>
                <ol>
                  <li><Link to={process.env.PUBLIC_URL + '/mba-teaching'} style={{ color: "#98002e" }}>11. Master in Business Administration</Link></li><br />
                  <li><Link to={process.env.PUBLIC_URL + '/mca-teaching'} style={{ color: "#98002e" }}>12. Master in Computer Application</Link></li><br />
                  <li><Link to={process.env.PUBLIC_URL + '/phy-teaching'} style={{ color: "#98002e" }}>13. Physics</Link></li><br />
                  <li><Link to={process.env.PUBLIC_URL + '/chem-teaching'} style={{ color: "#98002e" }}>14. Chemistry</Link></li><br />
                  <li><Link to={process.env.PUBLIC_URL + '/math-teaching'} style={{ color: "#98002e" }}>15. Mathematics</Link></li><br />
                </ol>
              </div>
            </div>
          </div>
          <br />
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}
