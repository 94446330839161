import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loading from '../../components/common/Loading';
import NoData from '../../components/common/NoData';
import InnovationSidebar from './components/InnovationSidebar';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../Config/FbConfig';
import { Modal } from 'react-bootstrap';
import { Styles } from "./style/InnovationStyle.js";

export default class InnovationSkillDevelopment extends Component {

    state = {
        Datas1: [],
        Loading: true,

        displayModal: false,
        modalImage: "",
        modalDescription: "",
        modalDate: "",
        modalVideo: "",
        Datas: []
    }

    //Display Modal
    displayModal = (e) => {
        this.setState({
            displayModal: !this.state.displayModal,
        })
    }

    //Display Content
    contentModal = (e) => {
        var result = this.state.Datas.filter((x) => x.Id === e.target.id);
        this.setState({
            displayModal: !this.state.displayModal,
            modalImage: result[0]["Image"],
            modalDescription: result[0]["Description"],
            modalDate: result[0]["Year"],
            modalVideo: result[0]["Video"]
        })
    }

    //Load Data Here
    async componentDidMount() {
        const docRef1 = doc(firestore, "Innovation", "SkillDevelopment");
        const docSnap1 = await getDoc(docRef1);

        if (docSnap1.exists()) {
            var Data = []
            Object.entries(docSnap1.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key, Priority: value.Priority != undefined ? value.Priority : "999" }, value))
            });

            Data.length === 0 ?
                this.setState({ Datas1: "NO_999", Loading: false }) :
                this.setState({ Datas1: Data.sort(function (a, b) { return a.Priority - b.Priority; }), Loading: false })

        } else {
            this.setState({
                Datas1: "NO_999",
                Loading: false
            })
        }

        const docRef = doc(firestore, "Innovation", "SkilldevEvents");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key }, value))
            });
            this.setState({
                Datas: Data
            })
        } else {
            this.setState({
                Datas: "NO_999"
            })
        }
    }

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Innovation" image="banners/banner-achievers.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Skill Development</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/innovation/skilldevelopment-1.jpg"} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <ul className="ttm-list ttm-list-style-icon">
                                                    <li>The higher education of the 21st century faces several challenges in providing skill-centric learning, meeting the needs of the industry and society in the global arena. In order to overcome such challenges, Bangalore Institute of Technology (BIT) aims to upgrade skills to universal standards through significant industry participation and develop necessary frameworks of standards for quality assurance. The institute believes that skills are as essential as one's academic status. Thus, education and skills should go hand in hand to empower students, safeguard their future, and the overall development of an individual.</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-12 section-title with-desc text-left clearfix paddingtop-2">
                                                <div className="section-title with-desc text-left clearfix">
                                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                                        <h6 className="title">Vision</h6>
                                                    </div>
                                                    <p>To be a recognized leader in skills and learning development, in support of empowered Institute.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-12 section-title with-desc text-left clearfix paddingtop-2">
                                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                                    <h6 className="title">Mission</h6>
                                                </div>
                                                <ul className="ttm-list ttm-list-style-icon">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To develop skillful students with strong value systems who are prepared to meet the challenges of an ever-changing world</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To create an end-to-end, outcome-focused implementation framework</span></li>
                                                </ul>
                                            </div>
                                            <div className="col-md-12 section-title with-desc text-left clearfix paddingtop-2">
                                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                                    <h6 className="title">Objectives</h6>
                                                </div>
                                                <ul className="ttm-list ttm-list-style-icon">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To conduct various skill development programs to students and improve their employability.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To promote entrepreneurship to address societal issue.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To endorse the knowledge exchange programs.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To increase employability, leadership qualities of faculty and students.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To improve the technical and writing skills and researchable activities of faculty and students.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To facilitate the industrial institutional interactions.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Improvement in the learning potentialities and enhancing self-confidence of faculty and students.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Preparing women for economic independence.</span></li>
                                                </ul>
                                            </div>
                                            <div className="col-md-12 section-title with-desc text-left clearfix paddingtop-2">
                                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                                    <h6 className="title">Faculty Details</h6>
                                                </div>
                                                <table class="table">
                                                    <thead style={{ backgroundColor: "#98002e", color: "white" }}>
                                                        <tr>
                                                            <th scope="col" style={{ width: "7%" }}>Sl No</th>
                                                            <th scope="col" style={{ width: "15%" }}>Name</th>
                                                            <th scope="col" >Designation</th>
                                                            <th scope="col" style={{ width: "20%" }}>Contact</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td>Dr. Roopa K M</td>
                                                            <td>Dean, Skill Development, Student welfare and Liaison officer, Prof., Department of Mathematics</td>
                                                            <td>+91 94838 60830</td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>Dr. Gunavathi H S</td>
                                                            <td>Associate Dean, Skill Development, Asst. Prof., Department of CSE</td>
                                                            <td>+91 96635 63598</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col-md-12 section-title with-desc text-left clearfix paddingtop-2">
                                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                                    <h6 className="title">Skill Labs</h6>
                                                </div>
                                                <p>All ten UG Programs of Bangalore Institute of Technology, Bengaluru have introduced Skill development in to NEP scheme 2021 batch in the Academic year 2021-22. It has designed in to eight modules, one module per semester, each module of duration 20 hrs. Syllabus and content delivery is planed department wise.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Skill Lab Details</h5>
                                        </div>
                                    </div>
                                    <table class="table">
                                        <thead style={{ backgroundColor: "#98002e", color: "white" }}>
                                            <tr>
                                                <th scope="col" style={{ width: "10%" }}>Sl No</th>
                                                <th scope="col" style={{ width: "30%" }}>Skill Lab Name</th>
                                                <th scope="col" style={{ width: "40%" }}>Department Name</th>
                                                <th scope="col" style={{ width: "20%" }}>Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.Datas1.length === 0 ? <Loading /> :
                                                    this.state.Datas1 === "NO_999" ? <NoData /> :
                                                        this.state.Datas1.map((data, i) => (
                                                            <tr>
                                                                <td>{i + 1}</td>
                                                                <td>{data.LabName}</td>
                                                                <td>{data.DepartmentName}</td>
                                                                <td><a href={data.Link} target="_blank" style={{ color: "#98002e", fontWeight: "bold" }}>View Details</a></td>
                                                            </tr>
                                                        ))
                                            }
                                        </tbody>
                                    </table>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Events - Skill Development</h5>
                                        </div>
                                    </div>
                                    <Styles>
                                        <div className="row ttm-service-description paddingtop-1">
                                            {
                                                this.state.Datas.length === 0 ? <Loading /> :
                                                    this.state.Datas === "NO_999" ? <NoData /> :
                                                        this.state.Datas.map((data, i) => (
                                                            <div className="col-md-4 ttm-box-col-wrapper d-flex align-items-stretch" key={i} style={{ marginBottom: "1rem" }}>
                                                                <div className="featured-imagebox featured-imagebox-blog">
                                                                    <div className="featured-thumbnail">
                                                                        <a onClick={this.contentModal}><img id={data.Id} className="img-fluid" alt="" src={data.Image} /></a>
                                                                    </div>
                                                                    <div className="featured-content">
                                                                        <div className="ttm-box-post-date">
                                                                            <span className="ttm-entry-date">
                                                                                <time className="entry-date">{data.Year}</time>
                                                                            </span>
                                                                        </div>
                                                                        <div className="featured-desc justify">
                                                                            <p>{data.Description}</p>
                                                                        </div>
                                                                        {
                                                                            data.Video != undefined ?
                                                                                <div className="post-meta" style={{ marginBottom: "-1rem" }}>
                                                                                    <a target="_blank" href={data.Video}><span className="ttm-meta-line" style={{ color: "gray" }}><i className="fa fa-link" />View Details</span></a>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                            }
                                        </div>
                                    </Styles>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <InnovationSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal */}
                <Modal size="lg" show={this.state.displayModal} onHide={this.displayModal} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-5 featured-thumbnail">
                                <img className="img-fluid" alt="" src={this.state.modalImage} />
                            </div>
                            <div className="col-7" >
                                <span className="ttm-meta-line"><b>Year : </b>{this.state.modalDate}</span>
                                <div className="featured-desc justify paddingtop-1"><p>{this.state.modalDescription}</p></div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
