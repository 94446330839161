import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class CSEContact extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner
          title="Computer Science and Engineering"
          image="banners/department-cse.jpg"
        />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <h4 className="title">Contact</h4>
                  </div>
                </div>
                <div className="row">
                <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="fa fa-phone" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h6>HOD</h6>
                        </div>
                        <div className="featured-desc">
                          <p>Dr. J. GIRIJA</p>
                          <p>Professor & Head</p>
                          <p>E-mail Id:hodcse@bit-bangalore.edu.in</p>
                          <p>Contact Number: 9880937557</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="fa fa-phone" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h6>ADMISSION</h6>
                        </div>
                        <div className="featured-desc">
                          <p>PROF. TEJASHWINI P S</p>
                          <p>E-mail Id:tejashwinips@bit-bangalore.edu.in</p>
                          <p>Contact Number: 9902714366</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="fa fa-phone" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h6>MARKS CARD INCHARGE</h6>
                        </div>
                        <div className="featured-desc">
                          <p>CHAITHRA P</p>
                          <p>E-mail Id:chaithraps.p7@gmail.com</p>
                          <p>Contact Number: 8722505071</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="fa fa-phone" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h6>PERMISSION LETTER FOR INTERNSHIP</h6>
                        </div>
                        <div className="featured-desc">
                          <p>Yogesha B C</p>
                          <p>E-mail Id: bcyogesh@gmail.com</p>
                          <p>Contact Number: 9591244266</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="fa fa-phone" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h6>ALUMNI COORDINATOR</h6>
                        </div>
                        <div className="featured-desc">
                          <p>PROF. TEJASHWINI PS AND MANJUSHREE N S</p>
                          <p>E-mail Id: tejashwinips@bit-bangalore.edu.in, manjushreens@bit-bangalore.edu.in</p>
                          <p>Contact Number: 9902714366, 8884314850</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="fa fa-phone" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h6>STUDENT ACHIEVEMENTS & DEPARTEMENT BRANDING</h6>
                        </div>
                        <div className="featured-desc">
                          <p>PROF. NIKITHA K S</p>
                          <p>E-mail Id: nikithaks@bit-bangalore.edu.in</p>
                          <p>Contact Number: 9743925282</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="fa fa-phone" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h6>VTU EXAM APPLICATION</h6>
                        </div>
                        <div className="featured-desc">
                          <p>JAGAN R</p>
                          <p>E-mail Id: reval.bitcse@gmail.com</p>
                          <p>Contact Number:9945511143</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="fa fa-phone" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h6>OPEN ELECTIVE COORDINATOR</h6>
                        </div>
                        <div className="featured-desc">
                          <p>PROF. NAGAMANI D R</p>
                          <p>E-mail Id: drnagamani@bit-bangalore.edu.in</p>
                          <p>Contact Number: 9448978149</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="fa fa-phone" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h6>PARALLEL/OTES BATCH</h6>
                        </div>
                        <div className="featured-desc">
                          <p>PROF. KANCHAN A PUROHIT</p>
                          <p>E-mail Id: kanchanpurohit@bit-bangalore.edu.in</p>
                          <p> Contact Number: 9886812827</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="fa fa-phone" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h6>STUDENT PROJECT PROPOSAL</h6>
                        </div>
                        <div className="featured-desc">
                          <p>DR. SAVITHA S.K</p>
                          <p>E-mail Id: savithask@bit-bangalore.edu.in</p>
                          <p> Contact Number: 9845964427</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="fa fa-phone" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h6>SDI CLUB</h6>
                        </div>
                        <div className="featured-desc">
                          <p>PROF. NETHRAVATHY V</p>
                          <p>E-mail Id: nethra@bit-bangalore.edu.in</p>
                          <p> Contact Number: 9986017912</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="fa fa-phone" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h6>PLACEMENT COORDINATOR</h6>
                        </div>
                        <div className="featured-desc">
                          <p>PROF. PRATHIMA M G</p>
                          <p>E-mail Id:prathimamg@bit-bangalore.edu.in</p>
                          <p> Contact Number: 9611999441</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="fa fa-phone" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h6>HIGHER STUDIES</h6>
                        </div>
                        <div className="featured-desc">
                          <p>PROF. PRASHANTHKUMAR N</p>
                          <p>E-mail Id:prashanthkumarkn@bit-bangalore.edu.in</p>
                          <p> Contact Number: 9611748108</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="fa fa-phone" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h6>EDC</h6>
                        </div>
                        <div className="featured-desc">
                          <p>PROF. NIKITHA K S</p>
                          <p>E-mail Id:nikithaks@bit-bangalore.edu.in </p>
                          <p> Contact Number: 9743925282</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                          <i className="fa fa-phone" />
                        </div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title">
                          <h6>COE</h6>
                        </div>
                        <div className="featured-desc">
                          <p>DR. B N SHANKAR GOWDA</p>
                          <p>E-mail Id:bnshankargowda@bit-bangalore.edu.in </p>
                          <p> Contact Number: 9902984984</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="cse" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
