import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loading from '../../components/common/Loading';
import NoData from '../../components/common/NoData';
import InnovationSidebar from './components/InnovationSidebar';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../Config/FbConfig';
import { Modal } from 'react-bootstrap';
import { Styles } from "./style/InnovationStyle.js";

export default class InnovationStartup extends Component {

    state = {
        displayModal: false,
        modalImage: "",
        modalDescription: "",
        modalDate: "",
        modalVideo: "",
        Datas: []
    }

    //Display Modal
    displayModal = (e) => {
        this.setState({
            displayModal: !this.state.displayModal,
        })
    }

    //Display Content
    contentModal = (e) => {
        var result = this.state.Datas.filter((x) => x.Id === e.target.id);
        this.setState({
            displayModal: !this.state.displayModal,
            modalImage: result[0]["Image"],
            modalDescription: result[0]["Description"],
            modalDate: result[0]["Year"],
            modalVideo: result[0]["Video"]
        })
    }

    //Load Data Here
    async componentDidMount() {
        const docRef = doc(firestore, "Innovation", "Startup");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key }, value))
            });
            this.setState({
                Datas: Data
            })
        } else {
            this.setState({
                Datas: "NO_999"
            })
        }
    }

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Innovation" image="banners/banner-achievers.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">About - Startup Incubation Center</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/innovation/skilldevelopment-1.jpg"} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <ul className="ttm-list ttm-list-style-icon">
                                                    <li>BIT has established a Startup Incubation Centre to conceive an ever-evolving ecosystem of start-ups and entrepreneurs. It aims to foster and support dynamic entrepreneurs who want to build scalable and sustainable enterprises. Incubation support includes providing technological facilities and advice, network and linkages, co-working spaces, lab facilities, mentoring, and advisory support.</li>
                                                    <li>BIT supports entrepreneurs in developing their businesses, especially in the initial stages. With more than 40 years of experience and networks with the business and technology world, BIT has geared towards speeding up the growth and success of start-ups and early-stage companies.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Events - Startup Incubation Center</h5>
                                        </div>
                                    </div>
                                    <Styles>
                                        <div className="row ttm-service-description paddingtop-1">
                                            {
                                                this.state.Datas.length === 0 ? <Loading /> :
                                                    this.state.Datas === "NO_999" ? <NoData /> :
                                                        this.state.Datas.map((data, i) => (
                                                            <div className="col-md-4 ttm-box-col-wrapper d-flex align-items-stretch" key={i} style={{ marginBottom: "1rem" }}>
                                                                <div className="featured-imagebox featured-imagebox-blog">
                                                                    <div className="featured-thumbnail">
                                                                        <a onClick={this.contentModal}><img id={data.Id} className="img-fluid" alt="" src={data.Image} /></a>
                                                                    </div>
                                                                    <div className="featured-content">
                                                                        <div className="ttm-box-post-date">
                                                                            <span className="ttm-entry-date">
                                                                                <time className="entry-date">{data.Year}</time>
                                                                            </span>
                                                                        </div>
                                                                        <div className="featured-desc justify">
                                                                            <p>{data.Description}</p>
                                                                        </div>
                                                                        {
                                                                            data.Video != undefined ?
                                                                                <div className="post-meta" style={{ marginBottom: "-1rem" }}>
                                                                                    <a target="_blank" href={data.Video}><span className="ttm-meta-line" style={{ color: "gray" }}><i className="fa fa-link" />View Details</span></a>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                            }
                                        </div>
                                    </Styles>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <InnovationSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal */}
                <Modal size="lg" show={this.state.displayModal} onHide={this.displayModal} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-5 featured-thumbnail">
                                <img className="img-fluid" alt="" src={this.state.modalImage} />
                            </div>
                            <div className="col-7" >
                                <span className="ttm-meta-line"><b>Year : </b>{this.state.modalDate}</span>
                                <div className="featured-desc justify paddingtop-1"><p>{this.state.modalDescription}</p></div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
