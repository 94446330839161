import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class AchieversSidebar extends Component {
    render() {
        return (
            <div className="widget widget-nav-menu">
                <ul className="widget-menu">
                    <li><NavLink to={process.env.PUBLIC_URL + '/achievers-academics'}>Academics</NavLink></li>
                    <li><NavLink to={process.env.PUBLIC_URL + '/achievers-sports'}>Sports</NavLink></li>
                    <li><NavLink to={process.env.PUBLIC_URL + '/achievers-art'}>Art and Culture</NavLink></li>
                    <li><NavLink to={process.env.PUBLIC_URL + '/achievers-i-e'}>Innovations and Entrepreneurship</NavLink></li>
                    <li><NavLink to={process.env.PUBLIC_URL + '/achievers-faculty'}>Faculty Achievers</NavLink></li>
                </ul>
            </div>
        )
    }
}
