import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import ResearchSidebar from './components/ResearchSidebar';

export default class ResearchBit extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Research" image="banners/banner-research.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Research @ BIT</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + `/assets/images/research/research-1.jpg`} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <p>The main purpose of R&#38;D Centers is to continuously strive to create conducive environment to carry out Research in the thrust areas of Science and Technology. These centers make teaching faculty to passionate about engineering education, inclusive of Research and furnish students with appropriate and stimulating learning opportunities.</p><br />
                                                <ul className="ttm-list ttm-list-style-icon">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To create and maintain favorable milieu to carry out Research and Development activities in the Institute.</span></li>
                                                </ul>
                                            </div>
                                            <div className="col-md-12 paddingtop-2 justify">
                                                <ul className="ttm-list ttm-list-style-icon">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To encourage Research and Development culture among Teaching Faculty and Students.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To promote Collaborative Research and Development activities with external R&#38;D Organizations, Institutions and Industries.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To encourage Faculty and Students to pursue Qualitative Research in the direction of thrust area problems associated with Urban and Rural society to seek sustainable solutions.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To facilitate continuous enhancement in the quality of Student Project and Dissertation works.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To encourage teachers to develop teaching aids and models to demonstrate fundamental concepts of Science and Engineering to instill inquisitiveness and enhance learning and inquiring abilities of students</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To initiate and encourage interdisciplinary Research and create awareness about importance of synergy of multidisciplinary Research team to address societal problems.</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row paddingtop-2">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + `/assets/images/research/research-2.jpg`} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <div className="section-title with-desc text-left clearfix">
                                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                                        <h5 className="title">IPR Cell</h5>
                                                    </div>
                                                </div>
                                                <div className="ttm-service-description">
                                                    <ul className="ttm-list ttm-list-style-icon">
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To help in introducing prudent IP management practices within the Institute to promote an IPR culture.</span></li>
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To provide legal support, wherever necessary, to defend and protect the intellectual property rights obtained by the Institute against any infringement/unauthorised use.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-12 paddingtop-2 justify">
                                                <ul className="ttm-list ttm-list-style-icon">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To create an environment for acquiring new knowledge through Innovation and Research compatible with the Educational Mission of the Institute.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To safeguard the interest of creator of Intellectual Property and provide fair distribution of returns accruing from the commercialisation of IPR.</span></li>
                                                </ul>
                                            </div>
                                            <div className="col-md-12 paddingtop-1">
                                                <div className="section-title text-left clearfix">
                                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                                        <h5 className="title">Ph.D. Registration Cell</h5>
                                                    </div>
                                                </div>
                                                <div className="ttm-service-description justify">
                                                    <ul className="ttm-list ttm-list-style-icon">
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To produce Creative and Technically competitive Research Scholars for pioneering solutions to global challenges with interdisciplinary engineering research and advanced problem solving to meet society’s needs.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-12 paddingtop-1">
                                                <div className="section-title text-left clearfix">
                                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                                        <h5 className="title">Funded Project Cell</h5>
                                                    </div>
                                                </div>
                                                <div className="ttm-service-description justify">
                                                    <ul className="ttm-list ttm-list-style-icon">
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To work towards obtaining Grant funding by identification of viable proposals for funding of projects which address educational priorities and goals.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                                    <h4 className="title">Student Projects</h4>
                                                </div>
                                            </div>
                                            <div className="ttm-service-description">
                                                <div className="row">
                                                    <div className="col-md-5">
                                                        <div className="ttm_single_image-wrapper">
                                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/research/studentProject-1.jpg"} alt="single-img-eighteen" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                        <p>Students are facing great challenges with the rapidly growing technologies and requirements of the market. In this context, students have to do project work that provides an opportunity to gain knowledge from various areas and apply it to real-world problems. Student Projects have obtained Grants/Awards from various funding agencies like Karnataka State Council for Science and Technology (KSCST), Visvesvaraya Technological University (VTU), etc.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                                    <h5 className="title">The desired outcomes of project works are:</h5>
                                                </div>
                                            </div>
                                            <ul className="ttm-list ttm-list-style-icon">
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Students learn how to solve problems more effectively.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Students apply creative thinking skills to innovate new product designs and possibilities for projects.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Students build on their research skills and deepen their learning beyond facts or memorization.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Students take ownership over their projects, reflecting on and celebrating their progress and accomplishments.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Students can work as a team to achieve their goals.</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">The Department offers Under-Graduation [UG] with intake of 180, Post-Graduation [PG] with intake of 18 and Research under Visvesvaraya Technological University.</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <ResearchSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
