import React, { Component } from "react";
import Banner from "../../components/common/Banner";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import PlacementsSidebar from "./components/PlacementsSidebar";

export default class PlacementStudents extends Component {
  render() {
    return (
      <div>
        <Header />

        <Banner title="Placement" image="banners/banner-placement.jpg" />

        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div style={{ marginTop: "1rem", marginBottom: "2rem" }}>
                  <img
                    className="img-fluid"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/graphs/placed.png"
                    }
                    alt="single-img-eighteen"
                  />
                </div>
                <hr />
                <div className="ttm-service-single-content-area pt-3">
                  <div className="section-title with-desc text-left clearfix">
                    <div
                      className="title-header"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">Students Placed</h4>
                    </div>
                  </div>
                  <p className="justify">
                    BIT has achieved a tremendous success in placements of its
                    UG and PG students across all programs. Every year more than
                    150 top notch companies visit our Institution and conduct
                    placement activities for the full-time recruitment. The
                    Training and Placement Centre takes immense pleasure in
                    stating that around 70 percent of eligible students are
                    placed in their dream companies with attractive packages.
                    The average compensation is Rs 6.5 LPA and highest
                    compensation is Rs 37.5 LPA during 21-22.  The detailed
                    lists of selected candidates are as under:
                  </p>
                  <br />
                  <table className="table">
                    <thead
                      style={{ backgroundColor: "#98002e", color: "white" }}
                    >
                      <tr>
                        <th scope="col">Sl No</th>
                        <th scope="col">Year</th>
                        <th scope="col">Link</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Offers during the year 2021-22</td>
                        <td>
                          <a
                            target="_blank"
                            href="https://drive.google.com/file/d/1S8TpVOpyts8V6qKwgoUAl6arOaaKPZva/view?usp=sharing"
                          >
                            Click Here
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Offers during the year 2022-23</td>
                        <td>
                          <a
                            target="_blank"
                            href="https://drive.google.com/file/d/1lvr0EGDiDi5Jsf7uOZz71jq1t4Ez5gaT/view?usp=sharing"
                          >
                            Click Here
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td>3</td>
                        <td>Offers during the year 2023-24</td>
                        <td>
                          <a
                            target="_blank"
                            href="https://drive.google.com/file/d/1Duba1X5VtEGoFxqGe67Rdww7FtWin-qC/view?usp=sharing"
                          >
                            Click Here
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <PlacementsSidebar />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
