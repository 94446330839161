import React from "react";
import {
  BrowserRouter as BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";
import ScrollToTop from "./helper/ScrollToTop";
import { GlobalStyle } from "./components/common/styles/global";
import { ParallaxProvider } from "react-scroll-parallax";

import ApprovalsNBA from "./pages/approvals/ApprovalsNBA";
import ApprovalsNAAC from "./pages/approvals/ApprovalsNAAC";
import ApprovalsIGUAGE from "./pages/approvals/ApprovalsIGUAGE";
import ApprovalsNIRF from "./pages/approvals/ApprovalsNIRF";
import ApprovalsVTU from "./pages/approvals/ApprovalsVTU";
import ApprovalsUGC from "./pages/approvals/ApprovalsUGC";
import ApprovalsAICTE from "./pages/approvals/ApprovalsAICTE";

import TeachingAndLearning from "./pages/teachinglearning/TeachingAndLearning";

import ELearningOnlineCourses from "./pages/elearningcorner/ELearningOnlineCourses";
import ELearningEContent from "./pages/elearningcorner/ELearningEContent";
import ELearningPracticalResources from "./pages/elearningcorner/ELearningPracticalResources";
import ELearningResearchResources from "./pages/elearningcorner/ELearningResearchResources";

import AchieversAcademics from "./pages/achievers/AchieversAcademics";
import AchieversSports from "./pages/achievers/AchieversSports";
import AchieversArtsAndCulture from "./pages/achievers/AchieversArtsandCulture";
import AchieversIandE from "./pages/achievers/AchieversIandE";
import AchieversFaculty from "./pages/achievers/AchieversFaculty";

import Alumni from "./pages/alumni/Alumni";

import Contact from "./pages/contact/Contact";
import sent from "./pages/contact/sent";

import BE from "./pages/admission/BE";
import MBA from "./pages/admission/MBA";
import MCA from "./pages/admission/MCA";
import Mtech from "./pages/admission/Mtech";
import PHD from "./pages/admission/PHD";

import Home from "./Home";

import ExploreRVS from "./pages/exploreus/ExploreRVS";
import ExploreAbout from "./pages/exploreus/ExploreAbout";
import ExploreHighlightsofBit from "./pages/exploreus/ExploreHighlightsofBit";
import ExploreChairman from "./pages/exploreus/ExploreChairman";
import ExplorePrincipal from "./pages/exploreus/ExplorePrincipal";
import ExploreVicePrincipal from "./pages/exploreus/ExploreVicePrincipal";
import ExploreAdministration from "./pages/exploreus/ExploreAdministration";
import ExploreMC from "./pages/exploreus/ExploreMC";
import ExploreGC from "./pages/exploreus/ExploreGC";
import ExploreBestPractices from "./pages/exploreus/ExploreBestPractices";
import ExploreRules from "./pages/exploreus/ExploreRules";

import DepartmentList from "./pages/departments/DepartmentList";
import DepartmentHODMsg from "./pages/departments/component/DepartmentHODMsg";
import DepartmentHODDetails from "./pages/departments/component/DepartmentHODDetails";
import DepartmentNonTeachingFaculties from "./pages/departments/component/DepartmentNonTeachingFaculties";
import DepartmentResearch from "./pages/departments/component/DepartmentResearch";
import DepartmentTeachingFaculties from "./pages/departments/component/DepartmentTeachingFaculties";
import DepartmentSpecificTeachingFaculties from "./pages/departments/component/DepartmentSpecificTeachingFaculties";
import DepartmentResearchAchievement from "./pages/departments/component/DepartmentResearchAchievement";
import DepartmentResearchGuides from "./pages/departments/component/DepartmentResearchGuides";
import DepartmentResearchPhd from "./pages/departments/component/DepartmentResearchPhd";
import DepartmentResearchFacilities from "./pages/departments/component/DepartmentResearchFacilities";
import DepartmentResearchGrants from "./pages/departments/component/DepartmentResearchGrants";
import DepartmentResearchStudentProjects from "./pages/departments/component/DepartmentResearchStudentProjects";
import DepartmentResearchPublications from "./pages/departments/component/DepartmentResearchPublications";
import DepartmentResearchPatents from "./pages/departments/component/DepartmentResearchPatents";
import DepartmentIndustryMOU from "./pages/departments/component/DepartmentIndustryMOU";
import DepartmentIndustryInternship from "./pages/departments/component/DepartmentIndustryInternship";
import DepartmentIndustryLabs from "./pages/departments/component/DepartmentIndustryLabs";
import DepartmentAlumniActivities from "./pages/departments/component/DepartmentAlumniActivities";
import DepartmentReports from "./pages/departments/component/DepartmentReports";
import DepartmentStudyMaterial from "./pages/departments/component/DepartmentStudyMaterial";

import CEAbout from "./pages/departments/CE/CEAbout";
import CEPrograms from "./pages/departments/CE/CEPrograms";
import CETeaching from "./pages/departments/CE/CETeaching";
import CEIndustry from "./pages/departments/CE/CEIndustry";
import CEConsultancy from "./pages/departments/CE/CEConsultancy";
import CECOE from "./pages/departments/CE/CECOE";
import CEContact from "./pages/departments/CE/CEContact";

import MEAbout from "./pages/departments/ME/MEAbout";
import MEPrograms from "./pages/departments/ME/MEPrograms";
import METeaching from "./pages/departments/ME/METeaching";
import MEIndustry from "./pages/departments/ME/MEIndustry";
import MEConsultancy from "./pages/departments/ME/MEConsultancy";
import MECOE from "./pages/departments/ME/MECOE";
import MEContact from "./pages/departments/ME/MEContact";

import EEEAbout from "./pages/departments/EEE/EEEAbout";
import EEEPrograms from "./pages/departments/EEE/EEEPrograms";
import EEETeaching from "./pages/departments/EEE/EEETeaching";
import EEEIndustry from "./pages/departments/EEE/EEEIndustry";
import EEEConsultancy from "./pages/departments/EEE/EEEConsultancy";
import EEECOE from "./pages/departments/EEE/EEECOE";
import EEEContact from "./pages/departments/EEE/EEEContact";

import ECEAbout from "./pages/departments/ECE/ECEAbout";
import ECEPrograms from "./pages/departments/ECE/ECEPrograms";
import ECETeaching from "./pages/departments/ECE/ECETeaching";
import ECEIndustry from "./pages/departments/ECE/ECEIndustry";
import ECECOE from "./pages/departments/ECE/ECECOE";
import ECEConsultancy from "./pages/departments/ECE/ECEConsultancy";
import ECEContact from "./pages/departments/ECE/ECEContact";

import CSEAbout from "./pages/departments/CSE/CSEAbout";
import CSEPrograms from "./pages/departments/CSE/CSEPrograms";
import CSETeaching from "./pages/departments/CSE/CSETeaching";
import CSEIndustry from "./pages/departments/CSE/CSEIndustry";
import CSECOE from "./pages/departments/CSE/CSECOE";
import CSEConsultancy from "./pages/departments/CSE/CSEConsultancy";
import CSEContact from "./pages/departments/CSE/CSEContact";

import EIEAbout from "./pages/departments/EIE/EIEAbout";
import EIEPrograms from "./pages/departments/EIE/EIEPrograms";
import EIETeaching from "./pages/departments/EIE/EIETeaching";
import EIEIndustry from "./pages/departments/EIE/EIEIndustry";
import EIEConsultancy from "./pages/departments/EIE/EIEConsultancy";
import EIECOE from "./pages/departments/EIE/EIECOE";
import EIEContact from "./pages/departments/EIE/EIEContact";

import IEMAbout from "./pages/departments/IEM/IEMAbout";
import IEMPrograms from "./pages/departments/IEM/IEMPrograms";
import IEMTeaching from "./pages/departments/IEM/IEMTeaching";
import IEMIndustry from "./pages/departments/IEM/IEMIndustry";
import IEMConsultancy from "./pages/departments/IEM/IEMConsultancy";
import IEMCOE from "./pages/departments/IEM/IEMCOE";
import IEMContact from "./pages/departments/IEM/IEMContact";

import ETEAbout from "./pages/departments/ETE/ETEAbout";
import ETEPrograms from "./pages/departments/ETE/ETEPrograms";
import ETETeaching from "./pages/departments/ETE/ETETeaching";
import ETEIndustry from "./pages/departments/ETE/ETEIndustry";
import ETEConsultancy from "./pages/departments/ETE/ETEConsultancy";
import ETECOE from "./pages/departments/ETE/ETECOE";
import ETEContact from "./pages/departments/ETE/ETEContact";

import ISEAbout from "./pages/departments/ISE/ISEAbout";
import ISEPrograms from "./pages/departments/ISE/ISEPrograms";
import ISETeaching from "./pages/departments/ISE/ISETeaching";
import ISEIndustry from "./pages/departments/ISE/ISEIndustry";
import ISECOE from "./pages/departments/ISE/ISECOE";
import ISEConsultancy from "./pages/departments/ISE/ISEConsultancy";
import ISEContact from "./pages/departments/ISE/ISEContact";

import AIAbout from "./pages/departments/AI/AIAbout";
import AIPrograms from "./pages/departments/AI/AIPrograms";
import AITeaching from "./pages/departments/AI/AITeaching";
import AIIndustry from "./pages/departments/AI/AIIndustry";
import AICOE from "./pages/departments/AI/AICOE";
import AIConsultancy from "./pages/departments/AI/AIConsultancy";
import AIContact from "./pages/departments/AI/AIContact";

import CYBERAbout from "./pages/departments/CYBER/CYBERAbout";

import DATASCIENCEAbout from "./pages/departments/DATASCIENCE/DATASCIENCEAbout";

import ROBOTICSAbout from "./pages/departments/ROBOTICS/ROBOTICSAbout";

import VLSIAbout from "./pages/departments/VLSI/VLSIAbout";

import MBAAbout from "./pages/departments/MBA/MBAAbout";
import MBAPrograms from "./pages/departments/MBA/MBAPrograms";
import MBATeaching from "./pages/departments/MBA/MBATeaching";
import MBAIndustry from "./pages/departments/MBA/MBAIndustry";
import MBACOE from "./pages/departments/MBA/MBACOE";
import MBAConsultancy from "./pages/departments/MBA/MBAConsultancy";
import MBAContact from "./pages/departments/MBA/MBAContact";

import MCAAbout from "./pages/departments/MCA/MCAAbout";
import MCAPrograms from "./pages/departments/MCA/MCAPrograms";
import MCATeaching from "./pages/departments/MCA/MCATeaching";
import MCAIndustry from "./pages/departments/MCA/MCAIndustry";
import MCACOE from "./pages/departments/MCA/MCACOE";
import MCAConsultancy from "./pages/departments/MCA/MCAConsultancy";
import MCAContact from "./pages/departments/MCA/MCAContact";

import PHYAbout from "./pages/departments/PHY/PHYAbout";
import PHYPrograms from "./pages/departments/PHY/PHYPrograms";
import PHYTeaching from "./pages/departments/PHY/PHYTeaching";
import PHYIndustry from "./pages/departments/PHY/PHYIndustry";
import PHYCOE from "./pages/departments/PHY/PHYCOE";
import PHYConsultancy from "./pages/departments/PHY/PHYConsultancy";
import PHYContact from "./pages/departments/PHY/PHYContact";

import CHEAbout from "./pages/departments/CHE/CHEAbout";
import CHEPrograms from "./pages/departments/CHE/CHEPrograms";
import CHETeaching from "./pages/departments/CHE/CHETeaching";
import CHEIndustry from "./pages/departments/CHE/CHEIndustry";
import CHECOE from "./pages/departments/CHE/CHECOE";
import CHEConsultancy from "./pages/departments/CHE/CHEConsultancy";
import CHEContact from "./pages/departments/CHE/CHEContact";

import MATHAbout from "./pages/departments/MATH/MATHAbout";
import MATHPrograms from "./pages/departments/MATH/MATHPrograms";
import MATHTeaching from "./pages/departments/MATH/MATHTeaching";
import MATHIndustry from "./pages/departments/MATH/MATHIndustry";
import MATHCOE from "./pages/departments/MATH/MATHCOE";
import MATHConsultancy from "./pages/departments/MATH/MATHConsultancy";
import MATHContact from "./pages/departments/MATH/MATHContact";

import ResearchBit from "./pages/research/ResearchBit";
import ResearchFacilities from "./pages/research/ResearchFacilities";
import ResearchCenter from "./pages/research/ResearchCenter";

import InnovationCell from "./pages/innovation/InnovationCell";
import InnovationIdeaClub from "./pages/innovation/InnovationIdeaClub";
import InnovationSkillDevelopment from "./pages/innovation/InnovationSkillDevelopment";
import InnovationStartup from "./pages/innovation/InnovationStartup";
import InnovationIncubationCenter from "./pages/innovation/InnovationIncubationCenter";
import InnovationScienceCenter from "./pages/innovation/InnovationScienceCenter";
import InnovationIPRCell from "./pages/innovation/InnovationIPRCell";

import PlacementAbout from "./pages/placement/PlacementAbout";
import PlacementStudents from "./pages/placement/PlacementStudents";
import PlacementOurRecruiters from "./pages/placement/PlacementOurRecruiters";
import PlacementActivities from "./pages/placement/PlacementActivities";
import PlacementAlumniFeedback from "./pages/placement/PlacementAlumniFeedback";
import PlacementFacilities from "./pages/placement/PlacementFacilities";
import PlacementTeam from "./pages/placement/PlacementTeam";

import lifeatBitCampus from "./pages/life@BIT/lifeatBitCampus";
import lifeatBitMathanFest from "./pages/life@BIT/lifeatBitMathanFest";
import lifeatBitGraduation from "./pages/life@BIT/lifeatBitGraduation";
import lifeatBitCultural from "./pages/life@BIT/lifeatBitCultural";
import lifeatBitStudentClubs from "./pages/life@BIT/lifeatBitStudentClubs";
import lifeatBitSportsAndGames from "./pages/life@BIT/lifeatBitSportsAndGames";
import lifeatBitNSS from "./pages/life@BIT/lifeatBitNSS";
import lifeatBitNCC from "./pages/life@BIT/lifeatBitNCC";
import lifeatBitFacilities from "./pages/life@BIT/lifeatBitFacilities";

import Gallery from "./pages/gallery/Gallery";
import ImageList from "./pages/gallery/ImageList";

import Downloads from "./pages/downloads/Downloads";

import DownloadList from "./pages/downloads/DownloadList";

export default function App() {
  return (
    <ParallaxProvider>
      <BrowserRouter basename="/">
        <GlobalStyle />
        <ScrollToTop />
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/approvals-nba"}`}
            component={ApprovalsNBA}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/approvals-naac"}`}
            component={ApprovalsNAAC}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/approvals-igauge"}`}
            component={ApprovalsIGUAGE}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/approvals-nirf"}`}
            component={ApprovalsNIRF}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/approvals-vtu"}`}
            component={ApprovalsVTU}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/approvals-ugc"}`}
            component={ApprovalsUGC}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/approvals-aicte"}`}
            component={ApprovalsAICTE}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/teaching-and-learning"}`}
            component={TeachingAndLearning}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/e-learning-online-courses"}`}
            component={ELearningOnlineCourses}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/e-learning-e-content"}`}
            component={ELearningEContent}
          />
          <Route
            exact
            path={`${
              process.env.PUBLIC_URL + "/e-learning-practical-resourse"
            }`}
            component={ELearningPracticalResources}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/e-learning-research-resourse"}`}
            component={ELearningResearchResources}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/achievers-academics"}`}
            component={AchieversAcademics}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/achievers-sports"}`}
            component={AchieversSports}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/achievers-art"}`}
            component={AchieversArtsAndCulture}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/achievers-i-e"}`}
            component={AchieversIandE}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/achievers-faculty"}`}
            component={AchieversFaculty}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/alumni"}`}
            component={Alumni}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/Sent"}`}
            component={sent}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/admission-be"}`}
            component={BE}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/admission-mba"}`}
            component={MBA}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/admission-mca"}`}
            component={MCA}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/admission-mtech"}`}
            component={Mtech}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/admission-phd"}`}
            component={PHD}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={Home}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/explore-rvs"}`}
            component={ExploreRVS}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/explore-about"}`}
            component={ExploreAbout}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/explore-highlights"}`}
            component={ExploreHighlightsofBit}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/explore-chairman"}`}
            component={ExploreChairman}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/explore-principal"}`}
            component={ExplorePrincipal}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/explore-vice-principal"}`}
            component={ExploreVicePrincipal}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/explore-administration"}`}
            component={ExploreAdministration}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/explore-mc"}`}
            component={ExploreMC}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/explore-gc"}`}
            component={ExploreGC}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/explore-bestpractices@bit"}`}
            component={ExploreBestPractices}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/explore-rules"}`}
            component={ExploreRules}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/department-list"}`}
            component={DepartmentList}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/department-hod/:id"}`}
            component={DepartmentHODMsg}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/department-hod-specific/:id"}`}
            component={DepartmentHODDetails}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/teaching-faculties/:id"}`}
            component={DepartmentTeachingFaculties}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/non-teaching-faculties/:id"}`}
            component={DepartmentNonTeachingFaculties}
          />
          <Route
            exact
            path={`${
              process.env.PUBLIC_URL +
              "/department-teaching-faculties/:department/:id"
            }`}
            component={DepartmentSpecificTeachingFaculties}
          />
          <Route
            exact
            path={`${
              process.env.PUBLIC_URL + "/teaching-learning-material/:id"
            }`}
            component={DepartmentStudyMaterial}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/research/:id"}`}
            component={DepartmentResearch}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/activities/:id"}`}
            component={DepartmentReports}
          />

          <Route
            exact
            path={`${
              process.env.PUBLIC_URL + "/department-research-achievement/:id"
            }`}
            component={DepartmentResearchAchievement}
          />
          <Route
            exact
            path={`${
              process.env.PUBLIC_URL + "/department-research-guides/:id"
            }`}
            component={DepartmentResearchGuides}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/department-research-phd/:id"}`}
            component={DepartmentResearchPhd}
          />
          <Route
            exact
            path={`${
              process.env.PUBLIC_URL + "/department-research-facilities/:id"
            }`}
            component={DepartmentResearchFacilities}
          />
          <Route
            exact
            path={`${
              process.env.PUBLIC_URL + "/department-research-grants/:id"
            }`}
            component={DepartmentResearchGrants}
          />
          <Route
            exact
            path={`${
              process.env.PUBLIC_URL + "/department-research-student/:id"
            }`}
            component={DepartmentResearchStudentProjects}
          />
          <Route
            exact
            path={`${
              process.env.PUBLIC_URL + "/department-research-publications/:id"
            }`}
            component={DepartmentResearchPublications}
          />
          <Route
            exact
            path={`${
              process.env.PUBLIC_URL + "/department-research-patents/:id"
            }`}
            component={DepartmentResearchPatents}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/department-industry-mou/:id"}`}
            component={DepartmentIndustryMOU}
          />
          <Route
            exact
            path={`${
              process.env.PUBLIC_URL + "/department-industry-internship/:id"
            }`}
            component={DepartmentIndustryInternship}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/department-industry-labs/:id"}`}
            component={DepartmentIndustryLabs}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/alumni/:id"}`}
            component={DepartmentAlumniActivities}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/department-reports/:id"}`}
            component={DepartmentReports}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/civil-about"}`}
            component={CEAbout}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/civil-programs"}`}
            component={CEPrograms}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/civil-teaching"}`}
            component={CETeaching}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/civil-industry"}`}
            component={CEIndustry}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/civil-coe"}`}
            component={CECOE}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/civil-ce"}`}
            component={CEConsultancy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/civil-contact"}`}
            component={CEContact}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/mech-about"}`}
            component={MEAbout}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/mech-programs"}`}
            component={MEPrograms}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/mech-teaching"}`}
            component={METeaching}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/mech-industry"}`}
            component={MEIndustry}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/mech-coe"}`}
            component={MECOE}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/mech-ce"}`}
            component={MEConsultancy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/mech-contact"}`}
            component={MEContact}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/eee-about"}`}
            component={EEEAbout}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/eee-programs"}`}
            component={EEEPrograms}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/eee-teaching"}`}
            component={EEETeaching}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/eee-industry"}`}
            component={EEEIndustry}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/eee-coe"}`}
            component={EEECOE}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/eee-ce"}`}
            component={EEEConsultancy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/eee-contact"}`}
            component={EEEContact}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ece-about"}`}
            component={ECEAbout}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ece-programs"}`}
            component={ECEPrograms}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ece-teaching"}`}
            component={ECETeaching}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ece-industry"}`}
            component={ECEIndustry}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ece-coe"}`}
            component={ECECOE}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ece-ce"}`}
            component={ECEConsultancy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ece-contact"}`}
            component={ECEContact}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/cse-about"}`}
            component={CSEAbout}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/cse-programs"}`}
            component={CSEPrograms}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/cse-teaching"}`}
            component={CSETeaching}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/cse-industry"}`}
            component={CSEIndustry}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/cse-coe"}`}
            component={CSECOE}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/cse-ce"}`}
            component={CSEConsultancy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/cse-contact"}`}
            component={CSEContact}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/eie-about"}`}
            component={EIEAbout}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/eie-programs"}`}
            component={EIEPrograms}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/eie-teaching"}`}
            component={EIETeaching}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/eie-industry"}`}
            component={EIEIndustry}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/eie-coe"}`}
            component={EIECOE}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/eie-ce"}`}
            component={EIEConsultancy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/eie-contact"}`}
            component={EIEContact}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/iem-about"}`}
            component={IEMAbout}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/iem-programs"}`}
            component={IEMPrograms}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/iem-teaching"}`}
            component={IEMTeaching}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/iem-industry"}`}
            component={IEMIndustry}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/iem-coe"}`}
            component={IEMCOE}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/iem-ce"}`}
            component={IEMConsultancy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/iem-contact"}`}
            component={IEMContact}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ete-about"}`}
            component={ETEAbout}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ete-programs"}`}
            component={ETEPrograms}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ete-teaching"}`}
            component={ETETeaching}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ete-industry"}`}
            component={ETEIndustry}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ete-coe"}`}
            component={ETECOE}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ete-ce"}`}
            component={ETEConsultancy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ete-contact"}`}
            component={ETEContact}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ise-about"}`}
            component={ISEAbout}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ise-programs"}`}
            component={ISEPrograms}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ise-teaching"}`}
            component={ISETeaching}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ise-industry"}`}
            component={ISEIndustry}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ise-coe"}`}
            component={ISECOE}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ise-ce"}`}
            component={ISEConsultancy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ise-contact"}`}
            component={ISEContact}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ai-about"}`}
            component={AIAbout}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ai-programs"}`}
            component={AIPrograms}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ai-teaching"}`}
            component={AITeaching}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ai-industry"}`}
            component={AIIndustry}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ai-coe"}`}
            component={AICOE}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ai-ce"}`}
            component={AIConsultancy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/ai-contact"}`}
            component={AIContact}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/cyber-about"}`}
            component={CYBERAbout}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/datascience-about"}`}
            component={DATASCIENCEAbout}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/robotics-about"}`}
            component={ROBOTICSAbout}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/vlsi-about"}`}
            component={VLSIAbout}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/mba-about"}`}
            component={MBAAbout}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/mba-programs"}`}
            component={MBAPrograms}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/mba-teaching"}`}
            component={MBATeaching}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/mba-industry"}`}
            component={MBAIndustry}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/mba-coe"}`}
            component={MBACOE}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/mba-ce"}`}
            component={MBAConsultancy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/mba-contact"}`}
            component={MBAContact}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/mca-about"}`}
            component={MCAAbout}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/mca-programs"}`}
            component={MCAPrograms}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/mca-teaching"}`}
            component={MCATeaching}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/mca-industry"}`}
            component={MCAIndustry}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/mca-coe"}`}
            component={MCACOE}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/mca-ce"}`}
            component={MCAConsultancy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/mca-contact"}`}
            component={MCAContact}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/phy-about"}`}
            component={PHYAbout}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/phy-programs"}`}
            component={PHYPrograms}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/phy-teaching"}`}
            component={PHYTeaching}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/phy-industry"}`}
            component={PHYIndustry}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/phy-coe"}`}
            component={PHYCOE}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/phy-ce"}`}
            component={PHYConsultancy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/phy-contact"}`}
            component={PHYContact}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/chem-about"}`}
            component={CHEAbout}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/chem-programs"}`}
            component={CHEPrograms}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/chem-teaching"}`}
            component={CHETeaching}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/chem-industry"}`}
            component={CHEIndustry}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/chem-coe"}`}
            component={CHECOE}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/chem-ce"}`}
            component={CHEConsultancy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/chem-contact"}`}
            component={CHEContact}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/math-about"}`}
            component={MATHAbout}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/math-programs"}`}
            component={MATHPrograms}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/math-teaching"}`}
            component={MATHTeaching}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/math-industry"}`}
            component={MATHIndustry}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/math-coe"}`}
            component={MATHCOE}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/math-ce"}`}
            component={MATHConsultancy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/math-contact"}`}
            component={MATHContact}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/research-bit"}`}
            component={ResearchBit}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/research-facilities"}`}
            component={ResearchFacilities}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/research-center"}`}
            component={ResearchCenter}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/innovation-cell"}`}
            component={InnovationCell}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/innovation-idea-club"}`}
            component={InnovationIdeaClub}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/innovation-skill-development"}`}
            component={InnovationSkillDevelopment}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/innovation-startup"}`}
            component={InnovationStartup}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/innovation-incubation-center"}`}
            component={InnovationIncubationCenter}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/innovation-science-center"}`}
            component={InnovationScienceCenter}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/innovation-ipr"}`}
            component={InnovationIPRCell}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/placement-about"}`}
            component={PlacementAbout}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/placement-students"}`}
            component={PlacementStudents}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/placement-ourrecruiters"}`}
            component={PlacementOurRecruiters}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/placement-activities"}`}
            component={PlacementActivities}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/placement-alumnifeedback"}`}
            component={PlacementAlumniFeedback}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/placement-facilities"}`}
            component={PlacementFacilities}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/placement-team"}`}
            component={PlacementTeam}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/life@bit-bitcampus"}`}
            component={lifeatBitCampus}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/life@bit-manthanfest"}`}
            component={lifeatBitMathanFest}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/life@bit-graduation"}`}
            component={lifeatBitGraduation}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/life@bit-culture"}`}
            component={lifeatBitCultural}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/life@bit-studentclubs"}`}
            component={lifeatBitStudentClubs}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/life@bit-Sportsandgames"}`}
            component={lifeatBitSportsAndGames}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/life@bit-nss"}`}
            component={lifeatBitNSS}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/life@bit-ncc"}`}
            component={lifeatBitNCC}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/life@bit-facilities"}`}
            component={lifeatBitFacilities}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/gallery"}`}
            component={Gallery}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/image-list"}`}
            component={ImageList}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/downloads"}`}
            component={Downloads}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/download-list"}`}
            component={DownloadList}
          />
        </Switch>
      </BrowserRouter>
    </ParallaxProvider>
  );
}
