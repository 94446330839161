import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import AdmissionSidebar from './components/AdmissionSidebar';

export default class Mtech extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Admission" image="banners/banner-admission.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Seats available</h4>
                                        </div>
                                        <div className="ttm-service-description">
                                            <table class="table">
                                                <thead style={{ backgroundColor: "#98002e", color: "white" }}>
                                                    <tr>
                                                        <th style={{ width: "7%" }}>Sl No</th>
                                                        <th>M. Tech Programmes</th>
                                                        <th>Intake</th>
                                                        <th>PGCET</th>
                                                        <th>Management</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>1</th>
                                                        <td>Structural Engineering</td>
                                                        <td>18</td>
                                                        <td>15</td>
                                                        <td>3</td>
                                                    </tr>
                                                    <tr>
                                                        <th>2</th>
                                                        <td>Computer Science & Engineering</td>
                                                        <td>18</td>
                                                        <td>15</td>
                                                        <td>3</td>
                                                    </tr>
                                                    <tr>
                                                        <th>3</th>
                                                        <td>VLSI Design & Embedded System</td>
                                                        <td>18</td>
                                                        <td>15</td>
                                                        <td>3</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Admission process and eligibility</h4>
                                        </div>
                                    </div>
                                    <p><b>Entrance Exam: GATE Qualified or PGCET of Karnataka.</b></p>
                                    <p className="justify">Candidates seeking admission to M.Tech should have passed Bachelor’s Degree of Visvesvaraya Technology University or any other University recognized as equivalent by the Visvesvaraya Technology University incorporated by an act of the central or state legislatures in India or other educational institutions established by an act of Parliament. This degree must entail a minimum of four years of education, obtained a minimum of 5.75 CGPA. In case of SC, ST and Category-I candidates a minimum of 5.25 CGPA should be obtained.</p>
                                </div>
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Documents required for the Admission</h4>
                                    </div>
                                </div>
                                <div className="ttm-pf-single-detail-box mb-35">
                                    <ul className="ttm-pf-detailbox-list">
                                        <li><i className="fa fa-caret-right" /><span>PGCET Allotment Order four photocopies</span></li>
                                        <li><i className="fas fa-caret-right" /><span>10th, 12th &#38; All Degree Original Marks Cards and three Photocopies </span></li>
                                        <li><i className="fas fa-caret-right" /><span>PDC Certificate and three Photocopies</span></li>
                                        <li><i className="fas fa-caret-right" /><span>Transfer Certificate and three Photocopies</span></li>
                                        <li><i className="fas fa-caret-right" /><span>Aadhar Card three photocopies </span></li>
                                        <li><i className="fas fa-caret-right" /><span>Three Passport Size Photos </span></li>
                                        <li><i className="fas fa-caret-right" /><span>Income &#38; Caste Certificate three Photocopies </span></li>
                                        <li><i className="fas fa-caret-right" /><span>DD in Favor of PRINCIPAL BIT </span></li>
                                    </ul>
                                </div>
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Admission contact information</h4>
                                    </div>
                                    <div className="ttm-pf-single-detail-box mb-35">
                                        <ul className="ttm-pf-detailbox-list">
                                            <li><i className="fa fa-user" /><span>Principal - Bangalore Institute of Technology - K.R. Road, V V Pura - Bangalore-560004</span></li>
                                            <li><i className="fas fa-envelope" /><span>Mail-ID : </span>admission@bit-bangalore.edu.in </li>
                                            <li><i className="fas fa-phone" /><span> Contact Number : </span>+91-9448512984, +91-9448512954</li>
                                        </ul>
                                    </div>
                                    <div className="ttm-pf-single-detail-box mb-35">
                                        <h5>For Admission queries please call during working hours</h5>
                                        <ul className="ttm-pf-detailbox-list">
                                            <li><h6><span> Working Hours :</span></h6></li>
                                            <li><i className="fas fa-caret-right" /><span> Monday to Fiday </span>9.00 AM - 5.00 PM / <span> Saturday</span>9.00 AM - 2.00 PM </li>
                                        </ul>
                                    </div>
                                    <div className="ttm-pf-single-detail-box mb-35">
                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white" href={`${process.env.PUBLIC_URL + "/assets/pdf/Admission/MTECH.pdf"}`} target="_blank">
                                            <h5>Please download application form for PG Program Admission  . . . . . </h5>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <AdmissionSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}