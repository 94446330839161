import React, { Component } from "react";
import Header from "./components/common/Header";
import LanderBlock from "./components/LanderBlock";
import IconBox from "./components/IconBox";
import StudentAchivers from "./components/StudentAchivers";
import FacultyAchivers from "./components/FacultyAchivers";
import Recruiters from "./components/Recruiters";
import IndustryPartners from "./components/IndustryPartners";
import ImageGallery from "./components/ImageGallery";
import Primelocation from "./components/Primelocation";
import BitUniqueness from "./components/BitUniqueness";
import WalkThrough from "./components/WalkThrough";
import Program from "./components/Program";
import Events from "./components/Events";
import Notification from "./components/Notification";
import Footer from "./components/common/Footer";

export default class Home extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Lander Slider New */}
        <LanderBlock />

        {/*Icon Box */}
        <IconBox />

        {/* Walk Through BIT + Numbers */}
        <WalkThrough />

        {/* News & Notification  */}
        <Notification />

        {/* UG / PG / Phd / Prospectus */}
        <Program />

        {/* Uniqueness of BIT */}
        <BitUniqueness />

        {/* Events */}
        <Events />

        {/* Student Achivers */}
        <StudentAchivers />

        {/* Faculty Achivers */}
        <FacultyAchivers />

        {/* Recruiters of BITians */}
        <Recruiters />

        {/* Industry Partner  */}
        <IndustryPartners />

        {/* BIT Prime Location */}
        <Primelocation />

        {/* Image Gallery Area */}
        <ImageGallery />

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
