import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import PlacementsSidebar from './components/PlacementsSidebar';
import { Modal } from 'react-bootstrap';
import Loading from '../../components/common/Loading';
import NoData from '../../components/common/NoData';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../Config/FbConfig';
import { Styles } from "./style/placementsActivities.js";

export default class PlacementActivities extends Component {

    state = {
        displayModal: false,
        modalImage: "",
        modalDescription: "",
        modalDate: "",
        Datas: []
    }

    //Display Modal
    displayModal = (e) => {
        this.setState({
            displayModal: !this.state.displayModal,
        })
    }

    //Display Content
    contentModal = (e) => {
        var result = this.state.Datas.filter((x) => x.Id === e.target.id);
        this.setState({
            displayModal: !this.state.displayModal,
            modalImage: result[0]["Image"],
            modalDescription: result[0]["Description"],
            modalDate: result[0]["Date"]
        })
    }

    //Load Data Here
    async componentDidMount() {
        const docRef = doc(firestore, "Placement", "Activities");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key }, value))
            });
            this.setState({
                Datas: Data
            })
        } else {
            this.setState({
                Datas: "NO_999"
            })
        }
    }

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Placement" image="banners/banner-placement.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Placement Activities</h4>
                                        </div>
                                    </div>
                                    <Styles>
                                        <div className="ttm-service-description">
                                            <div className="row">
                                                {
                                                    this.state.Datas.length === 0 ? <Loading /> :
                                                        this.state.Datas === "NO_999" ? <NoData /> :
                                                            this.state.Datas.map((data, i) => (
                                                                <div className="col-md-4 ttm-box-col-wrapper d-flex align-items-stretch" key={i} style={{ marginBottom: "1rem" }}>
                                                                    <div className="featured-imagebox featured-imagebox-blog">
                                                                        <div className="featured-thumbnail">
                                                                            <a onClick={this.contentModal}><img id={data.Id} src={data.Image} alt="Error" className="img-fluid" /></a>
                                                                        </div>
                                                                        <div className="featured-content" style={{ marginTop: "-1.5rem" }}>
                                                                            <div className="sec-title">
                                                                                <p className='justify'>{data.Description}</p>
                                                                            </div>
                                                                            <div className="date">{data.Date}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                }
                                            </div>
                                        </div>
                                    </Styles>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <PlacementsSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal */}
                <Modal size="lg" show={this.state.displayModal} onHide={this.displayModal} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-5">
                                <img className="img-fluid" alt="" src={this.state.modalImage} style={{ borderRight: "solid 5px", borderColor: "#98002e" }} />
                            </div>
                            <div className="col-7" style={{ padding: "1rem" }}>
                                <div className="post-meta justify">
                                    <p>{this.state.modalDescription}</p>
                                </div>
                                <div className="date" style={{ color: "#98002e" }}>{this.state.modalDate}</div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}