import React, { useState, useEffect } from "react";
import Banner from "../../components/common/Banner";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import ModalImage from "react-modal-image";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../Config/FbConfig";
import { useHistory } from "react-router-dom";

const Gallery = () => {
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  console.log(categories);
  const [expandedCategory, setExpandedCategory] = useState(null);

  console.log(expandedCategory);

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(firestore, "Other", "Gallery");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const categorizedData = Object.entries(data).reduce(
          (acc, [key, value]) => {
            const category = value.Category || "Others";
            if (!acc[category]) {
              acc[category] = [];
            }
            acc[category].push({
              Id: key,
              Priority:
                value.Priority !== undefined ? parseFloat(value.Priority) : 999,
              Image: value.Image,
              Description: value.Description,
              Header:
                value.Header && typeof value.Header === "string"
                  ? value.Header.trim()
                  : "",
            });
            return acc;
          },
          {}
        );

        // Sort categories and ensure "Others" is last
        const sortedCategories = Object.keys(categorizedData)
          .sort((a, b) => (a === "Others" ? 1 : b === "Others" ? -1 : 0))
          .reduce((acc, key) => {
            acc[key] = categorizedData[key];
            return acc;
          }, {});

        setCategories(sortedCategories);
      }
    };

    fetchData();
  }, []);

  const handleCategoryClick = (category, photos) => {
    console.log(category);
    history.push({
      pathname: "/image-list",
      state: { photos: photos, category: category },
    });

    if (expandedCategory === category) {
      setExpandedCategory(null);
    } else {
      setExpandedCategory(category);
    }
  };

  const renderPhotos = (photos, limit) => {
    const displayPhotos = limit ? photos.slice(0, limit) : photos;
    return displayPhotos.map((photo) => (
      <div className="col-lg-12 paddingtop-2" key={photo.Id}>
        <div>
          <ModalImage
            className="swaraimage1"
            small={photo.Image}
            large={photo.Image}
            alt=""
          />
        </div>
        <br />
        <p style={{ color: "black", textAlign: "center" }}>{photo.Header}</p>
      </div>
    ));
  };

  return (
    <div>
      <Header />
      <Banner title="Gallery" image="banners/banner-gallery.jpg" />
      <div className="container">
        <div className="row paddingtop-2">
          {Object.entries(categories).map(([category, photos]) => (
            <div
              key={category}
              className="col-lg-4"
              onClick={() => handleCategoryClick(category, photos)}
            >
              {expandedCategory === category
                ? renderPhotos(photos)
                : renderPhotos([photos[0]], 1)}

              <div style={{ display: "flex", justifyContent: "center" }}>
                <h5>{category}</h5>
              </div>
            </div>
          ))}
        </div>
        <br />
      </div>

      <Footer />
    </div>
  );
};

export default Gallery;
