import React, { Component } from "react";
import { Link } from "react-router-dom";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class AIAchievers extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Master of Computer Applications (MCA)" image="banners/department-mca.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header">
                                        <h4 className="title">Learning Resources</h4>
                                    </div>
                                </div>
                                <div className="ttm-service-description">
                                    <p>Wide arrays of learning materials are provided to students in advance for each course to catalyze their learning ability. These resources include syllabus, course materials, and links to digital aids. Click here to access learning resources.</p>
                                    <Link className="ttm-btn" style={{ fontSize: "1.15rem", paddingTop: "1rem", color: "#00a76a" }} to={process.env.PUBLIC_URL + `/teaching-learning-material/mca`}>Click Here for Learning Resources</Link>
                                </div>
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header">
                                        <h4 className="title">Teaching - Learning</h4>
                                    </div>
                                </div>
                                <div className="ttm-service-description justify">
                                    <p>Teaching Learning Process defines the framework for building effective content delivery system and evaluating student performance. For an effective content delivery - a systematic calendar of events, course statistics, innovative teaching methodologies, conducting remedial classes for slow learners, motivating bright students for certification courses and value added courses to bridge the gap between industry and institution is followed.</p><br />
                                    <p>Subject allotment for the courses will be done prior to the commencement of the semester.  Department calendar of events will be prepared in line with VTU calendar of events and college calendar of events. Course material is prepared as per the prescribed syllabus and course statistics will be prepared accordingly.</p><br />
                                    <h6>Different modes of teaching followed in the department:</h6><br />
                                    <ul className="ttm-list ttm-list-style-icon">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Teaching method for theory courses: The faculty members use chalk-board and/or power point presentation in teaching their respective course and also students are encouraged to interact during the lecture hours for doubts clarifications.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Teaching method for lab courses: All the lab-oriented courses are taught by giving instructions on how algorithms are to be developed and convert them into programs in a particular language prescribed by VTU. Students should execute the programs for all possible data inputs and outputs should be recorded.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Content beyond the syllabus: Students are encouraged to execute additional programs apart from the university prescribed curriculum, so that students will have better and clear understanding of the course. </li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Tutorial classes: Tutorial classes are conducted for all the courses to brief on the application aspects of the course.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Remedial classes: Remedial classes are conducted for the slow learners to improve their performance. </li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Assignments: Assignment questions in each course are prepared by the course coordinator in their respective courses to cover the content beyond the syllabus. </li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Seminars: Students are encouraged to present seminars on latest technologies which would help them to overcome stage fear, develop communication skills and prepare presentation materials.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Project Based Learning: During their final semester real time projects are chosen by the students and are guided by both internal guide from college and external guide from IT industry.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Course material: Lecture notes and lab programs for all courses are shared to students through WhatsApp, google drive and college website.</li>
                                    </ul>
                                </div>
                                <div className="ttm-service-description">
                                    <h6 className="paddingtop-1">Seminars/technical talk/ workshops/ webinars:</h6><br />
                                    <p>Regular seminars/ technical talk/ workshops/ webinars are organized through clubs, alumnus and department. Resource persons from industry, academia and research institutions are invited to deliver series of lectures.</p>
                                </div>
                                <div className="ttm-service-description">
                                    <h6 className="paddingtop-1">On line lectures:</h6><br />
                                    <p>In the event of unavoidable circumstances or as in case of lock down, online classes are engaged using various free/ paid software tools like Google meet, Microsoft team, WebEx, zoom, Skype etc.</p>
                                </div>
                                <div className="ttm-service-description">
                                    <h6 className="paddingtop-1">Lecture videos:</h6><br />
                                    <p>The videos of the lectures are recorded and uploaded to student resources in the college website. The students are informed to view the same at their convenient time for better understanding.</p>
                                </div>
                                <div className="ttm-service-description">
                                    <h6 className="paddingtop-1">Videos of Laboratory experiments:</h6><br />
                                    <p>Videos of Laboratory experiments.</p>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="mca" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
