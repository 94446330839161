import React, { Component } from "react";
import Banner from "../../components/common/Banner";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import ExploreSidebar from "./components/ExploreSidebar";

export default class ExploreVicePrincipal extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Explore BIT" image="banners/banner-explore.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <h3 className="title">Vice Principal</h3>
                  </div>
                </div>
                <div className="ttm-service-single-content-area">
                  <section className="ttm-row team-details-section clearfix">
                    <div className="container-xl">
                      <div className="row">
                        <div className="col-lg-4" style={{ margin: "auto" }}>
                          <div className="ttm-featured-wrapper">
                            <div className="featured-thumbnail text-center">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/assets/images/explore/viceprincipal.jpg`
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-8 justify"
                          style={{ margin: "auto" }}
                        >
                          <div className="ttm-team-member-content shadow-box res-991-mt-30">
                            <div className="ttm-team-member-content shadow-box res-991-mt-30">
                              <div className="ttm-team-member-single-list">
                                <h2 className="ttm-team-member-single-title">
                                  Dr. J. Prakash
                                </h2>
                                <span className="ttm-team-member-single-position">
                                  B.E., M.S., Ph.D, MISTE
                                </span>
                                <hr />
                                <p>
                                  With the ongoing pandemic of Covid-19 in
                                  addition to constantly changing global trends
                                  and economic inflation, we here at BIT make an
                                  effort to accomplish sustainable growth in
                                  strategy and security using the hands on
                                  application of Engineering and Technology at
                                  its best.
                                </p>
                                <br />
                                <p>
                                  Bangalore Institute of Technology
                                  (BIT) established by
                                  the Rajya Vokkaligara Sangha in the year 1979
                                  is built on the philosophy of education that
                                  is grounded in the real world and in the lives
                                  of the students we serve. It is available to
                                  every individual, as in the right atmosphere,
                                  the change flows organically from the students
                                  themselves.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 paddingtop-2">
                          <p>
                            This atmosphere requires systematic language change,
                            cultural change and personal change by students,
                            faculty, and all the stakeholders in education as
                            laid foundation by our Institution.
                          </p>
                          <br />
                          <p>
                            Our goal is to establish equitable access to
                            learning opportunities and skill acquisition with
                            diversification, using a wide range of education and
                            training modalities, so that all the students can
                            acquire relevant knowledge, skills and competencies
                            for decent work and life.
                          </p>
                          <br />
                          <p>
                            BIT’s record growth has been one of the continuous
                            improvement and evolution - taking measured risks,
                            making timely entries into scientifically important
                            and viable areas, investing in enterprises which are
                            multi-dimensional in scope and capable of rapid
                            growth, pursuing a strategy based on value thus
                            achieving its objectives of enhanced productivity,
                            whilst providing an open and flexible framework for
                            expansion.
                          </p>
                          <br />
                          <p>
                            I sincerely welcome you to Bangalore Institute of
                            Technology (BIT) where we hone the best of
                            facilities, well-equipped labs, research centres,
                            workshops, libraries and well defined faculty. We
                            strive hard to aim for overall growth of the
                            students with vibrant campus life. The MOU with more
                            than 100 companies supports industrial internships
                            and learning. The Placement & Training Centre
                            pursues leadership skills to establish
                            entrepreneurship at the earliest.
                          </p>
                          <br />
                          <p>
                            B.I.T is affiliated to Visvesvaraya Technological
                            University (VTU), Karnataka and approved
                            by AICTE New Delhi. All 10 UG programs and MCA are
                            accredited by National Board of Accreditation (NBA),
                            New Delhi. BIT is also recognised under section 2
                            (f) & 12(B) of the UGC Act 1956. As per National
                            Institutional Ranking Framework of Ministry of Human
                            Resource Development, Government of India (NIRF)
                            India Rankings 2020, BIT is in the Rank-band:
                            201-250 under Engineering. We are proud to announce
                            that we received Grade ‘A+’ in the accreditation
                            process of NAAC.
                          </p>
                          <br />
                          <p>
                            As an Institution dedicated to educating our future
                            contributors and leaders, and learning from each
                            other, we encourage one another to foster a
                            community free of intolerance and discrimination,
                            and to promote a campus climate that is respectful,
                            civil, supportive, and safe. These core values allow
                            us to provide a learning environment where we can
                            all pursue our dreams and reach our highest
                            potential with fair code of discipline
                          </p>
                          <br />
                          <p>
                            Let us seek and provide holistic entirety of
                            Knowledge, Comprehension, Application, Analysis,
                            Synthesis, and Evaluation in all aspects of our life
                            and become a humane, successful citizen of our
                            Nation.
                          </p>
                          <br />
                          <p>
                            “No one person can shape the life of another. Your
                            success and happiness depend upon your own self.
                            Think for yourself and have a plan of life” – Sir M
                            Visvesvaraya
                          </p>
                          <br />
                          <p>Best regards</p>
                          <br />
                          <p style={{ fontWeight: "bold" }}>Dr. J. Prakash</p>
                          <p>B.E., M.S., Ph.D, MISTE</p>
                          <p>Vice Principal,</p>
                          <p>9845070991</p>
                          <p>jogaiahprakash@gmail.com</p>
                          <p>BIT, Bangalore.</p>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <ExploreSidebar />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
