import React, { Component } from 'react';
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";
import NoData from "../../../components/common/NoData";
import Loading from "../../../components/common/Loading";
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../Config/FbConfig';

export default class DepartmentResearchAchievement extends Component {

  state = { Data: [], Data1: [] }

  //Load Data Here
  async componentDidMount() {
    //Guides
    const docRef = doc(firestore, this.props.match.params.id, "ResearchGuides");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      var Data = []
      Object.entries(docSnap.data()).forEach(([key, value]) => {
        Data.push(Object.assign({ Id: key, Priority: value.Priority != undefined ? value.Priority : "999" }, value))
      });
      Data.length === 0 ? this.setState({ Data: "NO_999" }) : this.setState({ Data: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })
    } else {
      this.setState({
        Data: "NO_999"
      })
    }

    //Phd
    const docRef1 = doc(firestore, this.props.match.params.id, "ResearchPhD");
    const docSnap1 = await getDoc(docRef1);

    if (docSnap1.exists()) {
      var Data1 = []
      Object.entries(docSnap1.data()).forEach(([key, value]) => {
        Data1.push(Object.assign({ Id: key, Priority: value.Priority != undefined ? value.Priority : "999" }, value))
      });
      Data1.length === 0 ? this.setState({ Datas1: "NO_999" }) : this.setState({ Data1: Data1.sort(function (a, b) { return a.Priority - b.Priority; }) })
    } else {
      this.setState({
        Data1: "NO_999"
      })
    }
  }

  render() {
    const banner = {
      cse: <Banner title="Computer Science and Engineering" image="banners/department-cse.jpg" />,
      ise: <Banner title="Information Science and Engineering" image="banners/department-ise.jpg" />,
      ai: <Banner title="Artificial Intelligence and Machine Learning" image="banners/department-ai.jpg" />,
      ece: <Banner title="Electronics and Communication Engineering" image="banners/department-ece.jpg" />,
      mech: <Banner title="Mechanical Engineering" image="banners/department-me.jpg" />,
      civil: <Banner title="Civil Engineering" image="banners/department-me.jpg" />,
      eee: <Banner title="Electrical and Electronics Engineering" image="banners/department-eee.jpg" />,
      ete: <Banner title="Electronics and Telecommunication Engineering" image="banners/department-ete.jpg" />,
      eie: <Banner title="Electronics and Instrumentation Engineering" image="banners/department-eie.jpg" />,
      iem: <Banner title="Industrial Engineering and Management" image="banners/department-iem.jpg" />,
      mba: <Banner title="Master of Business Administration" image="banners/department-mba.jpg" />,
      mca: <Banner title="Master of Computer Applications (MCA)" image="banners/department-mca.jpg" />,
      phy: <Banner title="Physics" image="banners/department-phy.jpg" />,
      chem: <Banner title="Chemistry" image="banners/department-che.jpg" />,
      math: <Banner title="Mathematics" image="banners/department-math.jpg" />,
      hum: <Banner title="Humanities" image="banners/department-hum.jpg" />,
    }

    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        {banner[this.props.match.params.id]}

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header" style={{ borderTopColor: "red" }}>
                    <h4 className="title">Research Guides</h4>
                  </div>
                </div>
                <div className="ttm-service-description paddingtop-1 justify">
                  <table class="table" style={{ textAlign: "center" }}>
                    <thead style={{ backgroundColor: "#98002e", color: "white" }}>
                      <tr>
                        <th scope="col">Sl. No.</th>
                        <th scope="col">Guide Name</th>
                        <th scope="col">Research Area</th>
                        <th scope="col">Students<br />pursuing Ph.D</th>
                        <th scope="col">Vacancies</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.Data.length === 0 ? <Loading /> :
                          this.state.Data === "NO_999" ? <NoData /> :
                            this.state.Data.map((data, i) => (
                              <tr key={i}>
                                <th scope="row">{i + 1}</th>
                                <td>{data.Name}</td>
                                <td>{data.Research}</td>
                                <td>{data.Phd}</td>
                                <td>{data.Vacancies}</td>
                              </tr>
                            ))
                      }
                    </tbody>
                  </table>
                </div>
                <br />
                <div className="section-title with-desc text-left clearfix paddingtop-2">
                  <div className="title-header" style={{ borderTopColor: "red" }}>
                    <h4 className="title">Ph.D Awarded</h4>
                  </div>
                </div>
                <div className="ttm-service-description">
                  <table class="table">
                    <thead style={{ backgroundColor: "#98002e", color: "white" }}>
                      <tr>
                        <th scope="col" style={{ width: "10%" }}>Sl. No.</th>
                        <th scope="col" style={{ width: "20%" }}>Student Name</th>
                        <th scope="col">Title</th>
                        <th scope="col">Year</th>
                        <th scope="col" style={{ width: "25%" }}>Guide Affiliated to BIT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.Data1.length === 0 ? <Loading /> :
                          this.state.Data1 === "NO_999" ? <NoData /> :
                            this.state.Data1.map((data, i) => (
                              <tr key={i}>
                                <th scope="row">{i + 1}</th>
                                <td>{data.Name}</td>
                                <td>{data.Thesis}</td>
                                <td>{data.Year}</td>
                                <td>{data.Guide}</td>
                              </tr>
                            ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department={this.props.match.params.id} />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}
