import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import DepartmentAchievers from "../component/DepartmentAchievers";
import DepartmentNumbers from "../component/DepartmentNumbers";
import Footer from "../../../components/common/Footer";
import DepartmentVideo from "../component/DepartmentVideo";

export default class CSEAbout extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Computer Science and Engineering" image="banners/department-cse.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-single-content-area">
                  <DepartmentAchievers department="cse"/>
                  <DepartmentNumbers
                    text1="Number of students on roll"
                    num1="829"

                    text2="Number of Faculty"
                    num2="32"

                    text3="Number of Faculty with Ph.D"
                    num3="11"

                    text4="Number of ranks since inception"
                    num4="28"

                    text5="Number of graduated batches"
                    num5="36"

                    text6="Average Placement (%) in last 5 years"
                    num6="83"
                  />
                  <div className="ttm-service-description">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="ttm_single_image-wrapper">
                          <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/departments/cse/about-1.jpg"} alt="single-img-eighteen" />
                        </div>
                      </div>
                      <div className="col-md-7 justify" style={{ margin: "auto" }}>
                        <p>Established in the year 1979, the department boasts of well-equipped laboratories and well qualified faculty. The department comprises of VTU recognized R&D Centre, exclusive class rooms, department library with 2417 volumes and project laboratories. The department has endeavored in organizing regular field trips to various industries and power stations. Hence giving ample industrial exposure and thus integrating practical & theoretical knowledge in the curriculum.  The Department is NBA accredited thrice.</p>
                      </div>
                      <div className="col-md-12 paddingtop-2 justify">
                        <p>The department has VTU recognized R&D Centre and 6 research scholars have registered for Ph.D. Five faculty members of our department have been awarded Ph.D. in diversified areas of EEE.</p>
                      </div>
                    </div>
                  </div>
                  <DepartmentVideo department="cse" />
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Vision</h4>
                    </div>
                    <p>To be a Center of Excellence in Computer Engineering education, empowering graduates as highly skilled professionals.</p>
                  </div>
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Mission</h4>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon justify">
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To provide a platform for effective learning with emphasis on technical excellence.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To train the students to meet current industrial standards and adapt to emerging technologies.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To instill the drive for higher learning and research initiatives.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To inculcate the qualities of leadership and entrepreneurship.</span></li>
                    </ul>
                  </div>
                </div>
                <div className="section-title with-desc text-left clearfix">
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Program Educational Objectives (PEO's)</h4>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon">
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Graduates will apply fundamental and advanced concepts of Computer Science and Engineering for solving real world problems.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Graduates will build successful professional careers in various sectors to facilitate societal needs.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Graduates will have the ability to strengthen the level of expertise through higher studies and research.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Graduates will adhere to professional ethics and exhibit leadership qualities to become an Entrepreneur.</span></li>
                    </ul>
                  </div>
                  <div className="ttm-service-single-content-area paddingtop-2">
                    <div className="section-title with-desc text-left clearfix">
                      <div className="title-header" style={{ borderTopColor: "red" }}>
                        <h4 className="title">Program Outcomes</h4>
                      </div>
                    </div>
                    <div className="ttm-service-description">
                      <ul className="ttm-list ttm-list-style-icon">
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Engineering knowledge: Apply the knowledge of mathematics, science, engineering fundamentals, and an engineering specialization to the solution of complex engineering problems.</span></li>
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Problem analysis: Identify, formulate, review research literature, and analyze complex nengineering problems reaching substantiated conclusions using first principles of mathematics, natural sciences, and engineering sciences.</span></li>
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Design/development of solutions: Design solutions for complex engineering problems and design system components or processes that meet the specified needs with appropriate consideration for the public health and safety, and the cultural, societal, and environmental considerations.</span></li>
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Conduct investigations of complex problems: Use research-based knowledge and research methods including design of experiments, analysis and interpretation of data, and synthesis of the information to provide valid conclusions.</span></li>
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Modern tool usage: Create, select, and apply appropriate techniques, resources, and modern engineering and IT tools including prediction and modelling to complex engineering activities with an understanding of the limitations.</span></li>
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">The engineer and society: Apply reasoning informed by the contextual knowledge to assess societal, health, safety, legal and cultural issues and the consequent responsibilities relevant to the professional engineering practice.</span></li>
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Environment and sustainability: Understand the impact of the professional engineering solutions in societal and environmental contexts, and demonstrate the knowledge of, and need for sustainable development.</span></li>
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Ethics: Apply ethical principles and commit toprofessional ethics and responsibilities andnorms of the engineering practice.</span></li>
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Individual and team work: Function effectively as an individual, and as a member or leader in diverse teams, and in multidisciplinary settings.</span></li>
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Communication: Communicate effectively on complex engineering activities with the engineering community and with society at large, such as, being able to comprehend and write effective reports and design documentation, make effective presentations, and give and receive clear instructions.</span></li>
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Project management and finance: Demonstrate knowledge and understanding of the engineering and management principles and apply these to one’s own work, as a member and leader in a team, to manage projects and in multidisciplinary environments.</span></li>
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Life-long learning: Recognize the need for, and have the preparation and ability to engage in independent and life-long learning in the broadest context of technological change.</span></li>
                      </ul>
                    </div>
                  </div>
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Program Specific Outcomes</h4>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon">
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">The graduates of the program will have the ability to build software products by applying theoretical concepts and programming skills.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">The graduates of the program will have the ability to pursue higher studies and research in themodern computing era.</span></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="cse" />
              </div>
            </div>

          </div>
        </div>
        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
