import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class MBACOE extends Component {

  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Master in Business Administration(MBA)" image="banners/department-mba.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header" style={{ borderTopColor: "red" }}>
                    <h4 className="title"> Center of Excellence</h4>
                  </div>
                  <div className="ttm-service-description">
                    <p>The Centers of Excellence at Department of Management Studies are set up to enhance students learning journey and knowledge quest of academic excellence through practical exposure, research-based learning, creative ignition and industry academia connect.</p><br/>
                    <p> Some aspects on which center focuses through its working includes the following.</p>
                    <br/>
                    <ul className="ttm-list ttm-list-style-icon justify">
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To inspire students to embark on the greatest journeys of one’s professional life and create an everlasting impact on the society.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To give an impetus to number of ideas one can come up with and to set a benchmark for generations to come.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To motivate students to reach the apex with aplomb.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To make students competent enough to grab the opportunities and to be successful in all the endeavors.</span></li>
                    </ul>
                  </div>
                  <div className="ttm-service-single-content-area">
                    <div className="section-title with-desc text-left clearfix">
                      <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                        <h5 className="title">Vision</h5>
                      </div>
                      <p>To become a world class learning center instilling students with higher knowledge and superior skills.</p>
                    </div>
                    <div className="section-title with-desc text-left clearfix">
                      <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                        <h5 className="title">Mission</h5>
                      </div>
                      <p>To accomplish greater outcomes through a prospective collaboration. At BIT, MBA we seek to advance to a newer, competitive heights through a strategic plan, design and program. The Center strives to demonstrate a design through a model and to be an exemplary point of learning and certification.</p><br/>
                      <p>Department of Management Studies has MoU with several companies. Few companies among them are in constant association with the department and exerting efforts to enhance student’s professional skills. Following are the companies with which department has attempted on collaborative efforts with regard to Center of Excellence.</p>
                      <br/>
                      <ul className="ttm-list ttm-list-style-icon justify">
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">I.Quantum Learnings</span></li>
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">II. Skilling India (Purnatvam Learning Solutions Pvt. Ltd).</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="mba" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}
