import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import DepartmentIndustryCards from "../component/DepartmentIndustryCards";
import Footer from "../../../components/common/Footer";

export default class ETEIndustry extends Component {
    render() {
        return (
            <div>
            {/* Header */}
            <Header />

            {/* Banner */}
            <Banner title="Electronics and Telecommunication Engineering" image="banners/department-ete.jpg" />

            {/* Content */}
            <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-lg-9 content-area order-lg-2">
                            <div className="ttm-service-single-content-area">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Industry Interactions</h4>
                                    </div>
                                </div>
                                <div className="ttm-service-description">
                                    <p>The MoU is intended to recognize the general basis for a cooperative and collaborative working relationship between the two parties. The purpose of MoU is to have mutual intentions to jointly work on projects required for industries and research needs, with learned faculty of good industrial experience and promising students, jointly agree to exchange their expertise for mutual benefit and growth, on the areas specified below:</p>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5>Areas of Objective</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <ul className="ttm-list ttm-list-style-icon">
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Industrial Visits </span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">In-plant Training & special Technical Training to make the students industry-ready</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Guest Lectures</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Mini Projects and Main Project Work</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Research & Development</span></li>
                                            </ul>
                                        </div>
                                        <div className="col-6">
                                            <ul className="ttm-list ttm-list-style-icon">
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Problem Solving</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Studies & Survey</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Placements</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Internships</span></li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Establishing Advanced Laboratories</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5>LINK</h5>
                                        </div>
                                        <a href="https://drive.google.com/file/d/16RVQ-peP695Xeta7HeHjnV86mTizha5o/view?usp=sharing" target="_blank">Internship Details: 2021-22</a><br/>
                                        <a href="https://drive.google.com/file/d/1jI9zlJuo6JGGUktRhsd9NN6yJsdwupq6/view?usp=sharing" target="_blank">Internship Details: 2020-21</a><br/>
                                        <a href="https://drive.google.com/file/d/19RUOKOxBM3sX8ELLTIG0KrcOuRZf5R7F/view?usp=sharing" target="_blank">Internship Details: 2019-20</a><br/>
                                    </div>
                                </div>
                                <DepartmentIndustryCards department="ete" />
                            </div>
                        </div>
                        <div className="col-lg-3 widget-area">
                            <DepartmentSidebar department="ete" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <Footer />
        </div>
    )
}
}
