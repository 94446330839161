import React, { Component } from "react";
import { Link } from "react-router-dom";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";
import DepartmentIndustryCards from "../component/DepartmentIndustryCards";

export default class ISEIndustry extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner
                    title="Information Science and Engineering"
                    image="banners/department-ise.jpg"
                />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Industry Interactions</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-12" style={{ margin: "auto" }}>
                                                <p className="justify">To strengthen interaction with industries and to make our students updated with the latest trends in the field of Information Technology, the ISE department has entered into an agreement with many IT industries to update contents of theory courses and laboratories.</p><br />
                                                <p className="justify">The Department of ISE has MOUs with many industries providing hands on experience to the students in recent advanced technologies practiced in the industry. This interaction creates industry environment in the Institute to improve employability of the students and to place the students in various reputed IT industries.</p><br />
                                                <p>In a step towards skill development of the students in the latest technologies several expert talks are organized in the Department of ISE.</p>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                                        <h5>The main objectives</h5>
                                                    </div>
                                                </div>
                                                <ul className="ttm-list ttm-list-style-icon">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Knowledge sharing between Institute and Industry will be done by conducting seminars, workshops, technical talks, etc.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Plan for setting up Industry attached laboratories.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Content framing for delivery of industry related courses.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Industry – Institute interaction will be initiated by making MoUs with Industries.</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <DepartmentIndustryCards department="ise" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="ise" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
