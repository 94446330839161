import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import ExploreSidebar from './components/ExploreSidebar';
import Loading from '../../components/common/Loading';
import NoData from '../../components/common/NoData';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../Config/FbConfig';
import { Styles } from "./style/exploreAdministration.js";

export default class ExploreAdministration extends Component {

    state = {
        Datas: [],
        Datas1: [],
        Datas2: [],
        Datas3: [],
        Datas4: [],
        Datas5: [],
        Datas6: [],
        Datas7: [],
        Datas8: []
    }

    async componentDidMount() {
        const docRef = doc(firestore, "Explore", "AdministrativeOffice");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key, image: value.Image, name: value.Name, designation: value.Designation, contact: value.Phone, Priority: value.Priority != undefined ? value.Priority : "999" }))
            });
            Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })
        }
        else { this.setState({ Datas: "NO_999" }) }

        const docRef1 = doc(firestore, "Explore", "PersonalSection");
        const docSnap1 = await getDoc(docRef1);
        if (docSnap1.exists()) {
            var Data = []
            Object.entries(docSnap1.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key, image: value.Image, name: value.Name, designation: value.Designation, contact: value.Phone, Priority: value.Priority != undefined ? value.Priority : "999" }))
            });
            Data.length === 0 ? this.setState({ Datas1: "NO_999" }) : this.setState({ Datas1: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })
        }
        else { this.setState({ Datas1: "NO_999" }) }

        const docRef2 = doc(firestore, "Explore", "EstablishmentSection");
        const docSnap2 = await getDoc(docRef2);
        if (docSnap2.exists()) {
            var Data = []
            Object.entries(docSnap2.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key, image: value.Image, name: value.Name, designation: value.Designation, contact: value.Phone, Priority: value.Priority != undefined ? value.Priority : "999" }))
            });
            Data.length === 0 ? this.setState({ Datas2: "NO_999" }) : this.setState({ Datas2: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })
        }
        else { this.setState({ Datas2: "NO_999" }) }

        const docRef3 = doc(firestore, "Explore", "AccountSection");
        const docSnap3 = await getDoc(docRef3);
        if (docSnap3.exists()) {
            var Data = []
            Object.entries(docSnap3.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key, image: value.Image, name: value.Name, designation: value.Designation, contact: value.Phone, Priority: value.Priority != undefined ? value.Priority : "999" }))
            });
            Data.length === 0 ? this.setState({ Datas3: "NO_999" }) : this.setState({ Datas3: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })
        }
        else { this.setState({ Datas3: "NO_999" }) }

        const docRef4 = doc(firestore, "Explore", "AdmissionSection");
        const docSnap4 = await getDoc(docRef4);
        if (docSnap4.exists()) {
            var Data = []
            Object.entries(docSnap4.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key, image: value.Image, name: value.Name, designation: value.Designation, contact: value.Phone, Priority: value.Priority != undefined ? value.Priority : "999" }))
            });
            Data.length === 0 ? this.setState({ Datas4: "NO_999" }) : this.setState({ Datas4: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })
        }
        else { this.setState({ Datas4: "NO_999" }) }

        const docRef5 = doc(firestore, "Explore", "StoreSection");
        const docSnap5 = await getDoc(docRef5);
        if (docSnap5.exists()) {
            var Data = []
            Object.entries(docSnap5.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key, image: value.Image, name: value.Name, designation: value.Designation, contact: value.Phone, Priority: value.Priority != undefined ? value.Priority : "999" }))
            });
            Data.length === 0 ? this.setState({ Datas5: "NO_999" }) : this.setState({ Datas5: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })
        }
        else { this.setState({ Datas5: "NO_999" }) }

        const docRef6 = doc(firestore, "Explore", "Housekeeping");
        const docSnap6 = await getDoc(docRef6);
        if (docSnap6.exists()) {
            var Data = []
            Object.entries(docSnap6.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key, image: value.Image, name: value.Name, designation: value.Designation, contact: value.Phone, Priority: value.Priority != undefined ? value.Priority : "999" }))
            });
            Data.length === 0 ? this.setState({ Datas6: "NO_999" }) : this.setState({ Datas6: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })
        }
        else { this.setState({ Datas6: "NO_999" }) }

        const docRef7 = doc(firestore, "Explore", "HelpDesk");
        const docSnap7 = await getDoc(docRef7);
        if (docSnap7.exists()) {
            var Data = []
            Object.entries(docSnap7.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key, image: value.Image, name: value.Name, designation: value.Designation, contact: value.Phone, Priority: value.Priority != undefined ? value.Priority : "999" }))
            });
            Data.length === 0 ? this.setState({ Datas7: "NO_999" }) : this.setState({ Datas7: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })
        }
        else { this.setState({ Datas7: "NO_999" }) }

        const docRef8 = doc(firestore, "Explore", "Drivers");
        const docSnap8 = await getDoc(docRef8);
        if (docSnap8.exists()) {
            var Data = []
            Object.entries(docSnap8.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key, image: value.Image, name: value.Name, designation: value.Designation, contact: value.Phone, Priority: value.Priority != undefined ? value.Priority : "999" }))
            });
            Data.length === 0 ? this.setState({ Datas8: "NO_999" }) : this.setState({ Datas8: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })
        }
        else { this.setState({ Datas8: "NO_999" }) }
    }

    render() {

        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Explore BIT" image="banners/banner-explore.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Administrative Office</h4>
                                        </div>
                                    </div>
                                    <Styles>
                                        <div className="row ttm-service-msg">
                                            {
                                                this.state.Datas.length === 0 ? <Loading /> :
                                                    this.state.Datas === "NO_999" ? <NoData /> :
                                                        this.state.Datas.map((data, i) => (
                                                            <div className='col-md-4 paddingtop-1' style={{ textAlign: "center" }} key={data.id}>
                                                                <img className="img-fluid" src={data.image} alt="error" />
                                                                <h6 className="paddingtop-1">{data.name}</h6>
                                                                <small>{data.designation}</small><br />
                                                                <small>{data.contact}</small>
                                                            </div>
                                                        ))
                                            }
                                        </div>

                                    </Styles>
                                </div>
                                <div className="ttm-service-single-content-area paddingtop-2">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Personal Section</h4>
                                        </div>
                                    </div>
                                    <Styles>
                                        <div className="row ttm-service-msg">
                                            {
                                                this.state.Datas1.length === 0 ? <Loading /> :
                                                    this.state.Datas1 === "NO_999" ? <NoData /> :
                                                        this.state.Datas1.map((data, i) => (
                                                            <div className='col-md-4 paddingtop-1' style={{ textAlign: "center" }} key={data.id}>
                                                                <img className="img-fluid" src={data.image} alt="error" />
                                                                <h6 className="paddingtop-1">{data.name}</h6>
                                                                <small>{data.designation}</small><br />
                                                                <small>{data.contact}</small>
                                                            </div>
                                                        ))
                                            }
                                        </div>

                                    </Styles>
                                </div>
                                <div className="ttm-service-single-content-area paddingtop-2">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Establishment Section</h4>
                                        </div>
                                    </div>
                                    <Styles>
                                        <div className="row ttm-service-msg">
                                            {
                                                this.state.Datas2.length === 0 ? <Loading /> :
                                                    this.state.Datas2 === "NO_999" ? <NoData /> :
                                                        this.state.Datas2.map((data, i) => (
                                                            <div className='col-md-4 paddingtop-1' style={{ textAlign: "center" }} key={data.id}>
                                                                <img className="img-fluid" src={data.image} alt="error" />
                                                                <h6 className="paddingtop-1">{data.name}</h6>
                                                                <small>{data.designation}</small><br />
                                                                <small>{data.contact}</small>
                                                            </div>
                                                        ))
                                            }
                                        </div>

                                    </Styles>
                                </div>
                                <div className="ttm-service-single-content-area paddingtop-2">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Account, Salary and Cash Section</h4>
                                        </div>
                                    </div>
                                    <Styles>
                                        <div className="row ttm-service-msg">
                                            {
                                                this.state.Datas3.length === 0 ? <Loading /> :
                                                    this.state.Datas3 === "NO_999" ? <NoData /> :
                                                        this.state.Datas3.map((data, i) => (
                                                            <div className='col-md-4 paddingtop-1' style={{ textAlign: "center" }} key={data.id}>
                                                                <img className="img-fluid" src={data.image} alt="error" />
                                                                <h6 className="paddingtop-1">{data.name}</h6>
                                                                <small>{data.designation}</small><br />
                                                                <small>{data.contact}</small>
                                                            </div>
                                                        ))
                                            }
                                        </div>

                                    </Styles>
                                </div>
                                <div className="ttm-service-single-content-area paddingtop-2">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Admission & Exam Section</h4>
                                        </div>
                                    </div>
                                    <Styles>
                                        <div className="row ttm-service-msg">
                                            {
                                                this.state.Datas4.length === 0 ? <Loading /> :
                                                    this.state.Datas4 === "NO_999" ? <NoData /> :
                                                        this.state.Datas4.map((data, i) => (
                                                            <div className='col-md-4 paddingtop-1' style={{ textAlign: "center" }} key={data.id}>
                                                                <img className="img-fluid" src={data.image} alt="error" />
                                                                <h6 className="paddingtop-1">{data.name}</h6>
                                                                <small>{data.designation}</small><br />
                                                                <small>{data.contact}</small>
                                                            </div>
                                                        ))
                                            }
                                        </div>

                                    </Styles>
                                </div>
                                <div className="ttm-service-single-content-area paddingtop-2">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Store Section</h4>
                                        </div>
                                    </div>
                                    <Styles>
                                        <div className="row ttm-service-msg">
                                            {
                                                this.state.Datas5.length === 0 ? <Loading /> :
                                                    this.state.Datas5 === "NO_999" ? <NoData /> :
                                                        this.state.Datas5.map((data, i) => (
                                                            <div className='col-md-4 paddingtop-1' style={{ textAlign: "center" }} key={data.id}>
                                                                <img className="img-fluid" src={data.image} alt="error" />
                                                                <h6 className="paddingtop-1">{data.name}</h6>
                                                                <small>{data.designation}</small><br />
                                                                <small>{data.contact}</small>
                                                            </div>
                                                        ))
                                            }
                                        </div>

                                    </Styles>
                                </div>
                                <div className="ttm-service-single-content-area paddingtop-2">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Housekeeping</h4>
                                        </div>
                                    </div>
                                    <Styles>
                                        <div className="row ttm-service-msg">
                                            {
                                                this.state.Datas6.length === 0 ? <Loading /> :
                                                    this.state.Datas6 === "NO_999" ? <NoData /> :
                                                        this.state.Datas6.map((data, i) => (
                                                            <div className='col-md-4 paddingtop-1' style={{ textAlign: "center" }} key={data.id}>
                                                                <img className="img-fluid" src={data.image} alt="error" />
                                                                <h6 className="paddingtop-1">{data.name}</h6>
                                                                <small>{data.designation}</small><br />
                                                                <small>{data.contact}</small>
                                                            </div>
                                                        ))
                                            }
                                        </div>

                                    </Styles>
                                </div>
                                <div className="ttm-service-single-content-area paddingtop-2">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Help Desk</h4>
                                        </div>
                                    </div>
                                    <Styles>
                                        <div className="row ttm-service-msg">
                                            {
                                                this.state.Datas7.length === 0 ? <Loading /> :
                                                    this.state.Datas7 === "NO_999" ? <NoData /> :
                                                        this.state.Datas7.map((data, i) => (
                                                            <div className='col-md-4 paddingtop-1' style={{ textAlign: "center" }} key={data.id}>
                                                                <img className="img-fluid" src={data.image} alt="error" />
                                                                <h6 className="paddingtop-1">{data.name}</h6>
                                                                <small>{data.designation}</small><br />
                                                                <small>{data.contact}</small>
                                                            </div>
                                                        ))
                                            }
                                        </div>

                                    </Styles>
                                </div>
                                <div className="ttm-service-single-content-area paddingtop-2">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Drivers and Lift Operators</h4>
                                        </div>
                                    </div>
                                    <Styles>
                                        <div className="row ttm-service-msg">
                                            {
                                                this.state.Datas8.length === 0 ? <Loading /> :
                                                    this.state.Datas8 === "NO_999" ? <NoData /> :
                                                        this.state.Datas8.map((data, i) => (
                                                            <div className='col-md-4 paddingtop-1' style={{ textAlign: "center" }} key={data.id}>
                                                                <img className="img-fluid" src={data.image} alt="error" />
                                                                <h6 className="paddingtop-1">{data.name}</h6>
                                                                <small>{data.designation}</small><br />
                                                                <small>{data.contact}</small>
                                                            </div>
                                                        ))
                                            }
                                        </div>

                                    </Styles>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <ExploreSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
} 