import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class ISEContact extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Information Science and Engineering" image="banners/department-ise.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-4">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <h4 className="title">Contact</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-lg-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle"><i className="fa fa-phone" /></div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title"><h6>Head of the Department</h6></div>
                        <div className="featured-desc">
                          <p>Dr. Asha. T</p>
                          <p>E-mail Id:ashat@bit-bangalore.edu.in</p>
                          <p>Contact Number:+91 9880511358</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle"><i className="fa fa-phone" /></div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title"><h6>Research Coordinator</h6></div>
                        <div className="featured-desc">
                          <p>Dr. Hema Jagadish</p>
                          <p>E-mail Id:hemajagadish@bit-bangalore.edu.in</p>
                          <p>Contact Number:+91 9880671918</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle"><i className="fa fa-phone" /></div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title"><h6>Placement Coordinator</h6></div>
                        <div className="featured-desc">
                          <p>Dr.Vani V</p>
                          <p>E-mail Id: vaniv@ bit-bangalore.edu.in</p>
                          <p>Contact Number: +91 9945824760</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle"><i className="fa fa-phone" /></div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title"><h6>Alumni Coordinator</h6></div>
                        <div className="featured-desc">
                          <p>Dr. C S Jayasheela</p>
                          <p>E-mail Id:jayasheela@bit-bangalore.edu.in</p>
                          <p>Contact Number:+91 9448677798</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle"><i className="fa fa-phone" /></div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title"><h6>Professional Body</h6></div>
                        <div className="featured-desc">
                          <p>Dr. Vani V</p>
                          <p>E-mail Id: vaniv@ bit-bangalore.edu.in</p>
                          <p>Contact Number:+91 9844474698</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle"><i className="fa fa-phone" /></div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title"><h6>MOU Coordinator</h6></div>
                        <div className="featured-desc">
                          <p>Dr. M Shilpa</p>
                          <p>E-mail Id: shilpam@bit-bangalore.edu.in</p>
                          <p>Contact Number: 9880017363</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle"><i className="fa fa-phone" /></div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title"><h6>Centre of Excellence</h6></div>
                        <div className="featured-desc">
                          <p>Dr. Roopa H</p>
                          <p>E-mail Id: roopah@ bit-bangalore.edu.in</p>
                          <p>Contact Number:+91 9900171052</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle"><i className="fa fa-phone" /></div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title"><h6>Media Club Coordinator</h6></div>
                        <div className="featured-desc">
                          <p>Prof.Padmanabha J</p>
                          <p>E-mail Id:padmanbhaj@ bit-bangalore.edu.in</p>
                          <p>Contact Number: +91 9900545713</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle"><i className="fa fa-phone" /></div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title"><h6>Department Library Coordinator</h6></div>
                        <div className="featured-desc">
                          <p> Dr.C.S Jayasheela</p>
                          <p>E-mail Id:jayasheela@ bit-bangalore.edu.in</p>
                          <p> Contact Number: +91 9448677798</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle"><i className="fa fa-phone" /></div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title"><h6>Chief Student Counsellor</h6></div>
                        <div className="featured-desc">
                          <p>Prof.Padmanabha J</p>
                          <p>E-mail Id:padmanbhaj@ bit-bangalore.edu.in</p>
                          <p>Contact Number: +91 9900545713</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle"><i className="fa fa-phone" /></div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title"><h6>Women's Grievance & Department Club Coordinator</h6></div>
                        <div className="featured-desc">
                          <p>Prof.Anupama K C</p>
                          <p>E-mail Id: anupamakc@bit-bangalore.edu.in</p>
                          <p> Contact Number: +91 9448229306</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle"><i className="fa fa-phone" /></div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title"><h6>Result Analysis Coordinator</h6></div>
                        <div className="featured-desc">
                          <p>Dr. S Mercy</p>
                          <p>E-mail Id: smercy@bit-bangalore.edu.in</p>
                          <p> Contact Number: +91 9886120843</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 paddingtop-2">
                    <div className="featured-icon-box style2 left-icon icon-align-top">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle"><i className="fa fa-phone" /></div>
                      </div>
                      <div className="featured-content">
                        <div className="featured-title"><h6>Attendance Coordinator</h6></div>
                        <div className="featured-desc">
                          <p>Prof. M. Chethana</p>
                          <p>E-mail Id: chethanayasmita9@gmail.com</p>
                          <p> Contact Number: +91 9844473613</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="ise" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
