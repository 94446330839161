import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class ApprovalsSidebar extends Component {
    render() {
        return (
            <div>
                <div className="widget widget-nav-menu">
                    <ul className="widget-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + '/approvals-nba'}>NBA</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/approvals-naac'}>NAAC</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/approvals-igauge'}>QS I-GAUGE</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/approvals-nirf'}>NIRF</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/approvals-vtu'}>VTU Approvals</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/approvals-ugc'}>UGC Approvals</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/approvals-aicte'}>AICTE Approvals</NavLink></li>
                    </ul>
                </div>
            </div>
        )
    }
}
