import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import ELearningSidebar from './components/ELearningSidebar';

export default class ELearningOnlineCourses extends Component {

  render() {

    const Datas = [
      {
        "id": "1",
        "header": "Massachusetts Institute of Technology (MIT) Open CourseWare",
        "description": "MIT OpenCourseWare is a free and open collection of material from thousands of MIT courses, covering the entire MIT curriculum. Knowledge is your reward - Use OCW to guide your own life-long learning, or to teach others. MIT does not offer credit or certification to users of OCW – and asks for nothing in return. No enrollment or registration- Freely browse and use OCW materials at your own pace. There's no signup, and no start or end dates. Made for sharing. Download files for later. Send to friends and colleagues. Modify, remix, and reuse (just remember to cite OCW as the source.)",
        "link": "https://ocw.mit.edu/"
      },
      {
        "id": "2",
        "header": "SWAYAM",
        "description": "SWAYAM is a programme initiated by Government of India which offers Massive Open Online Courses.   All the courses are interactive, prepared by the best teachers in the country and are available, free of cost to any learner. More than 1,000 specially chosen faculty and teachers from across the country have participated in preparing these courses. Courses delivered through SWAYAM are available free of cost to the learners, however learners wanting a SWAYAM certificate should register for the final proctored exams that come at a fee and attend in-person at designated centres on specified dates. Eligibility for the certificate will be announced on the course page and learners will get certificates only if this criteria is matched. Universities/colleges approving credit transfer for these courses can use the marks/certificate obtained in these courses for the same.",
        "link": "https://swayam.gov.in/"
      },
      {
        "id": "3",
        "header": "NPTEL",
        "description": "National Programme on Technology Enhanced Learning (NPTEL) is a project of MHRD initiated by seven Indian Institutes of Technology (Bombay, Delhi, Kanpur, Kharagpur, Madras, Guwahati and Roorkee) along with the Indian Institute of Science, Bangalore in 2003, to provide quality education to anyone interested in learning from the IITs. The main goal was to create web and video courses in all major branches of engineering and physical sciences at the undergraduate and postgraduate levels and management courses at the postgraduate level.",
        "link": "https://swayam.gov.in/nc_details/NPTEL"
      },
      {
        "id": "4",
        "header": "AICTE",
        "description": "AICTE has been appointed 9th National MOOCs Coordinator (NC) for all courses that do not fall in the purview of any of the existing NCs including foreign university courses. AICTE has been appointed National Coordinator for NRCs also identified by MHRD under PMMMNMTT for preparation of Annual Refresher Programme in Teaching for faculty of the Engineering/University/ Institutions for MOOCs Development under SWAYAM.",
        "link": "https://swayam.gov.in/nc_details/AICTE"
      },
      {
        "id": "5",
        "header": "IIMB",
        "description": "IIMB offers Massive Open Online Courses MOOCs. IIMB is the first management school in India to offer MOOCs on the edX. IIMB is also the coordinating institute for management education for SWAYAM, Government of India’s online education platform. The Quality Council of India (QCI) will be the supporting partner for IIMB Swayam courses. QCI is an autonomous body set up by the Ministry of Commerce and Industry, Government of India jointly with the Indian Industry represented by the three premier industry associations i.e. Associated Chambers of Commerce and Industry of India, Confederation of Indian Industry (CII) and Federation of Indian Chambers of Commerce and Industry (FICCI), to establish and operate national accreditation structure and promote quality through National Quality Campaign. In addition to IIMBx team from IIM Bangalore, QCI will also provide learner support to IIMB Swayam courses and help in dissemination of learner information from time to time.",
        "link": "https://swayam.gov.in/nc_details/IIMB"
      },
      {
        "id": "6",
        "header": "NITTTR",
        "description": "The National Institute of Technical Teachers Training and Research (NITTTR) Chennai  serves as one of the National Coordinator for SWAYAM for the teacher training program, along with the other NITTTRs (Bhopal, Chandigarh and Kolkata). It is offering courses in the area of technical teacher training. It takes initiatives to offer need-based Human Resource Development programs through appropriate modes and develop curricula and instructional resources to meet clientele requirements. It also fosters research in the interdisciplinary area of Engineering Education and offers consultancy and extension services for the total development of technical institutions (Engineering and Polytechnic Colleges), Industry, Service sector and the Community at large.",
        "link": "https://swayam.gov.in/nc_details/IIMB"
      },
      {
        "id": "7",
        "header": "IGNOU",
        "description": "The Indira Gandhi National Open University (IGNOU) offers about 228 certificate, diploma, degree and doctoral programmes, with a strength of nearly 810 faculty members and 574 academic staff at the headquarters and regional centres and about 33,212 academic counsellors from conventional institutions of higher learning, professional organisations, and industry among others.",
        "link": "https://swayam.gov.in/nc_details/IGNOU"
      },
      {
        "id": "8",
        "header": "Academic earth",
        "description": "Explore Free Online College Courses from Featured Universities.",
        "link": "https://academicearth.org"
      },
      {
        "id": "8",
        "header": "Saylor Academy",
        "description": "Saylor Academy is a nonprofit initiative working since 2008 to offer free and open online courses to all who want to learn. We offer nearly 100 full-length courses at the college and professional levels, each built by subject matter experts. All courses are available to complete — at your pace, on your schedule, and free of cost.",
        "link": "https://www.saylor.org/"
      },
      {
        "id": "9",
        "header": "Harward University Free Courses",
        "description": "Click here to know the free courses offered by Harvard University",
        "link": "https://pll.harvard.edu/catalog/free"
      },
      {
        "id": "10",
        "header": "Open Yale Courses",
        "description": "Open Yale Courses provides free and open access to a selection of introductory courses taught by distinguished teachers and scholars at Yale University. The aim of the project is to expand access to educational materials for all who wish to learn.",
        "link": "https://oyc.yale.edu/"
      },
      {
        "id": "11",
        "header": "Training Courses",
        "description": "The training programmes by the Indian Machine Tool Manufacturers' Association (IMTMA) is a sincere effort towards the knowledge upgradation and skill development in the country. Over 25 years, IMTMA training has helped industry professionals as well as students in understanding & implementing various technologies in the manufacturing domain. IMTMA has established state-of-art training facilities at its Technology centres, both at Bangalore & Pune for enabling learning through hands-on approach.",
        "link": "https://www.imtmaelearn.in/"
      },
      {
        "id": "12",
        "header": "Linked In Courses",
        "description": "Grow your skills. Learn in-demand skills with over 16,000+ online courses taught by real-world professionals. Keep learning in the moments that matter Expert-led courses across a variety of online class topics for every step of your career. Instructors with real-world experience.",
        "link": "https://www.linkedin.com/learning"
      },
      {
        "id": "13",
        "header": "Great Learning",
        "description": "Crack dream jobs with free certificate courses Upskilling for Professionals from World Class Universities",
        "link": "https://www.mygreatlearning.com/academy"
      },
      {
        "id": "14",
        "header": "Class Central",
        "description": "Class Central aggregates courses from many providers to help you find the best courses on almost any subject, wherever they exist.",
        "link": "https://www.classcentral.com/"
      },
      {
        "id": "15",
        "header": "Udemy",
        "description": "A broad selection of courses. Choose from 204,000 online video courses with new additions published every month",
        "link": "https://www.udemy.com/"
      },
      {
        "id": "16",
        "header": "eDX",
        "description": "Start learning from the world’s best institutions.",
        "link": "https://www.edx.org/"
      },
      {
        "id": "17",
        "header": "Coursera",
        "description": "Learn without limits. Start, switch, or advance your career with more than 5,000 courses, Professional Certificates, and degrees from world-class universities and companies.",
        "link": "https://www.coursera.org/"
      },
      {
        "id": "18",
        "header": "FutureLearn",
        "description": "Learn new skills online with world‑class universities and experts. Subscribe for limitless learning, whenever and wherever suits you. Earn CV-ready certificates, learn at your own pace and get long-term access to thousands of high-quality short courses.",
        "link": "https://www.futurelearn.com/"
      },
    ]

    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="E - Learning Corner" image="banners/banner-teaching.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header" style={{ borderTopColor: "red" }}>
                    <h4 className="title">Online Courses</h4>
                  </div>
                </div>
                <table class="table">
                  <thead style={{ backgroundColor: "#98002e", color: "white", textAlign: "center" }}>
                    <tr>
                      <th scope="col" style={{ width: "10%" }}>SL No</th>
                      <th scope="col" style={{ width: "25%" }}>Platform</th>
                      <th scope="col" style={{ width: "50%" }}>Description</th>
                      <th scope="col" style={{ width: "15%" }}>Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Datas.map((data, i) => (
                        <tr className="justify">
                          <td>{data.id}</td>
                          <td>{data.header}</td>
                          <td>{data.description}</td>
                          <td><a target="_blank" href={data.link}>Click Here</a></td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
              <div className="col-lg-3 widget-area">
                <ELearningSidebar />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}
