import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class CECOE extends Component {

  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Civil Engineering" image="banners/department-ce.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header" style={{ borderTopColor: "red" }}>
                    <h4 className="title"> Center of Excellence</h4>
                  </div>
                  <div className="ttm-service-description">
                    <p>The Department of Civil Engineering in association with department of electrical and electronics engineering has established Centre of Excellence (COE) in interdisciplinary area “Energy Engineering”.
                      <a className="ttm-btn" style={{ paddingLeft: "10px" }} href={process.env.PUBLIC_URL + "/assets/pdf/COE/COE - Civil.pdf"} target="_blank">For More Details Click Here</a>
                    </p>
                  </div>
                  <div className="ttm-service-single-content-area">
                    <div className="section-title with-desc text-left clearfix">
                      <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                        <h5 className="title">Vision</h5>
                      </div>
                      <p>To establish the centre of excellence for retrofit and rehabilitation is to become an expertise in the field of consultancy and training, to enhance the skills in meeting the dynamic challenges of the existing structures.</p>
                    </div>
                    <div className="section-title with-desc text-left clearfix">
                      <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                        <h5 className="title">Mission</h5>
                      </div>
                      <ul className="ttm-list ttm-list-style-icon justify">
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To create an Environment as a Center of Research. </span></li>
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To achieve excellence of research in the field of retrofitting and rehabilitation methods and materials and to become experts in consultancy activities.</span></li>
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To encourage teachers, consultants and students to take up consultancy activities.</span></li>
                      </ul>
                    </div>
                    <div className="section-title with-desc text-left clearfix">
                      <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                        <h5 className="title">Objectives</h5>
                      </div>
                      <ul className="ttm-list ttm-list-style-icon justify">
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To conduct various activities to develop solutions for strengthening </span></li>
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Supporting the personnel through training, mentoring and sharing best practices</span></li>
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To organize periodic workshops/webinars / seminars and interactions to enhance the research knowledge.</span></li>
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To train faculty, engineers and students about the innovative methods of strengthening.</span></li>
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To carry out the research on alternative materials and methods of retrofitting and rehabilitation.</span></li>
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">For providing the consultation and guidance for practicing engineers.</span></li>
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To suggest modifications in curriculum for academic institutions and research centers.</span></li>
                      </ul>
                    </div>
                    <div className="section-title with-desc text-left clearfix">
                      <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                        <h5 className="title">Goal</h5>
                      </div>
                      <p>To preserve research ambience and promote industry academic co-ordination for innovative practical application in Energy Engineering.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="civil" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}