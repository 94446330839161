import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import ModalImage from "react-modal-image";
import ReactPaginate from "react-paginate";
import { Button } from "react-bootstrap";
import Banner from "../../components/common/Banner";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";

function ImageList() {
  const history = useHistory();
  const location = useLocation();
  const photos = location.state.photos;
  const category = location.state.category;

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 9; // Number of items per page
  const pageCount = Math.ceil(photos.length / itemsPerPage);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const displayPhotos = photos.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <>
      <Header />
      <Banner title="Gallery" image="banners/banner-gallery.jpg" />
      <div className="container" style={{ padding: "3rem" }}>
        <div className="row">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "3rem",
            }}
          >
            <h4>{category}</h4>
            {/* <Button onClick={() => history.goBack()}>Go Back</Button> */}
          </div>

          {displayPhotos.map((item) => (
            <div key={item.id} className="col-lg-4 mb-4">
              <div className="card">
                <ModalImage
                  className="card-img-top swaraimage1"
                  small={item.Image}
                  large={item.Image}
                  alt={item.Description}
                />
                <div className="card-body">
                  <p
                    style={{ fontSize: "15px", fontWeight: "bold" }}
                    className="card-title"
                  >
                    {item.Description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          previousClassName={"previous"}
          nextClassName={"next"}
          disabledClassName={"disabled"}
        />
      </div>

      <Footer />
    </>
  );
}

export default ImageList;
