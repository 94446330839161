import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";
import DepartmentIndustryCards from "../component/DepartmentIndustryCards";

export default class MBAIndustry extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Master in Business Administration(MBA)" image="banners/department-mba.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Industry Interactions</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-12" style={{ margin: "auto" }}>
                                                <p className="justify">Industry interaction is essential for enhancing a student’s interpersonal skills and making him/her industry ready. It is regarded as a part of the curriculum as it gives insight into the regular working of a company. Interactions with industry through expert talk, seminars, conferences etc. helps the student’s to tackle the doubts that they have about the industry. Industrial visit, internships etc. have their own importance in building a career of a student, also benefited for better placement of students and utilization of expertise and improves the quality of faculty.</p>
                                            </div>
                                            <div className="col-md-6 col-lg-12 paddingtop-1">
                                                <div className="section-title with-desc text-left clearfix">
                                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                                        <h5>Objectives</h5>
                                                    </div>
                                                </div>
                                                <ul className="ttm-list ttm-list-style-icon">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To identify and explore common avenues of interaction with industry</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To establish Centre of Excellence by industry</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To establish convergence from various fields through MoU’s as part of interaction</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <DepartmentIndustryCards department="mba" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="mba" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
