import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import ExploreSidebar from './components/ExploreSidebar';

export default class ExploreAbout extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Explore BIT" image="banners/banner-explore.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">About BIT</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/explore/about-bit-1.jpg"} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{margin:"auto"}}>
                                                <p className="about-para">Bangalore Institute of Technology (BIT) was established in the year 1979 with an objective of providing quality education in the ﬁeld of technology and thereby serving the society. It has reached an enviable level of excellence in technical education. This was achieved due to the unconditional support of Rajya Vokkaligara Sangha, committed staff and students. The environment of BIT motivates the student to quench their thirst for knowledge.</p>
                                            </div>
                                            <div className="col-md-12 justify paddingtop-1">
                                                <p className="about-para">The college strives to achieve the all-round development of the students. BIT has always been at the forefront of modern technology and has the distinction of being the ﬁrst College to introduce a full-ﬂedged degree in Computer Science and Engineering in Karnataka. The institute has visualized the areas of future growth and incorporated various courses over the years. At present BIT has 13 undergraduate, 6 postgraduate and 13 research and development centers affiliated to Visvesvaraya Technological University, Approved by All India Council for Technical Education (AICTE), New Delhi, Recognized by University Grants Commission (UGC) as per the act 1956 under 2f and 12b, accredited by NBA (9 UG Programs and MCA) and ranked by National Institutional Ranking Framework (NIRF) (Band: 251-300).</p>
                                            </div>
                                            <div className="col-md-12 justify paddingtop-1">
                                                <p className="about-para">BIT has an intake of 1452 students. BIT has 13 R & D centres, centre of excellence in each department, Incubation, startup, Idea lab, Skill development and other supportive and nurturing centres. These centers help faculty and students to carry out interdisciplinary research and collaborative programs in association with organizations like Bosch, Mistral, UnicornMark, GTURNS Data Consultants Pvt., Ltd and many more. BIT also has the acclaim of being the study center for IGNOU programs. BIT is proud to have 262 qualified teaching faculties. Among these 100 faculty members are with Ph.D. degree and 56 are pursuing. Sixty percent of faculty members have served BIT for more than twenty years. The Institution has 277 efficient and committed teams of supporting staff.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Vision</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description justify">
                                        <p className="tab-desc">Establish and develop the Institute as the Centre of higher learning, ever abreast with expanding horizon of knowledge in the ﬁeld of Engineering and Technology with entrepreneurial thinking, leadership excellence for life-long success and solve societal problems.</p>                                      
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Mission</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description justify">
                                        <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Provide high quality education in the Engineering disciplines from the undergraduate through doctoral levels with creative academic and professional programs.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Develop the Institute as a leader in Science, Engineering, Technology, Management and Research and apply knowledge for the beneﬁt of society.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Establish mutual beneﬁcial partnerships with Industry, Alumni, Local, State and Central Governments by Public Service Assistance and Collaborative Research.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Inculcate personality development through sports, cultural and extracurricular activities and engage in social, economic and professional challenges.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <ExploreSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
