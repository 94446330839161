import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class DepartmentIndustryCards extends Component {
    render() {
        return (
            <div>
                <div className="section-title with-desc text-left clearfix paddingtop-2">
                    <div className="title-header" style={{ borderTopColor: "red" }}>
                        <h4 className="title">Affiliated Industry Details</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 ttm-box-col-wrapper d-flex align-items-stretch" style={{ marginBottom: "1rem" }}>
                        <div className="featured-imagebox featured-imagebox-blog">
                            <div className="featured-thumbnail">
                                <Link className="ttm-btn" style={{ padding: "10px" }} to={process.env.PUBLIC_URL + `/department-industry-mou/${this.props.department}`}>
                                    <div className="post-meta">
                                        <span style={{ fontWeight: "bold", fontSize: "14px", color: "#98002e" }}>MOUs with Industries</span><br />
                                    </div>
                                    <small style={{ color: "grey" }}>keep the students updated about rapid changes and development in industry , BIT is tied up with all sector industries with MOUs.</small>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 ttm-box-col-wrapper d-flex align-items-stretch" style={{ marginBottom: "1rem" }}>
                        <div className="featured-imagebox featured-imagebox-blog">
                            <div className="featured-thumbnail">
                                <Link className="ttm-btn" style={{ padding: "10px" }} to={process.env.PUBLIC_URL + `/department-industry-internship/${this.props.department}`}>
                                    <div className="post-meta">
                                        <span style={{ fontWeight: "bold", fontSize: "14px", color: "#98002e" }}>Internships with Industries</span><br />
                                    </div>
                                    <small style={{ color: "grey" }}>BIT has association with many industries so that students gain relevant knowledge, skills, and experience while establishing important connections in the field.</small>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 ttm-box-col-wrapper d-flex align-items-stretch" style={{ marginBottom: "1rem" }}>
                        <div className="featured-imagebox featured-imagebox-blog">
                            <div className="featured-thumbnail">
                                <Link className="ttm-btn" style={{ padding: "10px" }} to={process.env.PUBLIC_URL + `/department-industry-labs/${this.props.department}`}>
                                    <div className="post-meta">
                                        <span style={{ fontWeight: "bold", fontSize: "14px", color: "#98002e" }}>Industry Sponsored Labs</span><br />
                                    </div>
                                    <small style={{ color: "grey" }}>ISL are Bridge between BIT students and industry to integrate course curriculum with industry ready technologies there by to enhance and enrich students understanding and get certified in particular fields.</small>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
