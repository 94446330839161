import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import DepartmentAchievers from "../component/DepartmentAchievers";
import DepartmentNumbers from "../component/DepartmentNumbers";
import Footer from "../../../components/common/Footer";
import DepartmentVideo from "../component/DepartmentVideo";

export default class AIAbout extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner
          title="Artificial Intelligence &#38; Machine Learning"
          image="banners/department-ai.jpg"
        />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            {/* row */}
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-single-content-area">
                  <DepartmentAchievers department="ai" />
                  <DepartmentNumbers
                    text1="Number of students on roll"
                    num1="266"
                    text2="Number of faculty"
                    num2="08"
                    text3="Number of faculty with Ph. D"
                    num3="04"
                    text4="Number of publications"
                    num4="65"
                    text5="Activities Conducted"
                    num5="37"
                    text6="Student Achievement"
                    num6="21"
                  />
                  <div className="ttm-service-description">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="ttm_single_image-wrapper">
                          <img
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/departments/ai/about-1.png"
                            }
                            alt="single-img-eighteen"
                          />
                        </div>
                      </div>
                      <div
                        className="col-md-7 justify"
                        style={{ margin: "auto" }}
                      >
                        <p>
                          Artificial Intelligence and Machine Learning (AI & ML)
                          programme is started in the academic year 2020-2021.
                          The course is approved by AICTE and affiliated to VTU
                          with an intake of 60 students. The goal of keeping
                          AI’s impact on societal need motivated to start
                          Artificial Intelligence and Machine Learning
                          programme.
                        </p>
                        <br />
                        <p>
                          AI and ML are playing a transformational role in every
                          sphere of human endeavour and require to be nurtured
                          by talent and an ecosystem of innovation. It is the
                          success mantra of the current digital revolution and
                          is treated as the backbone for future innovations.
                        </p>
                      </div>
                      <div className="col-md-12 paddingtop-2 justify">
                        <p>
                          The main focus is to impart innovative education and
                          enhance technical skills to prepare and empower
                          students in the Artificial Intelligence and Machine
                          Learning era. The department will train students to
                          have good academic track record with professional
                          skills to meet Industrial and Societal challenges with
                          active participation and perseverance. The Research
                          Committee at college level maintains research groups
                          for carrying out collaborative and interdisciplinary
                          research.
                        </p>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
                <DepartmentVideo department="ai" />
                <div className="section-title with-desc text-left clearfix">
                  <div className="section-title with-desc text-left clearfix">
                    <div
                      className="title-header paddingtop-2"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">Vision</h4>
                    </div>
                    <p>
                      To accomplish excellence in education, grounded with
                      ethics and a competent Professional focusing on innovation
                      towards the advancement of society.
                    </p>
                  </div>
                  <div className="section-title with-desc text-left clearfix">
                    <div
                      className="title-header paddingtop-2"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">Mission</h4>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          To impart quality education by disseminating knowledge
                          through teaching &#38; Learning, Research to meet the
                          need of the profession &#38; Society.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          To inculcate the critical problem solving, leadership,
                          team work skills.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          To facilitate effective interaction among alumni,
                          academic institutes, Industries and Other Stake
                          holders{" "}
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {" "}
                          To practice and promote ethical behavior, transparency
                          and respect for others.
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="section-title with-desc text-left clearfix">
                    <div
                      className="title-header paddingtop-2"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">
                        Program Educational Objectives (PEO's)
                      </h4>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          To enhance the students’ analytical skills to enable
                          them to understand real world problems and formulate
                          solutions.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          To prepare students to meet the industrial
                          requirements at global level competitiveness or to
                          take up higher studies.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          To allow students to work in teams through group
                          project works and thus help them achieve interpersonal
                          and communication skills.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          To inculcate the habit of lifelong learning, adherence
                          to ethics in profession, concern for environmental and
                          regard for good professional practices.
                        </span>
                      </li>
                    </ul>
                    <div
                      className="title-header paddingtop-2"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">Program Outcomes</h4>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Engineering knowledge: Apply the knowledge of
                          mathematics, science, engineering fundamentals, and an
                          engineering specialization to the solution of complex
                          engineering problems.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Problem analysis: Identify, formulate, review research
                          literature, and analyze complex engineering problems
                          reaching substantiated conclusions using first
                          principles of mathematics, natural sciences, and
                          engineering sciences.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Design/development of solutions: Design solutions for
                          complex engineering problems and design system
                          components or processes that meet the specified needs
                          with appropriate consideration for the public health
                          and safety, and the cultural, societal, and
                          environmental considerations.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Conduct investigations of complex problems: Use
                          research-based knowledge and research methods
                          including design of experiments, analysis and
                          interpretation of data, and synthesis of the
                          information to provide valid conclusions.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Modern tool usage: Create, select, and apply
                          appropriate techniques, resources, and modern
                          engineering and IT tools including prediction and
                          modelling to complex engineering activities with an
                          understanding of the limitations.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          The engineer and society: Apply reasoning informed by
                          the contextual knowledge to assess societal, health,
                          safety, legal and cultural issues and the consequent
                          responsibilities relevant to the professional
                          engineering practice.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Environment and sustainability: Understand the impact
                          of the professional engineering solutions in societal
                          and environmental contexts, and demonstrate the
                          knowledge of, and need for sustainable development.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Ethics: Apply ethical principles and commit to
                          professional ethics and responsibilities and norms of
                          the engineering practice.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Individual and team work: Function effectively as an
                          individual, and as a member or leader in diverse
                          teams, and in multidisciplinary settings.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {" "}
                          Communication: Communicate effectively on complex
                          engineering activities with the engineering community
                          and with society at large, such as, being able to
                          comprehend and write effective reports and design
                          documentation, make effective presentations, and give
                          and receive clear instructions.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Project management and finance: Demonstrate knowledge
                          and understanding of the engineering and management
                          principles and apply these to one’s own work, as a
                          member and leader in a team, to manage projects and in
                          multidisciplinary environments.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          {" "}
                          Life-long learning: Recognize the need for, and have
                          the preparation and ability to engage in independent
                          and life-long learning in the broadest context of
                          technological change.
                        </span>
                      </li>
                    </ul>
                    <div
                      className="title-header paddingtop-2"
                      style={{ borderTopColor: "red" }}
                    >
                      <h4 className="title">Program Specific Outcomes</h4>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon">
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Graduates will continue their education in leading
                          graduate programs in engineering & interdisciplinary
                          areas to emerge as researchers, experts, educators &
                          entrepreneurs.
                        </span>
                      </li>
                      <li>
                        <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                        <span className="ttm-list-li-content">
                          Graduates will be able to recognize the need for, and
                          an ability to engage in life-long learning and apply
                          the gained knowledge to improve the society ensuring
                          ethical and moral values.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="ai" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
