import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class EIEConsultancy extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner
          title="Electronics and Instrumentation Engineering"
          image="banners/department-eie.jpg"
        />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                    <h4 className="title">Consultancy Services:</h4>
                  </div>
                </div>
                <p>Consultancy by an engineering faculty member of an institution undertakes consulting assignments to provide professional or technical solutions to specific fields. The consultancy provides an opportunity for the engineering faculty to share their insights for real-world problems. Towards Consultancy the department of Electronics and Instrumentation Engineering has initiated Industry-Institute collaboration with Kharya Solutions.</p><br/>
                <ul className="ttm-list ttm-list-style-icon">
                  <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Conduction of Certificate and Training courses for internal and external students.</span></li>
                  <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">IPR: Obtain patents for novel projects and convert them as products.</span></li>
                  <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Software development for academic / admission /administrative work.</span></li>
                  <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Conduction of Vocational, Skill development program using central government funds.</span></li>
                </ul>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="eie" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
