import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
    .event-area {
        background: ${colors.bg2};
        padding   : 30px 0;

        .sec-title {
            a {
                color       : ${colors.bg3};
                font-weight : bold;
                padding-top : 1rem;
                overflow    : hidden;
                display     : -webkit-box;
                -webkit-line-clamp: 2;
                        line-clamp: 2; 
                -webkit-box-orient: vertical;

                @media(max-width: 575px) {
                    margin-bottom: 15px;
                    font-size: 20px;
                }
            }

            a: hover {
                color       : ${colors.yellow};
            }

            p {
                color        : ${colors.text1};
                padding-top  : 1rem;
                overflow    : hidden;
                display     : -webkit-box;
                -webkit-line-clamp: 3;
                        line-clamp: 3; 
                -webkit-box-orient: vertical;

                @media(max-width: 575px) {
                    margin-bottom: 15px;
                    font-size: 20px;
                }
            }
        }

        .date {
            color        : ${colors.text3};
            font-weight  : bold;
            padding-top  : 0.5rem;
        }
    }
`;