import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import DepartmentAchievers from "../component/DepartmentAchievers";
import DepartmentNumbers from "../component/DepartmentNumbers";
import DepartmentVideo from "../component/DepartmentVideo";
import Footer from "../../../components/common/Footer";

export default class ECEAbout extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Electronics and Communication Engineering" image="banners/department-ece.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <DepartmentAchievers department="ece" />
                                    <DepartmentNumbers
                                        text1="Number of students on roll"
                                        num1="621"

                                        text2="Number of Faculty"
                                        num2="35"

                                        text3="Number of Faculty with Ph. D"
                                        num3="14"

                                        text4="Number of ranks since inception"
                                        num4="62"

                                        text5="Number of graduated batches"
                                        num5="39"

                                        text6="Average Placement (%) in last 3 years"
                                        num6="86"
                                    />
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/departments/ece/about-1.png"} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <p>Electronics and Communication Engineering department was established in the year 1979 with an intake of 40 students for Bachelor of Engineering (BE) program which was affiliated to Bangalore University. Subsequently the department of Electronics and Communication Engineering of Bangalore Institute of Technology was affiliated to Visvesvaraya Technological University, Belgaum during the year 1998. Subsequently the department added to its credit the PG Programs VLSI Design and Embedded systems in the year 2008-09, Digital Electronics and Communication in the year 2013-14 and VTU recognized research Program in the year 2012-13.</p>
                                            </div>
                                            <div className="col-md-12 justify paddingtop-2">Currently, the department is offering Under Graduate program Bachelor of Engineering in Electronics and Communication Engineering with an intake of 180 students, Post Graduate programs namely Master of Technology (M.Tech) in VLSI Design and Embedded systems with an intake of 18 students, Master of Technology (M.Tech) in Digital Electronics and Communication with an intake of 24 students and also offers Research Program under VTU, BIT ECE Research center.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <DepartmentVideo department="ece" />
                                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Vision</h4>
                                    </div>
                                    <p>Imparting   Quality  Education  to achieve Academic Excellence in Electronics and Communication Engineering for Global Competent Engineers.</p>
                                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Mission</h4>
                                    </div>
                                    <ul className="ttm-list ttm-list-style-icon justify">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Create state of art infrastucutre for quality education.</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Nurture innovative concepts and problem solving skills.</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Delivering Professional Engineers to meet the societal needs.</span></li>
                                    </ul>
                                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Program Educational Objectives (PEO's)</h4>
                                    </div>
                                    <ul className="ttm-list ttm-list-style-icon">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Prepare graduates to be Professionals, Practicing Engineers and entrepreneurs the field of Electronics and Communication Engineering.</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To acquire sufficient knowledge base for innovative techniques innovative techniques   in design and development of systems.</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Capable of competing globally in multidisciplinary field.</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Achieve personal and professional success with awareness and commitment to ethical and social responsibilities as an individual as well as a team.</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Graduates will maintain and improve technical competence through continuous learning process.</span></li>
                                    </ul>
                                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Program Outcomes</h4>
                                    </div>
                                    <ul className="ttm-list ttm-list-style-icon">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><b>ENGINEERING KNOWLEDGE:</b> Apply the knowledge of mathematics, science, engineering fundamentals, and an engineering specialization to the solution of complex engineering problems</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><b>PROBLEM ANALYSIS:</b> Identify, formulate, review research literature, and analyze complex engineering problems reaching substantiated conclusions using first principles of mathematics, natural sciences, and engineering sciences</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><b>DESIGN/DEVELOPMENT OF SOLUTIONS:</b> Design solutions for complex engineering problems and design system components or processes that meet the specified needs with appropriate consideration for the public health and safety, and the cultural, societal, and environmental considerations</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><b>CONDUCT INVESTIGATIONS OF COMPLEX PROBLEMS:</b> Use research-based knowledge and research methods including design of experiments, analysis and interpretation of data, and synthesis of the information to provide valid conclusions</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><b>MODERN TOOL USAGE:</b> Create, select, and apply appropriate techniques, resources, and modern engineering and IT tools including prediction and modelling to complex engineering activities with an understanding of the limitation</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><b>THE ENGINEER AND SOCIETY:</b> Apply reasoning informed by the contextual knowledge to assess societal, health, safety, legal and cultural issues and the consequent responsibilities relevant to the professional engineering practice</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><b>ENVIRONMENT AND SUSTAINABILITY:</b> Understand the impact of the professional engineering solutions in societal and environmental contexts, and demonstrate the knowledge of, and need for sustainable development.</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><b>ETHICS:</b> Apply ethical principles and commit to professional ethics and responsibilities and norms of the engineering practice.</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><b>INDIVIDUAL AND TEAM WORK:</b> Function effectively as an individual, and as a member or leader in diverse teams, and in multidisciplinary settings</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><b>COMMUNICATION:</b> Communicate effectively on complex engineering activities with the engineering community and with society at large, such as, being able to comprehend and write effective reports and design documentation, make effective presentations, and give and receive clear instructions.</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><b>PROJECT MANAGEMENT AND FINANCE:</b> Demonstrate knowledge and understanding of the engineering and management principles and apply these to one’s own work, as a member and leader in a team, to manage projects and in multidisciplinary environments.</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><b>LIFE-LONG LEARNING:</b> Recognize the need for, and have the preparation and ability to engage in independent and life-long learning in the broadest context of technological change.</span></li>
                                    </ul>
                                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Program Specific Outcomes</h4>
                                    </div>
                                    <ul className="ttm-list ttm-list-style-icon">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><b>PSO1:</b>	The graduates will be able to apply the Principles of Electronics and Communication Engineering in core areas.</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"> <b>PSO2:</b>An ability to use the latest hardware and software tools in Electronics and Communication Engineering</span></li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><b>PSO3:</b> Preparing Graduates to satisfy industrial needs and pursue higher studies with social awareness and universal moral values.</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="ece" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Footer */}
                <Footer />
            </div>
        )
    }
}