import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class IEMContact extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Industrial Engineering and Management" image="banners/department-iem.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header"><h4 className="title">Contact</h4></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                    <i className="fa fa-phone" />
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>HOD</h6></div>
                                                <div className="featured-desc">
                                                    <p>Dr. Davy George Valavi</p>
                                                    <p>Contact Number: +91 9845383302</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle"><i className="fa fa-phone" /></div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Women Grievances</h6></div>
                                                <div className="featured-desc">
                                                    <p>Preethi K H</p>
                                                    <p>Contact Number: +91 9632996630</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle"><i className="fa fa-phone" /></div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Admission Fees And Library Facilty</h6></div>
                                                <div className="featured-desc">
                                                    <p>Dr. A P Partha</p>
                                                    <p>Contact Number: +91 9448074436</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 paddingtop-2">
                                        <div className="featured-icon-box style2 left-icon icon-align-top">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle"><i className="fa fa-phone" /></div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title"><h6>Exam Application And Revaluation Process</h6></div>
                                                <div className="featured-desc">
                                                    <p>Sunil R</p>
                                                    <p>Contact Number: +91 8050935098</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="iem" />
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}

