import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import AdmissionSidebar from './components/AdmissionSidebar';

export default class Admission extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Admission" image="banners/banner-admission.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Bachelor of Engineering (BE) - Duration 4 years</h4>
                                        </div>
                                        <div className="ttm-service-description">
                                            <table class="table" style={{ textAlign: "center" }}>
                                                <thead style={{ backgroundColor: "#98002e", color: "white" }}>
                                                    <tr>
                                                        <th scope="col">Sl No</th>
                                                        <th scope="col" style={{ width: "30%" }}>Branches</th>
                                                        <th scope="col">Intake</th>
                                                        <th scope="col">CET (45%)</th>
                                                        <th scope="col">ComedK (30%)</th>
                                                        <th scope="col">Management (25%)</th>
                                                        <th scope="col">SNQ (5%)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>1</th>
                                                        <td style={{ textAlign: "left" }}>Civil Engineering</td>
                                                        <td>120</td>
                                                        <td>54</td>
                                                        <td>36</td>
                                                        <td>30</td>
                                                        <td>06</td>
                                                    </tr>
                                                    <tr>
                                                        <th>2</th>
                                                        <td style={{ textAlign: "left" }}>Mechanical Engineering</td>
                                                        <td>120</td>
                                                        <td>54</td>
                                                        <td>36</td>
                                                        <td>30</td>
                                                        <td>06</td>
                                                    </tr>
                                                    <tr>
                                                        <th>3</th>
                                                        <td style={{ textAlign: "left" }}>Electrical and Electronics Engineering</td>
                                                        <td>60</td>
                                                        <td>27</td>
                                                        <td>18</td>
                                                        <td>15</td>
                                                        <td>03</td>
                                                    </tr>
                                                    <tr>
                                                        <th>4</th>
                                                        <td style={{ textAlign: "left" }}>Electronics &#38; Communication Engineering</td>
                                                        <td>180</td>
                                                        <td>81</td>
                                                        <td>54</td>
                                                        <td>45</td>
                                                        <td>09</td>
                                                    </tr>
                                                    <tr>
                                                        <th>5</th>
                                                        <td style={{ textAlign: "left" }}>Computer Science &#38; Engineering</td>
                                                        <td>240</td>
                                                        <td>108</td>
                                                        <td>72</td>
                                                        <td>60</td>
                                                        <td>12</td>
                                                    </tr>
                                                    <tr>
                                                        <th>6</th>
                                                        <td style={{ textAlign: "left" }}>Electronics &#38; Instrumentation Engineering</td>
                                                        <td>60</td>
                                                        <td>27</td>
                                                        <td>18</td>
                                                        <td>15</td>
                                                        <td>03</td>
                                                    </tr>
                                                    <tr>
                                                        <th>7</th>
                                                        <td style={{ textAlign: "left" }}>Electronics &#38; Telecommunication Engineering</td>
                                                        <td>60</td>
                                                        <td>27</td>
                                                        <td>18</td>
                                                        <td>15</td>
                                                        <td>03</td>
                                                    </tr>
                                                    <tr>
                                                        <th>8</th>
                                                        <td style={{ textAlign: "left" }}>Information Science &#38; Engineering</td>
                                                        <td>180</td>
                                                        <td>81</td>
                                                        <td>54</td>
                                                        <td>45</td>
                                                        <td>09</td>
                                                    </tr>
                                                    <tr>
                                                        <th>9</th>
                                                        <td style={{ textAlign: "left" }}>Artificial Intelligence and Machine Learning</td>
                                                        <td>60</td>
                                                        <td>27</td>
                                                        <td>18</td>
                                                        <td>15</td>
                                                        <td>03</td>
                                                    </tr>
                                                    <tr>
                                                        <th>10</th>
                                                        <td style={{ textAlign: "left" }}>Computer Science & Engineering (Internet of Things & Cyber Security including Blockchain Technology)</td>
                                                        <td>60</td>
                                                        <td>27</td>
                                                        <td>18</td>
                                                        <td>15</td>
                                                        <td>03</td>
                                                    </tr>
                                                    <tr>
                                                        <th>11</th>
                                                        <td style={{ textAlign: "left" }}>Computer Science & Engineering (Data Science)</td>
                                                        <td>60</td>
                                                        <td>27</td>
                                                        <td>18</td>
                                                        <td>15</td>
                                                        <td>03</td>
                                                    </tr>
                                                    <tr>
                                                        <th>12</th>
                                                        <td style={{ textAlign: "left" }}>Robotics & Artificial Intelligence</td>
                                                        <td>60</td>
                                                        <td>27</td>
                                                        <td>18</td>
                                                        <td>15</td>
                                                        <td>03</td>
                                                    </tr>
                                                    <tr>
                                                        <th>13</th>
                                                        <td style={{ textAlign: "left" }}>Electronics Engineering (VLSI Design & Technology)</td>
                                                        <td>60</td>
                                                        <td>27</td>
                                                        <td>18</td>
                                                        <td>15</td>
                                                        <td>03</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ backgroundColor: "#98002e", color: "white" }}></th>
                                                        <th style={{ backgroundColor: "#98002e", color: "white", textAlign: "right" }}>TOTAL</th>
                                                        <th style={{ backgroundColor: "#98002e", color: "white" }}>1320</th>
                                                        <th style={{ backgroundColor: "#98002e", color: "white" }}>594</th>
                                                        <th style={{ backgroundColor: "#98002e", color: "white" }}>396</th>
                                                        <th style={{ backgroundColor: "#98002e", color: "white" }}>330</th>
                                                        <th style={{ backgroundColor: "#98002e", color: "white" }}>66</th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description paddingtop-2">
                                        <div className="section-title with-desc text-left clearfix">
                                            <div className="title-header" style={{ borderTopColor: "red" }}>
                                                <h4 className="title">Admission Process and Eligibility</h4>
                                            </div>
                                        </div>
                                        <p className="justify">The admission to First year BE courses in BIT is through three modes viz. KCET, Comed-K and Management quota. The lateral entry admission of Diploma students to Second year BE is only through Diploma KCET.</p><br />
                                        <div className="section-title with-desc text-left clearfix">
                                            <div className="title-header" style={{ borderTopColor: "red" }}>
                                                <h5 className="title">Eligibility: General Merit Candidates</h5>
                                            </div>
                                        </div>
                                        <p className="justify">Candidates seeking admission to the first year of the four years B.E. degree course should have passed two years of P.U.C. / 12th examinations conducted by the Pre-University Board of Karnataka State or any other examination recognized as equivalent by the Visvesvaraya Technology University.  Student should have obtained a minimum of 45% of aggregate marks in Physics  and Mathematics as compulsory subjects along with  Chemistry / Bio-Technology / Biology / Computer Science / Electronics as optional subjects in the qualifying examination.</p><br />
                                        <div className="section-title with-desc text-left clearfix">
                                            <div className="title-header" style={{ borderTopColor: "red" }}>
                                                <h5 className="title">Eligibility: SC/ST &#38; OBC (Cat-I, 2A, 2B, 3A 3B) Candidates</h5>
                                            </div>
                                        </div>
                                        <p className="justify">Candidates seeking admission to the first year of the four years B.E. degree course should have passed two years of P.U.C. / 12th examinations conducted by the Pre-University Board of Karnataka State or any other examination recognized as equivalent by the Visvesvaraya Technology University.  Student should have obtained a minimum of 40% of aggregate marks in Physics and Mathematics as compulsory subjects along with Chemistry / Bio Technology / Biology / Computer Science / Electronics as optional subjects in the qualifying examination.</p><br />
                                        <p className="justify"><b>For admission and eligibility criteria for all the UG programs visit to university website <a href="https://vtu.ac.in/" target="_blank">https://vtu.ac.in/</a></b></p>
                                    </div>
                                    <div className="ttm-service-description paddingtop-2">
                                        <div className="section-title with-desc text-left clearfix">
                                            <div className="title-header" style={{ borderTopColor: "red" }}>
                                                <h4 className="title">Legal Disclaimer</h4>
                                            </div>
                                        </div>
                                        <p className="justify">To resolve any disputes, Bengaluru Courts alone shall have the Jurisdiction.</p>
                                    </div>
                                </div>
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Documents to be submitted</h4>
                                    </div>
                                    <div className="ttm-service-description">
                                        <table class="table">
                                            <thead style={{ backgroundColor: "#98002e", color: "white" }}>
                                                <tr>
                                                    <th scope="col" style={{ width: "5%" }}>Sl No</th>
                                                    <th scope="col" style={{ width: "40%" }}>Particulars</th>
                                                    <th scope="col" style={{ width: "10%" }}>Original</th>
                                                    <th scope="col" style={{ width: "13%" }}>No of copies / Set</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th>1</th>
                                                    <td>Original Marks Cards of 10th and 12th (+2) standard</td>
                                                    <td>01</td>
                                                    <td>06</td>
                                                </tr>
                                                <tr>
                                                    <th>2</th>
                                                    <td>Transfer Certificate and  Study Certificate</td>
                                                    <td>01</td>
                                                    <td>06</td>
                                                </tr>
                                                <tr>
                                                    <th>3</th>
                                                    <td>Migration Certificate (For Non Karnataka Students only)</td>
                                                    <td>01</td>
                                                    <td>06</td>
                                                </tr>
                                                <tr>
                                                    <th>4</th>
                                                    <td>Aadhar card</td>
                                                    <td> - - </td>
                                                    <td>06</td>
                                                </tr>
                                                <tr>
                                                    <th>5</th>
                                                    <td>3 Passport Size Photographs</td>
                                                    <td> - - </td>
                                                    <td>06</td>
                                                </tr>
                                                <tr>
                                                    <th>6</th>
                                                    <td>CET or COMED-K allotment letter</td>
                                                    <td>01</td>
                                                    <td>06</td>
                                                </tr>
                                                <tr>
                                                    <th>7</th>
                                                    <td>Caste &#38; Income Certificate (For CET Students only)</td>
                                                    <td>01</td>
                                                    <td>06</td>
                                                </tr>
                                                <tr>
                                                    <th>8</th>
                                                    <td>Undertaking form in Rs. 100/- bond paper by both student & parent/guardian signed by notary.</td>
                                                    <td>01</td>
                                                    <td>06</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Admission contact information</h4>
                                    </div>
                                    <div className="ttm-pf-single-detail-box mb-35">
                                        <ul className="ttm-pf-detailbox-list">
                                            <li><i className="fa fa-user" /><span> Principal - Bangalore Institute of Technology - K.R. Road, V V Pura - Bangalore-560004</span></li>
                                            <li><i className="fas fa-envelope" /><span> Mail-ID : </span>admission@bit-bangalore.edu.in</li>
                                            <li><i className="fas fa-phone" /><span> Contact Number : </span>+91-9448512984, +91-9448512954</li>
                                        </ul>
                                    </div>
                                    <div className="ttm-pf-single-detail-box mb-35">
                                        <h5>For Admission queries please call during working hours</h5>
                                        <ul className="ttm-pf-detailbox-list">
                                            <li><h6><span> Working Hours :</span></h6></li>
                                            <li><i className="fas fa-caret-right" /><span> Monday to Fiday </span>9.00 AM - 5.00 PM / <span> Saturday</span>9.00 AM - 2.00 PM </li>
                                        </ul>
                                    </div>
                                    <div className="ttm-pf-single-detail-box mb-35" style={{ textAlign: "center" }}>
                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white" href={`${process.env.PUBLIC_URL + "/assets/pdf/Admission/BE.pdf"}`} target="_blank">
                                            <h5>Download Application form for UG Program Admission</h5>
                                        </a>
                                    </div>
                                    <div className="ttm-pf-single-detail-box mb-35" style={{ textAlign: "center" }}>
                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white" href="https://docs.google.com/forms/d/e/1FAIpQLSdd9ll2w4wV9frUaiVsq-WYUAcG8R5fw1FnFp4oc7zJD4quOQ/viewform" target="_blank">
                                            <h5>Click Here for Online registration</h5>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <AdmissionSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}