import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import LifeAtBitSidebar from './components/LifeAtBitSidebar';

export default class lifeatBitCampus extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Life@BIT" image="banners/banner-lifebit.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">BIT Campus</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifebit/bit-campus.jpg"} alt="Error" />
                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <h4 className="res-767-mt-20"></h4>
                                                <p className="justify">BIT is situated at the center of Bengaluru and has a beautiful campus spread over 5.3 acres. It is well connected by road and metro. The campus is very close to K R market, Tippu palace, Gandhi bazar and Basavanagudi - some of the historically important areas in Bengaluru. Campus life is extremely important in students’ life. Culture, art & performances, sports etc. bring out the talents of the students. In view of this, BIT is having dedicated cultural team and well established sports department. Canteen, hostel, hospital, bank with ATM, Xerox center, BIT employees co-operative Society(where students can buy the stationery and text books at discounted price) and parking space are</p>
                                            </div>
                                        </div>
                                        <div className="row paddingtop-1">
                                            <div className="col-md-12">
                                                <p className="justify">the components of BIT campus. Kuvempu kalakshetra, a multipurpose auditorium with seating capacity of about 800 is also a part of BIT campus.</p>
                                            </div>
                                        </div>
                                        <div className="row paddingtop-2">
                                            <div className="col-md-12 section-title with-desc text-left clearfix">
                                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                                    <h5 className="title">Campus Discipline</h5>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <ul className="ttm-list ttm-list-style-icon justify">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"> Wear ID card issued by the college.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"> Ragging is a criminal offence and not permitted in any form. If anybody indulge in such activities legal action will be initiated.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"> Involving in any anti-social activities, any act or deed which will defame the state, nation and institution are severely punishable.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"> Eve teasing, any act to defame the dignity of girls (physically or verbally) smoking, consumption or distribution of alcoholic drinks or any kind of hallucinogenic drugs, etc. invite the legal punishment as per the rules and regulations of the government.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"> Any other activities such as unauthorized fund rising, damage to the institution property considered by the college as of gross indiscipline are punishable.</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row paddingtop-2">
                                        <div className="col-md-4">
                                            <div className="ttm_single_image-wrapper">
                                                <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifebit/bit-campus-1.jpg"} alt="Error" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="ttm_single_image-wrapper">
                                                <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifebit/bit-campus-2.jpg"} alt="Error" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="ttm_single_image-wrapper">
                                                <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifebit/bit-campus-3.jpg"} alt="Error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <LifeAtBitSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
