import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loading from '../../components/common/Loading';
import NoData from '../../components/common/NoData';
import InnovationSidebar from './components/InnovationSidebar';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../Config/FbConfig';
import { Modal } from 'react-bootstrap';
import { Styles } from "./style/InnovationStyle.js";

export default class InnovationIPRCell extends Component {

    state = {
        displayModal: false,
        modalImage: "",
        modalDescription: "",
        modalDate: "",
        modalVideo: "",
        Datas: []
    }

    //Display Modal
    displayModal = (e) => {
        this.setState({
            displayModal: !this.state.displayModal,
        })
    }

    //Display Content
    contentModal = (e) => {
        var result = this.state.Datas.filter((x) => x.Id === e.target.id);
        this.setState({
            displayModal: !this.state.displayModal,
            modalImage: result[0]["Image"],
            modalDescription: result[0]["Description"],
            modalDate: result[0]["Year"],
            modalVideo: result[0]["Video"]
        })
    }

    //Load Data Here
    async componentDidMount() {
        const docRef = doc(firestore, "Innovation", "IPR");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key }, value))
            });
            this.setState({
                Datas: Data
            })
        } else {
            this.setState({
                Datas: "NO_999"
            })
        }
    }

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Innovation" image="banners/banner-achievers.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">About - IPR Cell</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/innovation/ipr-1.jpg"} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <ul className="ttm-list ttm-list-style-icon">
                                                    <li>Intellectual property plays an important role in providing a competitive edge to an organization. The intangible assets of an organization - such as knowhow, inventions, brands, designs and other creative and innovative products - are, today, often more valuable than its physical assets.</li>
                                                    <li>Intellectual Property Rights (IPR) plays a key role in gaining an advantageous position in these days of competition for technology advancement and consequent economic growth.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">OBJECTIVES</h5>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <ul className="ttm-list ttm-list-style-icon">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To promote academic freedom and safeguard in creation of intellectual property at the Institute.</li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To help in introducing prudent IP management practices within the Institute to promote an IPR culture.</li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To provide legal support, wherever necessary, to defend and protect the intellectual property rights obtained by the Institute against any infringement/unauthorised use.</li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To create an environment for acquiring new knowledge through innovation and research, compatible with the educational mission of the Institute.</li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To safeguard the interest of creator of intellectual property and provide fair distribution of returns accruing from the commercialisation of IPR.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Constitution - Faculty Exceutive Committee</h5>
                                        </div>
                                        <table className='table' style={{ margin: "auto" }}>
                                            <thead style={{ backgroundColor: "#98002e", color: "white", margin: "auto", }}>
                                                <tr>
                                                    <th scope="col" style={{ width: "8%" }}>Sl No</th>
                                                    <th scope="col" style={{ width: "40%" }}>Faculty Name</th>
                                                    <th scope="col" style={{ width: "20%" }}>Department</th>
                                                    <th scope="col" style={{ width: "20%" }}>Role</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr><th scope="row">1</th><td>DR. M. U. ASWATH</td><td>PRINCIPAL</td><td>CHAIRMAN</td></tr>
                                                <tr><th scope="row">2</th><td>DR K M ROOPA</td><td>MATHS</td><td>COORDINATOR</td></tr>
                                                <tr><th scope="row">3</th><td>EXECUTIVE SECRETARY</td><td>KSCST</td><td>MEMBER</td></tr>
                                                <tr><th scope="row">4</th><td>IP ATTORNEY &amp; CONSULTANT</td><td>KSCST</td><td>MEMBER</td></tr>
                                                <tr><th scope="row">5</th><td>DR.VATHSALA</td><td>CIVL</td><td>MEMBER</td></tr>
                                                <tr><th scope="row">6</th><td>PROF .S.S. VIDYA</td><td>EIE</td><td>MEMBER</td></tr>
                                                <tr><th scope="row">7</th><td>DR.MADHUSUDHAN.M</td><td>MECH</td><td>MEMBER</td></tr>
                                                <tr><th scope="row">8</th><td>PROF.MAHESH CHANDRA</td><td>CIVIL</td><td>MEMBER</td></tr>
                                                <tr><th scope="row">9</th><td>DR. N. JEEVAN</td><td>CIVIL</td><td>MEMBER</td></tr>
                                                <tr><th scope="row">10</th><td>DR. D.G.JYOTHI</td><td>AIML</td><td>MEMBER</td></tr>
                                                <tr><th scope="row">11</th><td>DR. T.VIJAY KUMAR</td><td>MCA</td><td>MEMBER</td></tr>
                                                <tr><th scope="row">12</th><td>DR. NAVANEETH NATARAJ</td><td>ECE</td><td>MEMBER</td></tr>
                                                <tr><th scope="row">13</th><td>DR. JALAJA.S</td><td>ECE</td><td>MEMBER</td></tr>
                                                <tr><th scope="row">14</th><td>DR.H.B.NAGESH</td><td>EEE</td><td>MEMBER</td></tr>
                                                <tr><th scope="row">15</th><td>DR.ASWATHAPPA.P</td><td>EIE</td><td>MEMBER</td></tr>
                                                <tr><th scope="row">16</th><td>PROF.ANUPAMA.K.C</td><td>ISE</td><td>MEMBER</td></tr>
                                                <tr><th scope="row">17</th><td>DR.K.V.DEEPAK</td><td>MBA</td><td>MEMBER</td></tr>
                                                <tr><th scope="row">18</th><td>PROF H.K.MADHU</td><td>MCA</td><td>MEMBER</td></tr>
                                                <tr><th scope="row">19</th><td>DR.REDDAPPA.H.N.</td><td>ME</td><td>MEMBER</td></tr>
                                                <tr><th scope="row">20</th><td>DR.A.CHANDRASHEKAR</td><td>ME</td><td>MEMBER</td></tr>
                                                <tr><th scope="row">21</th><td>DR.M.RAJESHWARI</td><td>TCE</td><td>MEMBER</td></tr>
                                                <tr><th scope="row">22</th><td>DR. CHALUVARAJU.S.V</td><td>PHY</td><td>MEMBER</td></tr>
                                                <tr><th scope="row">23</th><td>DR.DHANANJAYA.M</td><td>MATHS</td><td>MEMBER</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Events - IPR Cell</h5>
                                        </div>
                                    </div>
                                    <Styles>
                                        <div className="row ttm-service-description paddingtop-1">
                                            {
                                                this.state.Datas.length === 0 ? <Loading /> :
                                                    this.state.Datas === "NO_999" ? <NoData /> :
                                                        this.state.Datas.map((data, i) => (
                                                            <div className="col-md-4 ttm-box-col-wrapper d-flex align-items-stretch" key={i} style={{ marginBottom: "1rem" }}>
                                                                <div className="featured-imagebox featured-imagebox-blog">
                                                                    <div className="featured-thumbnail">
                                                                        <a onClick={this.contentModal}><img id={data.Id} className="img-fluid" alt="" src={data.Image} /></a>
                                                                    </div>
                                                                    <div className="featured-content">
                                                                        <div className="ttm-box-post-date">
                                                                            <span className="ttm-entry-date">
                                                                                <time className="entry-date">{data.Year}</time>
                                                                            </span>
                                                                        </div>
                                                                        <div className="featured-desc justify">
                                                                            <p>{data.Description}</p>
                                                                        </div>
                                                                        {
                                                                            data.Video != undefined ?
                                                                                <div className="post-meta" style={{ marginBottom: "-1rem" }}>
                                                                                    <a target="_blank" href={data.Video}><span className="ttm-meta-line" style={{ color: "gray" }}><i className="fa fa-link" />View Details</span></a>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                            }
                                        </div>
                                    </Styles>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <InnovationSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal */}
                <Modal size="lg" show={this.state.displayModal} onHide={this.displayModal} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-5 featured-thumbnail">
                                <img className="img-fluid" alt="" src={this.state.modalImage} />
                            </div>
                            <div className="col-7" >
                                <span className="ttm-meta-line"><b>Year : </b>{this.state.modalDate}</span>
                                <div className="featured-desc justify paddingtop-1"><p>{this.state.modalDescription}</p></div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
 