import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import AdmissionSidebar from './components/AdmissionSidebar';

export default class MBA extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Admission" image="banners/banner-admission.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Seats available</h4>
                                        </div>
                                        <div className="ttm-service-description">
                                            <table class="table">
                                                <thead style={{ backgroundColor: "#98002e", color: "white" }}>
                                                    <tr>
                                                        <th style={{ width: "10%" }}>Sl No</th>
                                                        <th style={{ width: "40%" }}>Branches</th>
                                                        <th style={{ width: "10%" }}>Total Intake</th>
                                                        <th style={{ width: "10%" }}>PGCET</th>
                                                        <th style={{ width: "10%" }}>Management</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>1</th>
                                                        <td>MBA</td>
                                                        <td>120</td>
                                                        <td>60</td>
                                                        <td>60</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <div className="ttm-service-description paddingtop-2 justify">
                                        <div className="section-title with-desc text-left clearfix">
                                            <div className="title-header" style={{ borderTopColor: "red" }}>
                                                <h4 className="title">Admission process and eligibility</h4>
                                            </div>
                                        </div>
                                        <p>Procedure for Admission through PGCET and Management Quota. Entrance Exam: KMAT / CMAT / MAT/ PGCET – any one mandatory </p><br/>
                                        <p>Candidates seeking admission should have cleared qualifying examination which being any recognized Bachelor’s Degree with minimum of 3 years duration or equivalent examination and obtained 5.75 CGPA (for General Merit Candidates), is eligible for admission to full time MBA course and 5.25 CGPA in qualifying examination case of SC, ST and Category-I of Karnataka Candidates.</p>
                                    </div>
                                </div>
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Following Documents are requested to be submitted during admission </h4>
                                    </div>
                                    <div className="ttm-pf-single-detail-box mb-35">
                                        <ul className="ttm-pf-detailbox-list">
                                            <li><i className="fa fa-caret-right" /><span>PGCET Allotment Order four photocopies</span></li>
                                            <li><i className="fas fa-caret-right" /><span>10th, 12th &#38; All Degree Original Marks Cards and three Photocopies </span></li>
                                            <li><i className="fas fa-caret-right" /><span>PDC Certificate and three Photocopies</span></li>
                                            <li><i className="fas fa-caret-right" /><span>Transfer Certificate and three Photocopies</span></li>
                                            <li><i className="fas fa-caret-right" /><span>Aadhar Card three photocopies </span></li>
                                            <li><i className="fas fa-caret-right" /><span>Three Passport Size Photos </span></li>
                                            <li><i className="fas fa-caret-right" /><span>Income &#38; Caste Certificate three Photocopies </span></li>
                                            <li><i className="fas fa-caret-right" /><span>DD in Favor of PRINCIPAL BIT </span></li>
                                        </ul>
                                    </div>
                                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Contact information</h4>
                                    </div>
                                    <div className="ttm-pf-single-detail-box mb-35">
                                        <ul className="ttm-pf-detailbox-list">
                                            <li><i className="fa fa-user" /><span> Principal - Bangalore Institute of Technology - K.R. Road, V V Pura - Bangalore-560004</span></li>
                                            <li><i className="fas fa-envelope" /><span> Mail-ID : </span>admission@bit-bangalore.edu.in </li>
                                            <li><i className="fas fa-phone" /><span> Contact Number : </span>+91-9448512984, +91-9448512954</li>
                                        </ul>
                                    </div>
                                    <div className="ttm-pf-single-detail-box mb-35">
                                        <h5>For Admission queries please call during working hours</h5>
                                        <ul className="ttm-pf-detailbox-list">
                                            <li><h6><span> Working Hours :</span></h6></li>
                                            <li><i className="fas fa-caret-right" /><span> Monday to Fiday </span>9.00 AM - 5.00 PM / <span> Saturday</span>9.00 AM - 2.00 PM </li>
                                        </ul>
                                    </div>
                                    <div className="ttm-pf-single-detail-box mb-35">
                                        <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white" href={`${process.env.PUBLIC_URL + "/assets/pdf/Admission/MBA.pdf"}`} target="_blank">
                                            <h5>Please download application form for MBA Program Admission  . . . . . </h5>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <AdmissionSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}