import React, { Component } from 'react';
import Banner from '../../../components/common/Banner';
import Header from '../../../components/common/Header';
import DepartmentSidebar from '../component/DepartmentSidebar';
import Footer from '../../../components/common/Footer';
import DepartmentIndustryCards from "../component/DepartmentIndustryCards";

export default class CEIndustry extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Civil Engineering" image="banners/department-ce.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Industry Interactions</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description justify">
                                        <p className="justify">The Department of Civil Engineering-Industry Interaction facilitates in the exchange of technical know-how amongst students, faculty and the industry. The prime objective of Industry Interaction is to strengthen the relationship between the institute and industry resulting in upgrading the skills among students and faculty relevant to the present scenario. The Industry Interaction facilitates in collaborating with industrial organizations for working on various problems of mutual interest by entering into MoU’s.</p>
                                        <div className="section-title with-desc text-left clearfix paddingtop-2">
                                            <div className="title-header" style={{ borderTopColor: "red" }}>
                                                <h5>Memorandum of Understanding (MoU's)</h5>
                                            </div>
                                        </div>
                                        <p>The Department of Civil Engineering initiated to sign MoU's with leading Civil Engineering service industries to provide industry exposure to the faculty and students.</p>
                                        <div className="section-title with-desc text-left clearfix paddingtop-2">
                                            <div className="title-header" style={{ borderTopColor: "red" }}>
                                                <h5>Internship/ Professional Practice:</h5>
                                            </div>
                                        </div>
                                        <p>Civil Engineering is a very broad field, so internship narrow the targets of the graduates for choosing their career after the graduation. Internship is one of the best way add practical real-life experience of construction industry. Graduates with internship experience will generally find graduate employment easily and also internship gives an introduction to a certain company to begin their career.</p><br/>
                                        <p>Internship for Civil Engineering students has been started from the academic tear 2019-20. The Department presently coordinates with many leading organizations like, Ultratech Cements, Construction Management Training Institute (CMTI), SPECS Consulting Civil Engineers, Karnataka Test House Pvt. Ltd, M/s Nishicon Infrastructures, G.N.R Construction, Sobha Limited, Shell Castle Infra Pvt. Ltd. (SCIPL), Samanvi Construction, Srushti Consultants, J.N. Project Pvt. Ltd., Sarvashri Constructions, Public Works Department GoK, JMC Projects(India) Limited, Bangalore Urban Division KSPH and IDCL, Bhadra Group Real Estate and Construction Company, BMR Constructions Architects & Engineers, Colliers International, Samruddhi Constructions and Interiors, Hampan Associates Private Limited, Civil Tec Lab, Krupa Constructions, Prinston Smart Engineers, NSR Projects, Sahaaya Construction Pvt. Ltd., among others, actively associated with the department for providing internships to students. </p>
                                        <DepartmentIndustryCards department="civil" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="civil" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
