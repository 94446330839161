import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class ETECOE extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Electronics and Telecommunication Engineering" image="banners/department-ete.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header" style={{ borderTopColor: "red" }}>
                    <h4 className="title"> Center of Excellence</h4>
                  </div>
                  <div className="ttm-service-description">
                    <p>The Centre of Excellence in RF Communication, Information Processing and Networking provides a Technology Laboratory for Faculty/Researchers, Industry-standard proficiency courses for upgrading skills and a Platform for offering special Course/Consulting Projects.
                      <a className="ttm-btn" style={{ paddingLeft: "10px" }} href={process.env.PUBLIC_URL + "/assets/pdf/COE/COE - ETE.pdf"} target="_blank">For More Details Click Here</a>
                    </p>
                  </div>
                  <div className="ttm-service-single-content-area paddingtop-2">
                    <p>The Centre of Excellence in RF Communication, Information Processing and Networking provides a Technology Laboratory for Faculty/Researchers, Industry-standard proficiency courses for upgrading skills and a Platform for offering special Course/Consulting Projects.</p>
                    <div className="section-title with-desc text-left clearfix">
                      <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                        <h5 className="title">Objectives</h5>
                      </div>
                      <p>To create innovative applications and domain capability for Industry and Society needs such as Smart City, Smart Health, Smart Manufacturing and Smart Agriculture.</p><br />
                      <p>To build industry proficient talent, start-up community, and entrepreneurial ecosystem.</p><br />
                      <p>To reduce costs in Research and Development by providing multidisciplinary & interoperable and multi-technology laboratory facilities.</p><br />
                    </div>
                    <div className="section-title with-desc text-left clearfix">
                      <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                        <h5 className="title">Current Research Themes</h5>
                      </div>
                      <ul className="ttm-list ttm-list-style-icon justify">
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Feature Extraction using Segmentation Methods</span></li>
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Aquaculture.</span></li>
                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Data Monitoring using IoT</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="ete" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
