import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import ExploreSidebar from './components/ExploreSidebar';

export default class ExploreBestPractices extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Explore BIT" image="banners/banner-explore.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">BIT - Best Practices</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/explore/bp.jpg"} alt="Error" />
                                                </div>
                                            </div>
                                            <div className="col-md-7" style={{ margin: "auto" }}>
                                                <ul className="ttm-list ttm-list-style-icon">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><div style={{ fontWeight: "bold" }}>Proctorship:</div> A group of around fifteen to twenty students will be allotted to faculty, who will be the proctor /mentor/counselor for that groups of students till they complete the course.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><div style={{ fontWeight: "bold" }}>Placements:</div>To provide placement opportunities, conducting pre-placement training from the industry experts and soft skill improvement programs.</span></li>
                                                </ul>
                                            </div>
                                            <div className="col-md-12 paddingtop-2" style={{ margin: "auto" }}>
                                                <ul className="ttm-list ttm-list-style-icon">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><div style={{ fontWeight: "bold" }}>IQAC:</div> To inculcate the quality learning leading to optimal, efficient and effective performance in academic tasks.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><div style={{ fontWeight: "bold" }}>Feedback System:</div> To enhance quality teaching and learning of the faculty and thereby the progress of the institution. </span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><div style={{ fontWeight: "bold" }}>Transparent Evaluation:</div> To ensure proper evaluation through discussion with scheme and shared the same to the students.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><div style={{ fontWeight: "bold" }}>Remedial Classes:</div> The aim is to address the problems faced by students to cope up with the learning process along with their co-learners by scheduling additional classes and accounting the same. </span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><div style={{ fontWeight: "bold" }}> Multidimensional Learning:</div> The act of assessing and implementing an approach viz., method, tactic, strategy, etc., that consists of more than one feature or design. A multidimensional approach examines students in interdisciplinary learning like tutorial classes and assignments given to students.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><div style={{ fontWeight: "bold" }}>Extra-Curricular Activities:</div> To create a platform through different activity clubs for students to showcase their talents other than academics.</span></li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"><div style={{ fontWeight: "bold" }}>Co-Curriculum Activities:</div> The objective is to create an environment where students are engaged in technical activities, problem solving, and creative thinking also do collaborative work to enhance their skill development.</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row paddingtop-2">
                                        <div className="col-md-4">
                                            <div className="ttm_single_image-wrapper">
                                                <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifebit/bp-1.jpg"} alt="Error" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="ttm_single_image-wrapper">
                                                <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifebit/bp-2.jpg"} alt="Error" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="ttm_single_image-wrapper">
                                                <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifebit/bp-3.jpg"} alt="Error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <ExploreSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
