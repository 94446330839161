import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../Config/FbConfig';

export default class notification extends Component {

    state = {
        Datas: [],
        Loading: true
    }

    //Load Data Here
    async componentDidMount() {
        const docRef = doc(firestore, "Home", "Notifications");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key }, value))
            });
            this.setState({
                Datas: Data,
                Loading: false
            })
        } else {
            this.setState({
                Datas: "NO_999",
                Loading: false
            })
        }
    }

    render() {
        
        const settings = {
            slidesPerView: 4,
            loop: true,
            speed: 1000,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            direction: "vertical",
        };

        return (
            <Container>
                <div className="row" style={{ marginBottom: "3rem", border: "solid", borderColor: "#98002e" }}>
                    <div className="col-lg-8 col-md-12">
                        <div className="section-title text-center with-desc clearfix" style={{ padding: "1rem", height: "40rem", overflow: "hidden" }}>
                            <div className="title-header">
                                <h2 className="title">News & Notifications</h2>
                            </div>
                            {this.state.Loading === true ? "Loading.." :
                                <Swiper {...settings}>
                                    {
                                        this.state.Datas.map((data, i) => (
                                            <div className="row featured-icon-box style3 left-icon icon-align-top" style={{ marginBottom: "-2.5rem" }}>
                                                <div className="col-1 featured-icon only-desk">
                                                    <div className="ttm-icon ttm-textcolor-skincolor">
                                                        <i style={{ fontSize: "1.5rem" }} className="fas fa-bell" />
                                                    </div>
                                                </div>
                                                <div className="col-11 featured-content">
                                                    <div className="featured-title justify">
                                                        <a href={data.Content} target="_blank" style={{ color: "black" }}>{data.Header}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Swiper>
                            }
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12" style={{ backgroundColor: "#98002e" }}>
                        <div className="section-title text-center with-desc clearfix" style={{ padding: "1rem" }}>
                            <div className="title-header">
                                <h2 className="title" style={{ color: "white" }}>Useful links</h2>
                            </div>
                            <div className="row featured-icon-box style3 left-icon icon-align-top">
                                <div className="col-1 featured-icon">
                                    <div className="ttm-icon ttm-textcolor-skincolor" style={{ fontSize: "1rem", color: "white" }}>
                                        <i className="fas fa-link" />
                                    </div>
                                </div>
                                <div className="col-10 featured-content">
                                    <div className="featured-title justify" style={{ marginTop: "0.75rem" }}>
                                        <a href='https://vimeo.com/813498542' target="_blank" style={{ color: "white" }}>BIT Campus Tour</a>
                                    </div>
                                </div>
                            </div>
                            <div className="row featured-icon-box style3 left-icon icon-align-top">
                                <div className="col-1 featured-icon">
                                    <div className="ttm-icon ttm-textcolor-skincolor" style={{ fontSize: "1rem", color: "white" }}>
                                        <i className="fas fa-link" />
                                    </div>
                                </div>
                                <div className="col-10 featured-content">
                                    <div className="featured-title justify" style={{ marginTop: "0.75rem" }}>
                                        <a href={`${process.env.PUBLIC_URL + "/assets/pdf/Home/VTU_Links.pdf"}`} target="_blank" style={{ color: "white" }}>VTU Links</a>
                                    </div>
                                </div>
                            </div>
                            <div className="row featured-icon-box style3 left-icon icon-align-top">
                                <div className="col-1 featured-icon">
                                    <div className="ttm-icon ttm-textcolor-skincolor" style={{ fontSize: "1rem", color: "white" }}>
                                        <i className="fas fa-link" />
                                    </div>
                                </div>
                                <div className="col-10 featured-content">
                                    <div className="featured-title justify" style={{ marginTop: "0.75rem" }}>
                                        <a href={`${process.env.PUBLIC_URL + "/assets/pdf/Home/Scholorship.pdf"}`} target="_blank" style={{ color: "white" }}>Scholarships</a>
                                    </div>
                                </div>
                            </div>
                            <div className="row featured-icon-box style3 left-icon icon-align-top">
                                <div className="col-1 featured-icon">
                                    <div className="ttm-icon ttm-textcolor-skincolor" style={{ fontSize: "1rem", color: "white" }}>
                                        <i className="fas fa-link" />
                                    </div>
                                </div>
                                <div className="col-10 featured-content">
                                    <div className="featured-title justify" style={{ marginTop: "0.75rem" }}>
                                        <a href='https://internshala.com/internships/engineering-internship/' target="_blank" style={{ color: "white" }}>Internships</a>
                                    </div>
                                </div>
                            </div>
                            <div className="row featured-icon-box style3 left-icon icon-align-top">
                                <div className="col-1 featured-icon">
                                    <div className="ttm-icon ttm-textcolor-skincolor" style={{ fontSize: "1rem", color: "white" }}>
                                        <i className="fas fa-link" />
                                    </div>
                                </div>
                                <div className="col-10 featured-content">
                                    <div className="featured-title justify" style={{ marginTop: "0.75rem" }}>
                                        <a href='https://gate.iitkgp.ac.in/' target="_blank" style={{ color: "white" }}>GATE</a>
                                    </div>
                                </div>
                            </div>
                            <div className="row featured-icon-box style3 left-icon icon-align-top">
                                <div className="col-1 featured-icon">
                                    <div className="ttm-icon ttm-textcolor-skincolor" style={{ fontSize: "1rem", color: "white" }}>
                                        <i className="fas fa-link" />
                                    </div>
                                </div>
                                <div className="col-10 featured-content">
                                    <div className="featured-title justify" style={{ marginTop: "0.75rem" }}>
                                        <a href='https://www.kscst.org.in/spp.html' target="_blank" style={{ color: "white" }}>KSCST Student Project Programme</a>
                                    </div>
                                </div>
                            </div>
                            <div className="row featured-icon-box style3 left-icon icon-align-top">
                                <div className="col-1 featured-icon">
                                    <div className="ttm-icon ttm-textcolor-skincolor" style={{ fontSize: "1rem", color: "white" }}>
                                        <i className="fas fa-link" />
                                    </div>
                                </div>
                                <div className="col-10 featured-content">
                                    <div className="featured-title justify" style={{ marginTop: "0.75rem" }}>
                                        <a href="https://www.sih.gov.in" target="_blank" style={{ color: "white" }}>Smart India Hackathon</a>
                                    </div>
                                </div>
                            </div>
                            <div className="row featured-icon-box style3 left-icon icon-align-top">
                                <div className="col-1 featured-icon">
                                    <div className="ttm-icon ttm-textcolor-skincolor" style={{ fontSize: "1rem", color: "white" }}>
                                        <i className="fas fa-link" />
                                    </div>
                                </div>
                                <div className="col-10 featured-content">
                                    <div className="featured-title justify" style={{ marginTop: "0.75rem" }}>
                                        <a href='https://www.aicte-india.org/bureaus#:~:text=The%20organization%20structure%20of%20the%20AICTE%20comprises%20of%20the%20following%20bureaus&text=The%20Approvals%20Bureau%20processes%20proposals,institution%20or%20an%20integrated%20campus.&text=Special%20Cell%20for%20Research%20%26%20Coordination%20with%20State%20Govt.%2FUTs.' target="_blank" style={{ color: "white" }}>AICTE Cells</a>
                                    </div>
                                </div>
                            </div>
                            <div className="row featured-icon-box style3 left-icon icon-align-top">
                                <div className="col-1 featured-icon">
                                    <div className="ttm-icon ttm-textcolor-skincolor" style={{ fontSize: "1rem", color: "white" }}>
                                        <i className="fas fa-link" />
                                    </div>
                                </div>
                                <div className="col-10 featured-content">
                                    <div className="featured-title justify" style={{ marginTop: "0.75rem" }}>
                                        <a href='https://results.vtu.ac.in/' target="_blank" style={{ color: "white" }}>Exam Results</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
}