import styled from "styled-components";
import { colors } from "../../components/common/element/elements.js";

export const Styles = styled.div`
    p {
        color        : ${colors.text1};
        padding-top  : 1rem;
        overflow    : hidden;
        display     : -webkit-box;
        -webkit-line-clamp: 3;
                line-clamp: 3; 
        -webkit-box-orient: vertical;

        @media(max-width: 575px) {
            margin-bottom: 15px;
            font-size: 20px;
        }
    }

    h6 {
        color        : ${colors.bg3};
        overflow    : hidden;
        display     : -webkit-box;
        -webkit-line-clamp: 1;
                line-clamp: 1; 
        -webkit-box-orient: vertical;
    }

    small{
        overflow    : hidden;
        display     : -webkit-box;
        -webkit-line-clamp: 1;
                line-clamp: 1; 
        -webkit-box-orient: vertical;
    }

    img{
        min-height: 200px;
        max-height: 200px;
        object-fit: cover;
        min-width: 100%;
        max-width: 100%;
    }
`;