import React, { Component } from "react";
import { Link } from "react-router-dom";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";


export default class ETETeaching extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Electronics and Telecommunication Engineering" image="banners/department-ete.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <h4 className="title">Learning Resources</h4>
                  </div>
                </div>
                <div className="ttm-service-description">
                  <p>Wide arrays of learning materials are provided to students in advance for each course to catalyze their learning ability. These resources include syllabus, course materials, and links to digital aids. Click here to access learning resources.</p>
                  <Link className="ttm-btn" style={{ fontSize: "1.15rem", paddingTop: "1rem", color: "#00a76a" }} to={process.env.PUBLIC_URL + `/teaching-learning-material/ete`}>Click Here for Learning Resources</Link>
                </div>
                <div className="section-title with-desc text-left clearfix paddingtop-2">
                  <div className="title-header">
                    <h4 className="title">Teaching - Learning</h4>
                  </div>
                </div>
                <div className="ttm-service-description justify">
                  <p>Program Outcomes are specified by National Board for Accreditation based on Washington Accord. Course Outcomes framed by VTU are refined by the concerned Program Committee Members of the Department. The following Teaching learning methods are adopted to attain Program Outcomes (POs) and Program Specific Outcomes (PSOs).</p><br />
                  <h6 className="paddingtop-1">Initiatives in Teaching and Learning:</h6><br />
                  <ul className="ttm-list ttm-list-style-icon">
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />New Pedagogies.</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Competency-Based Learning</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Flipped Classrooms.</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Practical Learning using Virtual Lab facilities.</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Digital mode- Learning Management Systems(I-Learning & e-Learning)</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Industrial visits offer a great source to  students to gain practical knowledgeTechnical talks are conducted for students to update their knowledge according to industry needs</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Providing Soft Skills, Leadership qualities and Team spirit through AICTE activity points</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Encourage Staff and Students  to register for Outreach Programs</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Encourage Staff and Students to take up online courses like NPTEL, EDUSAT, COURSERA and MOOC etc.</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Implement Team based learning</li>
                  </ul>
                </div>
                <div className="ttm-service-description">
                  <h6 className="paddingtop-1">Evaluation:</h6><br />
                  <ul className="ttm-list ttm-list-style-icon">
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Rubrics are adopted for the evaluation of Technical Seminars/Presentations, Mini and Major Project Works, Internships and Lab Records which indicates Quality Assessment</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />Continuous Internal Evaluation is attained using Direct Assessment tools and Indirect Assessment tools</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />The Direct Assessment tool consists of three internal assessment tests and Assignments/Quiz/Seminars</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />The Indirect Assessment method includes a Course-end Survey conducted at the end of the Semester</li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content" />All Course Outcomes of a course are evaluated considering the performance of all students</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="ete" />
              </div>
            </div>{/* row end */}
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}

