import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import DepartmentIndustryCards from "../component/DepartmentIndustryCards";
import Footer from "../../../components/common/Footer";

export default class IEMIndustry extends Component {
    render() {
        return (
            <div>
            {/* Header */}
            <Header />

            {/* Banner */}
            <Banner title="Industrial Engineering and Management" image="banners/department-iem.jpg" />

            {/* Content */}
            <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                <div className="container-xl">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-9 content-area order-lg-2">
                            <div className="ttm-service-single-content-area">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Industry Interactions</h4>
                                    </div>
                                </div>
                                <div className="ttm-service-description">
                                    <div className="row">
                                        <div className="col-md-12" style={{margin:"auto"}}>
                                            <p className="justify">The students are encouraged to take up internship/summer training during summer and winter vacation. Senior faculties of the department help students to select the suitable industries, R& D organizations. Awareness is created among the students about the importance of taking up Internship/Summer training programs.</p><br/>
                                            <p>The required permission letter is issued by the department, based on which the students are officially undergo the said program. The duration of the program is usually 4 weeks. After the completion of the program, the students have to submit a report to the Department along with the certificate.</p>
                                            <p>A feedback is collected from the students, to understand the usefulness of the program, so that, it is made more effective and improvised year by year. It is generally observed that those students who undergo training program are able to relate technology with the academics. The exposure gained helps them in getting placements.</p>
                                        </div>
                                        <div className="col-md-6 col-lg-12 paddingtop-2">
                                            <div className="section-title with-desc text-left clearfix">
                                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                                    <h5>The department has signed MOUs with 4 companies in order to provide:</h5>
                                                </div>
                                            </div>
                                            <ul className="ttm-list ttm-list-style-icon">
                                                <li><i className="fa fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Internship </span></li>
                                                <li><i className="fa fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Project</span></li>
                                                <li><i className="fa fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Industrial visits</span></li>
                                                <li><i className="fa fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Workshops for students</span></li>
                                                <li><i className="fa fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Faculty Development Program</span></li>
                                            </ul>
                                            
                                            <p className="justify">Further, all the students of IEM branch compulsorily carry out their academic projects in manufacturing organizations like Bosch, HAL, BHEL, Sansera, Toyota and others.</p>
                                      
                                        </div>
                                    </div>
                                    <DepartmentIndustryCards department="iem" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 widget-area">
                            <DepartmentSidebar department="iem" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <Footer />
        </div>
    )
}
}
