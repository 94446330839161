import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import NewDepartmentSidebar from "../component/NewDepartmentSidebar";
import Footer from "../../../components/common/Footer";
import myData from '../component/HOD.json';
import { Link } from "react-router-dom";

export default class ROBOTICSAbout extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Robotics & Artificial Intelligence" image="banners/department-robotics.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Department coordinator</h4>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 paddingtop-1 justify">{myData["robotics"].msg1}</div>
                                        <div className="col-lg-12 paddingtop-1 justify">{myData["robotics"].msg2}</div>
                                        <div className="col-lg-12 paddingtop-1 justify">{myData["robotics"].msg3}</div>  
                                        <div className="col-lg-4" style={{ margin: "auto" }}>
                                            <div className="ttm_single_image-wrapper mb-35">
                                                <img className="img-fluid" src={process.env.PUBLIC_URL + `/assets/images/departments/${myData["robotics"].photo}`} title="single-img-twenty" alt="single-img-twenty" />
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <div className="ttm-pf-single-detail-box mb-35">
                                                <ul className="ttm-pf-detailbox-list">
                                                    <li><i className="fa fa-user" /><span>Name : </span>{myData["robotics"].name}</li>
                                                    <li><i className="fas fa-id-card" /><span>Designation :</span>{myData["robotics"].designation}</li>
                                                    <li><i className="fas fa-graduation-cap" /><span>Qualification :</span>{myData["robotics"].Qualification}</li>
                                                    <li><i className="fas fa-envelope" /><span>Mail-ID : </span>{myData["robotics"].mail}</li>
                                                    <li><i className="fas fa-phone" /><span>Contact Number : </span>{myData["robotics"].contact}</li>
                                                    <li><i className="fas fa-link" /><span>Read More : </span><Link to={process.env.PUBLIC_URL + `/department-teaching-faculties/${myData["robotics"].knowmore}`}>Click Here</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <NewDepartmentSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}