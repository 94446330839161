import React, { Component } from 'react';
import Header from '../../components/common/Header';
import Banner from '../../components/common/Banner';
import Footer from '../../components/common/Footer';
import { Link } from 'react-router-dom';

export default class sent extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Breadcroumb */}
                <Banner title="Message Sent Successfully" image="banners/department-list.jpg" />
                <hr /><h6 style={{ textAlign: "center" }}><Link to="/">Go Back to Home</Link></h6><hr />

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}