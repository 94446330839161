import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Loading from '../../components/common/Loading';
import NoData from '../../components/common/NoData';
import InnovationSidebar from './components/InnovationSidebar';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../Config/FbConfig';
import { Modal } from 'react-bootstrap';
import { Styles } from "./style/InnovationStyle.js";

export default class InnovationCell extends Component { 

    state = {
        displayModal: false,
        modalImage: "",
        modalDescription: "",
        modalDate: "",
        modalVideo: "",
        Datas: []
    }

    //Display Modal
    displayModal = (e) => {
        this.setState({
            displayModal: !this.state.displayModal,
        })
    }

    //Display Content
    contentModal = (e) => {
        var result = this.state.Datas.filter((x) => x.Id === e.target.id);
        this.setState({
            displayModal: !this.state.displayModal,
            modalImage: result[0]["Image"],
            modalDescription: result[0]["Description"],
            modalDate: result[0]["Year"],
            modalVideo: result[0]["Video"]
        })
    }

    //Load Data Here
    async componentDidMount() {
        const docRef = doc(firestore, "Innovation", "InnovationCell");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key }, value))
            });
            this.setState({
                Datas: Data
            })
        } else {
            this.setState({
                Datas: "NO_999"
            })
        }
    }

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Innovation" image="banners/banner-achievers.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Innovation Cell (IEDC)</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + `/assets/images/innovation/innovation-1.jpg`} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <p>The Innovation and Entrepreneurship Development Cell of Bangalore Institute of Technology (IEDC: BIT) is set up with the primary motive of encouraging entrepreneurship and works towards developing and strengthening entrepreneurial qualities in the budding professionals. The Cell aims to provide the required support to the students with innovative ideas to transform into new products and services. IEDC: BIT acts as an Incubation Center for these students with ideas.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Vision</h5>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p className="tab-desc">Help students to change lives and achieve dreams through Innovation & Entrepreneurship.</p>
                                            </div>
                                            <div className="col-md-12 paddingtop-2">
                                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                                        <h5 className="title">Mission</h5>
                                                    </div>
                                                </div>
                                                <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To promote students of BIT to become successful entrepreneurs by providing the opportunity, skill, knowledge and resources needed for Idea Incubation.</li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>IEDC-BIT (Innovation and Entrepreneurs Development Center, Bangalore    Institute of Technology) is working towards igniting young minds which creates an opportunity to change lives.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">OBJECTIVES</h5>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <ul className="ttm-list ttm-list-style-icon">
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>IEDC shall work towards creating awareness of Entrepreneurship and nurturing Entrepreneurial activities in the Institute. IEDC shall create an entrepreneurial ecosystem for promoting establishment of start-ups through its Incubation and Innovation center.</li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>The IEDC shall create an entrepreneurial culture in the institution by organizing various entrepreneurial activities like Entrepreneurship Motivation Camps, Entrepreneurship Awareness Camps, and Entrepreneurship Development Programs.</li>
                                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>IEDC through its network center, shall strive to foster better linkages between industries and institutions engaged in promoting entrepreneurial activities</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    {/*sports constitution*/}
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Constitution - Exceutive Committee</h5>
                                        </div>
                                        <table className='table' style={{ margin: "auto" }}>
                                            <thead style={{ backgroundColor: "#98002e", color: "white", margin: "auto", }}>
                                                <tr>
                                                    <th scope="col" style={{ width: "8%" }}>Sl No</th>
                                                    <th scope="col" style={{ width: "30%" }}>Name</th>
                                                    <th scope="col" style={{ width: "20%" }}>Department</th>
                                                    <th scope="col" style={{ width: "30%" }}>Position</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th>1</th>
                                                    <td>C.R.Krishna Prasad</td>
                                                    <td>MBA</td>
                                                    <td>Chairman</td>
                                                </tr>
                                                <tr>
                                                    <th>2</th>
                                                    <td>Pranav Subrahmanya Bhat</td>
                                                    <td>EIE</td>
                                                    <td>President</td>
                                                </tr>
                                                <tr>
                                                    <th>3</th>
                                                    <td>Shashank Bhat</td>
                                                    <td>EIE</td>
                                                    <td>Vice President of Strategy</td>
                                                </tr>
                                                <tr>
                                                    <th>4</th>
                                                    <td>Manoj K</td>
                                                    <td>CSE</td>
                                                    <td>Coordinator of Strategy</td>
                                                </tr>
                                                <tr>
                                                    <th>5</th>
                                                    <td>Kothakota BinduSree</td>
                                                    <td>CSE</td>
                                                    <td>Coordinator of Strategy</td>
                                                </tr>
                                                <tr>
                                                    <th>6</th>
                                                    <td>Harsh Kumar</td>
                                                    <td>CSE</td>
                                                    <td>Vice President of Design</td>
                                                </tr>
                                                <tr>
                                                    <th>7</th>
                                                    <td>Navyashree LS</td>
                                                    <td>CSE</td>
                                                    <td>Coordinator Of Design</td>
                                                </tr>
                                                <tr>
                                                    <th>8</th>
                                                    <td>Hamsa</td>
                                                    <td>EIE</td>
                                                    <td>Vice President of Editorial</td>
                                                </tr>
                                                <tr>
                                                    <th>9</th>
                                                    <td>Keerthana G</td>
                                                    <td>MBA</td>
                                                    <td>Coordinator of Editorial</td>
                                                </tr>
                                                <tr>
                                                    <th>10</th>
                                                    <td>Vibha R</td>
                                                    <td>MBA</td>
                                                    <td>Vice President of Marketing & Social Media</td>
                                                </tr>
                                                <tr>
                                                    <th>11</th>
                                                    <td>Pranav M</td>
                                                    <td>CSE</td>
                                                    <td>Coordinator of Marketing & Social Media</td>
                                                </tr>
                                                <tr>
                                                    <th>12</th>
                                                    <td>Varsha Padmanabha</td>
                                                    <td>IEM</td>
                                                    <td>Vice President of Operations</td>
                                                </tr>
                                                <tr>
                                                    <th>13</th>
                                                    <td>Ishani Priya</td>
                                                    <td>CSE</td>
                                                    <td>Coordinator of Operations</td>
                                                </tr>
                                                <tr>
                                                    <th>14</th>
                                                    <td>Madan Gowda S</td>
                                                    <td>ECE</td>
                                                    <td>Coordinator of Photography & Videography</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="section-title with-desc text-left clearfix paddingtop-2">
                                            <div className="title-header" style={{ borderTopColor: "red" }}>
                                                <h5 className="title">Events - Innovation Cell</h5>
                                            </div>
                                        </div>
                                        <Styles>
                                            <div className="row ttm-service-description paddingtop-1">
                                                {
                                                    this.state.Datas.length === 0 ? <Loading /> :
                                                        this.state.Datas === "NO_999" ? <NoData /> :
                                                            this.state.Datas.map((data, i) => (
                                                                <div className="col-md-4 ttm-box-col-wrapper d-flex align-items-stretch" key={i} style={{ marginBottom: "1rem" }}>
                                                                    <div className="featured-imagebox featured-imagebox-blog">
                                                                        <div className="featured-thumbnail">
                                                                            <a onClick={this.contentModal}><img id={data.Id} className="img-fluid" alt="" src={data.Image} /></a>
                                                                        </div>
                                                                        <div className="featured-content">
                                                                            <div className="ttm-box-post-date">
                                                                                <span className="ttm-entry-date">
                                                                                    <time className="entry-date">{data.Year}</time>
                                                                                </span>
                                                                            </div>
                                                                            <div className="featured-desc justify">
                                                                                <p>{data.Description}</p>
                                                                            </div>
                                                                            {
                                                                                data.Video != undefined ?
                                                                                    <div className="post-meta" style={{ marginBottom: "-1rem" }}>
                                                                                        <a target="_blank" href={data.Video}><span className="ttm-meta-line" style={{ color: "gray" }}><i className="fa fa-link" />View Details</span></a>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                }
                                            </div>
                                        </Styles>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <InnovationSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal */}
                <Modal size="lg" show={this.state.displayModal} onHide={this.displayModal} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-5 featured-thumbnail">
                                <img className="img-fluid" alt="" src={this.state.modalImage} />
                            </div>
                            <div className="col-7" >
                                <span className="ttm-meta-line"><b>Year : </b>{this.state.modalDate}</span>
                                <div className="featured-desc justify paddingtop-1"><p>{this.state.modalDescription}</p></div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
