import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/header.js";
import MobileMenu from "./MobileMenu.js";

export default class Header extends Component {
  render() {
    return (
      <div>
        <Styles>
          <section className="top-bar">
            <Container>
              <Row>
                <Col lg="5">
                  <div className="logo">
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/general/Logo.png"
                        }
                        style={{ width: "32.5rem", marginTop: "1rem" }}
                      />
                    </Link>
                  </div>
                </Col>
                <Col lg="6">
                  <div className="menu-box bar-right d-flex justify-content-center">
                    <ul className="nav menu-nav">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/teaching-and-learning"}
                        >
                          Teaching - Learning
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <a className="nav-link">e - Learning Corner</a>
                        <ul className="dropdown list-unstyled">
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL +
                                "/e-learning-online-courses"
                              }
                            >
                              Online Courses
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL + "/e-learning-e-content"
                              }
                            >
                              E - Contents
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL +
                                "/e-learning-practical-resourse"
                              }
                            >
                              Practical Resources
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL +
                                "/e-learning-research-resourse"
                              }
                            >
                              Research Resources
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <div className="nav-link" style={{ color: "white" }}>
                          |
                        </div>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="https://www.facebook.com/bitsince1979"
                          target="_blank"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="https://twitter.com/bitsince1979"
                          target="_blank"
                        >
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="https://www.instagram.com/bitsince1979/"
                          target="_blank"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="https://www.youtube.com/bitsince1979"
                          target="_blank"
                        >
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/* Logo Area */}
          <section className="header-area">
            <Container>
              <div className="row">
                <div className="offset-3 col-9">
                  <div className="menu-box d-flex justify-content-end">
                    <ul className="nav menu-nav">
                      <li className="nav-item active">
                        <a
                          className="nav-link"
                          href={process.env.PUBLIC_URL + "/bit-library"}
                          target="_blank"
                        >
                          Library
                        </a>
                      </li>
                      <li className="nav-item active">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/achievers-academics"}
                        >
                          Achievements
                        </Link>
                      </li>
                      <li className="nav-item active">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/alumni"}
                        >
                          Alumni
                        </Link>
                      </li>
                      <li className="nav-item active">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/contact"}
                        >
                          Contact
                        </Link>
                      </li>
                    </ul>
                    <div className="apply-btn">
                      <Link to={process.env.PUBLIC_URL + "/admission-be"}>
                        <i className="fas fa-clipboard-list"></i>Admission
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </section>

          <section className="top-bar-info">
            <Container>
              <div className="d-flex justify-content-center">
                <ul className="nav menu-nav">
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/"}
                    >
                      HOME
                    </Link>
                  </li>
                  <div className="line">|</div>
                  <li className="nav-item dropdown">
                    <a className="nav-link">ABOUT US</a>
                    <ul className="dropdown" style={{ width: "60rem" }}>
                      <Row>
                        <Col md="4">
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/explore-rvs"}
                            >
                              Management - RVS
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/explore-about"}
                            >
                              About BIT
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL + "/explore-highlights"
                              }
                            >
                              Highlights of BIT
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/explore-chairman"}
                            >
                              Chairman - Governing council
                            </Link>
                          </li>
                        </Col>
                        <Col md="4">
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/explore-principal"}
                            >
                              Principal
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL +
                                "/explore-vice-principal"
                              }
                            >
                              Vice Principal
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL +
                                "/explore-administration"
                              }
                            >
                              Administrative Staff
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/explore-mc"}
                            >
                              Mandatory Committees
                            </Link>
                          </li>
                        </Col>
                        <Col md="4">
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/explore-gc"}
                            >
                              Governance Committees
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL +
                                "/explore-bestpractices@bit"
                              }
                            >
                              Best Practices @ BIT
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/explore-rules"}
                            >
                              Rules and Regulations
                            </Link>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              href="https://drive.google.com/file/d/18DyQbeQt04JKNwM1kpJlKz7uS0sqPuy6/view"
                              target="_blank"
                            >
                              Mandatory Disclosure
                            </a>
                          </li>
                        </Col>
                      </Row>
                    </ul>
                  </li>
                  <div className="line">|</div>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/department-list"}
                    >
                      ACADEMICS
                    </Link>
                  </li>
                  <div className="line">|</div>
                  <li className="nav-item dropdown">
                    <a className="nav-link">R & I</a>
                    <ul className="dropdown" style={{ width: "35rem" }}>
                      <Row>
                        <Col md="6">
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/research-bit"}
                            >
                              Research @ BIT{" "}
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL + "/research-facilities"
                              }
                            >
                              Research Facilities
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/research-center"}
                            >
                              Research Centers @ BIT
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/innovation-cell"}
                            >
                              Innovation Cell
                            </Link>
                          </li>
                        </Col>
                        <Col md="6">
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL +
                                "/innovation-skill-development"
                              }
                            >
                              Skill Development
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL + "/innovation-startup"
                              }
                            >
                              Startup Incubation Center
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/innovation-ipr"}
                            >
                              IPR Cell
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL +
                                "/innovation-science-center"
                              }
                            >
                              Science Center
                            </Link>
                          </li>
                        </Col>
                      </Row>
                    </ul>
                  </li>
                  <div className="line">|</div>
                  <li className="nav-item dropdown">
                    <a className="nav-link">PLACEMENT</a>
                    <ul className="dropdown" style={{ width: "35rem" }}>
                      <Row>
                        <Col md="6">
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/placement-about"}
                            >
                              About Placement
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL + "/placement-students"
                              }
                            >
                              Students Placed
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL +
                                "/placement-ourrecruiters"
                              }
                            >
                              Our recruiters
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL + "/placement-activities"
                              }
                            >
                              Placement Activities
                            </Link>
                          </li>
                        </Col>
                        <Col md="6">
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL +
                                "/placement-alumnifeedback"
                              }
                            >
                              Alumni Feedback
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL + "/placement-facilities"
                              }
                            >
                              Facilities
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/placement-team"}
                            >
                              Placement Team
                            </Link>
                          </li>
                        </Col>
                      </Row>
                    </ul>
                  </li>
                  <div className="line">|</div>
                  <li className="nav-item dropdown">
                    <a className="nav-link">LIFE@BIT</a>
                    <ul className="dropdown" style={{ width: "35rem" }}>
                      <Row>
                        <Col md="6">
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL + "/life@bit-bitcampus"
                              }
                            >
                              BIT Campus
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL + "/life@bit-manthanfest"
                              }
                            >
                              Manthan BIT fest
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL +
                                "/life@bit-studentclubs"
                              }
                            >
                              Student Clubs
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL +
                                "/life@bit-Sportsandgames"
                              }
                            >
                              Sports and Games
                            </Link>
                          </li>
                        </Col>
                        <Col md="6">
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/life@bit-nss"}
                            >
                              NSS @ BIT
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/life@bit-ncc"}
                            >
                              NCC @ BIT
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL + "/life@bit-facilities"
                              }
                            >
                              Facilities
                            </Link>
                          </li>
                        </Col>
                      </Row>
                    </ul>
                  </li>
                  <div className="line">|</div>
                  <li className="nav-item dropdown">
                    <a className="nav-link">ACCREDITATION AND APPROVALS</a>
                    <ul className="dropdown" style={{ width: "30rem" }}>
                      <Row>
                        <Col md="6">
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/approvals-nba"}
                            >
                              NBA
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/approvals-naac"}
                            >
                              NAAC
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/approvals-igauge"}
                            >
                              QS I-GUAGE
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/approvals-nirf"}
                            >
                              NIRF
                            </Link>
                          </li>
                        </Col>
                        <Col md="6">
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/approvals-vtu"}
                            >
                              VTU Approvals
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/approvals-ugc"}
                            >
                              UGC Approvals
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/approvals-aicte"}
                            >
                              AICTE Approvals
                            </Link>
                          </li>
                        </Col>
                      </Row>
                    </ul>
                  </li>
                  <div className="line">|</div>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/approvals-nirf"}
                    >
                      NIRF
                    </Link>
                  </li>
                  <div className="line">|</div>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/gallery"}
                    >
                      GALLERY
                    </Link>
                  </li>
                </ul>
              </div>
            </Container>
          </section>
        </Styles>
        <MobileMenu />
      </div>
    );
  }
}
