import React, { useState, useEffect } from "react";
import Banner from "../../components/common/Banner";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import NoData from "../../components/common/NoData";
import Loading from "../../components/common/Loading";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../Config/FbConfig";
import { useHistory } from "react-router-dom";

const Downloads = () => {
  const history = useHistory();
  const [downloadsData, setDownloadsData] = useState({});
  const [visibleItems, setVisibleItems] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(firestore, "Home", "Downloads");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const categorizedData = Object.entries(data).reduce(
          (acc, [key, value]) => {
            const category = value.Category || "Others";
            if (!acc[category]) {
              acc[category] = [];
            }
            acc[category].push({
              Id: key,
              Priority:
                value.Priority !== undefined ? parseFloat(value.Priority) : 999,
              Content: value.Content,
              Header:
                value.Header && typeof value.Header === "string"
                  ? value.Header.trim()
                  : "",
            });
            return acc;
          },
          {}
        );

        // Sort categories and ensure "Others" is last
        const sortedCategories = Object.keys(categorizedData)
          .sort((a, b) => (a === "Others" ? 1 : b === "Others" ? -1 : 0))
          .reduce((acc, key) => {
            acc[key] = categorizedData[key];
            return acc;
          }, {});

        setDownloadsData(sortedCategories);
        setVisibleItems(
          Object.keys(sortedCategories).reduce((acc, category) => {
            acc[category] = 1; // Initially show one item per category
            return acc;
          }, {})
        );
      } else {
        setDownloadsData({ Others: "NO_999" });
        setVisibleItems({ Others: 1 });
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleShowMore = (category, items) => {
    setVisibleItems((prevState) => ({
      ...prevState,
      [category]: prevState[category] + 1,
    }));

    history.push({
      pathname: "/download-list",
      state: { category: category, items: items },
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Header />
      <Banner title="Downloads" image="banners/banner-explore.jpg" />

      <div style={{ padding: "6rem" }}>
        <div className="row">
          {Object.entries(downloadsData).map(([category, items]) => (
            <div key={category} className="col-lg-4 col-md-6 mb-4">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "1rem",
                }}
              >
                <h5>{category}</h5>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {items.slice(0, visibleItems[category]).map((item) => (
                  <div
                    key={item.Id}
                    style={{
                      background: "#fff",
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                      padding: "20px",
                      boxSizing: "border-box",
                      transition: "box-shadow 0.3s ease",
                      marginBottom: "20px",
                      width: "100%",
                    }}
                  >
                    <div>
                      <h5
                        style={{
                          marginTop: "0",
                          marginBottom: "10px",
                          fontSize: "15px",
                        }}
                      >
                        {item.Header}
                      </h5>
                      {/* <a
                        href={item.Content}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          display: "inline-block",
                          padding: "10px 20px",
                          color: "#fff",
                          backgroundColor: "#007bff",
                          textAlign: "center",
                          borderRadius: "5px",
                          textDecoration: "none",
                          transition: "background-color 0.3s ease",
                        }}
                      >
                        View Document
                      </a> */}

                      {visibleItems[category] < items.length && (
                        <button
                          onClick={() => handleShowMore(category, items)}
                          style={{
                            background: "none",
                            border: "none",
                            color: "#007bff",
                            cursor: "pointer",
                            textDecoration: "underline",
                            fontSize: "16px",
                            marginTop: "20px",
                          }}
                        >
                          View Documents
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Downloads;
