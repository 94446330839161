import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import PlacementsSidebar from './components/PlacementsSidebar';
import { Modal } from 'react-bootstrap';
import Loading from '../../components/common/Loading';
import NoData from '../../components/common/NoData';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../Config/FbConfig';
import { Styles } from "./style/placementAlumniFeedbackStyle.js";

export default class PlacementAlumniFeedback extends Component {

    state = {
        displayModal: false,
        modalImage: "",
        modalName: "",
        modalDesignation: "",
        modalMsg: "",
        Datas: []
    }

    //Display Modal
    displayModal = (e) => {
        this.setState({
            displayModal: !this.state.displayModal,
        })
    }

    //Display Content
    contentModal = (e) => {
        var result = this.state.Datas.filter((x) => x.Id === e.target.id);
        this.setState({
            displayModal: !this.state.displayModal,
            modalImage: result[0]["Image"],
            modalName: result[0]["Name"],
            modalDesignation: result[0]["Designation"],
            modalMsg: result[0]["Feedback"]
        })
    }

    //Load Data Here
    async componentDidMount() {
        const docRef = doc(firestore, "Placement", "Feedback");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key }, value))
            });
            this.setState({
                Datas: Data
            })
        } else {
            this.setState({
                Datas: "NO_999"
            })
        }
    }

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Placement" image="banners/banner-placement.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{borderTopColor:"red"}}>
                                            <h4 className="title">Alumni Feedback</h4>
                                        </div>
                                    </div>
                                    <Styles>
                                        <div className="row ttm-service-msg">
                                            {
                                                this.state.Datas.length == 0 ? <Loading /> :
                                                this.state.Datas.map((data, i) => (
                                                    <div className="col-6 card" style={{marginBottom:"2rem"}}>
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <a onClick={this.contentModal}><img id={data.Id} className="img-fluid" alt="" src={data.Image} style={{borderRight:"solid 5px",borderColor:"#98002e"}}/></a>
                                                            </div>
                                                            <div className="col-6" style={{padding:"1rem"}}>
                                                                <div className="featured-title">
                                                                    <h6>{data.Name}</h6>
                                                                    <small>{data.Designation}</small>
                                                                </div>
                                                                <div className="post-meta">
                                                                    <p>{data.Feedback}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </Styles>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <PlacementsSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal */}
                <Modal size="lg" show={this.state.displayModal} onHide={this.displayModal} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-5">
                                <a onClick={this.contentModal}><img className="img-fluid" alt="" src={this.state.modalImage} style={{borderRight:"solid 5px",borderColor:"#98002e"}}/></a>
                            </div>
                            <div className="col-7" style={{padding:"1rem"}}>
                                <div className="featured-title">
                                    <h5 style={{color:"#98002e"}}>{this.state.modalName}</h5>
                                    <small>{this.state.modalDesignation}</small>
                                </div>
                                <div className="post-meta justify">
                                    <p>{this.state.modalMsg}</p>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}