import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class EIECOE extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Electronics and Instrumentation Engineering" image="banners/department-eie.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header" style={{ borderTopColor: "red" }}>
                    <h4 className="title">Center of Excellence</h4>
                  </div>
                  <div className="ttm-service-description">
                    <p>The Department has Centre of Excellence in Process Control and Automation Technologies.
                      <a className="ttm-btn" style={{ paddingLeft: "10px" }} href={process.env.PUBLIC_URL + "/assets/pdf/COE/COE - EIE.pdf"} target="_blank">For More Details Click Here</a>
                    </p><br/>
                    <p>Centre of Excellence is envisioned resource centre, established/working in partnership with Industry to raise training standards, boost productivity, address emerging skill gaps and align training & research with industry needs.</p>
                    <p>The Department has set up Centre of Excellence in Process Control and Automation Technologies with Kharya Solutions to promote best practices to facilitate training and research, solutions to Industry problem and enhance the knowledge and skill set of faculty and students.</p><br />
                  </div>
                  <div className="title-header" style={{ borderTopColor: "red" }}>
                    <h5 className="title">It includes the following areas</h5>
                  </div>
                  <ul className="ttm-list ttm-list-style-icon">
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Embedded System and Internet of Things</span></li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Building Management System</span></li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Process Control and Automation</span></li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Programmable Logic Controller ( PLC )</span></li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Distributed Control System ( DCS )</span></li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Supervisory Control and Data Acquisition ( SCADA)</span></li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Adaptive and Robust Control Systems</span></li>
                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Advanced Instrumentation System</span></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="eie" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }
}