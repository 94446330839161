import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class NewDepartmentSidebar extends Component {
    render() {
        return (
            <div className="widget widget-nav-menu">
                <ul className="widget-menu">
                    <li><NavLink to={process.env.PUBLIC_URL + `/teaching-and-learning`}>Teaching - Learning</NavLink></li>
                    <li><NavLink to={process.env.PUBLIC_URL + `/e-learning-online-courses`}>E - Learning Corner</NavLink></li>
                    <li><NavLink to={process.env.PUBLIC_URL + `/admission-be`}>Admission</NavLink></li>
                    <li><NavLink to={process.env.PUBLIC_URL + `/department-list`}>Departments List</NavLink></li>
                </ul>
            </div>
        )
    }
}

