import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }
    
    td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }
`;