import React, { Component } from "react";
import { Link } from "react-router-dom";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class EIETeaching extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner
          title="Electronics and Instrumentation Engineering"
          image="banners/department-eie.jpg"
        />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <h4 className="title">Learning Resources</h4>
                  </div>
                </div>
                <div className="ttm-service-description">
                  <p>Wide arrays of learning materials are provided to students in advance for each course to catalyze their learning ability. These resources include syllabus, course materials, and links to digital aids. Click here to access learning resources.</p>
                  <Link className="ttm-btn" style={{ fontSize: "1.15rem", paddingTop: "1rem", color: "#00a76a" }} to={process.env.PUBLIC_URL + `/teaching-learning-material/eie`}>Click Here for Learning Resources</Link>
                </div>
                <div className="ttm-service-single-content-area paddingtop-2">
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Teaching - Learning</h4>
                    </div>
                  </div>
                  <div className="ttm-service-description">
                    <p>The teaching and learning process can be defined as a transformation process of knowledge from teachers to students. The research findings will enable the educators to help create and implement an inclusive teaching and learning environment to improve the learner's expectations and academic performance.</p><br />
                    <p>The teaching-learning process is a transaction or a complex cooperative and personal relationship between faculty and students. When viewed from the perspective of the ‘learning paradigm’ rather than the ‘instructional paradigm’, the teaching-learning process is a personal interactive relationship that extends beyond the subject matter.</p><br />
                    <p>Within the interactive relationship faculty, relate to students with dignity and respect, with the expectation that students will be supported and stimulated to develop intellectual integrity and independent judgment.</p><br />
                    <p>The roles of the Teacher are facilitator, guide, coach, and mentor acting in partnership with students.</p><br />
                    <p>The Student roles become those of learner inquirer and seeker of knowledge within an active participate student-faculty relationship.</p>
                    <h6 className="paddingtop-1">Different Learning Methods in Practise at Department level</h6><br />
                    <p>As per the VTU curriculum, teaching-learning methodologies are planned and practised. Based on VTU academic calendar, a calendar of events at the institution level will be planned well in advance before the start of the Semester.</p>
                    <div className="row paddingtop-2">
                      <div className="col-lg-6">
                        <ul className="ttm-list ttm-list-style-icon">
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Lecture methods and Interactive learning</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Experimental learning</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Tutorial classes</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Assignments</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Participative Learning</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Seminar-based learning</span></li>
                        </ul>
                      </div>
                      <div className="col-lg-6">
                        <ul className="ttm-list ttm-list-style-icon">
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Project-based learning</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Industrial Visits</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Seminars/Technical Talk/ Workshops/ Webinars</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Lecture videos (Online lectures)</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Videos of Laboratory Experiments (Online lectures)</span></li>
                          <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Kinesthetic Learning</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="eie" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
