import React, { Component } from 'react';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import ApprovalsSidebar from './components/ApprovalsSidebar';
import Footer from '../../components/common/Footer';
import Loading from '../../components/common/Loading';
import NoData from '../../components/common/NoData';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../Config/FbConfig';

export default class ApprovalsIGUAGE extends Component {

    state = { Datas: [] }

    //Load Data Here
    async componentDidMount() {
        const docRef = doc(firestore, "Accreditation", "IGUAGE");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key }, value))
            });
            this.setState({
                Datas: Data
            })
        } else {
            this.setState({
                Datas: "NO_999"
            })
        }
    }

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Accreditation and Approvals" image="banners/banner-accreditation.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/accreditation/approval_8.png"} alt="single-img-eighteen" />
                                        </div>
                                        <div className="col-md-10" style={{ margin: "auto" }}>
                                            <h3 style={{ color: "#572700" }}>QS I-GUAGE</h3>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description paddingtop-2 justify">
                                        <table class="table">
                                            <tbody>
                                                {
                                                    this.state.Datas.length === 0 ? <Loading /> :
                                                        this.state.Datas === "NO_999" ? <NoData /> :
                                                            this.state.Datas.map((data, i) => (
                                                                <tr key={i}>
                                                                    <th scope="row">{i + 1}</th>
                                                                    <td>{data.Description}</td>
                                                                    <td><a href={data.File} target="_blank" style={{ color: "#98002e", fontWeight: "bold" }}>View Document</a></td>
                                                                </tr>
                                                            ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <ApprovalsSidebar />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
