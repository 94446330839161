import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
    p {
        color        : ${colors.text1};
        overflow    : hidden;
        display     : -webkit-box;
        -webkit-line-clamp: 3;
                line-clamp: 3; 
        -webkit-box-orient: vertical;
        min-height  : 4.5rem;

        @media(max-width: 575px) {

        }
    }

    h6 {
        overflow    : hidden;
        display     : -webkit-box;
        -webkit-line-clamp: 2;
                line-clamp: 2; 
        -webkit-box-orient: vertical;
        min-height  : 3rem;
    }
`;