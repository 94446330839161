import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import Footer from "../../../components/common/Footer";

export default class CEConsultancy extends Component {

  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Civil Engineering" image="banners/department-ce.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Consultancy Services:</h4>
                    </div>
                    <p>Civil Engineering Department offers the following consultancy services:</p>
                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                      <h5 className="title">Topographical Survey</h5>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon justify">
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Preparation of drawings for Residential and Industrial layouts.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Land Survey to fix the boundaries and cadastral mapping.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Detailed Survey for providing sewer lines and water supply lines.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Detailed Survey required for the construction of Lakes.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Survey for Restoration of Tanks.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Detailed Survey for the Construction of irrigation networks.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Setting out centre line and marking for building construction.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Detailed Survey services for the Construction of Highways.</span></li>
                    </ul>
                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                      <h5 className="title">Software Training and Services</h5>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon justify">
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Training Aids :AutoCAD, STAAD PRO, ETABS, ANSYS, QGIS</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Design of Structures and Detailing</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Computer Aided Drafting Services</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Estimation of Buildings and valuation</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Third Party Design Check</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Preparation of Detailed Project Reports (DPR) for Infrastructure projects</span></li>
                    </ul>
                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                      <h5 className="title">Testing of Materials</h5>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon justify">
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Testing of Cubes, Cylinders and beams</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Cement Quality test</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Concrete Mix Design</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Strength tests on: Wood, Tiles, Bricks</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Tensile, Compressive and tension tests on reinforcement steel</span></li>
                    </ul>
                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                      <h5 className="title">Highway Technology Services</h5>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon justify">
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Test on Coarse & Fine Aggregates</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Test on Bitumen</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Job Mix Formula for Bituminous Mixes</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Test on soil (CBR test)</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Pavement evaluation-structural evaluation of pavement </span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Benkelman beam deflection studies (BBD)</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Design of bitumen mixes</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Traffic Volume Estimation</span></li>
                    </ul>
                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Geotechnical Engineering </h4>
                    </div>
                    <div className="title-header" style={{ borderTopColor: "red" }}>
                      <h5 className="title">Laboratory Testing </h5>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon justify">
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Classification of Soils & Index Properties</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Consolidation</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Unconfined Compression</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Triaxial Shear Test</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Direct shear</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Permeability</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Pullout & Interfacial Friction (Geotextiles)</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">CBR</span></li>
                    </ul>
                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                      <h5 className="title">Field Testing</h5>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon justify">
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Standard Penetration Test</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">DCPT</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Subsoil Deformometer</span></li>
                    </ul>
                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                      <h5 className="title">Geotechnical Engineering Design</h5>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon justify">
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Shallow & Mat Foundation</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Deep Foundation</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Ground Improvement</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Earth Retaining Structures</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Slope Stability Analysis</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Post-Failure Investigation</span></li>
                    </ul>
                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                      <h5 className="title">Environmental Engineering</h5>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon justify">
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Physical and Chemical Characteristics of water</span></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="civil" />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
