import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import DepartmentAchievers from "../component/DepartmentAchievers";
import DepartmentNumbers from "../component/DepartmentNumbers";
import DepartmentVideo from "../component/DepartmentVideo";
import Footer from "../../../components/common/Footer";

export default class MEAbout extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Mechanical Engineering" image="banners/department-me.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <DepartmentAchievers department="mech"/>
                                    <DepartmentNumbers
                                        text1="Number of students on roll"
                                        num1="619"
                    
                                        text2="Number of Faculty"
                                        num2="28"
                    
                                        text3="Number of Faculty with Ph.D"
                                        num3="17"
                    
                                        text4="Number of ranks since inception"
                                        num4="30"
                    
                                        text5="Average Placement (%) in last 5 years"
                                        num5="80"
                                        
                                        text6="Number of publications"
                                        num6="200"
                                    />
                                    <div className="ttm-service-description">
                                        <div className="row paddingtop-2">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/departments/mech/about-1.jpg"} title="single-img-twenty" alt="single-img-twenty" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <p>The Department of Mechanical Engineering was established in 1979 with an intake of 70 students, and the department has grown strong over the last 32 years, with the intake rising to 180 to train and develop mechanical engineering professionals who will serve for the betterment of society. The department was affiliated with Bangalore University for a period of 18 years, starting in 1979, and then the affiliation changed to Visvesvaraya Technological University (VTU) in 1997. The Department offers a research programme that includes MSc Engineering by Research and a PhD degree from VTU, and at present, 10 researchers are pursuing Ph.D.</p><br />
                                            </div>
                                        </div>
                                        <div className="row paddingtop-1">
                                            <p>The department has a well-qualified and experienced staff of 28 faculties, of which 17 are Ph.D. holders and another 11 are carrying out research in various topics of mechanical engineering, with an average teaching experience of more than 15 years. The Department is engaged in sponsored research, consultancy, testing, and characterization, primarily in the areas of advanced material development, processing, and design. The department is committed to excelling in the fields of teaching, learning, and research and providing the right echo system for nurturing the budding professional skills of students. The department also has a vision to offer high-quality education, employability, entrepreneurs, and inspiration for higher studies and research.</p>
                                        </div>
                                    </div>
                                </div>
                                <DepartmentVideo department="mech" />
                                <div className="section-title with-desc text-left clearfix">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Vision</h4>
                                        </div>
                                        <p>Mechanical Engineering Department Strive to Transform the Students Technically Educated, Broadly Knowledged and Competent Mechanical Engineers.</p>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Mission</h4>
                                        </div>
                                        <ul className="ttm-list ttm-list-style-icon justify">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Create an Environment as a Center of Learning</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Train the Staff to Ensure the Students to get an Excellent Education at par with Latest Technology.</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Provide an Effective Means of Learning, Advance Knowledge, and Facilitates Personal Commitment to the Educational Success of Students.</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Preparing the Students for Productive Careers Globally.</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Inspire Hope and Contribute to Society by Providing the Best Practices</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Prepares the Students for Research and Advanced Problem Solving Technique Needed to Address Complex Mechanical Engineering Problems.</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Educate Students to become Highly Successful Alumni who contribute to the Profession in the Global Society.</span></li>
                                        </ul>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Programme Educational Objectives (PEOs)</h4>
                                        </div>
                                        <ul className="ttm-list ttm-list-style-icon justify">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To prepare the students for Successful professional careers with strong fundamental knowledge in Science, Mathematics, English and Engineering Sciences so as to enable them to analyse the Mechanical Engineering related problems leading to leadership, entrepreneurship or pursuing higher education (Preparation).</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To develop ability among the students for acquiring technical knowledge in specialized areas of Mechanical Engineering such as Materials, Design, Manufacturing and Thermal Engineering with a focus on research and innovation and gaining the technical skills in classical software packages (Core competence and professionalism).</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To provide opportunities for the students to work with multidiscipline field of engineering so as to enlarge the ability among the students to understand the different industrial environments (Breadth)</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To promote the students for continuous learning, research and development with strong professional, moral and ethical values and zeal for life-long learning. (Learning environment)
                                            </span></li>
                                        </ul>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Program Specific Outcomes (PSOs)</h4>
                                        </div>
                                        <p>A graduate of the Mechanical Engineering Program will have: </p><br />
                                        <ul className="ttm-list ttm-list-style-icon justify">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">PSO - 1: An ability that meets the needs of Industries/ society and the country in general and to synthesize data and technical concepts for application to mechanical engineering software.</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">PSO - 2 : Ability to accept new challenges for multidisciplinary projects with a sound foundation in mathematical, scientific and engineering areas necessary to achieve excellence in solving and analysing engineering problems and to prepare for graduate studies.</span></li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">PSO -3: Ability to promote and develop an ambition of life-long learning and to apply them to professional ethics and codes of professional practices.</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="mech" />
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* Footer */}
                <Footer />
            </div>
        );
    }
}
