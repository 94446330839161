import React, { Component } from "react";
import Banner from "../../../components/common/Banner";
import Header from "../../../components/common/Header";
import DepartmentSidebar from "../component/DepartmentSidebar";
import DepartmentAchievers from "../component/DepartmentAchievers";
import DepartmentNumbers from "../component/DepartmentNumbers";
import DepartmentVideo from "../component/DepartmentVideo";
import Footer from "../../../components/common/Footer";

export default class EIEAbout extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Electronics and Instrumentation Engineering" image="banners/department-eie.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="ttm-service-single-content-area">
                  <DepartmentAchievers department="eie"/>
                  <DepartmentNumbers
                    text1="Number of students on roll"
                    num1="212"

                    text2="Number of faculty"
                    num2="09"

                    text3="Number of faculty with Ph. D"
                    num3="03"

                    text4="Number of ranks since inception"
                    num4="82"

                    text5="Number of graduated batches"
                    num5="38"

                    text6="Average Placement (%) in last 3 years"
                    num6="82"
                  />
                  <div className="ttm-service-description">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="ttm_single_image-wrapper">
                          <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/departments/eie/about-1.jpg"} title="single-img-twenty" alt="single-img-twenty" />
                        </div>
                      </div>
                      <div className="col-md-7 justify" style={{ margin: "auto" }}>
                        <p>The Department of Instrumentation Technology was started in the year 1981-82. Now the department has been renamed as Electronics and Instrumentation Engineering bearing 60 intake. We have a research centre recognised by VTU.</p><br />
                        <p>The main objective of the department is to fulfil the requirement in frontier technology of Electronics and Instrumentation Engineering in the area of Manufacturing, Instrumentation, Process Control, Embedded System, Signal Processing and Industrial Automation. The faculty members have published their research papers in international and</p>
                      </div>
                      <div className="col-md-12 paddingtop-2 justify">
                        <p>national journals and conferences. The department has been continuously imparting standard and quality service to the students for the past 40 years. The department produced 37 batches of undergraduates so far in which 17 batches were under Bangalore University and 20 batches under Visvesvaraya Technological University. The department has been inculcating moral values in addition to the curricular and co-curricular activities. The Research and Development Centre was started in the academic year 2010-2011 to guide and assist the research scholars.</p><br />
                        <p>Our students have secured the first RANK many with Gold Medals times under Bangalore University and VTU Examinations, till date department bagged 77 RANK in University Examination. Ten research scholars of our research centre have been awarded PhD Degree.</p><br />
                        <p>E & IE curriculum incorporates industry-relevant technologies like signal and image processing, Microcontrollers, VLSI Design, Computer Communication Networks, Processing Instrumentation, Control System, Bio-Medical Instrumentation, Virtual Instrumentation, Robotics and Controls etc. the contents of which are updated in compatibility with the changing industrial needs.</p><br />
                        <p>The department has well-equipped to ensure that the students of the department get enough practical experience apart from the theoretical concepts.</p><br />
                        <p>The Centre of Excellence was established to support our students to learn and implement new technologies in the area of Processes Automation and IoT. The placement is about 92%, placed in core companies and MNC.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <DepartmentVideo department="eie" />
                <div className="section-title with-desc text-left clearfix">
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Vision</h4>
                    </div>
                    <p>Graduates of Electronics and Instrumentation Engineering will have clarity of purpose and the solid decision-making abilities to be confident, selective and competitive in managing their careers and further Academic pursuits and to extend their hands for social service.</p>
                  </div>
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Mission</h4>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon">
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To evolve continually as a Centre of Excellence in offering quality in Electronics and Instrumentation Engineering education.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To nurture the students to meet the Global Competency in Industry and Academia.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To provide Reliable, Modern and Integrated Technology to support and facilitate Teaching, Learning, Research and Service.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To promote collaboration with industry and Institute for constructive interaction for better Integration of Theory and Real-Time Applications.</span></li>
                    </ul>
                  </div>
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Programme Educational Objectives (PEOs)</h4>
                    </div>
                    <ul className="ttm-list ttm-list-style-icon">
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">The Program Educational Objectives define future roles for which we are preparing our students by studying four years of undergraduate engineering program. The major objectives for the Department of Electronics and Instrumentation Engineering at Bangalore Institute of Technology are:</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To provide foundation in Mathematical, Scientific, and Electronics and Instrumentation Engineering, necessary to solve Engineering Problems.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To prepare Electronics and Instrumentation Engineering graduates for Industry, Academia and globally employable with Professional Ethics. </span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">The graduates of Electronics and Instrumentation Engineering should be able to use their comprehensive skill set to launch a Carrier in Industry, Research and to become an Entrepreneur.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To provide a knowledge base for creating new Innovative Techniques in Instrumentation and Process Industry with Multidisciplinary projects.</span></li>
                    </ul>
                  </div>
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Program Outcomes (PO)</h4>
                    </div>
                    <p>The Program Outcomes define the knowledge and skills that will be mastered by our graduates. The graduates will have the ability to</p><br />
                    <ul className="ttm-list ttm-list-style-icon">
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"> Engineering knowledge: Apply the knowledge of mathematics, science, engineering fundamentals, and an engineering specialization to the solution of complex engineering problems.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Problem analysis: Identify, formulate, review research literature, and analyze complex engineering problems reaching substantiated conclusions using first principles of mathematics, natural sciences, and engineering sciences.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Design/development of solutions: Design solutions for complex engineering problems and design system components or processes that meet the specified needs with appropriate consideration for the public health and safety, and the cultural, societal, and environmental considerations.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Conduct investigations of complex problems: Use research-based knowledge and research methods including design of experiments, analysis and interpretation of data, and synthesis of the information to provide valid conclusions.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Modern tool usage: Create, select, and apply appropriate techniques, resources, and modern engineering and IT tools including prediction and modeling to complex engineering activities with an understanding of the limitations.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"> The engineer and society: Apply reasoning informed by the contextual knowledge to assess societal, health, safety, legal and cultural issues and the consequent responsibilities relevant to the professional engineering practice.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Environment and sustainability: Understand the impact of the professional engineering solutions in societal and environmental contexts, and demonstrate the knowledge of, and need for sustainable development.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Ethics: Apply ethical principles and commit to professional ethics and responsibilities and norms of the engineering practice.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Individual and team work: Function effectively as an individual, and as a member or leader in diverse teams, and in multidisciplinary settings.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">  Communication: Communicate effectively on complex engineering activities with the engineering community and with society at large, such as, being able to comprehend and write effective reports and design documentation, make effective presentations, and give and receive clear instructions.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content"> Project management and finance: Demonstrate knowledge and understanding of the engineering and management principles and apply these to one’s own work, as a member and leader in a team, to manage projects and in multidisciplinary environments.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">  Life-long learning: Recognize the need for, and have the preparation and ability to engage in independent and life-long learning in the broadest context of technological change.</span></li>
                    </ul>
                  </div>
                  <div className="section-title with-desc text-left clearfix">
                    <div className="title-header paddingtop-2" style={{ borderTopColor: "red" }}>
                      <h4 className="title">Program Specific Outcomes (PSOs)</h4>
                    </div>
                    <p>The Program Specific Outcomes define future roles for which we are preparing our students by studying Electronics and Instrumentation Engineering. The major objectives for the Department of Electronics and Instrumentation Engineering at Bangalore Institute of Technology are:</p><br />
                    <ul className="ttm-list ttm-list-style-icon">
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To provide students with core competence in Electronics and Instrumentation Engineering.</span></li>
                      <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">To provide our graduates with skill development in Process Automation and Control Engineering.</span></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <DepartmentSidebar department="eie" />
              </div>
            </div>
          </div>
        </div>
        
        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
