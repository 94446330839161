import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Banner from '../../../components/common/Banner';
import Header from '../../../components/common/Header';
import DepartmentSidebar from '../component/DepartmentSidebar';
import Footer from '../../../components/common/Footer';

export default class CEPrograms extends Component {
    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Civil Engineering" image="banners/department-ce.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Under Graduate Program</h4>
                                        </div>
                                    </div>
                                    <table className='table'>
                                        <tbody>
                                            <tr>
                                                <th>Bachelor of Engineering</th>
                                                <td>Civil Engineering</td>
                                            </tr>
                                            <tr>
                                                <th>Started in year</th>
                                                <td>1979</td>
                                            </tr>
                                            <tr>
                                                <th>Intake</th>
                                                <td>120 students</td>
                                            </tr>
                                            <tr>
                                                <th>For Admission</th>
                                                <td><Link to={process.env.PUBLIC_URL + "/admission-be"}>click here</Link></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="ttm-service-single-content-area paddingtop-2">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Post Graduate Program</h4>
                                        </div>
                                    </div>
                                    <table className='table'>
                                        <tbody>
                                            <tr>
                                                <th>M. Tech in</th>
                                                <td>Structural Engineering</td>
                                            </tr>
                                            <tr>
                                                <th>Started in year</th>
                                                <td>2003</td>
                                            </tr>
                                            <tr>
                                                <th>Intake</th>
                                                <td>18 students</td>
                                            </tr>
                                            <tr>
                                                <th>For Admission</th>
                                                <td><Link to={process.env.PUBLIC_URL + "/admission-mtech"}>click here</Link></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="ttm-service-single-content-area paddingtop-2">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Ph.D Program</h4>
                                        </div>
                                    </div>
                                    <table className='table'>
                                        <tbody>
                                            <tr>
                                                <th>Name of Ph. D guide</th>
                                                <th>Area of research</th>
                                            </tr>
                                            <tr>
                                                <th>Dr. Aswath M U</th>
                                                <td>Structural Engineering</td>
                                            </tr>
                                            <tr>
                                                <th>Dr. H B Balakrishna</th>
                                                <td>Water Resource, Remote Sensing and GIS, Environmental Engineering</td>
                                            </tr>
                                            <tr>
                                                <th>Dr. H N Jagannatha Reddy</th>
                                                <td>Structural Engineering</td>
                                            </tr>
                                            <tr>
                                                <th>Dr. P M Ravindra</th>
                                                <td>Structural Engineering</td>
                                            </tr>
                                            <tr>
                                                <th>Dr. B S Puttegowda</th>
                                                <td>Concrete technology Structural Engineering</td>
                                            </tr>
                                            <tr>
                                                <th>For Admission</th>
                                                <td><Link to={process.env.PUBLIC_URL + "/admission-phd"}>click here</Link></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar department="civil" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}

