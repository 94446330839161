import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Banner from '../../components/common/Banner';
import Header from '../../components/common/Header';
import Loading from '../../components/common/Loading';
import NoData from '../../components/common/NoData';
import Footer from '../../components/common/Footer';
import { Styles } from "./AlumniStyle.js";
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../Config/FbConfig';

export default class Alumni extends Component {

    state = {
        displayModal: false,
        modalImage: "",
        modalName: "",
        modalDesignation: "",
        modalMsg: "",
        Datas: []
    }

    //Display Modal
    displayModal = (e) => {
        this.setState({
            displayModal: !this.state.displayModal,
        })
    }

    //Display Content
    contentModal = (e) => {
        var result = this.state.Datas.filter((x) => x.Id === e.target.id);
        this.setState({
            displayModal: !this.state.displayModal,
            modalImage: result[0]["Image"],
            modalName: result[0]["Name"],
            modalDesignation: result[0]["Designation"],
            modalMsg: result[0]["Description"]
        })
    }

    //Load Data Here
    async componentDidMount() {
        const docRef = doc(firestore, "Other", "Alumni");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key }, value))
            });
            this.setState({
                Datas: Data
            })
        } else {
            this.setState({
                Datas: "NO_999"
            })
        }
    }

    render() {
        return (
            <div>
                {/* Header */}
                <Header />

                {/* Banner */}
                <Banner title="Alumni" image="banners/banner-alumni.jpg" />

                {/* Alumni Portal */}
                <section className="ttm-row bottomzero-padding-section  ttm-bg ttm-bgimage-yes bg-img5 clearfix" style={{ backgroundColor: "#98002e" }}>
                    <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
                    <div className="container-xl pb-5 res-991-pb-0">
                        <div className="row align-items-center ttm-textcolor-white">
                            <div className="col-lg-5 res-991-text-center">
                                <div className="section-title style3 mb-0 clearfix">
                                    <div className="title-header mb-0">
                                        <h2 className="title ttm-textcolor-white">BIT - <span className="ttm-textcolor-white"> Alumni</span></h2>
                                        <h5 style={{ color: "white" }}>Technology for prosperity</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 res-991-text-center">
                                <div className="title-desc">BIT with a strong history and service in engineering and technological education has global spread network of Alumni.</div>
                            </div>
                            <div className="col-lg-3 text-right res-991-text-center res-991-mt-20">
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white" href="https://www.bit-alumni.org/" target="_blank">Alumni Association</a>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Noted Alumni List */}
                <div className="container-xl" style={{ paddingTop: "4rem" }}>
                    <div className="ttm-service-description">
                        <Styles>
                            <div className="row ttm-service-msg">
                                {
                                    this.state.Datas.length === 0 ? <Loading /> :
                                        this.state.Datas === "NO_999" ? <NoData /> :
                                            this.state.Datas.map((data, i) => (
                                                <div className="col-4 card" style={{ marginBottom: "2rem" }} key={i}>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <a onClick={this.contentModal}><img id={data.Id} className="img-fluid" alt="" src={data.Image} style={{ borderRight: "solid 5px", borderColor: "#98002e" }} /></a>
                                                        </div>
                                                        <div className="col-6" style={{ padding: "1rem" }}>
                                                            <div className="featured-title">
                                                                <h6>{data.Name}</h6>
                                                                <small>{data.Designation}</small>
                                                            </div>
                                                            <div className="post-meta">
                                                                <p>{data.Description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                }
                            </div>
                        </Styles>
                    </div>
                </div>

                {/* Modal */}
                <Modal size="lg" show={this.state.displayModal} onHide={this.displayModal} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-5">
                                <Styles><img className="img-fluid modal-img" alt="" src={this.state.modalImage} /></Styles>
                            </div>
                            <div className="col-7" style={{ padding: "1rem" }}>
                                <div className="featured-title">
                                    <h5 style={{ color: "#98002e" }}>{this.state.modalName}</h5>
                                    <small>{this.state.modalDesignation}</small>
                                </div>
                                <div className="post-meta justify">
                                    <p>{this.state.modalMsg}</p>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Footer */}
                <Footer />
            </div>
        )
    }
}
